import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { Button } from 'primereact/button';
import reduce from 'lodash/reduce';
import EmailInput from 'components/Form/EmailInput';
import TextInput from 'components/Form/TextInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import MultiSelectInput from 'components/Form/MultiSelect';
import { actorItemTemplate, selectedActorItemTemplate } from 'utils/formUtils';
import { CREATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION } from './graphql';

function CreateTeamMemberGroupForm({
  teamMemberActors, refetchTeamMembersAndGroups, hideModal, toastRef,
}) {
  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: {
      displayName: '',
      email: '',
      phoneNumber: '',
      memberActorIds: [],
    },
  });

  const [createTeamMemberGroupMutation] = useMutation(CREATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION, {
    onCompleted: async () => {
      hideModal();
      await refetchTeamMembersAndGroups();
      showSuccessToast(toastRef, 'Successfully created team member group');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const onSubmit = (values) => createTeamMemberGroupMutation({
    variables: { input: { ...values } },
  });

  const mappedTeamMembers = (
    reduce(teamMemberActors, (mapped, actor) => {
      mapped.push({
        label: actor.name, value: actor.id, id: actor.id, initials: actor.initials, avatarUrl: actor.avatarUrl,
      });
      return mapped;
    }, [])
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        control={control}
        name="displayName"
        label="Name"
      />
      <EmailInput
        control={control}
        name="email"
        label="Email"
      />
      <PhoneNumberInput
        control={control}
        name="phoneNumber"
        label="Phone Number"
      />
      <MultiSelectInput
        control={control}
        name="memberActorIds"
        label="Members"
        required={false}
        options={mappedTeamMembers}
        placeholder="Select members"
        inputProps={{
          itemTemplate: (option) => actorItemTemplate({ option }),
          selectedItemTemplate: (value) => selectedActorItemTemplate({ options: mappedTeamMembers, value, placeholder: 'Select members' }),
        }}
      />
      <div className="grid grid-cols-12 mt-4">
        <Button
          className="xxs:col-span-12 md:col-span-4 md:col-start-5"
          size="small"
          label="Create"
          type="submit"
        />
      </div>
    </form>
  );
}

export default CreateTeamMemberGroupForm;
