import gql from 'graphql-tag';

const UPDATE_VENDOR_CONTACTS_MUTATION = gql`
  mutation UpdateVendorContacts($input: VendorUpdateMutationInput!) {
    vendorUpdate(input: $input) {
      vendor {
        id
      }
    }
  }
`;

const VENDOR_CONTACTS_QUERY = gql`
  query VendorContacts {
    currentVendor {
      id
      name
      primaryPointOfContactActor {
        id
        name
        initials
        avatarUrl
      }
      defaultTaskAssigneeActor {
        id
        name
        initials
        avatarUrl
      }
    }
  }
`;

export {
  UPDATE_VENDOR_CONTACTS_MUTATION,
  VENDOR_CONTACTS_QUERY,
};
