import gql from 'graphql-tag';

const EVENTS_MERGE_MUTATION = gql`
  mutation MergeEvents($input: EventsMergeMutationInput!) {
    eventsMerge(input: $input) {
      event {
        id
      }
    }
  }
`;

export {
  EVENTS_MERGE_MUTATION,
};
