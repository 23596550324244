import React, { useRef } from 'react';
import { Tag } from 'primereact/tag';
import { OverlayPanel } from 'primereact/overlaypanel';

import { mergeClassNames, cursorClassName } from 'utils/styleUtils';

function Labels({
  labels, onClick = () => {}, clickable = false, inlineLabelTagClassName = '', labelTotalTagClassName = '',
}) {
  const labelsRef = useRef(null);

  if (!labels?.length) { return null; }

  if (labels.length < 3) {
    return labels.map((label, idx) => (
      <Tag
        onClick={onClick}
        key={`${label.name}-label-tag`}
        style={{ backgroundColor: label.colorHexCode, borderColor: label.colorHexCode }}
        className={mergeClassNames(`${idx === labels.length - 1 ? '' : 'mr-2'} ${inlineLabelTagClassName} ${cursorClassName(clickable)}`)}
        value={label.name}
      />
    ));
  }

  return (
    <>
      <Tag
        onClick={onClick}
        className={labelTotalTagClassName}
        style={{ backgroundColor: labels[0].colorHexCode, borderColor: labels[0].colorHexCode }}
        onMouseEnter={(e) => labelsRef.current.show(e)}
        onMouseLeave={(e) => labelsRef.current.hide(e)}
        value={`${labels.length} Labels`}
      />
      <OverlayPanel ref={labelsRef}>
        <div className="flex flex-col">
          {
            labels.map((label) => (
              <Tag
                onClick={onClick}
                key={`${label.name}-label-tag`}
                className={`mb-1 ${cursorClassName(clickable)}`}
                style={{ backgroundColor: label.colorHexCode }}
                value={label.name}
              />
            ))
          }
        </div>
      </OverlayPanel>
    </>
  );
}

export default Labels;
