import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import AddFileForm from '.';

function AddFileModal({
  files, refetch, toastRef, show, onHide,
}) {
  return (
    <>
      <Toast ref={toastRef} position="bottom-left" />
      <Dialog
        header="Add files to event"
        className="xl:w-7/12"
        visible={show}
        onHide={onHide}
        resizable={false}
        draggable={false}
      >
        <AddFileForm
          refetch={refetch}
          toastRef={toastRef}
          files={files}
        />
      </Dialog>
    </>
  );
}

export default AddFileModal;
