import React from 'react';
import { useController } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { classNames } from 'primereact/utils';

import { mergeClassNames } from 'utils/styleUtils';

function UrlInput(props) {
  const {
    control, name, label, required = true, className, inputClassName = '', controllerProps, inputProps,
  } = props;

  const URL_REGEX = /^((http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      required,
      pattern: URL_REGEX,
    },
    defaultValue: '',
    ...controllerProps,
  });

  const inputClasses = classNames(
    'block',
    'w-full',
    inputClassName,
    {
      'p-invalid': error,
    },
  );

  const renderUrlError = (fieldError) => {
    if (!fieldError) { return null; }

    let errorMessage = '';
    switch (fieldError.type) {
      case 'required':
        errorMessage = 'Url is required';
        break;
      case 'pattern':
        errorMessage = 'Invalid url. E.g. https://www.example.com';
        break;
      default:
        break;
    }

    return <p className="text-danger mt-2">{errorMessage}</p>;
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className="block mb-2">{label}</label> }
      <IconField iconPosition="left">
        <InputIcon className="pi pi-globe" />
        <InputText
          id={name}
          value={value || ''}
          onChange={onChange}
          className={inputClasses}
          {...inputProps}
        />
      </IconField>
      { renderUrlError(error) }
    </div>
  );
}

export default UrlInput;
