import React from 'react';
import { Link } from 'react-router-dom';

function SidebarMenuItem(props) {
  const {
    text,
    icon,
    route,
    toggleSidebarVisible = () => {},
  } = props;

  return (
    <Link to={route} onClick={() => toggleSidebarVisible()}>
      <li className="
        p-ripple flex items-center cursor-pointer p-4 rounded text-gray-700
         hover:bg-gray-200 rounded-md duration-150 transition-colors w-full
      "
      >
        <i className={`mr-2 pi pi-${icon}`} />
        <span>{ text }</span>
      </li>
    </Link>
  );
}

export default SidebarMenuItem;
