import React from 'react';
import { useController } from 'react-hook-form';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function TextAreaInput(props) {
  const {
    control,
    name,
    label,
    required = true,
    className,
    controllerProps,
    inputProps,
    labelClassName = '',
    onChange = () => {},
  } = props;

  const {
    field: { value, onChange: onFieldChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: null,
    ...controllerProps,
  });

  const inputClasses = classNames(
    'block',
    'w-full',
    {
      'p-invalid': error,
    },
  );

  const handleChange = ({ target }) => {
    onFieldChange(target.value);
    onChange(target.value);
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className={mergeClassNames(`block mb-2 ${labelClassName}`)}>{label}</label> }
      <InputTextarea
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        className={inputClasses}
        autoResize
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default TextAreaInput;
