import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import map from 'lodash/map';
import { mapSelectOptions } from 'utils/formUtils';
import AutoComplete from 'components/Form/AutoComplete';
import { HOSTS_SEARCH_QUERY } from './graphql';

function FindOrCreateHostForm(props) {
  const [searchHostPlaceholder, setSearchHostPlaceholder] = useState('Search existing hosts');
  const [hostsSearchResult, setHostsSearchResult] = useState([{ id: null, label: 'Search for a host' }]);
  const {
    multiple = true,
    name = 'hostActors',
    control,
    watch,
    getValues,
    setShowCreateHostModal,
  } = props;

  useEffect(() => {
    if (watch('hostActors').length) { setSearchHostPlaceholder(''); }
  }, [watch('hostActors')]);

  const [
    searchHosts,
  ] = useLazyQuery(
    HOSTS_SEARCH_QUERY,
    {
      onCompleted: ({ hostActorsSearch }) => {
        if (hostActorsSearch.length > 0) {
          setHostsSearchResult(mapSelectOptions({ data: hostActorsSearch, labelFieldName: 'name', valueFieldName: 'id' }));
        } else {
          setHostsSearchResult([{ id: null, label: 'No results found' }]);
        }
      },
    },
  );

  const updateSearchTerm = ({ query }) => {
    searchHosts({ variables: { filters: { searchTerm: query, id: { not: map(getValues('hostActors'), 'value') } } } });
  };

  return (
    <div className="flex items-center md:grid md:grid-cols-2 gap-4">
      <AutoComplete
        multiple={multiple}
        control={control}
        className="mb-0"
        name={name}
        label="Host(s)"
        suggestions={hostsSearchResult}
        completeMethod={updateSearchTerm}
        required={false}
        inputProps={{
          className: 'event-add-modal--hosts-search-input w-full',
          placeholder: searchHostPlaceholder,
        }}
      />
      <Button
        label="Can't find the host you're looking for? Create new host"
        text
        className="create-host-button mt-2 w-full"
        type="button"
        onClick={() => setShowCreateHostModal(true)}
      />
    </div>
  );
}

export default FindOrCreateHostForm;
