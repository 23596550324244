import React from 'react';
import { Dialog } from 'primereact/dialog';
import CreateTeamMemberForm from '.';

function CreateTeamMemberModal({
  show, onHide, refetchEventVendor, toastRef, eventVendor, sendInvite, isManagingCurrentVendor,
}) {
  return (
    <Dialog
      header={sendInvite ? `Create primary point of contact for ${eventVendor.vendor.name}` : 'Create Team Member'}
      className="xl:w-5/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
      maskClassName="backdrop-blur-sm"
      dismissableMask
    >
      <CreateTeamMemberForm
        refetchEventVendor={refetchEventVendor}
        hideModal={onHide}
        toastRef={toastRef}
        eventVendor={eventVendor}
        sendInvite={sendInvite}
        isManagingCurrentVendor={isManagingCurrentVendor}
      />
    </Dialog>
  );
}

export default CreateTeamMemberModal;
