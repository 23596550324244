import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import AutoComplete from 'components/Form/AutoComplete';

import { mapSelectOptions } from 'utils/formUtils';
import { VENDORS_SEARCH_QUERY, VENDOR_DETAILS_QUERY } from './graphql';

function GooglePlacesDropdownInput(props) {
  const {
    control, name, fillVendorInfo, setValue, focus = false, className = '',
  } = props;

  // Downtown Austin, TX
  const DEFAULT_LATITUDE = 30.283070;
  const DEFAULT_LONGITUDE = -97.745430;

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlace, setSelectedPlace] = useState({});
  const [placesResult, setPlacesResult] = useState([{ id: null, label: 'Search for a vendor' }]);

  const [
    searchVendors,
  ] = useLazyQuery(
    VENDORS_SEARCH_QUERY,
    {
      onCompleted: ({ vendorsSearch }) => {
        if (vendorsSearch.length > 0) {
          setPlacesResult(mapSelectOptions({ data: vendorsSearch, labelFieldName: 'name', valueFieldName: 'id' }));
        } else {
          setPlacesResult([{ id: null, label: 'No results found' }]);
        }
      },
    },
  );

  const [
    fetchVendorDetails,
  ] = useLazyQuery(
    VENDOR_DETAILS_QUERY,
    {
      onCompleted: ({ vendorsSearch }) => {
        fillVendorInfo({ vendorInfo: vendorsSearch[0], vendorName: selectedPlace?.label });
      },
    },
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      // User did not accept getting current location
      () => {
        setLatitude(DEFAULT_LATITUDE);
        setLongitude(DEFAULT_LONGITUDE);
      },
    );
  }, []);

  const searchGoogle = (googleSearchTerm) => {
    if (latitude && longitude) {
      searchVendors({ variables: { filters: { googleSearchTerm, latitude, longitude } } });
    }
  };

  useEffect(() => {
    searchGoogle(searchTerm);
  }, [latitude, longitude]);

  const updateSearchTerm = ({ query }) => {
    setSearchTerm(query);
    searchGoogle(query);
  };

  const fillInput = (input) => {
    setSelectedPlace(input);
    setValue('vendorSearch', null);
    fetchVendorDetails({ variables: { filters: { googlePlaceId: input.value } } });
  };

  return (
    <AutoComplete
      focus={focus}
      control={control}
      name={name}
      className={className}
      value={searchTerm}
      suggestions={placesResult}
      completeMethod={updateSearchTerm}
      required={false}
      inputProps={{
        placeholder: 'Search Google',
        onSelect: (event) => fillInput(event.value),
      }}
    />
  );
}

export default GooglePlacesDropdownInput;
