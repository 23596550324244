import React, { useState, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Toast } from 'primereact/toast';
import map from 'lodash/map';
import { showErrorToast } from 'utils/toastUtils';
import UpdateDefaultContactsForm from './DefaultContactsTab/UpdateDefaultContactsForm';
import TeamMemberIndexTable from './TeamMembersTab/IndexTable';
import TeamMemberGroupsIndexTable from './GroupsTab/IndexTable';
import PermissionsIndexTable from './PermissionsTab/IndexTable';
import { TEAM_MEMBER_GROUP_ACTORS_QUERY, TEAM_MEMBER_ACTORS_QUERY } from './graphql';

const PAGE_SIZE = 25;
function TeamMembers({ currentVendorName, tab }) {
  const [teamMemberGroupActors, setTeamMemberGroupActors] = useState([]);
  const [teamMemberActorData, setTeamMemberActorData] = useState(null);
  const [paginationBounds, setPaginationBounds] = useState({ first: PAGE_SIZE });
  const [actors, setActors] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const toastRef = useRef(null);

  const {
    loading: teamMemberGroupActorsLoading, refetch: refetchTeamMemberGroupActors,
  } = useQuery(
    TEAM_MEMBER_GROUP_ACTORS_QUERY,
    {
      onCompleted: ({ currentVendor }) => {
        setTeamMemberGroupActors(currentVendor.teamMemberGroupActors);
      },
      onError: ({ graphQLErrors }) => {
        graphQLErrors.map(({ message }) => (
          showErrorToast(toastRef, `Error fetching team member groups: ${message}`)
        ));
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    refetch: refetchTeamMemberActors,
  } = useQuery(
    TEAM_MEMBER_ACTORS_QUERY,
    {
      variables: { ...paginationBounds, ...{ actorFilters: filters, roleFilters: { resourceType: 'VENDOR' } } },
      onCompleted: ({ currentVendor }) => {
        setTeamMemberActorData(currentVendor.teamMemberActors);
        setActors(map(currentVendor.actors.edges, 'node'));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    },
  );

  const refetchTeamMembersAndGroups = async () => {
    await refetchTeamMemberGroupActors();
    await refetchTeamMemberActors();
  };

  if (!teamMemberActorData || teamMemberGroupActorsLoading) { return null; }

  const tabContent = () => {
    switch (tab) {
      case 'team-members': {
        return (
          <TeamMemberIndexTable
            teamMemberActorData={teamMemberActorData}
            paginationBounds={paginationBounds}
            setPaginationBounds={setPaginationBounds}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={PAGE_SIZE}
            setFilters={setFilters}
            refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
            toastRef={toastRef}
            currentVendorName={currentVendorName}
          />
        );
      }
      case 'contacts': {
        return <UpdateDefaultContactsForm actors={actors} toastRef={toastRef} />;
      }
      case 'groups': {
        return (
          <TeamMemberGroupsIndexTable
            refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
            teamMemberActors={map(teamMemberActorData.edges, 'node')}
            teamMemberGroupActors={teamMemberGroupActors}
            toastRef={toastRef}
          />
        );
      }
      case 'permissions': {
        return (
          <PermissionsIndexTable
            refetchTeamMemberActors={refetchTeamMemberActors}
            toastRef={toastRef}
          />
        );
      }
      default: return null;
    }
  };

  return (
    <>
      <Toast ref={toastRef} position="bottom-left" style={{ zIndex: '1000' }} />
      { tabContent() }
    </>
  );
}

export default TeamMembers;
