import gql from 'graphql-tag';
import UPLOADED_FILE_FIELDS from 'graphql/fragments/uploadedFile';

const UPLOAD_FILES_MUTATION = gql`
  ${UPLOADED_FILE_FIELDS}

  mutation EventUploadedFilesCreate($input: EventUploadedFilesCreateMutationInput!) {
    eventUploadedFilesCreate(input: $input) {
      uploadResults {
        success
        file {
          ...UploadedFileFields
        }
      }
    }
  }
`;

const DELETE_FILE_MUTATION = gql`
  mutation UploadedFileDelete($input: UploadedFileDeleteMutationInput!) {
    uploadedFileDelete(input: $input) {
      id
    }
  }
`;

export {
  UPLOAD_FILES_MUTATION,
  DELETE_FILE_MUTATION,
};
