import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import map from 'lodash/map';

import SubTimeBlockGroup from '.';

function SubTimeBlockGroupModal({
  updatable,
  parentTimeBlock,
  subTimeBlockGroup,
  setShowSubTimeBlockGroupModal,
  updateTimeBlock,
  deleteTimeBlock,
  actorOptions,
  vendorOptions,
  venueOptions,
  teamMemberOptionsForVendorId,
  currentVendor,
  toastRef,
  show,
  onHide,
}) {
  const header = () => <p className="col-span-6">Sub Time Block Group</p>;
  const formatDateTimeValue = (dateTime) => DateTime.fromISO(dateTime).toISO();
  const mapSubTimeBlocks = () => subTimeBlockGroup.subTimeBlocks.map((subTimeBlock) => ({
    id: subTimeBlock.id,
    startDateTime: formatDateTimeValue(subTimeBlock.startTime),
    endDateTime: formatDateTimeValue(subTimeBlock.endTime),
    description: subTimeBlock.description || '',
    participantActors: subTimeBlock.hostActors,
    participantActorIds: map(subTimeBlock.hostActors, 'id'),
  }));

  const {
    control, setValue, watch, getValues,
  } = useForm({
    defaultValues: {
      vendorId: subTimeBlockGroup.vendors.find(({ isVenue }) => !isVenue)?.id,
      venueId: subTimeBlockGroup.vendors.find(({ isVenue }) => isVenue)?.id,
      description: subTimeBlockGroup.description,
      details: subTimeBlockGroup.details,
      attachmentIds: map(subTimeBlockGroup.attachments, 'id'),
      teamMemberActorIds: map(subTimeBlockGroup.teamMemberActors, 'id'),
      subTimeBlocks: mapSubTimeBlocks(),
    },
  });

  const onHideModal = () => {
    onHide();
    setValue('vendorId', currentVendor?.id);
    setValue('venueId', null);
    setValue('description', '');
    setValue('details', '');
    setValue('attachmentIds', []);
    setValue('teamMemberActorIds', []);
    setValue('subTimeBlocks', []);
  };

  return (
    <Dialog
      header={header()}
      id="sub-time-block-group-modal"
      className="xl:w-2/3"
      visible={show}
      onHide={onHideModal}
      draggable={false}
      dismissableMask
      maximizable
      resizable
    >
      <SubTimeBlockGroup
        updateTimeBlock={updateTimeBlock}
        deleteTimeBlock={deleteTimeBlock}
        setShowSubTimeBlockGroupModal={setShowSubTimeBlockGroupModal}
        updatable={updatable || true}
        toastRef={toastRef}
        control={control}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        parentTimeBlock={parentTimeBlock}
        teamMemberOptionsForVendorId={teamMemberOptionsForVendorId}
        subTimeBlockGroup={subTimeBlockGroup}
        actorOptions={actorOptions}
        vendorOptions={vendorOptions}
        venueOptions={venueOptions}
      />
    </Dialog>
  );
}

export default SubTimeBlockGroupModal;
