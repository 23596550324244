import gql from 'graphql-tag';

const SUBTASK_DELETE_MUTATION = gql`
  mutation DeleteSubtask($input: SubtaskDeleteMutationInput!) {
    subtaskDelete(input: $input) {
      id
    }
  }
`;

export {
  SUBTASK_DELETE_MUTATION,
};
