import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { setAuthHeaders } from 'auth/api';

function SetAuthHeaders({ refetchCurrentUserAndVendor }) {
  const navigate = useNavigate();
  /* eslint-disable no-unused-vars */
  const [searchParams, _] = useSearchParams();
  /* eslint-enable no-unused-vars */

  useEffect(() => {
    const authHeaders = {
      'access-token': searchParams.get('auth_token'),
      client: searchParams.get('client_id'),
      uid: searchParams.get('uid'),
      expiry: searchParams.get('expiry'),
      'token-type': 'Bearer',
    };

    setAuthHeaders(authHeaders);

    if (authHeaders.uid) {
      refetchCurrentUserAndVendor();
    } else {
      navigate('/login');
    }
  }, []);

  return null;
}

export default SetAuthHeaders;
