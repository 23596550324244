import gql from 'graphql-tag';
import TIME_BLOCK_FIELDS from 'graphql/fragments/timeBlock';

const EVENT_UPDATE_MUTATION = gql`
  mutation UpdateEvent($input: EventUpdateMutationInput!) {
    eventUpdate(input: $input) {
      event {
        id
        isUpdatableByCurrentActor
        timeZone
      }
    }
  }
`;

const TIMELINE_TIME_BLOCK_CREATE_MUTATION = gql`
  ${TIME_BLOCK_FIELDS}

  mutation CreateTimelineTimeBlock($input: TimelineTimeBlockCreateMutationInput!) {
    timelineTimeBlockCreate(input: $input) {
      timeBlock {
        ...TimeBlockFields
      }
    }
  }
`;

const TIMELINE_TIME_BLOCK_UPDATE_MUTATION = gql`
  ${TIME_BLOCK_FIELDS}

  mutation UpdateTimelineTimeBlock($input: TimelineTimeBlockUpdateMutationInput!) {
    timelineTimeBlockUpdate(input: $input) {
      timeBlock {
        ...TimeBlockFields
      }
    }
  }
`;

const EVENT_VENDORS_QUERY = gql`
  query EventVendors(
    $eventFilters: EventFilterInput,
    $eventHostActorFilters: EventHostActorFilterInput
  ) {
    currentVendor {
      id
      name
    }
    currentActor {
      id
      uuid
      initials
      name
      avatarUrl
      events(filters: $eventFilters) {
        edges {
          node {
            id
            eventHostActors(filters: $eventHostActorFilters) {
              id
              status
              hostActor {
                id
                name
                initials
                avatarUrl
                actorType
                uuid
              }
            }
            eventVendors {
              id
              status
              categories
              primaryPointOfContactActor {
                id
                uuid
              }
              vendor {
                id
                isVenue
                name
                initials
                avatarUrl
              }
              teamMemberActors {
                id
                uuid
                avatarUrl
                initials
                actorType
                name
              }
            }
          }
        }
      }
    }
  }
`;

const TIMELINE_TIME_BLOCK_DELETE_MUTATION = gql`
  mutation DeleteTimelineTimeBlock($input: TimelineTimeBlockDeleteMutationInput!) {
    timelineTimeBlockDelete(input: $input) {
      id
    }
  }
`;

export {
  EVENT_VENDORS_QUERY,
  EVENT_UPDATE_MUTATION,
  TIMELINE_TIME_BLOCK_CREATE_MUTATION,
  TIMELINE_TIME_BLOCK_UPDATE_MUTATION,
  TIMELINE_TIME_BLOCK_DELETE_MUTATION,
};
