import React from 'react';
import { Dialog } from 'primereact/dialog';
import MergeEventsForm from '.';

function MergeEventsFormModal(props) {
  const {
    show,
    onHide,
    mergeableEventOptions,
    toastRef,
  } = props;

  return (
    <Dialog
      className="xl:w-5/12"
      header="Merge Events"
      visible={show}
      onHide={onHide}
      dismissableMask
      resizable={false}
      draggable={false}
    >
      <MergeEventsForm mergeableEventOptions={mergeableEventOptions} onHide={onHide} toastRef={toastRef} />
    </Dialog>
  );
}

export default MergeEventsFormModal;
