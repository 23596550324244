import gql from 'graphql-tag';
import UPLOADED_FILE_FIELDS from 'graphql/fragments/uploadedFile';

const TIME_BLOCK_FIELDS = gql`
  ${UPLOADED_FILE_FIELDS}

  fragment TimeBlockFields on TimelineTimeBlock {
    id
    hostActors {
      id
      name
      uuid
      initials
      avatarUrl
    }
    description
    startTime
    endTime
    details
    attachments {
      ...UploadedFileFields
    }
    vendors {
      id
      isVenue
      categories
      avatarUrl
      initials
      name
    }
    teamMemberActors {
      id
      name
      initials
      avatarUrl
      vendor {
        id
      }
    }
    hostActors {
      id
      name
      initials
      avatarUrl
      vendor {
        id
      }
    }
    isUpdatableByCurrentActor
    subTimeBlocks {
      id
      startTime
      endTime
      details
      description
      isUpdatableByCurrentActor
      vendors {
        id
        isVenue
        categories
        initials
        avatarUrl
        name
      }
      hostActors {
        id
        name
        uuid
        initials
        avatarUrl
      }
      teamMemberActors {
        id
        name
        uuid
        initials
        avatarUrl
      }
      attachments {
        ...UploadedFileFields
      }
      subTimeBlocks {
        id
        startTime
        endTime
        details
        description
        isUpdatableByCurrentActor
        vendors {
          id
          isVenue
          categories
          initials
          name
        }
        hostActors {
          id
          name
          uuid
          initials
          avatarUrl
        }
        attachments {
          ...UploadedFileFields
        }
      }
    }
  }
`;

export default TIME_BLOCK_FIELDS;
