import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import without from 'lodash/without';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import FileInput, { findUploadResult } from 'components/Form/FileInput';
import EditableText from 'components/Form/EditableFields/Text';
import {
  UPLOAD_FILES_MUTATION,
  DELETE_FILE_MUTATION,
  CREATE_FILE_REQUEST_MUTATION,
  UPDATE_FILE_REQUEST_MUTATION,
} from './graphql';

function UploadFileForm({
  fileRequest,
  refetch,
  task,
  onCreate = () => {},
  isSubtaskTemplate,
  templateName,
  setSubtaskUpdated,
  onRemoveEmptyFileRequest,
  toastRef,
  isUpdatableByCurrentActor,
}) {
  const [isEditingTemplateName, setIsEditingTemplateName] = useState(isSubtaskTemplate && !fileRequest.id);
  const [uploadFileResults, setUploadFileResults] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { id: fileRequestId, fileRequested = '' } = fileRequest;
  const {
    control, setValue, getValues, handleSubmit,
  } = useForm({ defaultValues: { fileRequested, templateName } });

  useEffect(() => {
    setValue('templateName', templateName);
  }, [templateName]);

  const [subtaskFileRequestFilesUploadMutation] = useMutation(UPLOAD_FILES_MUTATION, {
    onCompleted: ({ subtaskFileRequestFilesUpload }) => {
      setIsUploading(false);

      const { uploadResults } = subtaskFileRequestFilesUpload;

      const results = uploadFileResults;
      let retriedFile;

      map(uploadResults, (result, idx) => {
        retriedFile = findUploadResult({ results, file: uploadingFiles[idx] });

        if (retriedFile) {
          retriedFile.success = result.success;
          retriedFile.file = result.file;
        } else {
          results.push({
            success: result.success,
            originalFile: uploadingFiles[idx],
            file: result.file,
          });
        }
      });

      const allFailures = results.filter((result) => !result.success);

      const uploadFailures = () => {
        if (retriedFile) {
          return retriedFile.success ? [] : [retriedFile];
        }
        return allFailures;
      };

      const fileMessage = (uploadingFiles.length === 1) ? '1 file' : `${uploadingFiles.length} files`;

      if (uploadFailures().length) {
        showErrorToast(toastRef, `Failed to upload ${fileMessage}. Please try again.`);
      } else {
        showSuccessToast(toastRef, `Successfully uploaded ${fileMessage}!`);
      }

      setUploadFileResults(results);
      setUploadingFiles([]);
      refetch();
    },
    onError: ({ graphQLErrors }) => {
      setUploadingFiles([]);
      setIsUploading(false);

      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const [subtaskFileRequestFileDelete] = useMutation(DELETE_FILE_MUTATION, {
    onCompleted: ({ uploadedFileDelete }) => {
      setUploadFileResults(without(uploadFileResults, (result) => result.file.id === uploadedFileDelete.id));
      refetch();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const [updateFileRequestMutation] = useMutation(UPDATE_FILE_REQUEST_MUTATION, {
    onCompleted: () => { refetch(); },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const [createFileRequestMutation] = useMutation(CREATE_FILE_REQUEST_MUTATION, {
    onCompleted: ({ subtaskFileRequestCreate }) => {
      onCreate(subtaskFileRequestCreate.fileRequest);
      refetch();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const updateFileRequest = () => (
    updateFileRequestMutation({
      variables: {
        input: {
          id: fileRequestId,
          templateName: getValues('templateName'),
          fileRequested: getValues('fileRequested'),
        },
      },
    })
  );

  const createFileRequest = () => {
    if (isSubtaskTemplate && (!getValues('templateName') || !getValues('fileRequested'))) { return null; }

    return createFileRequestMutation({
      variables: {
        input: {
          taskId: task.id,
          isTemplate: isSubtaskTemplate,
          templateName: getValues('templateName'),
          fileRequested: getValues('fileRequested'),
        },
      },
    });
  };

  const onSubmit = ({ files }) => {
    setUploadingFiles(files);
    setIsUploading(true);

    subtaskFileRequestFilesUploadMutation({
      variables: { input: { fileRequestId, files } },
    });
  };

  const removeUploadedFile = ({ fileId }) => {
    subtaskFileRequestFileDelete({
      variables: { input: { id: fileId } },
    });
  };

  const uploadNewOrFailedFiles = ({ files }) => {
    setSubtaskUpdated(true);

    const filesToUpload = files.filter((file) => {
      const uploadResult = findUploadResult({ results: uploadFileResults, file });
      return !uploadResult || !uploadResult.success;
    });

    onSubmit({ files: filesToUpload });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full items-center">
        {
          isSubtaskTemplate && (
            <EditableText
              focus
              className={`w-1/2 ${fileRequestId && !isEditingTemplateName ? 'mb-5' : ''}`}
              textInputClassName="w-1/2 mb-4"
              text={getValues('templateName')}
              label="Template Name"
              isEditing={isEditingTemplateName || !fileRequestId}
              setIsEditing={setIsEditingTemplateName}
              name="templateName"
              updatable={isUpdatableByCurrentActor}
              onUpdate={fileRequestId ? updateFileRequest : createFileRequest}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
          )
        }
        <FileInput
          focus={!isEditingTemplateName}
          showFileRequested
          className="w-full"
          control={control}
          setValue={setValue}
          getValues={getValues}
          isCreating={!fileRequestId}
          onUpdate={fileRequestId ? updateFileRequest : createFileRequest}
          updatable={isUpdatableByCurrentActor}
          name="files"
          titlePlaceholder="File being requested"
          uploadResults={uploadFileResults}
          isUploading={isUploading}
          inputProps={{
            panelClassName: 'w-full',
            inputClassName: 'w-full',
          }}
          onUpload={uploadNewOrFailedFiles}
          uploadDisabled={task.isTemplate || isSubtaskTemplate}
          onRemoveEmptyFileRequest={onRemoveEmptyFileRequest}
          onRemoveUploadedFile={removeUploadedFile}
          alreadyUploadedFiles={fileRequest.files}
        />
      </div>
    </form>
  );
}

export default UploadFileForm;
