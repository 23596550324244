const CATEGORY_BACKGROUND_COLOR_MAPPING = {
  catering: 'bg-orange-500',
  dessert: 'bg-yellow-500',
  bartending: 'bg-cyan-500',
  beauty: 'bg-pink-500',
  photobooth: 'bg-purple-500',
  photography: 'bg-red-500',
  videography: 'bg-teal-500',
  dj: 'bg-lime-500',
  band: 'bg-rose-500',
  planning: 'bg-blue-500',
  transportation: 'bg-indigo-500',
  floristry: 'bg-green-500',
  rental: 'bg-zinc-500',
  venue: 'bg-emerald-500',
  lodging: 'bg-brown-500',
  decor: 'bg-green-500',
  officiant: 'bg-red-500',
  stationary: 'bg-purple-500',
  other: 'bg-primary-500',
  default: 'bg-primary',
};

const CATEGORY_BORDER_COLOR_MAPPING = {
  catering: 'border-orange-500',
  dessert: 'border-yellow-500',
  bartending: 'border-cyan-500',
  beauty: 'border-pink-500',
  photobooth: 'border-purple-500',
  photography: 'border-red-500',
  videography: 'border-teal-500',
  dj: 'border-lime-500',
  band: 'border-rose-500',
  planning: 'border-blue-500',
  transportation: 'border-indigo-500',
  floristry: 'border-green-500',
  rental: 'border-zinc-500',
  venue: 'border-emerald-500',
  lodging: 'border-brown-500',
  decor: 'border-green-500',
  officiant: 'border-red-500',
  stationary: 'border-purple-500',
  other: 'border-primary-500',
  default: 'border-primary',
};

const CATEGORY_TEXT_COLOR_MAPPING = {
  catering: 'text-orange-500',
  dessert: 'text-yellow-500',
  bartending: 'text-cyan-500',
  beauty: 'text-pink-500',
  photobooth: 'text-purple-500',
  photography: 'text-red-500',
  videography: 'text-teal-500',
  dj: 'text-lime-500',
  band: 'text-rose-500',
  planning: 'text-blue-500',
  transportation: 'text-indigo-500',
  floristry: 'text-green-500',
  rental: 'text-zinc-500',
  venue: 'text-emerald-500',
  lodging: 'text-brown-500',
  decor: 'text-green-500',
  officiant: 'text-red-500',
  stationary: 'text-purple-500',
  other: 'text-primary-500',
  default: 'text-primary',
};

export {
  CATEGORY_BACKGROUND_COLOR_MAPPING,
  CATEGORY_BORDER_COLOR_MAPPING,
  CATEGORY_TEXT_COLOR_MAPPING,
};
