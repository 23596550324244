import React from 'react';
import Features from './Features';
import Hero from './Hero';
import Footer from './Footer';

function LandingPage() {
  return (
    <div className="scrollable h-screen">
      <Hero />
      <Features />
      <Footer />
    </div>
  );
}

export default LandingPage;
