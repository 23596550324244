import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import DropdownField from 'components/Form/Dropdown';
import { mapSelectOptions } from 'utils/formUtils';
import { showErrorToast } from 'utils/toastUtils';
import { VENDORS_QUERY, SELECT_VENDOR_MUTATION } from './graphql';

function SelectVendor({ refetchCurrentUserAndVendor }) {
  const toastRef = useRef(null);
  const [vendorOptions, setVendorOptions] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      vendorId: '',
    },
  });

  const [selectVendorMutation] = useMutation(SELECT_VENDOR_MUTATION, {
    onCompleted: () => {
      refetchCurrentUserAndVendor();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error selecting vendor: ${message}`)
      ));
    },
  });

  const onSubmit = ({ vendorId }) => {
    selectVendorMutation({
      variables: { input: { id: vendorId } },
    });
  };

  const {
    loading, error,
  } = useQuery(
    VENDORS_QUERY,
    {
      onCompleted: ({ currentUser }) => {
        const { employerVendors } = currentUser;

        setVendorOptions(mapSelectOptions({ data: employerVendors, labelFieldName: 'name', valueFieldName: 'id' }));
      },
    },
  );

  if (loading || error) return null;

  return (
    <>
      <Card className="w-screen h-screen flex items-center justify-center select-vendor-card bg-primary-gray">
        <div className="w-6/12 flex items-center justify-center bg-primary-gray w-full h-full text-center">
          <div>
            <div className="flex justify-center mb-6">
              <Image imageStyle={{ height: '4rem' }} src="/images/ep-logo.png" alt="Enterplan" />
            </div>
            <p className="text-lg mb-4">Select Vendor</p>
            <form className="flex flex-col justify-center items-center w-full" onSubmit={handleSubmit(onSubmit)}>
              <DropdownField
                searchable={false}
                control={control}
                name="vendorId"
                options={vendorOptions}
                inputProps={{ placeholder: 'Select a vendor', className: 'select-vendor-dropdown' }}
              />
              <Button type="submit" label="Submit" className="w-full" />
            </form>
          </div>
        </div>
      </Card>
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default SelectVendor;
