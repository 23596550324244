import React, { useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import without from 'lodash/without';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { titleize } from 'utils/stringUtils';
import EmailInput from 'components/Form/EmailInput';
import UrlInput from 'components/Form/UrlInput';
import TextInput from 'components/Form/TextInput';
import Dropdown from 'components/Form/Dropdown';
import NumberInput from 'components/Form/NumberInput';
import MultiSelect from 'components/Form/MultiSelect';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import ManageReviewSourcesModal from 'containers/ManageReviewSourcesForm/Modal';
import { US_STATES_QUERY, VENDOR_CATEGORIES_QUERY } from 'graphql/shared';
import { VENDOR_DETAILS_QUERY, VENDOR_UPDATE_MUTATION } from './graphql';

function VendorProfile() {
  const [usStates, setUsStates] = useState([]);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [reviewSourceOptions, setReviewSourceOptions] = useState([]);
  const [editableReviewSources, setEditableReviewSources] = useState([]);
  const [showManageReviewSourcesModal, setShowManageReviewSourcesModal] = useState(false);

  const {
    control, handleSubmit, setValue, getValues,
  } = useForm();

  const {
    usStatesQueryLoading, usStatesQueryError,
  } = useQuery(
    US_STATES_QUERY,
    {
      onCompleted: (data) => setUsStates(data.usStates),
    },
  );

  useQuery(
    VENDOR_CATEGORIES_QUERY,
    {
      onCompleted: (data) => {
        const mappedVendorCategories = reduce(data.vendorCategories, (options, category) => {
          options.push({ label: titleize(category), value: category });
          return options;
        }, []);

        setVendorCategories(mappedVendorCategories);
      },
    },
  );

  const {
    vendorDetailsLoading, vendorDetailsError, refetch,
  } = useQuery(
    VENDOR_DETAILS_QUERY,
    {
      onCompleted: ({ currentVendor }) => {
        const mappedReviewSourceOptions = reduce(currentVendor.reviewSourceOptions, (options, reviewSource) => {
          options.push({ label: reviewSource.name, value: reviewSource.id });
          return options;
        }, []);
        mappedReviewSourceOptions.push({ label: 'Manage Sources', value: 'non-selectable' });
        setReviewSourceOptions(mappedReviewSourceOptions);
        setEditableReviewSources(currentVendor.editableReviewSources);
        setValue('name', currentVendor.name);
        setValue('reviewSourceIds', map(currentVendor.reviewSources, 'id'));
        setValue('avatarUrl', currentVendor.avatarUrl);
        setValue('categories', currentVendor.categories);
        setValue('reviewSourceOptions', currentVendor.reviewSourceOptions);
        setValue('email', currentVendor.email);
        setValue('websiteUrl', currentVendor.websiteUrl);
        setValue('phoneNumber', currentVendor.phoneNumber);
        setValue('instagramHandle', currentVendor.instagramHandle);
        setValue('street1', currentVendor.address?.street1);
        setValue('street2', currentVendor.address?.street2);
        setValue('city', currentVendor.address?.city);
        setValue('state', currentVendor.address?.usState?.id);
        setValue('zipCode', currentVendor.address?.zipCode);
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  if (
    vendorDetailsLoading
    || vendorDetailsError
    || usStatesQueryLoading
    || usStatesQueryError
  ) {
    return null;
  }
  const toastRef = useRef(null);

  const [updateVendorMutation] = useMutation(VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ vendorUpdate }) => {
      setValue('avatarUrl', vendorUpdate.vendor.avatarUrl);
      showSuccessToast(toastRef, 'Successfully updated vendor details!');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  const onSubmit = (values) => {
    const address = {
      street1: values.street1,
      street2: values.street2,
      city: values.city,
      usStateId: values.state,
      zipCode: values.zipCode?.toString(),
    };

    updateVendorMutation({
      variables: {
        input: {
          name: values.name,
          categories: values.categories,
          email: values.email,
          websiteUrl: values.websiteUrl,
          phoneNumber: values.phoneNumber,
          instagramHandle: values.instagramHandle,
          reviewSourceIds: without(values.reviewSourceIds, 'non-selectable'),
          address,
        },
      },
    });
  };

  const avatarUpload = () => (
    <div className="flex flex-col">
      <Avatar image={getValues('avatarUrl')} size="xlarge" shape="circle" className="w-52 h-52" />
      <div className="flex w-full justify-center">
        <FileUpload
          mode="basic"
          chooseOptions={{ icon: 'pi pi-pencil', iconOnly: true, className: '-mt-4 border-circle p-3 button-icon-only' }}
          onSelect={({ files }) => updateVendorMutation({ variables: { input: { avatar: files[0] } } })}
        />
      </div>
    </div>
  );

  const onChange = (item) => {
    if (item.selectedOption.value === 'non-selectable') {
      setShowManageReviewSourcesModal(true);
    }
  };

  return (
    <>
      <p className="font-bold text-xl">Vendor Profile</p>
      <div className="content-with-nav-bar">
        <Card className="flex justify-center">
          <Toast ref={toastRef} position="bottom-left" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full justify-center xxs:flex md:hidden">
              { avatarUpload() }
            </div>
            <div className="flex items-center grid xxs:grid-cols-1 md:grid-cols-2 gap-2">
              <div>
                <TextInput
                  control={control}
                  className="xxs:col-span-12 md:col-span-6 mb-4"
                  name="name"
                  label="Name"
                />
                <MultiSelect
                  control={control}
                  name="categories"
                  label="Categories"
                  className="xxs:col-span-12 md:col-span-6 mb-4"
                  options={vendorCategories}
                  showSelectAll={false}
                  placeholder="Select a category"
                />
                <UrlInput
                  control={control}
                  name="websiteUrl"
                  className="xxs:col-span-12 md:col-span-4 mb-4"
                  label="Website URL"
                  required={false}
                />
                <EmailInput
                  control={control}
                  name="email"
                  className="xxs:col-span-12 md:col-span-4 mb-4"
                  label="Email"
                  required={false}
                />
                <PhoneNumberInput
                  control={control}
                  name="phoneNumber"
                  className="xxs:col-span-12 md:col-span-4 mb-4"
                  label="Phone Number"
                />
                <InstagramHandleInput
                  control={control}
                  name="instagramHandle"
                  className="xxs:col-span-12 md:col-span-4 mb-4"
                  label="Instagram Handle"
                  required={false}
                />
                <MultiSelect
                  control={control}
                  name="reviewSourceIds"
                  label="Review Sources"
                  className="xxs:col-span-12 md:col-span-6 mb-4"
                  options={reviewSourceOptions}
                  placeholder="Select review sources"
                  onChange={onChange}
                  inputProps={{
                    itemClassName: 'review-source-option',
                  }}
                />
              </div>
              <div className="md:flex md:w-full md:justify-center xxs:hidden">
                { avatarUpload() }
              </div>
            </div>
            <p className="font-bold text-md xxs:col-span-12 my-5">Address</p>
            <div className="flex items-center grid xxs:grid-cols-1 md:grid-cols-2 gap-2">
              <TextInput
                control={control}
                name="street1"
                label="Address (line 1)"
                required={false}
              />
              <TextInput
                control={control}
                name="street2"
                label="Address (line 2)"
                required={false}
              />
              <TextInput
                control={control}
                name="city"
                label="City"
                required={false}
              />
              <Dropdown
                control={control}
                name="state"
                label="State"
                options={usStates.map((usState) => ({
                  label: usState.abbreviation,
                  value: usState.id,
                }))}
                required={false}
              />
              <NumberInput
                control={control}
                name="zipCode"
                label="Zip Code"
                required={false}
              />
            </div>
            <div className="grid grid-cols-12 mt-5">
              <Button type="submit" label="Submit" className="xxs:col-span-12 md:col-span-2 md:col-start-6 mx-0" />
            </div>
          </form>
        </Card>
        <ManageReviewSourcesModal
          reviewSources={editableReviewSources}
          show={showManageReviewSourcesModal}
          onHide={() => setShowManageReviewSourcesModal(false)}
          refetch={refetch}
          toastRef={toastRef}
        />
      </div>
    </>
  );
}

export default VendorProfile;
