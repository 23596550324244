import gql from 'graphql-tag';

const VENDOR_DETAILS_QUERY = gql`
  query Vendor {
    currentVendor {
      id
      name
      categories
      email
      websiteUrl
      phoneNumber
      instagramHandle
      avatarUrl
      address {
        id
        street1
        street2
        city
        usState {
          id
          name
        }
        zipCode
      }
      reviewSources {
        id
        name
      }
      reviewSourceOptions {
        id
        name
      }
      editableReviewSources {
        id
        name
        createdByActor {
          id
          initials
          avatarUrl
          name
        }
        lastUpdatedByActor {
          id
          initials
          avatarUrl
          name
        }
      }
    }
  }
`;

const VENDOR_UPDATE_MUTATION = gql`
  mutation UpdateVendor($input: VendorUpdateMutationInput!) {
    vendorUpdate(input: $input) {
      vendor {
        id
        name
        categories
        email
        phoneNumber
        instagramHandle
        avatarUrl
        address {
          id
          street1
          street2
          city
          usState {
            id
            name
          }
          zipCode
        }
      }
    }
  }
`;

export {
  VENDOR_UPDATE_MUTATION,
  VENDOR_DETAILS_QUERY,
};
