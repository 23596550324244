import React from 'react';
import { Image } from 'primereact/image';

function InvalidPage({
  header, subHeader1, subHeader2, imgSrc,
}) {
  return (
    <div className="flex flex-col h-full justify-center invalid-page">
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="text-center lg:text-right mr-0 md:mr-12">
          <div className="mt-12 mb-4 font-bold text-6xl text-900">{ header }</div>
          <div className="mb-12">
            <p className="text-gray-700 text-2xl">{ subHeader1 }</p>
            { subHeader2 && <p className="text-gray-700 text-2xl">{ subHeader2 }</p> }
          </div>
        </div>
        <div>
          <Image imageClassName="rounded" src={imgSrc} />
        </div>
      </div>
    </div>
  );
}

export default InvalidPage;
