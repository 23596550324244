import React, { useRef, useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import { actorItemTemplate } from 'utils/formUtils';
import { timeOptions } from 'constants/selectOptions';
import EditableDropdown from 'components/Form/EditableFields/Dropdown';
import EditableMultiSelect from 'components/Form/EditableFields/MultiSelect';
import EditableTextArea from 'components/Form/EditableFields/TextArea';
import ActorsOrVendorsItem from '../ActorsOrVendorsItem';

function TimeBlock({
  focus = true,
  isSubtaskTemplate,
  timeBlock,
  actorOptions,
  vendorOptions,
  venueOptions,
  onClickCreateSubTimeBlock,
  minimumStartTime,
  maximumEndTime,
  updateTimeBlock,
  deleteTimeBlock,
  updatable,
  control,
  getValues,
  setValue,
  watch,
  setShowTimeBlockModal = () => {},
}) {
  const [isEditing, setIsEditing] = useState(false);
  const defaultTimeOptions = timeOptions({
    startTime: DateTime.fromISO(minimumStartTime),
    endTime: DateTime.fromISO(maximumEndTime),
  });
  const [startTimeSelectOptions, setStartTimeSelectOptions] = useState(defaultTimeOptions);
  const [endTimeSelectOptions, setEndTimeSelectOptions] = useState(defaultTimeOptions);
  const formRef = useRef();

  useEffect(() => {
    const endDateTime = DateTime.fromISO(maximumEndTime);

    setStartTimeSelectOptions(timeOptions({ startTime: DateTime.fromISO(minimumStartTime).startOf('day'), endTime: endDateTime }));
    setEndTimeSelectOptions(timeOptions({ startTime: DateTime.fromISO(watch('startTime') || minimumStartTime), endTime: endDateTime }));
  }, [minimumStartTime, maximumEndTime, watch('startTime'), watch('endTime')]);

  const deleteButton = () => {
    if (!timeBlock) { return null; }

    const confirmDelete = () => {
      setShowTimeBlockModal(false);

      return confirmDialog({
        className: 'xl:w-3/12',
        message: 'Are you sure you want to delete this time block?',
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => deleteTimeBlock({ timeBlockId: timeBlock.id }),
        reject: () => setShowTimeBlockModal(true),
      });
    };

    return (
      <Button
        key={`${timeBlock.id}-delete`}
        className="mr-2"
        type="button"
        onClick={confirmDelete}
        icon="pi pi-trash"
        size="small"
        label="Delete"
        severity="danger"
        aria-label="Delete"
      />
    );
  };

  const onSubmit = async () => {
    const response = await updateTimeBlock({
      startStr: getValues('startTime'),
      endStr: getValues('endTime'),
      details: getValues('details'),
      description: getValues('description'),
      participantActorIds: getValues('participantActorIds'),
      vendorIds: getValues('vendorIds').concat(getValues('venueIds')),
      attachmentIds: getValues('attachmentIds'),
    });

    if (!response?.errors) {
      setIsEditing(false);
    }
  };

  const submitButton = () => (
    <Button
      key={`${timeBlock.id}-submit`}
      type="button"
      onClick={onSubmit}
      size="small"
      label="Submit"
      aria-label="Submit"
    />
  );

  const onClick = (e) => {
    const clickableTarget = String(e.target.className).toLowerCase().match('button|multiselect|mask|svg|header');

    if (updatable && e.target.tagName !== 'IMG' && !clickableTarget) {
      setIsEditing(true);
    }
  };

  const onUpdateDetails = (values) => {
    setValue('details', values.details);
    setValue('attachmentIds', values.attachmentIds);
  };

  const venues = ({ vendors }) => vendors.filter(({ isVenue }) => isVenue);
  const nonVenues = ({ vendors }) => vendors.filter(({ isVenue }) => !isVenue);

  return (
    <form ref={formRef} className="create-time-block-form grid grid-cols-1" onClick={onClick}>
      <EditableTextArea
        focus={focus}
        formats={[]}
        textInputClassName="mt-1"
        textViewClassName="text-with-line-breaks"
        label="Description"
        labelClassName="font-semibold"
        isEditing={isEditing}
        setIsEditing={() => {}}
        name="description"
        updatable={updatable}
        onUpdate={(values) => setValue('description', values.description)}
        control={control}
        setValue={setValue}
        getValues={getValues}
      />
      <div className="flex items-center grid grid-cols-2 gap-2 mt-4">
        <EditableDropdown
          updatable={updatable}
          control={control}
          label="Start Time"
          labelClassName="font-semibold"
          showClose={false}
          inputWrapperClassName="w-full"
          name="startTime"
          value={watch('startTime')}
          setValue={setValue}
          icon="clock"
          options={startTimeSelectOptions}
          isEditing={isEditing}
          setIsEditing={() => {}}
          onUpdate={(newStartTime) => setValue('startTime', newStartTime)}
        />
        <EditableDropdown
          updatable={updatable}
          control={control}
          label="End Time"
          labelClassName="font-semibold"
          inputWrapperClassName="w-full"
          name="endTime"
          showClose={false}
          value={watch('endTime')}
          setValue={setValue}
          icon="clock"
          options={endTimeSelectOptions}
          isEditing={isEditing}
          setIsEditing={() => {}}
          onUpdate={(newEndTime) => setValue('endTime', newEndTime)}
        />
      </div>
      {
        !isSubtaskTemplate && (
          <>
            {
              venueOptions.length > 1 ? (
                <EditableMultiSelect
                  className="mt-4"
                  updatable={updatable}
                  control={control}
                  label="Venues"
                  labelClassName="font-semibold"
                  name="venueIds"
                  value={watch('venueIds')}
                  setValue={setValue}
                  options={venueOptions}
                  isEditing={isEditing}
                  itemTemplate={(option) => actorItemTemplate({ option })}
                >
                  <ActorsOrVendorsItem actorsOrVendors={venues({ vendors: timeBlock.vendors })} showText={false} />
                </EditableMultiSelect>
              ) : null
            }
            <EditableMultiSelect
              className="mt-4"
              updatable={updatable}
              control={control}
              label="Vendors"
              labelClassName="font-semibold"
              name="vendorIds"
              value={watch('vendorIds')}
              setValue={setValue}
              options={vendorOptions}
              isEditing={isEditing}
              itemTemplate={(option) => actorItemTemplate({ option })}
            >
              <ActorsOrVendorsItem actorsOrVendors={nonVenues({ vendors: timeBlock.vendors })} showText={false} />
            </EditableMultiSelect>
            <EditableMultiSelect
              className="mt-4"
              updatable={updatable}
              control={control}
              label="Hosts"
              labelClassName="font-semibold"
              name="participantActorIds"
              value={watch('participantActorIds')}
              setValue={setValue}
              options={actorOptions}
              isEditing={isEditing}
              itemTemplate={(option) => actorItemTemplate({ option })}
            >
              <ActorsOrVendorsItem actorsOrVendors={timeBlock.hostActors} showText={false} />
            </EditableMultiSelect>
          </>
        )
      }
      <div className="mt-4">
        <EditableTextArea
          focus={false}
          updatable={updatable}
          label="Details"
          labelClassName="font-semibold"
          attachmentsDisabled={false}
          attachments={watch('attachments')}
          name="details"
          placeholder="Add details..."
          isEditing={isEditing}
          setIsEditing={() => {}}
          onUpdate={onUpdateDetails}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </div>
      {
        updatable && !isEditing && (
          <div className="flex mt-6 sm:mt-0">
            <Button
              label="Add Sub Time Block Group"
              className="mt-2 text-xs"
              type="button"
              size="small"
              onClick={() => onClickCreateSubTimeBlock({ parentTimeBlockId: timeBlock.id })}
            />
          </div>
        )
      }
      {
        isEditing && updatable && (
          <div className="flex mt-4 sm:mt-0 w-full justify-end">
            <div className="flex submittable-area">
              { deleteButton() }
              { submitButton() }
            </div>
          </div>
        )
      }
    </form>
  );
}

export default TimeBlock;
