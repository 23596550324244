import { classNames } from 'primereact/utils';

/* eslint-disable no-return-assign */
const mergeClassNames = (classNamesStr) => {
  const classHash = {};

  classNamesStr.split(' ').map((str) => str.split(/\s+/g).map((token) => {
    if (token.match(/offset|hidden|col-start|col-end|border|hover|text-nowrap|text/)) {
      return classHash[token] = token;
    }

    return (
      classHash[token.split('-')[0]] = token
    );
  }));

  return Object.values(classHash).sort().join(' ');
};

const cursorClassName = (clickable) => classNames({ 'cursor-pointer': clickable });

export {
  mergeClassNames,
  cursorClassName,
};
/* eslint-disable no-return-assign */
