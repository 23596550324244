import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router';
import { showErrorToast } from 'utils/toastUtils';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import TextInput from 'components/Form/TextInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import PasswordInput from 'components/Form/PasswordInput';
import PasswordConfirmationInput from 'components/Form/PasswordConfirmationInput';
import { acceptInvitation } from 'auth/api';

function CreateAccountForm({ currentActor, refetchCurrentUserAndVendor, onFormSubmit }) {
  const toastRef = useRef(null);

  const { invitationToken } = currentActor;

  if (!invitationToken) {
    return <Navigate to="/app/dashboard" />;
  }

  const {
    control, handleSubmit, getValues,
  } = useForm({
    defaultValues: {
      firstName: currentActor.firstName,
      lastName: currentActor.lastName,
      phoneNumber: currentActor.phoneNumber,
      instagramHandle: currentActor.instagramHandle,
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = async ({
    firstName, lastName, phoneNumber, instagramHandle, password, passwordConfirmation,
  }) => {
    const { errors } = await acceptInvitation({
      firstName, lastName, phoneNumber, instagramHandle, password, passwordConfirmation, invitationToken,
    });

    if (errors) {
      errors.map((error) => showErrorToast(toastRef, `Error creating account: ${error}`));
    } else {
      onFormSubmit();
      refetchCurrentUserAndVendor();
    }
  };

  return (
    <div className="flex justify-center mt-8">
      <Toast ref={toastRef} position="bottom-left" />
      <div className="w-full grid grid-cols-12">
        <p className="text-center font-normal text-xl mt-12 col-span-12">Welcome to Enterplan!</p>
        <p className="text-center font-normal text-lg col-span-12">Tell us about yourself.</p>
        <div className="w-full col-span-8 mt-6 col-start-3">
          <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-12 col-span-10 col-start-2 mt-6 gap-4">
            <TextInput
              control={control}
              className="xxs:col-span-12 md:col-span-6"
              name="firstName"
              label="First Name"
            />
            <TextInput
              control={control}
              name="lastName"
              className="xxs:col-span-12 md:col-span-6"
              label="Last Name"
            />
            <PhoneNumberInput
              control={control}
              name="phoneNumber"
              className="xxs:col-span-12 md:col-span-6"
              label="Your Phone Number"
            />
            <InstagramHandleInput
              control={control}
              name="instagramHandle"
              className="xxs:col-span-12 md:col-span-6"
              label="Your Instagram Handle"
              required={false}
            />
            <PasswordInput
              control={control}
              feedback
              className="xxs:col-span-12 md:col-span-6"
              name="password"
              label="Password"
            />
            <PasswordConfirmationInput
              control={control}
              className="xxs:col-span-12 md:col-span-6"
              getValues={getValues}
            />
            <Button type="submit" label="Submit" size="small" className="xxs:col-span-12 md:col-span-2 md:col-start-6 mt-4" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateAccountForm;
