import React, { useRef } from 'react';
import { Avatar } from 'primereact/avatar';
import { Fieldset } from 'primereact/fieldset';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';
import { Tag } from 'primereact/tag';
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import flatten from 'lodash/flatten';
import moment from 'moment';
import { DateTime } from 'luxon';

import AvatarWithName from 'components/AvatarWithName';
import { titleize } from 'utils/stringUtils';
import { generateRandomKey } from 'utils/keyUtils';

const UPDATABLE_TYPE_TO_CHANGED_DISPLAY_NAME = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  STATUS: 'status',
  DUE_DATE: 'due date',
  ASSIGNED_TO_ACTOR_ID: 'assignee',
  PARTICIPANT_ACTOR_ID: 'participant',
  TASK_LABEL_ID: 'label',
  CHECKLIST_TITLE: 'checklist title',
  CHECKLIST_ITEM_BODY: 'checklist item',
  CHECKLIST_ITEM_COMPLETED_STATUS: 'checklist item',
  QUESTION_COMPLETED_STATUS: 'question',
  FILE_REQUEST_COMPLETED_STATUS: 'file request',
  CHECKLIST_COMPLETED_STATUS: 'checklist',
  MENTION_MENTIONED_ACTOR_ID: 'mention',
  QUESTION_BODY: 'question',
  COMMENT_BODY: 'comment',
  ANSWER_BODY: 'answer',
  FILE_REQUEST_FILE_REQUESTED: 'file request',
  FILE_REQUEST_FILE_NAME: 'file request file',
  TIME_BLOCK_START_DATE_TIME: 'time block start time',
  TIME_BLOCK_END_DATE_TIME: 'time block end time',
  TIME_BLOCK_DESCRIPTION: 'time block description',
  TIME_BLOCK_DETAILS: 'details',
  TIME_BLOCK_COMPLETED_STATUS: 'time block',
};
const UPDATABLE_TYPE_TO_ADDED_OR_REMOVED_DISPLAY_NAME = {
  ...UPDATABLE_TYPE_TO_CHANGED_DISPLAY_NAME,
  CHECKLIST_TITLE: 'checklist',
  TIME_BLOCK_DESCRIPTION: 'time block',
};
function ActivityLog({ activityLog, labels, actors }) {
  const itemsRef = useRef([]);

  /* eslint-disable consistent-return */
  const formatChange = ({ updatableType, change }) => {
    switch (updatableType) {
      case 'TITLE':
      case 'DESCRIPTION':
      case 'CHECKLIST_ITEM_BODY':
      case 'COMMENT_BODY':
      case 'ANSWER_BODY':
      case 'TIME_BLOCK_DETAILS':
      case 'FILE_REQUEST_FILE_NAME':
        return <b className="mx-1">{flatten([change]).join(', ')}</b>;
      case 'CHECKLIST_ITEM_COMPLETED_STATUS':
      case 'QUESTION_COMPLETED_STATUS':
      case 'FILE_REQUEST_COMPLETED_STATUS':
      case 'TIME_BLOCK_COMPLETED_STATUS':
      case 'CHECKLIST_COMPLETED_STATUS':
        if (change !== 'incomplete') { return; }

        return <b className="mx-1 text-red-500">{change}</b>;
      case 'DUE_DATE':
        return (
          <div className="flex items-center mx-1">
            <b>
              <i className="pi pi-clock text-xs mr-1" />
              {moment(change).format('M/D/YY')}
            </b>
          </div>
        );
      case 'STATUS':
        return (
          <Badge
            className={`status-badge status-${change}-bg max-w-max mx-1 rounded-md`}
            value={titleize(change)}
          />
        );
      case 'ASSIGNED_TO_ACTOR_ID':
      case 'PARTICIPANT_ACTOR_ID':
      case 'MENTION_MENTIONED_ACTOR_ID': {
        return flatten([change]).map((c) => {
          const actor = actors.find((a) => a.value === c);
          const avatarBadgeClassNames = classNames(
            'justify-end',
            'font-semibold',
            'border-secondary',
            'text-xs',
            'p-chip',
            'bg-secondary',
            'text-white',
            'border',
            'py-1',
            'px-2',
            'mx-1',
            'rounded-md',
          );

          return (
            <AvatarWithName
              id={actor.id}
              textClassName="text-xs"
              initials={actor.initials}
              avatarUrl={actor.avatarUrl}
              text={actor.name}
              subtext={actor.vendor?.name}
              className={avatarBadgeClassNames}
              avatarClassName="border text-xs bg-white text-secondary border-secondary"
              size="small"
            />
          );
        });
      }
      case 'CHECKLIST_TITLE':
        return flatten([change]).map((c) => (
          <Tag
            key={`${generateRandomKey()}-activity-log-checklist-title-tag`}
            className="mx-1"
            icon="pi pi-list-check"
            value={c}
          />
        ));
      case 'TIME_BLOCK_DESCRIPTION':
        return flatten([change]).map((c) => (
          <Tag
            key={`${generateRandomKey()}-activity-log-time-block-title-tag`}
            className={`mx-1 ${c ? '' : 'tag-icon-only'}`}
            icon="pi pi-clock"
            value={c}
          />
        ));
      case 'TIME_BLOCK_START_DATE_TIME':
      case 'TIME_BLOCK_END_DATE_TIME':
        return flatten([change]).map((c) => (
          <Tag
            key={`${generateRandomKey()}-activity-log-time-block-title-tag`}
            className="mx-1"
            icon="pi pi-clock"
            value={DateTime.fromISO(c).toFormat('h:mm a')}
          />
        ));
      case 'FILE_REQUEST_FILE_REQUESTED':
        return flatten([change]).map((c) => (
          <Tag
            key={`${generateRandomKey()}-activity-log-file-request-file-tag`}
            className="mx-1"
            icon="pi pi-file"
            value={c}
          />
        ));
      case 'QUESTION_BODY':
        return flatten([change]).map((c) => (
          <Tag
            key={`${generateRandomKey()}-activity-log-question-body-tag`}
            className="mx-1"
            icon="pi pi-question"
            value={c}
          />
        ));
      case 'TASK_LABEL_ID':
        return flatten([change]).map((c) => {
          const label = labels.find((l) => l.id === c);

          if (!label) { return; }

          return (
            <Tag
              key={`${label.id}-activity-log-label-tag`}
              className="ml-1"
              value={label.name}
              style={{ backgroundColor: label.colorHexCode }}
            />
          );
        });
      default:
    }
  };
  /* eslint-enable consistent-return */

  /* eslint-disable no-return-assign */
  const addedOrRemovedChangeText = ({
    changes, actionType, displayName, updatableType, parentItem, idx,
  }) => {
    let iconClassName;
    let textColorClassName;
    let parentItemText;

    if (actionType === 'added') {
      iconClassName = 'pi-plus';
      textColorClassName = 'text-green-500';
      parentItemText = 'to';
    } else {
      iconClassName = 'pi-minus';
      textColorClassName = 'text-red-500';
      parentItemText = 'from';
    }

    const display = changes.length > 1 ? `${displayName}s` : displayName;

    return (
      changes.length > 3 ? (
        <>
          <div
            className="flex items-center cursor-pointer"
            onMouseEnter={(e) => itemsRef.current[idx].show(e)}
            onMouseLeave={(e) => itemsRef.current[idx].hide(e)}
          >
            <i className={`pi ${iconClassName} mr-2 ${textColorClassName} text-xs`} />
            {`${titleize(actionType)} ${changes.length} ${display}`}
            {
              parentItem?.value && (
                <>
                  {` ${parentItemText} `}
                  {formatChange({ updatableType: parentItem.updatableType, change: parentItem.value })}
                </>
              )
            }
          </div>
          <OverlayPanel ref={(el) => (itemsRef.current[idx] = el)}>
            <div className="flex flex-col">
              {
                changes.map((change) => (
                  <span key={generateRandomKey()} className="mb-1">{formatChange({ updatableType, change })}</span>
                ))
              }
            </div>
          </OverlayPanel>
        </>
      ) : (
        <>
          <i className={`pi ${iconClassName} mr-2 ${textColorClassName} text-xs`} />
          {`${titleize(actionType)} ${display}: `}
          {formatChange({ updatableType, change: changes })}
          {
            parentItem?.value && (
              <>
                {` ${parentItemText} `}
                {formatChange({ updatableType: parentItem.updatableType, change: parentItem.value })}
              </>
            )
          }
        </>
      )
    );
  };
  /* eslint-enable no-return-assign */

  /* eslint-disable consistent-return */
  const customActionText = ({ updatableType, displayName, updatedTo }) => {
    switch (updatableType) {
      case 'CHECKLIST_ITEM_COMPLETED_STATUS':
      case 'CHECKLIST_COMPLETED_STATUS':
      case 'QUESTION_COMPLETED_STATUS':
      case 'TIME_BLOCK_COMPLETED_STATUS':
      case 'FILE_REQUEST_COMPLETED_STATUS': {
        if (updatedTo === 'complete') {
          return (
            <div className="flex items-center">
              <i className="pi pi-chevron-right mr-2 text-purple-500 text-xs" />
              <p><span className="text-green-500">Completed</span>{` ${displayName}`}</p>
            </div>
          );
        }
        break;
      }
      case 'ARCHIVED_STATUS': {
        const iconClassNames = classNames(
          'pi',
          { 'pi-trash text-red-500': updatedTo === 'archived' },
          { 'pi-chevron-right text-green-500': updatedTo === 'unarchived' },
          'text-xs',
        );

        return (
          <div className="flex items-center">
            <i className={iconClassNames} />
            <p className="mx-1">
              {titleize(updatedTo)} task
            </p>
          </div>
        );
      }
      case 'MENTION_READ_STATUS': {
        return (
          <div className="flex items-center">
            <i className="pi pi-chevron-right mr-2 text-purple-500 text-xs" />
            <p className="mr-1">Read mention</p>
          </div>
        );
      }
      default:
    }
  };
  /* eslint-enable consistent-return */

  /* eslint-disable react/jsx-no-useless-fragment */
  const changeText = ({
    updatedFrom, updatedTo, added, removed, updatableType, subjectItem, parentItem,
  }, idx) => {
    let displayName = UPDATABLE_TYPE_TO_ADDED_OR_REMOVED_DISPLAY_NAME[updatableType];
    const customText = customActionText({ updatableType, displayName, updatedTo });

    if (customText) {
      return (
        <>
          { customText }
          {
            subjectItem?.value && (
              <>
                {formatChange({ updatableType: subjectItem.updatableType, change: subjectItem.value })}
              </>
            )
          }
          {
            parentItem?.value && (
              <>
                {' on '}
                {formatChange({ updatableType: parentItem.updatableType, change: parentItem.value })}
              </>
            )
          }
        </>
      );
    }

    if (added?.length) {
      return addedOrRemovedChangeText({
        changes: added, actionType: 'added', displayName, updatableType, subjectItem, parentItem, idx,
      });
    } if (removed?.length) {
      return addedOrRemovedChangeText({
        changes: removed, actionType: 'removed', displayName, updatableType, subjectItem, parentItem, idx,
      });
    } if (!updatedFrom) {
      return (
        <>
          <i className="pi pi-chevron-right mr-2 text-purple-500 text-xs" />
          {`Set ${displayName}`}
          {
            subjectItem?.value && (
              <>
                {formatChange({ updatableType: subjectItem.updatableType, change: subjectItem.value })}
              </>
            )
          }
          {' to '}
          {formatChange({ updatableType, change: updatedTo })}
          {
            parentItem?.value && (
              <>
                {' on '}
                {formatChange({ updatableType: parentItem.updatableType, change: parentItem.value })}
              </>
            )
          }
        </>
      );
    } if (!updatedTo) {
      return <>{<i className="pi pi-minus mr-2 text-red-500 text-xs" />}{`Removed ${displayName}`}</>;
    }

    displayName = UPDATABLE_TYPE_TO_CHANGED_DISPLAY_NAME[updatableType];

    return (
      <>
        <i className="pi pi-arrow-right-arrow-left mr-2 text-blue-500 text-xs" />
        {`Updated ${displayName}`}
        { updatedFrom !== updatedTo && <>{' from '}{formatChange({ updatableType, change: updatedFrom })}{' to '}</> }
        {formatChange({ updatableType, change: updatedTo })}
        {
          parentItem?.value && (
            <>
              {' on '}
              {formatChange({ updatableType: parentItem.updatableType, change: parentItem.value })}
            </>
          )
        }
      </>
    );
  };

  /* eslint-enable react/jsx-no-useless-fragment */

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 mt-4 task-activity-log px-0 overflow-auto">
        <Fieldset>
          {
          activityLog.map(({
            id, updatedByActor, action, updatedAt, changes,
          }, idx) => {
            const isFirstItem = !idx;
            const isLastItem = idx === activityLog.length - 1;
            const activityLogItemContainerClassNames = classNames(
              'flex',
              'items-start',
              'justify-between',
              { 'py-4': !isFirstItem && !isLastItem },
              { 'pt-0 pb-4': isFirstItem && !isLastItem },
              { 'pt-4 pb-0': isLastItem && !isFirstItem },
              { 'border-gray-200 border-b': !isLastItem },
            );

            return (
              <div key={`activity-log-item-${id}`} className={activityLogItemContainerClassNames}>
                <div>
                  <div className="flex items-center mb-3">
                    <Avatar
                      key={`${id}-activity-log-item-avatar`}
                      image={updatedByActor.avatarUrl}
                      label={updatedByActor.initials}
                      shape="circle"
                      className="mr-2 bg-info text-white h-8 w-8"
                    />
                    <div className="flex flex-col">
                      <p>{updatedByActor.name}</p>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="mr-2 h-8 w-8" />
                    <div>
                      {
                        changes.map((change, index) => {
                          const itemClassNames = classNames(
                            'text-sm',
                            'flex',
                            'items-center',
                            'text-nowrap',
                            { 'mt-2': index },
                          );

                          return <div key={generateRandomKey()} className={itemClassNames}>{changeText(change, `${idx}-${index}`)}</div>;
                        })
                      }
                      {
                        isLastItem && action === 'CREATE' && (
                          <div className="flex items-center text-sm mt-1">
                            <i className="pi pi-plus-circle mr-2 text-green-500 text-xs" />
                            <p>Created task</p>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
                <Tooltip target={`.activity-log-${id}-updated-at-tooltip`} />
                <p
                  className={`block text-slate-500 font-medium text-nowrap activity-log-${id}-updated-at-tooltip`}
                  data-pr-tooltip={moment(updatedAt).format('M/D/YY h:mma')}
                  data-pr-position="top"
                >
                  { moment(updatedAt).fromNow() }
                </p>
              </div>
            );
          })
        }
        </Fieldset>
      </div>
    </div>
  );
}

export default ActivityLog;
