import gql from 'graphql-tag';

const CURRENT_VENDOR_AND_ACTOR_DETAILS_QUERY = gql`
  query CurrentVendorAndActorDetails {
    currentVendor {
      id
      name
      initials
      avatarUrl
    }
    currentActor {
      id
      name
      initials
      avatarUrl
    }
  }
`;

export {
  CURRENT_VENDOR_AND_ACTOR_DETAILS_QUERY,
};
