import React from 'react';
import { classNames } from 'primereact/utils';
import { Tag } from 'primereact/tag';

function StatusTag({
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  onClick = null,
  status,
  text = null,
  icon = null,
  border = true,
  show = true,
}) {
  if (status === 'APPROVED') { return null; }

  let tagTextColorClass = null;
  let tagBorderColorClass = null;
  let hoverBgClass = null;
  let tagLabel = '';
  let tagIcon = null;

  const defaultOnClick = () => {};

  switch (status) {
    case 'ADDED':
      tagTextColorClass = 'text-blue-500';
      tagBorderColorClass = 'border-blue-500';
      hoverBgClass = 'hover:bg-blue-50';
      tagLabel = 'Send Invite';
      tagIcon = 'send';
      break;
    case 'REQUESTED':
      tagTextColorClass = 'text-purple-500';
      tagBorderColorClass = 'border-purple-500';
      hoverBgClass = 'hover:bg-purple-50';
      tagLabel = 'Requested to Join';
      tagIcon = 'plus-circle';
      break;
    case 'INVITED':
      tagTextColorClass = 'text-blue-500';
      tagBorderColorClass = 'border-blue-500';
      hoverBgClass = 'hover:bg-blue-50';
      tagLabel = 'Invite Pending';
      tagIcon = 'envelope';
      break;
    case 'REJECTED':
      tagTextColorClass = 'text-orange-500';
      tagBorderColorClass = 'border-orange-500';
      hoverBgClass = 'hover:bg-orange-50';
      tagLabel = 'Invite Rejected';
      tagIcon = 'times';
      break;
    case 'REMOVED':
      tagTextColorClass = 'text-red-500';
      tagBorderColorClass = 'border-red-500';
      hoverBgClass = 'hover:bg-red-50';
      tagLabel = 'Invite Rejected';
      tagLabel = 'Removed from Event';
      tagIcon = 'trash';
      break;
    default:
      break;
  }

  const tagClassNames = classNames(
    { hidden: !show },
    tagTextColorClass,
    tagBorderColorClass,
    'cursor-pointer',
    '[&:not(:hover)]:bg-white',
    { [`${hoverBgClass}`]: onClick },
    { border: border || status },
  );

  return (
    <Tag
      className={tagClassNames}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick || defaultOnClick}
      icon={`pi pi-${icon || tagIcon}`}
      value={text || tagLabel}
    />
  );
}

export default StatusTag;
