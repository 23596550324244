import React from 'react';
import { Tooltip } from 'primereact/tooltip';

import AvatarWithName from 'components/AvatarWithName';

export default function ActorsOrVendorsItem({ actorsOrVendors, showText = true }) {
  return (
    <div className="flex items-center">
      {
        actorsOrVendors?.length ? (
          actorsOrVendors.map((actorOrVendor) => {
            const actorOrVendorName = actorOrVendor.name;
            const tooltip = actorOrVendor.vendor
              ? `${actorOrVendorName} (${actorOrVendor.vendor.name})`
              : actorOrVendorName;

            return (
              <span key={`${actorOrVendor.id}-avatar`}>
                <Tooltip key={`${actorOrVendor.id}-avatar-tooltip`} target={`.avatar-tooltip-${actorOrVendor.id}`} />
                <AvatarWithName
                  key={`${actorOrVendor.id}-avatar`}
                  image={actorOrVendor.avatarUrl}
                  initials={actorOrVendor.initials}
                  label={actorOrVendor.initials}
                  tooltip={showText ? null : tooltip}
                  text={showText ? actorOrVendor.name : null}
                  shape="circle"
                  className={`avatar-tooltip-${actorOrVendor.id}`}
                />
              </span>
            );
          })
        ) : (
          <p className="text-sm">None set</p>
        )
      }
    </div>
  );
}
