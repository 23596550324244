import Quill from 'quill';

const BaseImage = Quill.import('formats/image');

class Image extends BaseImage {
  static create(value) {
    return super.create(value);
  }
}

export default Image;
