import gql from 'graphql-tag';
import UPLOADED_FILE_FIELDS from 'graphql/fragments/uploadedFile';
import TIME_BLOCK_FIELDS from 'graphql/fragments/timeBlock';

const SUBTASK_TEMPLATE_FETCH_QUERY = gql`
  ${UPLOADED_FILE_FIELDS}
  ${TIME_BLOCK_FIELDS}

  query FetchSubtaskTemplate($filters: SubtaskFilterInput) {
    currentActor {
      id
      subtaskTemplates(filters: $filters) {
        id
        subtaskType
        templateName
        question {
          id
          body
          attachments {
            ...UploadedFileFields
          }
        }
        fileRequest {
          id
          fileRequested
          files {
            ...UploadedFileFields
          }
        }
        checklist {
          id
          title
          items {
            id
            body
            completed
          }
        }
        timeBlock {
          ...TimeBlockFields
        }
        createdByActor {
          id
          name
          initials
          avatarUrl
        }
        lastUpdatedByActor {
          id
          name
          initials
          avatarUrl
        }
      }
    }
  }
`;

export {
  SUBTASK_TEMPLATE_FETCH_QUERY,
};
