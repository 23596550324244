import React from 'react';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

const formatDateTimeStr = (dateTimeStr, format) => DateTime.fromISO(dateTimeStr).toFormat(format);

const titleize = (string) => (
  string
    .toLowerCase()
    .replace(/^[-_\s]*(.)/, (_, character) => character.toUpperCase())
    .replace(/[-_\s]+(.)/g, (_, character) => ` ${character.toUpperCase()}`)
);

const formatPhoneNumber = (phoneNumber) => phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

const emailLink = ({
  email, showType = 'text', className = '', iconClassName = '', shouldLink = true, showEmpty = false, showIcon = false,
}) => {
  if (!email) {
    if (showEmpty) {
      return (
        <div className={showIcon ? 'flex items-center justify-center' : null}>
          { showIcon ? <i className={`pi pi-envelope mr-1 ${iconClassName}`} /> : null }
          <p className="italic text-xs">Add Email</p>
        </div>
      );
    }
    return null;
  }

  return (
    <a className={className} target="_blank" href={shouldLink ? `mailto:${email}` : null} rel="noreferrer">
      {
        showType === 'text' ? (
          <div className={showIcon ? 'flex items-center justify-center' : null}>
            { showIcon ? <i className={`pi pi-envelope mr-1 ${iconClassName}`} /> : null }
            <p>{email}</p>
          </div>
        ) : (
          <Button
            text
            rounded
            icon="pi pi-envelope"
            tooltip={email}
            tooltipOptions={{ position: 'top' }}
          />
        )
      }
    </a>
  );
};

const urlLink = ({
  url, showType = 'text', className = '', iconClassName = '', shouldLink = true, showEmpty = false, showIcon = false,
}) => {
  if (!url) {
    if (showEmpty) {
      return (
        <div className={showIcon ? 'flex items-center justify-center' : null}>
          { showIcon ? <i className={`pi pi-globe mr-1 ${iconClassName}`} /> : null }
          <p className="italic text-xs">Add URL</p>
        </div>
      );
    }
    return null;
  }

  return (
    <a className={className} target="_blank" href={shouldLink ? url : null} rel="noreferrer">
      {
        showType === 'text' ? (
          <div className={showIcon ? 'flex items-center justify-center' : null}>
            { showIcon ? <i className={`pi pi-envelope mr-1 ${iconClassName}`} /> : null }
            <p>{url}</p>
          </div>
        ) : (
          <Button
            text
            rounded
            icon="pi pi-globe"
            tooltip={url}
            tooltipOptions={{ position: 'top' }}
          />
        )
      }
    </a>
  );
};

const phoneLink = ({
  phone, showType = 'text', className = '', iconClassName = '', buttonClassName = '', shouldLink = true, showEmpty = false, showIcon = false,
}) => {
  if (!phone) {
    if (showEmpty) {
      return (
        <div className={showIcon ? 'flex items-center justify-center' : null}>
          { showIcon ? <i className={`pi pi-phone mr-1 ${iconClassName}`} /> : null }
          <p className="italic text-xs">Add Phone Number</p>
        </div>
      );
    }
    return null;
  }

  return (
    <a className={className} target="_blank" href={shouldLink ? `tel:${phone}` : null} rel="noreferrer">
      {
        showType === 'text' ? (
          <div className={showIcon ? 'flex items-center justify-center' : null}>
            { showIcon ? <i className={`pi pi-phone mr-1 ${iconClassName}`} /> : null }
            <p>{formatPhoneNumber(phone)}</p>
          </div>
        ) : (
          <Button
            className={buttonClassName}
            text
            rounded
            icon="pi pi-phone"
            tooltip={formatPhoneNumber(phone)}
            tooltipOptions={{ position: 'top' }}
          />
        )
      }
    </a>
  );
};
const instagramLink = ({
  instagramHandle,
  showType = 'text',
  className = '',
  iconClassName = '',
  buttonClassName = '',
  shouldLink = true,
  showEmpty = false,
  showIcon = false,
}) => {
  if (!instagramHandle) {
    if (showEmpty) {
      return (
        <div className={showIcon ? 'flex items-center justify-center' : null}>
          { showIcon ? <i className={`pi pi-instagram mr-1 ${iconClassName}`} /> : null }
          <p className="italic text-xs">Add Instagram Handle</p>
        </div>
      );
    }
    return null;
  }

  return (
    <a
      className={className}
      target="_blank"
      href={shouldLink ? `https://www.instagram.com/${instagramHandle.replace('@', '')}` : null}
      rel="noreferrer"
    >
      {
        showType === 'text' ? (
          <div className={showIcon ? 'flex items-center justify-center' : null}>
            { showIcon ? <i className={`pi pi-instagram mr-1 ${iconClassName}`} /> : null }
            <p>{instagramHandle}</p>
          </div>
        ) : (
          <Button
            className={buttonClassName}
            text
            rounded
            icon="pi pi-instagram"
            tooltip={instagramHandle}
            tooltipOptions={{ position: 'top' }}
          />
        )
      }
    </a>
  );
};

export {
  titleize,
  emailLink,
  urlLink,
  phoneLink,
  instagramLink,
  formatDateTimeStr,
};
