import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import kebabCase from 'lodash/kebabCase';

import MultiSelect from 'components/Form/MultiSelect';
import GroupedMultiSelect from 'components/Form/GroupedMultiSelect';
import { cursorClassName } from 'utils/styleUtils';

function EditableMultiSelect({
  label,
  labelClassName = '',
  inputClassName = '',
  tooltip = null,
  grouped = false,
  name,
  value,
  setValue,
  options,
  isEditing,
  control,
  updatable,
  className = '',
  children,
  itemTemplate,
}) {
  const onChange = (values) => {
    setValue(name, values.value);
  };

  return (
    <div className={`${className} ${cursorClassName(updatable)}`}>
      {
        label ? (
          <div className="flex items-center mb-1">
            <p className={labelClassName}>{label}</p>
            {
              tooltip && (
                <>
                  <Tooltip target={`.${kebabCase(name)}-item`} />
                  <i
                    className={`pi pi-info-circle ${kebabCase(name)}-item text-xs ml-1`}
                    data-pr-tooltip={tooltip}
                    data-pr-position="top"
                  />
                </>
              )
            }
          </div>
        ) : null
      }
      {
        isEditing ? (
          <div className="flex items-center">
            {
              grouped ? (
                <GroupedMultiSelect
                  className={`${inputClassName} mb-0`}
                  control={control}
                  name={name}
                  value={value}
                  options={options}
                  placeholder="Select..."
                  inputProps={{
                    itemTemplate,
                    onChange,
                  }}
                />
              ) : (
                <MultiSelect
                  className={`${inputClassName} mb-0`}
                  control={control}
                  name={name}
                  value={value}
                  options={options}
                  placeholder="Select..."
                  inputProps={{
                    itemTemplate,
                    onChange,
                  }}
                />
              )
            }
          </div>
        ) : children
      }
    </div>
  );
}

export default EditableMultiSelect;
