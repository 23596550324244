import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useController } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';

import { mergeClassNames } from 'utils/styleUtils';
import { renderFormFieldError } from 'utils/formUtils';

function DropdownField(props) {
  const {
    control,
    name,
    tooltip = null,
    label = null,
    searchable = true,
    placeholder = '',
    labelClassName = '',
    required = true,
    options,
    className = '',
    inputClassName = '',
    controllerProps,
    inputProps,
    onChange = () => {},
  } = props;

  const {
    field: { value, onChange: onFieldChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: null,
    ...controllerProps,
  });

  const dropdownClasses = classNames(
    { 'p-invalid': error },
    'items-center',
    inputClassName,
  );

  const handleChange = (data) => {
    onFieldChange(data.value);
    onChange(data.value);
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      {
        (label || tooltip) && (
        <div className="flex items-center mb-2">
          { label && <label htmlFor={name} className={`block ${labelClassName}`}>{label}</label> }
          {
            tooltip && (
              <>
                <Tooltip target={`.dropdown-tooltip-icon-${name}`} />

                <i
                  className={`dropdown-tooltip-icon-${name} pi pi-${tooltip.icon} ml-1`}
                  data-pr-tooltip={tooltip.message}
                  data-pr-position="top"
                  style={{ cursor: 'pointer' }}
                />
              </>
            )
          }
        </div>
        )
      }
      <Dropdown
        id={name}
        filter={searchable}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        className={dropdownClasses}
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default DropdownField;
