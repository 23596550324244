import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { mapSelectOptions } from 'utils/formUtils';
import { showErrorToast } from 'utils/toastUtils';
import CreateHostModal from 'containers/Events/CreateHostForm/Modal';
import FindOrCreateHostForm from 'containers/Events/CreateHostForm/FindOrCreateHostForm';
import { EVENT_HOST_ACTOR_CREATE_MUTATION } from './graphql';

function FindOrCreateHostModal(props) {
  const [showCreateHostModal, setShowCreateHostModal] = useState(false);
  const {
    show,
    onHide,
    eventId,
    toastRef,
    hostActors,
    refetchEventOverview,
  } = props;

  const {
    control, handleSubmit, setValue, getValues, watch, reset,
  } = useForm({
    defaultValues: { hostActors: mapSelectOptions({ data: hostActors, labelFieldName: 'name', valueFieldName: 'id' }) },
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const [addHostToEvent] = useMutation(EVENT_HOST_ACTOR_CREATE_MUTATION, {
    onCompleted: async () => {
      onClose();
      await refetchEventOverview();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(() => showErrorToast(toastRef, 'Error adding host to event!'));
    },
  });

  const onSubmit = (values) => {
    addHostToEvent({
      variables: {
        input: {
          eventId,
          hostActorId: values.hostActor.value,
        },
      },
    });
  };

  return (
    <>
      {
        !showCreateHostModal && (
          <Dialog
            className="xl:w-5/12"
            header="Add Host"
            visible={show}
            onHide={onClose}
            dismissableMask
            resizable={false}
            draggable={false}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FindOrCreateHostForm
                multiple={false}
                name="hostActor"
                control={control}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
                setShowCreateHostModal={setShowCreateHostModal}
              />
              <div className="w-full flex justify-end mt-2">
                <Button
                  label="Add"
                  size="small"
                  type="submit"
                />
              </div>
            </form>
          </Dialog>
        )
      }
      <CreateHostModal
        show={showCreateHostModal}
        onHide={() => setShowCreateHostModal(false)}
        toastRef={toastRef}
        afterCreate={({ id }) => addHostToEvent({ variables: { input: { hostActorId: id, eventId } } })}
      />
    </>
  );
}

export default FindOrCreateHostModal;
