import gql from 'graphql-tag';

const CREATE_TEAM_MEMBER_ACTOR_MUTATION = gql`
  mutation CreateActor($input: TeamMemberActorCreateMutationInput!) {
    teamMemberActorCreate(input: $input) {
      teamMemberActor {
        id
        roles {
          id
          name
        }
        initials
        avatarUrl
        name
        firstName
        lastName
        email
        phoneNumber
        instagramHandle
        teamMemberUser {
          id
          inviteStatus
          accountHolder
          isCurrentUser
        }
      }
    }
  }
`;

export {
  CREATE_TEAM_MEMBER_ACTOR_MUTATION,
};
