import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import map from 'lodash/map';
import without from 'lodash/without';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import FileInput, { findUploadResult } from 'components/Form/FileInput';
import {
  UPLOAD_FILES_MUTATION,
  DELETE_FILE_MUTATION,
} from './graphql';

function AddFileForm({
  refetch,
  toastRef,
  files,
}) {
  const [uploadFileResults, setUploadFileResults] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const { control } = useForm();
  const { eventId } = useParams();

  useEffect(() => {
    setUploadedFiles(
      files.filter((file) => uploadFileResults.find((uploadFileResult) => uploadFileResult.file.id === file.id)),
    );
  }, [files]);

  const [uploadedFilesUploadMutation] = useMutation(UPLOAD_FILES_MUTATION, {
    onCompleted: ({ eventUploadedFilesCreate }) => {
      setIsUploading(false);

      const { uploadResults } = eventUploadedFilesCreate;

      const results = uploadFileResults;
      let retriedFile;

      map(uploadResults, (result, idx) => {
        retriedFile = findUploadResult({ results, file: uploadingFiles[idx] });

        if (retriedFile) {
          retriedFile.success = result.success;
          retriedFile.file = result.file;
        } else {
          results.push({
            success: result.success,
            originalFile: uploadingFiles[idx],
            file: result.file,
          });
        }
      });

      const allFailures = results.filter((result) => !result.success);

      const uploadFailures = () => {
        if (retriedFile) {
          return retriedFile.success ? [] : [retriedFile];
        }
        return allFailures;
      };

      const fileMessage = (uploadingFiles.length === 1) ? '1 file' : `${uploadingFiles.length} files`;

      if (uploadFailures().length) {
        showErrorToast(toastRef, `Failed to upload ${fileMessage}. Please try again.`);
      } else {
        showSuccessToast(toastRef, `Successfully uploaded ${fileMessage}!`);
      }

      setUploadFileResults(results);
      setUploadingFiles([]);
      refetch();
    },
    onError: ({ graphQLErrors }) => {
      setUploadingFiles([]);
      setIsUploading(false);

      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const [uploadedFileDeleteMutation] = useMutation(DELETE_FILE_MUTATION, {
    onCompleted: ({ uploadedFileDelete }) => {
      setUploadFileResults(without(uploadFileResults, (result) => result.file.id === uploadedFileDelete.id));
      refetch();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const onSubmit = (data) => {
    setUploadingFiles(data.files);
    setIsUploading(true);

    uploadedFilesUploadMutation({
      variables: { input: { eventId, files: data.files } },
    });
  };

  const removeUploadedFile = ({ fileId }) => {
    uploadedFileDeleteMutation({
      variables: { input: { id: fileId } },
    });
  };

  const uploadNewOrFailedFiles = (data) => {
    const filesToUpload = data.files.filter((file) => {
      const uploadResult = findUploadResult({ results: uploadFileResults, file });
      return !uploadResult || !uploadResult.success;
    });

    onSubmit({ files: filesToUpload });
  };

  return (
    <FileInput
      focus
      control={control}
      name="files"
      className="w-full"
      updatable
      uploadResults={uploadFileResults}
      isUploading={isUploading}
      inputProps={{
        panelClassName: 'w-full',
        inputClassName: 'w-full',
      }}
      onUpload={uploadNewOrFailedFiles}
      onUpdate={refetch}
      onRemoveUploadedFile={removeUploadedFile}
      alreadyUploadedFiles={uploadedFiles}
    />
  );
}

export default AddFileForm;
