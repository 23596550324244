import React from 'react';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';

function Hero() {
  return (
    <div>
      <header className="py-4">
        <div className="mx-auto max-w-screen-xl xxs:px-4 md:px-8">
          <div className="flex items-center justify-between">
            <div className="flex-shrink-0">
              <Image imageStyle={{ height: '2rem' }} src="/images/ep-logo.png" alt="Enterplan" />
            </div>

            <div className="flex md:ml-16 items-center justify-center">
              <a href="/login" title="" className="text-gray-900">Login</a>
              <a href="/sign-up" className="text-white">
                <Button className="max-w-max text-nowrap ml-6 text-white xxs:hidden xs:block" label="Create free account" />
              </a>
            </div>
          </div>
        </div>
      </header>

      <section className="py-12 mt-32 md:pb-36">
        <div className="mx-auto xxs:px-8 md:px-8 max-w-screen-xl">
          <div className="grid md:grid-cols-5 sm:grid-cols-1 gap-6 items-center sm:gap-y-20">
            <div className="sm:text-center md:col-span-2 sm:col-span-1 md:text-left md:px-2 md:mr-4 md:px-0">
              <div className="max-w-full mx-auto sm:max-w-md">
                <h1 className="text-7xl font-semibold leading-tight ">Say hello to the future of event planning</h1>
              </div>

              <p className="mt-4 text-2xl ">Start collaborating with other event vendors to build organized and successful events</p>
              <div className="mt-8 sm:flex sm:items-center sm:justify-center md:justify-start md:mt-12">
                <a
                  href="/sign-up"
                  className="inline-flex items-center px-8 py-4 text-md font-bold text-white bg-primary border border-transparent rounded-xl"
                  role="button"
                >
                  Get started
                </a>
              </div>
            </div>

            <div className="sm:col-span-1 md:col-span-3">
              <Image imageStyle={{ height: '36rem' }} src="/images/product-preview.svg" alt="Product Preview" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hero;
