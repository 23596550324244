import React, { useRef, useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { classNames } from 'primereact/utils';

import { renderFormFieldError } from 'utils/formUtils';
import { generateRandomKey } from 'utils/keyUtils';
import EditableText from 'components/Form/EditableFields/Text';
import { mergeClassNames } from 'utils/styleUtils';
import FileItem from './FileItem';

export const findUploadResult = ({ results, file }) => results.find((r) => r.originalFile === file);
function FileInput(props) {
  const {
    focus = true,
    showFileRequested = false,
    name,
    control,
    setValue = () => {},
    getValues = () => {},
    onRemoveEmptyFileRequest = () => {},
    updatable = false,
    onUpdate = () => {},
    onUpload,
    onRemoveUploadedFile,
    uploadResults,
    alreadyUploadedFiles = [],
    multiple = true,
    uploadDisabled = false,
    required = true,
    titlePlaceholder = null,
    className,
    controllerProps,
    isCreating = false,
    isUploading,
  } = props;
  const fileUploadRef = useRef(null);
  const [isEditing, setIsEditing] = useState(isCreating);

  useEffect(() => {
    fileUploadRef.current.setFiles([]);
  }, [alreadyUploadedFiles]);

  const {
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: null,
    ...controllerProps,
  });

  const onTemplateRemove = (file, callback) => { callback(); };
  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'file-choose-btn p-button-rounded p-button-outlined' };

  const headerTemplate = (options) => {
    const { className: defaultClassName, chooseButton } = options;

    const headerClassNames = classNames(
      defaultClassName,
      'flex',
      'justify-between',
      'items-center',
      'pr-2',
      { 'py-4': uploadDisabled },
      { 'py-2': !uploadDisabled },
    );

    return (
      <>
        <div className={headerClassNames}>
          {
            showFileRequested && (
              <EditableText
                focus={focus}
                className="w-6/12"
                textInputClassName="w-5/6"
                text={getValues('fileRequested')}
                isEditing={isEditing || isCreating}
                setIsEditing={setIsEditing}
                name="fileRequested"
                updatable={updatable}
                onUpdate={onUpdate}
                onClear={onRemoveEmptyFileRequest}
                control={control}
                setValue={setValue}
                getValues={getValues}
                placeholder={titlePlaceholder}
              />
            )
          }
          <div>
            { !uploadDisabled && chooseButton}
          </div>
        </div>
        { isUploading && <ProgressBar mode="indeterminate" /> }
      </>
    );
  };

  const itemTemplate = (file, { onRemove }) => {
    const uploadResult = findUploadResult({ results: uploadResults, file });

    let onFileRemove;
    let failedFile;

    if (uploadResult) {
      if (uploadResult.success) {
        onFileRemove = () => {
          onRemoveUploadedFile({ fileId: uploadResult.file.id });
          onTemplateRemove(file, onRemove);
        };
      } else {
        failedFile = file;
        onFileRemove = () => onTemplateRemove(file, onRemove);
      }
    }

    return (
      <FileItem
        key={`${generateRandomKey()}-file-item`}
        file={file}
        onUpdate={onUpdate}
        failedFile={failedFile}
        onUpload={onUpload}
        isUploading={isUploading}
        onRemove={onFileRemove}
      />
    );
  };

  const mappedAlreadyUploadedFiles = () => (
    alreadyUploadedFiles.map((file) => (
      <FileItem
        key={file.id}
        uploadResult={{ success: true }}
        file={file}
        isUploading={isUploading}
        onUpdate={onUpdate}
        onUpload={onUpload}
        onRemove={() => {
          onRemoveUploadedFile({ fileId: file.id });
        }}
      />
    ))
  );

  const emptyTemplate = () => {
    const iconClassNames = classNames(
      { 'pi pi-image  border-2': !uploadDisabled },
      { 'fa-regular fa-images': uploadDisabled },
      'mt-4',
      'p-4',
      'text-2xl',
      'border-gray',
      'leading-none',
      'rounded-full',
      'text-gray-500',
    );

    if (alreadyUploadedFiles.length) {
      return mappedAlreadyUploadedFiles();
    }
    return (
      <div className="flex items-center flex-col">
        <i className={iconClassNames} />
        <span className="my-4 text-gray-500 text-lg">
          {`${uploadDisabled ? 'Files will be uploaded here' : 'Drag and Drop Files Here'}`}
        </span>
      </div>
    );
  };

  /* eslint-disable react/jsx-no-useless-fragment */
  const progressBarTemplate = <></>;
  /* eslint-enable react/jsx-no-useless-fragment */

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      <FileUpload
        id={name}
        ref={fileUploadRef}
        multiple={multiple}
        auto
        chooseOptions={chooseOptions}
        customUpload
        uploadHandler={onUpload}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        progressBarTemplate={progressBarTemplate}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default FileInput;
