import React, { useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { Toast } from 'primereact/toast';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { titleize } from 'utils/stringUtils';
import CommentCard from './CommentCard';

function Comments({
  mentionables = [],
  comments = [],
  commentableId = null,
  commentType = 'comment',
  currentActor,
  focus = false,
  showAccordion = true,
  refetch = () => {},
}) {
  const toastRef = useRef(null);

  const commentsHeader = () => {
    if (isEmpty(comments)) {
      return `Add ${titleize(commentType)}`;
    }
    return `${capitalize(commentType)}s (${comments.length})`;
  };

  const commentCards = () => (
    comments.map((comment) => (
      <CommentCard
        key={`${comment.id}-comment`}
        comment={comment}
        commentType={commentType}
        currentActorId={currentActor.id}
        updatable={comment.updatable}
        mentionables={mentionables}
        refetch={refetch}
        toastRef={toastRef}
      />
    ))
  );

  const body = () => (
    <>
      <CommentCard
        focus={focus}
        comment={{ body: '', createdByActor: currentActor, attachments: [] }}
        commentType={commentType}
        commentableId={commentableId}
        currentActorId={currentActor.id}
        updatable
        mentionables={mentionables}
        refetch={refetch}
        toastRef={toastRef}
      />
      { commentCards() }
    </>
  );

  if (showAccordion) {
    const expandIcon = isEmpty(comments) ? 'pi pi-plus' : 'pi pi-chevron-right';

    return (
      <>
        <Accordion activeIndex={isEmpty(comments) ? null : 0} className={`${commentType}-accordion text-sm`} expandIcon={expandIcon}>
          <AccordionTab header={commentsHeader()}>{body()}</AccordionTab>
        </Accordion>
        <Toast ref={toastRef} position="bottom-left" />
      </>
    );
  }
  return (
    <>
      {body()}
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default Comments;
