import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import CreateTimeBlockForm from '.';

function CreateTimeBlockModal({
  minimumStartTime,
  createTimeBlock,
  actorOptions,
  vendorOptions,
  venueOptions,
  eventVendors,
  show,
  showDelete,
  onHide,
  timeline,
}) {
  const header = () => <p className="col-span-6">Create Time Block</p>;
  const startTime = DateTime.fromISO(timeline.startTime);

  const {
    control, handleSubmit, setValue, watch, getValues,
  } = useForm({
    defaultValues: {
      startTime: startTime.toISO(),
      endTime: null,
      description: '',
      details: '',
      attachmentIds: [],
      participantActorIds: [],
      venueIds: [eventVendors.find(({ vendor }) => vendor.isVenue)?.vendor?.id],
      vendorIds: [],
    },
  });

  useEffect(() => {
    setValue('venueIds', [eventVendors.find(({ vendor }) => vendor.isVenue)?.vendor?.id]);
  }, [venueOptions]);

  const onHideModal = () => {
    onHide();
    setValue('startTime', startTime.toISO());
    setValue('endTime', startTime.plus({ minutes: 15 }).toISO());
    setValue('description', '');
    setValue('details', '');
    setValue('attachmentIds', []);
    setValue('participantActorIds', []);
    setValue('venueIds', []);
    setValue('vendorIds', []);
  };

  return (
    <Dialog
      header={header()}
      className="xl:w-1/3"
      visible={show}
      onHide={onHideModal}
      draggable={false}
      dismissableMask
      maximizable
      resizable
    >
      <CreateTimeBlockForm
        minimumStartTime={minimumStartTime}
        createTimeBlock={createTimeBlock}
        actorOptions={actorOptions}
        vendorOptions={vendorOptions}
        venueOptions={venueOptions}
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        onHideModal={onHideModal}
        showCancel={false}
        showDelete={showDelete}
        eventVendors={eventVendors}
      />
    </Dialog>
  );
}

export default CreateTimeBlockModal;
