import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { showErrorToast, showSuccessToast } from 'utils/toastUtils';
import Dropdown from 'components/Form/Dropdown';
import { EVENTS_MERGE_MUTATION } from './graphql';

function MergeEventsForm({ mergeableEventOptions, onHide, toastRef }) {
  const { eventId } = useParams();
  const {
    control, handleSubmit,
  } = useForm({
    defaultValues: { eventIdToMerge: mergeableEventOptions[0]?.id },
  });

  const [mergeEvents] = useMutation(EVENTS_MERGE_MUTATION, {
    onCompleted: () => {
      showSuccessToast(toastRef, 'Merged!');
      onHide();
    },
    onError: () => {
      showErrorToast(toastRef, 'Error merging event!');
    },
    refetchQueries: ['EventOverview'],
  });

  const onSubmit = ({ eventIdToMerge }) => {
    mergeEvents({
      variables: {
        input: {
          eventIdToMergeInto: eventId,
          eventIdToMerge,
        },
      },
    });
  };

  return (
    <>
      <p>
        We found some of your events that are happening on the same day. Do you want to merge any of them into this one?
        Merging events will move all activity from one event into the other, including tasks, timeblocks, and vendor information.
        The merged event will no longer be accessible.
        <span className="font-semibold">{' This action cannot be undone.'}</span>
      </p>
      <form className="flex flex-col md:flex-row grid grid-cols-12 justify-start items-center" onSubmit={handleSubmit(onSubmit)}>
        <Dropdown
          searchable={false}
          className="col-span-12 md:col-span-8 p-0 mt-2 md:mr-2"
          control={control}
          name="eventIdToMerge"
          options={mergeableEventOptions}
          inputProps={{ placeholder: 'Select an event to merge' }}
        />
        <div className="col-span-12 md:col-span-4 mt-1 md:mt-2 p-0 md:p-2 flex justify-end grid grid-cols-12">
          <Button type="submit" label="Merge into current event" className="col-span-12 text-nowrap" />
        </div>
      </form>
    </>
  );
}

export default MergeEventsForm;
