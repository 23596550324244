import gql from 'graphql-tag';

const US_STATES_QUERY = gql`
  query UsStates {
    usStates {
      id
      name
      abbreviation
    }
  }
`;

const VENDOR_CATEGORIES_QUERY = gql`
  query vendorCategories {
    vendorCategories
  }
`;

export {
  US_STATES_QUERY,
  VENDOR_CATEGORIES_QUERY,
};
