import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import TextInput from 'components/Form/TextInput';
import Dropdown from 'components/Form/Dropdown';
import NumberInput from 'components/Form/NumberInput';
import { isSubmittableArea } from 'utils/formUtils';
import { US_STATES_QUERY } from 'graphql/shared';
import { EVENT_VENDOR_UPDATE_MUTATION } from '../graphql';

function UpdateEventVendorAddress({
  toastRef, vendor, eventVendor, setIsEditing, refetchEventVendor,
}) {
  const [usStates, setUsStates] = useState([]);
  const formRef = useRef();

  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      street1: vendor.address?.street1,
      street2: vendor.address?.street2,
      city: vendor.address?.city,
      state: vendor.address?.usState?.id,
      zipCode: vendor.address?.zipCode,
    },
  });

  const [updateEventVendorMutation] = useMutation(EVENT_VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ eventVendorUpdate }) => {
      refetchEventVendor();
      showSuccessToast(toastRef, `Successfully updated vendor ${eventVendorUpdate.eventVendor.vendor.name}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (isSubmittableArea(formRef, e)) {
        if (isDirty) {
          const address = {
            street1: getValues('street1'),
            street2: getValues('street2'),
            city: getValues('city'),
            usStateId: getValues('state'),
            zipCode: getValues('zipCode')?.toString(),
          };

          await updateEventVendorMutation({
            variables: {
              input: {
                id: eventVendor.id,
                vendorFields: { address },
              },
            },
          });
        }

        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty]);

  const {
    usStatesQueryLoading, usStatesQueryError,
  } = useQuery(
    US_STATES_QUERY,
    {
      onCompleted: (data) => setUsStates(data.usStates),
    },
  );

  if (usStatesQueryLoading || usStatesQueryError) {
    return null;
  }

  return (
    <div ref={formRef} className="md:grid md:grid-cols-12 gap-2">
      <TextInput
        focus
        control={control}
        name="street1"
        label="Street 1"
        required={false}
        className="md:col-span-6"
      />
      <TextInput
        control={control}
        name="street2"
        label="Street 2"
        required={false}
        className="md:col-span-6"
      />
      <TextInput
        control={control}
        name="city"
        label="City"
        required={false}
        className="md:col-span-5"
      />
      <Dropdown
        control={control}
        name="state"
        label="State"
        className="md:col-span-3"
        required={false}
        options={usStates.map((usState) => ({
          label: usState.abbreviation,
          value: usState.id,
        }))}
      />
      <NumberInput
        control={control}
        name="zipCode"
        label="Zip Code"
        className="md:col-span-4"
        required={false}
      />
    </div>
  );
}

export default UpdateEventVendorAddress;
