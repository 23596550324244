import React, { useRef, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import last from 'lodash/last';

import FileModal from 'containers/Events/Show/FilesTab/FileModal';
import { mergeClassNames } from 'utils/styleUtils';
import Attachments from './Attachments';
import { FETCH_FILE_QUERY } from './graphql';

/* eslint-disable react/no-danger */
function RichTextView({
  onClick = () => {}, className = '', body, attachments = [],
}) {
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageUuid, setImageUuid] = useState(null);
  const [file, setFile] = useState(null);
  const toastRef = useRef();
  const handleClick = (e) => {
    if (e.target.tagName === 'IMG') {
      setShowImageModal(true);
      setImageUuid(last(e.target.src.split('uploaded-files/')));
    } else {
      onClick(e);
    }
  };

  const [
    fetchFile,
  ] = useLazyQuery(
    FETCH_FILE_QUERY,
    {
      onCompleted: ({ currentActor }) => {
        setFile(currentActor.viewableFile);
      },
    },
  );

  useEffect(() => {
    if (imageUuid) {
      fetchFile({ variables: { uuid: imageUuid } });
    } else {
      setFile(null);
    }
  }, [imageUuid]);

  return (
    <div className="w-full">
      <div
        onClick={handleClick}
        className={mergeClassNames(`rich-text-container text-sm ${className}`)}
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <Attachments attachments={attachments} />
      {
        showImageModal && file && (
          <FileModal
            show={showImageModal}
            onHide={() => setShowImageModal(false)}
            file={file}
            toastRef={toastRef}
            refetch={() => fetchFile({ variables: { uuid: imageUuid } })}
          />
        )
      }
    </div>
  );
}
/* eslint-enable react/no-danger */

export default RichTextView;
