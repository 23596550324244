import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Ripple } from 'primereact/ripple';
import map from 'lodash/map';
import replace from 'lodash/replace';
import last from 'lodash/last';
import { classNames } from 'primereact/utils';

import { mergeClassNames } from 'utils/styleUtils';

const CAROTS = {
  first: '<<',
  previous: '<',
  next: '>',
  last: '>>',
};
function LazyPaginatedTable({
  graphQLData = { pageInfo: {}, edges: [] },
  pageSize,
  onRowClick = () => {},
  setPaginationBounds,
  currentPage,
  setCurrentPage,
  scrollHeight = 'calc(100vh - 23rem)',
  className = '',
  clickable = true,
  children: columns,
}) {
  const { hasPreviousPage, hasNextPage } = graphQLData.pageInfo;
  const { totalRecords } = graphQLData;
  const lastPage = Math.ceil(graphQLData.totalRecords / pageSize);
  const firstCursor = graphQLData.edges[0]?.cursor;
  const lastCursor = last(graphQLData.edges)?.cursor;
  const tableData = map(graphQLData.edges, 'node');

  const pageLink = (options, pageType, pageAvailable, onPageChange) => {
    const pageLinkClassName = pageAvailable ? (
      replace(options.className, 'p-disabled', '')
    ) : `${options.className} p-disabled`;

    return (
      <button type="button" className={pageLinkClassName} onClick={() => onPageChange(pageType)}>
        <span className="p-4">{CAROTS[pageType]}</span>
        <Ripple />
      </button>
    );
  };

  const onPageChange = (pageChangeType) => {
    let newPaginationBounds = {};
    let newCurrentPage = null;

    switch (pageChangeType) {
      case 'first':
        newPaginationBounds = { first: pageSize };
        newCurrentPage = 1;
        break;
      case 'previous':
        newPaginationBounds = { last: pageSize, before: firstCursor };
        newCurrentPage = currentPage - 1;
        break;
      case 'next':
        newPaginationBounds = { first: pageSize, after: lastCursor };
        newCurrentPage = currentPage + 1;
        break;
      case 'last':
        newPaginationBounds = { last: pageSize };
        newCurrentPage = lastPage;
        break;
      default:
        break;
    }

    setPaginationBounds(newPaginationBounds);
    setCurrentPage(newCurrentPage);
  };

  const currentPageReport = () => (
    <span style={{
      color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center',
    }}
    >
      {currentPage} of {lastPage}
    </span>
  );

  const paginatorTemplate = {
    layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
    FirstPageLink: (options) => pageLink(options, 'first', hasPreviousPage, onPageChange),
    PrevPageLink: (options) => pageLink(options, 'previous', hasPreviousPage, onPageChange),
    NextPageLink: (options) => pageLink(options, 'next', hasNextPage, onPageChange),
    LastPageLink: (options) => pageLink(options, 'last', hasNextPage, onPageChange),
    CurrentPageReport: () => currentPageReport(),
  };

  const tableClassNames = classNames(
    {
      'cursor-pointer': clickable,
    },
  );

  return (
    <DataTable
      value={tableData}
      paginator
      paginatorTemplate={paginatorTemplate}
      rows={pageSize}
      rowHover={!!clickable}
      totalRecords={totalRecords}
      lazy
      scrollable
      scrollHeight={scrollHeight}
      onRowClick={onRowClick}
      className={mergeClassNames(`${tableClassNames} ${className}`)}
    >
      { columns }
    </DataTable>
  );
}

export default LazyPaginatedTable;
