import gql from 'graphql-tag';
import TIME_BLOCK_FIELDS from 'graphql/fragments/timeBlock';

const EVENT_QUERY = gql`
  ${TIME_BLOCK_FIELDS}

  query Event($filters: EventFilterInput) {
    currentVendor {
      id
    }
    currentActor {
      id
      name
      events(filters: $filters) {
        edges {
          cursor
          node {
            id
            isUpdatableByCurrentActor
            timeZone
            name
            startDate
            hostActors {
              id
              name
              initials
              avatarUrl
            }
            timeline {
              id
              startTime
              endTime
              timeBlocks {
                ...TimeBlockFields
              }
            }
          }
        }
      }
    }
  }
`;

const EVENT_UPDATE_MUTATION = gql`
  mutation EventUpdate($input: EventUpdateMutationInput!) {
    eventUpdate(input: $input) {
      event {
        id
        name
        startDate
      }
    }
  }
`;

export {
  EVENT_QUERY,
  EVENT_UPDATE_MUTATION,
};
