import React from 'react';
import { Button } from 'primereact/button';

function Footer() {
  return (
    <section className="py-10 bg-gray-50 sm:pt-16 lg:pt-24 flex items-center justify-center mt-32">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <h1 className="text-4xl font-semibold text-gray-900 leading-tight xxs:text-center">We focus on the details, so you can focus on the fun</h1>
        <div className="flex w-full items-ceneter justify-center">
          <a href="mailto:hello@enterplan.com" className="text-white">
            <Button className="max-w-max text-nowrap mt-10 text-white" icon="pi pi-envelope" label="Get in touch" />
          </a>
        </div>

        <hr className="mt-16 mb-10 border-gray-200" />

        <p className="text-sm text-center text-gray-600">© Copyright 2024, All Rights Reserved by Enterplan</p>
      </div>
    </section>
  );
}

export default Footer;
