import React from 'react';
import Dropdown from '../Dropdown';

function GroupedDropdown(props) {
  const {
    control,
    name,
    searchable = true,
    label,
    labelClassName,
    options,
    placeholder,
    className,
    controllerProps,
    required = true,
    tooltip = null,
    inputProps,
  } = props;

  const optionGroupTemplate = (option) => (
    <div className="flex items-center">
      <div>{option.label}</div>
    </div>
  );

  return (
    <Dropdown
      control={control}
      name={name}
      label={label}
      searchable={searchable}
      labelClassName={labelClassName}
      options={options}
      placeholder={placeholder}
      className={className}
      controllerProps={controllerProps}
      required={required}
      tooltip={tooltip}
      inputProps={{
        ...inputProps,
        optionGroupTemplate,
        optionLabel: 'label',
        optionGroupLabel: 'label',
        optionGroupChildren: 'items',
      }}
    />
  );
}

export default GroupedDropdown;
