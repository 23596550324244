import React, { useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { titleize } from 'utils/stringUtils';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import EventInviteForm from './EventInviteForm';

function VendorCard({
  currentVendorId,
  eventVendor,
  event,
  confirmSendInvite,
  refetchEventVendors,
  confirmRemoveVendor,
  setActiveEventVendor,
  toastRef,
  showEventVendorProfile,
}) {
  const menuRef = useRef(null);
  const {
    vendor,
    categories,
    status,
  } = eventVendor;
  const footerClasses = classNames(
    'border-t',
    'border-gray-200',
    'more-info-container',
    'pt-4',
  );

  const footer = () => (
    <div className={footerClasses}>
      <EventInviteForm
        event={event}
        toastRef={toastRef}
        eventVendorOrActor={eventVendor}
        refetchEventVendors={refetchEventVendors}
        confirmSendInvite={confirmSendInvite}
        setActiveEventVendor={setActiveEventVendor}
      />
    </div>
  );

  /* eslint-disable consistent-return */
  const onCardClick = () => showEventVendorProfile(eventVendor.id, vendor.name);
  /* eslint-enable consistent-return */

  const categoriesMapping = () => (
    categories.map((category) => (
      <Tag
        key={`${vendor.id}-${category}`}
        value={titleize(category)}
        className={`${CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default} mr-1`}
      />
    ))
  );

  const renderActions = () => {
    if (!event.isUpdatableByCurrentActor) { return null; }

    let label = '';
    const icon = status === 'APPROVED' ? 'trash' : 'times';

    if (status === 'APPROVED') {
      if (vendor.id === currentVendorId) {
        label = 'Leave';
      } else {
        label = 'Remove';
      }
    } else if (status === 'INVITED') {
      label = 'Cancel Invite';
    } else {
      label = 'Remove';
    }

    return (
      <>
        <Menu
          key={`${vendor.id}-card-actions`}
          model={[{
            label,
            icon: `pi pi-${icon}`,
            command: ({ originalEvent }) => {
              originalEvent.stopPropagation();
              confirmRemoveVendor(eventVendor);
            },
          }]}
          popup
          ref={menuRef}
          id="vendor-card-actions-menu"
          className="text-sm w-max"
        />
        <Button
          key={`${vendor.id}-card-actions-toggle`}
          icon="pi pi-bars"
          text
          size="small"
          className="p-0"
          onClick={(e) => {
            e.stopPropagation();
            menuRef.current.toggle(e);
          }}
          aria-controls="vendor-card-actions-menu"
          aria-haspopup
        />
      </>
    );
  };

  const header = () => (
    <div className="flex items-start justify-between h-24">
      <div className="flex items-start">
        <Avatar
          key={`${vendor.id}-avatar`}
          label={vendor.name[0]}
          size="xlarge"
          shape="circle"
          className={`mr-2 text-white ${vendor.avatarUrl ? '' : 'bg-gray'}`}
          image={vendor.avatarUrl}
        />
        <div>
          <p key={`${vendor.id}-vendor-name`} className="font-semibold m-0 text-gray-800"><span className="mr-2">{ vendor.name }</span></p>
          <div key={`${vendor.id}-vendor-categories`} className="font-medium">{ categoriesMapping() }</div>
        </div>
      </div>
      { renderActions() }
    </div>
  );

  return (
    <Card
      key={`${vendor.id}-card`}
      className="vendor-card rounded-md p-6 relative h-[11.4rem] cursor-pointer"
      header={header()}
      footer={footer()}
      onClick={onCardClick}
    />
  );
}

export default VendorCard;
