import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Dialog } from 'primereact/dialog';
import EventInvite from 'components/EventInvite';
import { EVENT_QUERY } from './graphql';

function EventInviteModal({
  eventId, toastRef, show, setShow, onHide, refetch, status,
}) {
  const [event, setEvent] = useState(null);
  const [eventVendor, setEventVendor] = useState(null);
  const [eventHostActor, setEventHostActor] = useState(null);
  const [actionedActorOrVendor, setActionedActorOrVendor] = useState(null);

  useQuery(
    EVENT_QUERY,
    {
      variables: { filters: { id: eventId } },
      onCompleted: (data) => {
        const { currentVendor, currentActor } = data;
        const eventData = currentActor.events.edges[0].node;

        setEvent(eventData);
        setEventVendor(eventData.currentEventVendor);
        setEventHostActor(eventData.currentEventHostActor);
        setActionedActorOrVendor(currentVendor || currentActor);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  if (!event) { return null; }

  const eventInviteObject = event.currentEventVendor || event.currentEventHostActor;

  return (
    <Dialog
      className="xl:w-5/12"
      header="Accept Event Invite"
      visible={show}
      onHide={onHide}
      dismissableMask
      resizable={false}
      draggable={false}
    >
      <EventInvite
        className="border-none"
        status={status}
        toastRef={toastRef}
        onSuccess={onHide}
        onUndo={(data) => setShow({ eventId: data.eventId, status: 'INVITED' })}
        event={event}
        eventVendorId={eventVendor?.id}
        eventHostActorId={eventHostActor?.id}
        actionedActorOrVendor={actionedActorOrVendor}
        createdAt={eventInviteObject.inviteLastActionedAt}
        actionerActor={eventInviteObject.inviteLastActionedByActor}
        inviterActor={eventInviteObject.invitedByActor}
        requesterActor={eventInviteObject.requestedByActor}
        approvalSubjectType={eventInviteObject.inviteApprovalSubjectType}
        currentActorIsOnEvent={eventInviteObject.currentActorIsOnEvent}
        currentActorIsActioner={eventInviteObject.currentActorIsLastInviteActioner}
        currentVendorIsActioner={eventInviteObject.currentVendorIsLastInviteActioner}
        currentVendorIsActioned
        refetch={refetch}
      />
    </Dialog>
  );
}

export default EventInviteModal;
