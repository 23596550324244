import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';

import { titleize } from 'utils/stringUtils';
import { showSuccessToast } from 'utils/toastUtils';
import EditableText from 'components/Form/EditableFields/Text';
import EditableDropdown from 'components/Form/EditableFields/Dropdown';
import {
  DOWNLOAD_FILE_MUTATION, PREVIEW_FILE_MUTATION, UPDATE_EVENT_FILE_MUTATION, UPDATE_FILE_MUTATION,
} from './graphql';

const FILE_CATEGORIES = [
  'ATTACHMENT',
  'MISC',
  'CONTRACT',
  'PROPOSAL',
  'INVOICE',
  'QUESTIONNAIRE',
  'SUB_AGREEMENT',
  'TIMELINE',
];
const VIEWABLE_BY = [
  'SELF',
  'VENDOR',
  'ADMINS',
  'ALL',
];
const FILE_CATEGORY_TAG_CLASSES = {
  ATTACHMENT: 'border-blue-500 [&:not(:hover)]:bg-blue-500 [&:not(:hover)]:text-white hover:text-blue-500',
  MISC: 'border-green-500 [&:not(:hover)]:bg-green-500 [&:not(:hover)]:text-white hover:text-green-500',
  CONTRACT: 'border-yellow-500 [&:not(:hover)]:bg-yellow-500 [&:not(:hover)]:text-white hover:text-yellow-500',
  PROPOSAL: 'border-cyan-500 [&:not(:hover)]:bg-cyan-500 [&:not(:hover)]:text-white hover:text-cyan-500',
  INVOICE: 'border-pink-500 [&:not(:hover)]:bg-pink-500 [&:not(:hover)]:text-white hover:text-pink-500',
  QUESTIONNAIRE: 'border-purple-500 [&:not(:hover)]:bg-purple-500 [&:not(:hover)]:text-white hover:text-purple-500',
  SUB_AGREEMENT: 'border-red-500 [&:not(:hover)]:bg-red-500 [&:not(:hover)]:text-white hover:text-red-500',
  TIMELINE: 'border-teal-500 [&:not(:hover)]:bg-teal-500 [&:not(:hover)]:text-white hover:text-teal-500',
};
function FileModal({
  eventFile = {}, file, show, toastRef, refetch, onHide,
}) {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingFileCategory, setIsEditingFileCategory] = useState(false);
  const [isEditingViewableBy, setIsEditingViewableBy] = useState(false);

  const { eventId } = useParams();

  const isPdf = file.fileExtension === 'pdf';
  const { isUpdatableByCurrentActor } = file;

  const [fetchFilePreviewUrlMutation] = useMutation(PREVIEW_FILE_MUTATION, {
    onCompleted: ({ uploadedFilePreview }) => {
      setPreviewUrl(uploadedFilePreview.previewUrl);
    },
  });

  const [fetchFileDownloadUrlMutation] = useMutation(DOWNLOAD_FILE_MUTATION, {
    onCompleted: ({ uploadedFileDownload }) => {
      setPreviewUrl(uploadedFileDownload.downloadUrl);
    },
  });

  const {
    control, setValue, getValues, watch,
  } = useForm({
    defaultValues: { name: file.name, fileCategory: file.fileCategory, viewableBy: file.viewableBy },
  });

  useEffect(() => {
    if (isPdf) {
      fetchFilePreviewUrlMutation({ variables: { input: { id: file.id } } });
    } else {
      fetchFileDownloadUrlMutation({ variables: { input: { id: file.id } } });
    }
  }, [file.id]);

  const [updateFileMutation] = useMutation(UPDATE_FILE_MUTATION, {
    onCompleted: ({ uploadedFileUpdate }) => {
      const { uploadedFile } = uploadedFileUpdate;

      showSuccessToast(toastRef, 'Successfully updated file!');
      refetch();
      setValue('name', uploadedFile.name);
      setValue('fileCategory', uploadedFile.fileCategory);
      setIsEditingName(false);
      setIsEditingFileCategory(false);
    },
  });

  const [updateEventFileMutation] = useMutation(UPDATE_EVENT_FILE_MUTATION, {
    onCompleted: ({ eventUploadedFileUpdate }) => {
      const { eventUploadedFile } = eventUploadedFileUpdate;

      showSuccessToast(toastRef, 'Successfully updated file!');
      refetch();
      setValue('viewableBy', eventUploadedFile.file.viewableBy);
      setIsEditingViewableBy(false);
    },
  });

  const updateFile = ({ name, fileCategory }) => {
    updateFileMutation({ variables: { input: { id: file.id, name, fileCategory } } });
  };

  const updateEventFile = ({ viewableBy }) => {
    updateEventFileMutation({ variables: { input: { id: eventFile.id, viewableBy } } });
  };

  if (!previewUrl) { return null; }

  const fileCategoryOptions = FILE_CATEGORIES.map((fileCategory) => ({
    value: fileCategory,
    label: titleize(fileCategory),
  }));

  const viewableByOptions = VIEWABLE_BY.map((viewableBy) => ({
    value: viewableBy,
    label: titleize(viewableBy),
  }));

  const viewableInfo = () => {
    const { task, timelineTimeBlock } = file;

    if (!task && !timelineTimeBlock) {
      return (
        <div className="flex items-center text-sm mr-4">
          <p className="mr-2 text-blue-500">Viewable By:</p>
          <EditableDropdown
            control={control}
            name="viewableBy"
            updatable={isUpdatableByCurrentActor}
            value={watch('viewableBy')}
            setValue={setValue}
            hoverClassNames="border hover:bg-white border-blue-500 [&:not(:hover)]:bg-blue-500 [&:not(:hover)]:text-white hover:text-blue-500"
            options={viewableByOptions}
            isEditing={isEditingViewableBy}
            setIsEditing={setIsEditingViewableBy}
            onUpdate={(viewableBy) => updateEventFile({ viewableBy })}
          />
        </div>
      );
    }

    const defaultEndpoint = eventId ? `/app/events/${eventId}` : '/app';

    return (
      <div className="text-sm mr-4">
        <p className="mr-2 text-blue-500">Viewable On:</p>
        {
            task ? (
              <a target="_blank" href={`${defaultEndpoint}/tasks/${task.id}`} rel="noreferrer">
                <p className="truncate w-48">Task ({task.title})</p>
              </a>
            ) : (
              <div>
                <div>
                  {
                    timelineTimeBlock.tasks.map((timeBlockTask) => (
                      <a target="_blank" href={`${defaultEndpoint}/tasks/${timeBlockTask.id}`} rel="noreferrer">
                        <p className="truncate w-48">Task ({timeBlockTask.title})</p>
                      </a>
                    ))
                  }
                </div>
                <a target="_blank" href={`${defaultEndpoint}/timeline`} rel="noreferrer">
                  <p className="truncate w-48">Time Block ({timelineTimeBlock.description})</p>
                </a>
              </div>
            )
          }
      </div>
    );
  };

  const header = () => (
    <div className="flex items-center w-full justify-between">
      <div className="flex items-center">
        <EditableText
          text={getValues('name')}
          className="mr-2"
          updatable={isUpdatableByCurrentActor}
          onUpdate={updateFile}
          isEditing={isEditingName}
          setIsEditing={setIsEditingName}
          control={control}
          setValue={setValue}
          getValues={getValues}
          name="name"
        />
        <EditableDropdown
          control={control}
          name="fileCategory"
          updatable={isUpdatableByCurrentActor}
          value={watch('fileCategory')}
          setValue={setValue}
          hoverClassNames={`text-nowrap border hover:bg-white ${FILE_CATEGORY_TAG_CLASSES[getValues('fileCategory')]}`}
          options={fileCategoryOptions}
          isEditing={isEditingFileCategory}
          setIsEditing={setIsEditingFileCategory}
          onUpdate={(fileCategory) => updateFile({ fileCategory })}
        />
      </div>
      { viewableInfo() }
    </div>
  );

  return (
    <>
      <Toast ref={toastRef} position="bottom-left" />
      <Dialog
        header={header()}
        className={`xl:w-7/12 ${isPdf ? 'h-screen' : ''}`}
        dismissableMask
        visible={show}
        onHide={onHide}
        draggable={false}
      >
        <div className={`${isPdf ? 'h-screen' : ''}`}>
          {
            isPdf ? (
              <embed
                src={previewUrl}
                type="application/pdf"
                width="100%"
                height="100%"
              />
            ) : (
              <Image src={previewUrl} alt={file.name} preview downloadable />
            )
          }
        </div>
      </Dialog>
    </>
  );
}

export default FileModal;
