import gql from 'graphql-tag';

const EVENT_OVERVIEW_QUERY = gql`
  query EventOverview($filters: EventFilterInput) {
    currentVendor {
      id
      name
    }
    currentActor {
      id
      name
      events(filters: $filters) {
        edges {
          node {
            id
            isUpdatableByCurrentActor
            currentEventVendor {
              id
              isAdmin
              mergeableEvents {
                id
                name
              }
            }
            currentEventHostActor {
              id
              isAdmin
              mergeableEvents {
                id
                name
              }
            }
            currentActorTaskOverview {
              id
              unreadMentionsCount
              createdBy {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
              assignedTo {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
            }
            currentVendorTaskOverview {
              id
              unreadMentionsCount
              createdBy {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
              assignedTo {
                id
                overdueCount
                notStartedCount
                inProgressCount
              }
            }
            eventHostActors {
              id
              status
              isAdmin
              isUpdatableByCurrentActor
              inviteLastActionedAt
              inviteApprovalSubjectType
              currentActorIsLastInviteActioner
              currentVendorIsLastInviteActioner
              requestedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                  categories
                }
              }
              invitedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                }
              }
              inviteLastActionedByActor {
                id
                name
                initials
                avatarUrl
                vendor {
                  id
                  name
                  initials
                }
              }
              notes
              internalNotes
              hostActor {
                id
                firstName
                lastName
                name
                initials
                avatarUrl
                email
                phoneNumber
                instagramHandle
              }
            }
          }
        }
      }
    }
  }
`;

export {
  EVENT_OVERVIEW_QUERY,
};
