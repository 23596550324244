import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { classNames } from 'primereact/utils';

import TextAreaInput from 'components/Form/TextAreaInput';
import Attachments from 'components/RichTextView/Attachments';
import RichTextView from 'components/RichTextView';
import RichTextAreaMentionableInput, { DEFAULT_FORMATS } from 'components/Form/RichTextAreaMentionableInput';
import { mergeClassNames } from 'utils/styleUtils';

const EMPTY_BODY = '<p></p>';
function EditableTextArea({
  focus = true,
  name,
  updateFieldName = null,
  label = null,
  labelClassName = '',
  emptyTextViewClassName = '',
  required = true,
  attachments = [],
  updatable,
  isEditing,
  setIsEditing,
  control,
  setValue,
  getValues,
  formats = DEFAULT_FORMATS,
  attachmentsDisabled = true,
  mentionsDisabled = true,
  mentionables = [],
  placeholder = 'Add text...',
  textViewClassName = '',
  textViewWrapperClassName = '',
  setSubmitDisabled = () => {},
  onUpdate = () => {},
  onClear = () => {},
}) {
  const fieldName = name;
  const text = getValues(fieldName);
  const updateName = updateFieldName || fieldName;
  const [textAttachments, setTextAttachments] = useState(attachments || []);
  const [inputSubmitDisabled, setInputSubmitDisabled] = useState(false);
  const [editorClassName, setEditorClassName] = useState('w-full rounded-md');
  const [isFocused, setIsFocused] = useState(focus);

  useEffect(() => {
    if (isFocused) {
      setEditorClassName(`${editorClassName} border border-secondary`);
    } else {
      setEditorClassName('w-full rounded-md');
    }
  }, [isFocused]);

  useEffect(() => {
    setTextAttachments(attachments);
  }, [attachments.length]);

  useEffect(() => {
    setSubmitDisabled(inputSubmitDisabled);
  }, [inputSubmitDisabled]);

  const hasBody = () => {
    let newBody = getValues(fieldName);

    if (newBody && typeof getValues(fieldName) === 'object') { newBody = newBody[fieldName]; }

    return !!newBody && newBody.replace(/\s/g, '') !== EMPTY_BODY;
  };

  const checkIfSubmitShouldBeDisabled = () => {
    if (required && !hasBody() && !textAttachments?.length) {
      setInputSubmitDisabled(true);
    } else {
      setInputSubmitDisabled(false);
    }
  };

  useEffect(() => {
    checkIfSubmitShouldBeDisabled();
    if (!attachmentsDisabled) { setValue('attachmentIds', map(textAttachments, 'id').sort()); }
  }, [textAttachments]);

  const cancel = () => {
    setIsFocused(false);

    if (!text) { onClear(); }

    setValue(fieldName, text);
    setTextAttachments(attachments || []);
    setIsEditing(false);
  };

  const setEditing = () => {
    if (!updatable) { return; }

    setIsEditing(true);
  };

  const update = async () => {
    setIsFocused(false);

    let newBody = getValues(fieldName);
    const hasNewBody = hasBody();
    const newAttachmentIds = map(textAttachments, 'id').sort();
    const values = {};

    if (!attachmentsDisabled) { values.attachmentIds = newAttachmentIds; }
    if (required && !hasNewBody && !newAttachmentIds.length) { return cancel(); }
    if (!hasNewBody && newAttachmentIds.length) { newBody = '(Attached)'; }

    values[updateName] = newBody;
    setValue(fieldName, newBody);

    await onUpdate(values);

    setTextAttachments(attachments || []);

    return setIsEditing(false);
  };

  const onDeleteAttachment = (attachmentId) => { setTextAttachments(textAttachments.filter((attachment) => attachment.id !== attachmentId)); };

  if (isEditing) {
    return (
      <div className={`w-full ${textViewWrapperClassName}`}>
        {
          formats.length ? (
            <>
              <RichTextAreaMentionableInput
                focus={focus}
                control={control}
                name={fieldName}
                formats={formats}
                placeholder={placeholder}
                mentionsDisabled={mentionsDisabled}
                mentionables={mentionables}
                disabledMessage=""
                label={label}
                labelClassName={labelClassName}
                className="w-full p-0"
                editorClassName={editorClassName}
                onFocus={() => setIsFocused(true)}
                onBlur={inputSubmitDisabled ? cancel : update}
                onChange={checkIfSubmitShouldBeDisabled}
                onAttachmentsAdded={(newAttachments) => setTextAttachments(textAttachments.concat(newAttachments))}
                onImageUploadLoading={() => setInputSubmitDisabled(true)}
                onImageUploadComplete={() => setInputSubmitDisabled(false)}
              />
              <div className="w-full py-0 mb-3">
                <Attachments attachments={textAttachments} deleteable onDelete={onDeleteAttachment} />
              </div>
            </>
          ) : (
            <TextAreaInput
              required={required}
              focus={focus}
              control={control}
              name={fieldName}
              placeholder={placeholder}
              disabledMessage=""
              label={label}
              labelClassName={labelClassName}
              className="w-full p-0"
              onFocus={() => setIsFocused(true)}
              onChange={checkIfSubmitShouldBeDisabled}
            />
          )
        }
      </div>
    );
  }

  const defaultTextViewClassNames = classNames(
    'w-full',
    {
      'cursor-pointer': updatable,
    },
  );

  if (text || attachments.length) {
    return (
      <div className={textViewWrapperClassName}>
        { label && <p className={`mb-2 ${labelClassName}`}>{ label }</p> }
        <RichTextView
          className={mergeClassNames(`${defaultTextViewClassNames} ${textViewClassName}`)}
          body={text}
          attachments={textAttachments}
          onClick={setEditing}
        />
      </div>
    );
  }

  const emptyTextViewClassNames = classNames(
    'w-full',
    'text-gray-500',
    'italic',
    emptyTextViewClassName,
    {
      'cursor-pointer': updatable,
    },
  );

  return (
    <div onClick={setEditing}>
      { label && <p className={`mb-2 ${labelClassName}`}>{ label }</p> }
      <p className={emptyTextViewClassNames}>{ placeholder }</p>
    </div>
  );
}

export default EditableTextArea;
