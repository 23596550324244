import gql from 'graphql-tag';

const CREATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION = gql`
  mutation CreateTeamMemberGroupActor($input: TeamMemberGroupActorCreateMutationInput!) {
    teamMemberGroupActorCreate(input: $input) {
      teamMemberGroupActor {
        id
        name
        email
        phoneNumber
        memberActors {
          id
          name
        }
      }
    }
  }
`;

export {
  CREATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION,
};
