import gql from 'graphql-tag';

const REVIEW_SOURCE_CREATE_MUTATION = gql`
  mutation CreateReviewSource($input: ReviewSourceCreateMutationInput!) {
    reviewSourceCreate(input: $input) {
      reviewSource {
        id
        name
      }
    }
  }
`;

const REVIEW_SOURCE_UPDATE_MUTATION = gql`
  mutation UpdateReviewSource($input: ReviewSourceUpdateMutationInput!) {
    reviewSourceUpdate(input: $input) {
      reviewSource {
        id
        name
      }
    }
  }
`;

const REVIEW_SOURCE_DELETE_MUTATION = gql`
  mutation DeleteReviewSource($input: ReviewSourceDeleteMutationInput!) {
    reviewSourceDelete(input: $input) {
      id
    }
  }
`;

export {
  REVIEW_SOURCE_CREATE_MUTATION,
  REVIEW_SOURCE_UPDATE_MUTATION,
  REVIEW_SOURCE_DELETE_MUTATION,
};
