import gql from 'graphql-tag';

const DEACTIVATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION = gql`
  mutation DeactivateTeamMemberGroupActor($input: TeamMemberGroupActorDeactivateMutationInput!) {
    teamMemberGroupActorDeactivate(input: $input) {
      id
    }
  }
`;

export {
  DEACTIVATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION,
};
