import React from 'react';
import { Card } from 'primereact/card';

import HideOrDeleteFileButton from './HideOrDeleteFileButton';
import ShowFileButton from './ShowFileButton';
import { vendorTextColorClassName, fileDetails } from './helpers';

function CategoryFileCard({
  setActiveEventFileId, eventFile, uploader, refetchFiles, toastRef,
}) {
  const { file } = eventFile;

  const handleClick = (event) => {
    if (event.target.className.includes('button')) { return; }
    setActiveEventFileId(file.id);
  };

  return (
    <Card className="border border-round cursor-pointer file-card" onClick={handleClick}>
      <div className="flex items-center justify-between w-full">
        <p className={`text-sm font-semibold ${vendorTextColorClassName(uploader.categories?.[0])}`}>{uploader.name}</p>
        {
          file.isUpdatableByCurrentActor && (
            file.showOnSummary ? (
              <HideOrDeleteFileButton file={file} eventFileId={eventFile.id} refetchFiles={refetchFiles} toastRef={toastRef} />
            ) : (
              <ShowFileButton file={file} eventFileId={eventFile.id} refetchFiles={refetchFiles} toastRef={toastRef} />
            )
          )
        }
      </div>
      { fileDetails(file) }
    </Card>
  );
}

export default CategoryFileCard;
