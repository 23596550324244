import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

const HTML = `
<div class="quill-loading-image-placeholder">
  <i class="pi pi-spin pi-spinner"></i>
</div>
`;

class LoadingImagePlaceholder extends BlockEmbed {
  static create(value) {
    const node = super.create(value);

    node.insertAdjacentHTML('afterbegin', HTML);

    return node;
  }

  static value(node) {
    return node;
  }
}

LoadingImagePlaceholder.blotName = 'loadingImagePlaceholder';
LoadingImagePlaceholder.className = 'quill-loading-image-placeholder-container';
LoadingImagePlaceholder.tagName = 'div';

export default LoadingImagePlaceholder;
