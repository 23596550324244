import gql from 'graphql-tag';

const SELECT_ACTOR_TYPE_MUTATION = gql`
  mutation SelectActorType($input: ActorTypeSelectMutationInput!) {
    actorTypeSelect(input: $input) {
      currentActor {
        id
      }
    }
  }
`;

export {
  SELECT_ACTOR_TYPE_MUTATION,
};
