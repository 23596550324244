import React, { useState } from 'react';
import { Outlet } from 'react-router';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Sidebar from './Sidebar';
import TopNav from './TopNav';

function App({ user, sidebarMenuItems, refetchEvents = () => {} }) {
  const isSidebarVisible = localStorage.getItem('sidebarVisible') === 'true';
  const [sidebarVisible, setSidebarVisible] = useState(isSidebarVisible || false);

  const toggleSidebarVisible = () => {
    window.localStorage.setItem('sidebarVisible', !sidebarVisible);
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div className="app-container flex h-screen overflow-hidden">
      { user && <TopNav toggleSidebarVisible={toggleSidebarVisible} refetchEvents={refetchEvents} /> }
      { user && <Sidebar sidebarMenuItems={sidebarMenuItems} visible={sidebarVisible} toggleSidebarVisible={toggleSidebarVisible} /> }

      <div className="main-content m-12 pt-16 bg-primary-gray flex flex-col grow-1 shrink-1 flex-auto overflow-hidden">
        <div className="content-wrapper h-full w-full overflow-hidden">
          <Outlet />
        </div>
      </div>
      <ConfirmDialog />
    </div>
  );
}

export default App;
