import React from 'react';
import { classNames } from 'primereact/utils';

import TextInput from 'components/Form/TextInput';
import { mergeClassNames } from 'utils/styleUtils';

function EditableText({
  text,
  updatable,
  onUpdate,
  onClear = () => {},
  isEditing,
  setIsEditing,
  label = null,
  focus = true,
  labelClassName = '',
  control,
  setValue,
  getValues,
  name,
  updateFieldName = null,
  className = '',
  textInputClassName = '',
  textViewClassName = '',
  placeholder = null,
  defaultValue = null,
}) {
  const fieldName = name;
  const updateName = updateFieldName || fieldName;
  const setEditing = () => {
    if (!updatable) { return; }

    setIsEditing(true);
  };
  const cancel = () => {
    if (!text) { onClear(); }
    setValue(fieldName, text);
    setIsEditing(false);
  };

  const update = async () => {
    let newText = getValues(fieldName);

    if (!newText?.length) {
      if (defaultValue) {
        newText = defaultValue;
      } else {
        return cancel();
      }
    }

    setValue(fieldName, newText);
    await onUpdate({ [updateName]: newText });

    return setIsEditing(false);
  };

  const emptyTextViewClassNames = classNames(
    textViewClassName,
    {
      'cursor-pointer': updatable,
    },
  );

  return (
    <div className={mergeClassNames(`field m-0 w-full p-0 ${className}`)}>
      { label && <label htmlFor={fieldName} className={`block mb-1 ${labelClassName}`}>{label}</label> }
      {
        isEditing ? (
          <div className="flex items-center p-0 col-span-12">
            <TextInput
              focus={focus}
              control={control}
              onBlur={update}
              name={fieldName}
              placeholder={placeholder}
              className={mergeClassNames(`col-span-12 mb-0 ${textInputClassName}`)}
            />
          </div>
        ) : (
          <p className={emptyTextViewClassNames} onClick={setEditing}>{text}</p>
        )
      }
    </div>
  );
}

export default EditableText;
