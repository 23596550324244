import React, { useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { classNames } from 'primereact/utils';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TabView, TabPanel } from 'primereact/tabview';
import EventInvite from 'components/EventInvite';
import TaskNotification from './TaskNotification';
import { NOTIFICATIONS_QUERY, NOTIFICATION_REMOVE_MUTATION, NOTIFICATION_MARK_AS_READ_MUTATION } from './graphql';

function Notifications({ toastRef, refetchEvents }) {
  const [notifications, setNotifications] = useState([]);
  const notificationRef = useRef();
  const notificationButtonRef = useRef();

  const { refetch } = useQuery(
    NOTIFICATIONS_QUERY,
    {
      variables: { eventVendorFilters: { status: ['APPROVED'] } },
      onCompleted: (data) => {
        setNotifications(data.currentActor.notifications);
      },
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'ignore',
    },
  );

  const [removeNotificationMutation] = useMutation(NOTIFICATION_REMOVE_MUTATION, {
    onCompleted: () => { refetch(); },
  });

  const onEventInviteUpdate = () => {
    refetch();
    refetchEvents();
  };

  const headerTemplate = ({ options, header, count }) => {
    const countText = count ? ` (${count})` : '';

    return (
      <a className="p-tabview-nav-link" onClick={options.onClick}>
        <span className="p-tabview-title">{`${header}${countText}`}</span>
      </a>
    );
  };

  const [markNotificationAsReadMutation] = useMutation(NOTIFICATION_MARK_AS_READ_MUTATION, {
    onCompleted: () => { refetch(); },
  });

  const markNotificationAsRead = ({ id }) => { markNotificationAsReadMutation({ variables: { input: { id } } }); };
  const inviteNotifications = notifications.filter((notification) => notification.category === 'INVITE');
  const taskNotifications = notifications.filter((notification) => notification.category === 'TASK');
  const unreadNotifications = notifications.filter((notification) => !notification.isRead);

  const unreadCategoryNotificationsCount = (category) => unreadNotifications.filter(((notification) => notification.category === category)).length;

  if (!notifications.length) { return null; }

  const notificationBadgeIconClassNames = classNames(
    { hidden: !unreadNotifications.length },
  );

  const activeIndex = [inviteNotifications.length, taskNotifications.length].findIndex((anyNotifications) => !!anyNotifications);

  return (
    <>
      <i
        ref={notificationButtonRef}
        className="pi pi-bell p-overlay-badge mr-4 mt-0.5 text-2xl cursor-pointer nav-bar-notifications-button"
        onClick={(e) => notificationRef?.current?.toggle(e)}
      >
        <Badge className={notificationBadgeIconClassNames} value={unreadNotifications.length} severity="danger" />
      </i>
      <OverlayPanel ref={notificationRef} className="top-nav--notifications-panel overflow-y-auto w-[30rem]">
        <TabView activeIndex={activeIndex}>
          {
            inviteNotifications.length ? (
              <TabPanel
                headerTemplate={(options) => headerTemplate({ options, header: 'Invites', count: unreadCategoryNotificationsCount('INVITE') })}
              >
                {
                  inviteNotifications.map((notification, idx) => {
                    const lastInvite = idx === inviteNotifications.length - 1;
                    const panelClassNames = classNames(
                      { 'mb-2': !lastInvite },
                    );
                    const { inviteDetails } = notification;

                    return (
                      <EventInvite
                        key={`${notification.id}-invited-event`}
                        className={panelClassNames}
                        toastRef={toastRef}
                        refetch={onEventInviteUpdate}
                        onUndo={() => notificationButtonRef?.current?.click()}
                        status={`${notification.action}ED`.replace('EE', 'E')}
                        notificationId={notification.id}
                        onInView={!notification.isRead && markNotificationAsRead}
                        event={inviteDetails.event}
                        createdAt={notification.createdAt}
                        inviterActor={inviteDetails.invitedByActor}
                        requesterActor={inviteDetails.requestedByActor}
                        approvalSubjectType={inviteDetails.approvalSubjectType}
                        actionerActor={notification.actioningActor}
                        actionedActorOrVendor={inviteDetails.eventVendor?.vendor || notification.eventHostActor?.hostActor}
                        currentActorIsOnEvent={inviteDetails.currentActorIsOnEvent}
                        currentActorIsActioner={notification.currentActorIsActioner}
                        currentVendorIsActioner={notification.currentVendorIsActioner}
                        currentVendorIsActioned={notification.currentVendorIsActioned}
                        eventVendorId={inviteDetails.eventVendor?.id}
                        eventHostActorId={inviteDetails.eventHostActor?.id}
                        onRemove={() => removeNotificationMutation({ variables: { input: { id: notification.id } } })}
                      />
                    );
                  })
                }
              </TabPanel>
            ) : null
          }
          {
            taskNotifications.length ? (
              <TabPanel headerTemplate={(options) => headerTemplate({ options, header: 'Tasks', count: unreadCategoryNotificationsCount('TASK') })}>
                {
                  taskNotifications.map((notification, idx) => {
                    const lastInvite = idx === taskNotifications.length - 1;
                    const panelClassNames = classNames(
                      { 'mb-2': !lastInvite },
                    );

                    return (
                      <TaskNotification
                        key={`${notification.id}-task-notification`}
                        className={panelClassNames}
                        toastRef={toastRef}
                        onInView={!notification.isRead && markNotificationAsRead}
                        notification={notification}
                        onRemove={() => removeNotificationMutation({ variables: { input: { id: notification.id } } })}
                      />
                    );
                  })
                }
              </TabPanel>
            ) : null
          }
        </TabView>
      </OverlayPanel>
    </>
  );
}

export default Notifications;
