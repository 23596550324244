import gql from 'graphql-tag';

const PERMISSIONS_ROLES_QUERY = gql`
  query Permissions {
    currentVendor {
      id
      teamMemberActorPermissions {
        id
        name
        roles {
          id
          name
        }
      }
      teamMemberActorRoles {
        id
        name
      }
    }
  }
`;

const PERMISSION_ROLE_UPDATE_MUTATION = gql`
  mutation UpdatePermissionRole($input: PermissionRoleUpdateMutationInput!) {
    permissionRoleUpdate(input: $input) {
      permission {
        id
        name
        roles {
          id
          name
        }
      }
    }
  }
`;

export {
  PERMISSIONS_ROLES_QUERY,
  PERMISSION_ROLE_UPDATE_MUTATION,
};
