import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router';
import { DateTime } from 'luxon';
import first from 'lodash/first';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Avatar } from 'primereact/avatar';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';

function TaskNotification({
  className,
  onInView,
  onRemove,
  notification,
}) {
  const {
    id,
    task,
    action,
    relativeUrl,
    createdAt,
    actioningActor,
    actionedActor,
    currentVendorIsActioner,
  } = notification;

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && onInView) { onInView({ id }); }
  }, [inView]);

  const navigate = useNavigate();
  const categoryBackgroundColor = (category) => CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default;

  /* eslint-disable consistent-return */
  const actionText = () => {
    const actionerName = actioningActor.vendor?.name || actioningActor.name;
    const actionedName = currentVendorIsActioner || !actionedActor.vendor ? actionedActor.name : actionedActor.vendor.name;

    return (
      <p>
        <span className="font-semibold">{ actionerName }</span>{` ${action.toLowerCase()}ed `}
        <span className="font-semibold">{ actionedName }</span>{' on'}
      </p>
    );
  };
  /* eslint-enable consistent-return */

  const actionButtons = () => {
    const buttonContainerClassNames = classNames(
      'flex',
      'justify-center',
      'mt-4',
    );
    const buttonClassNames = classNames(
      'text-xs',
      'mr-0',
      'text-nowrap',
    );

    return (
      <div className={buttonContainerClassNames}>
        <Button
          className={buttonClassNames}
          label="Go to task"
          type="button"
          onClick={() => navigate(relativeUrl)}
        />
      </div>
    );
  };

  const actionerAttribute = (attributeName) => (actioningActor.vendor || {})[attributeName] || actioningActor.attributeName;

  return (
    <Panel className={className} key={`${task.id}-notification`}>
      <div ref={ref} className="flex flex-col items-center justify-center">
        <div className="flex items-center">
          <div className="w-12 h-12 mr-2 rounded-full">
            <Avatar
              key={`${task.id}-actioner-avatar`}
              image={actionerAttribute('avatarUrl')}
              label={actionerAttribute('initials')}
              shape="circle"
              size="large"
              className={`${first(categoryBackgroundColor(actioningActor.vendor?.categories))} text-white`}
            />
          </div>
          <div className="flex align-items-start">
            {actionText()}
            {
              onRemove && (
                <Button
                  icon="pi pi-times"
                  className="p-0 max-w-min ml-2"
                  text
                  size="small"
                  type="button"
                  severity="secondary"
                  onClick={onRemove}
                />
              )
            }
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full text-center text-lg mt-4">
          <div
            className="cursor-pointer text-primary mb-4 font-semibold"
            onClick={() => navigate(relativeUrl)}
          >
            {task.title}
          </div>
        </div>
        <p className="text-xs mr-2 mt-2">
          {DateTime.fromISO(createdAt).toRelative()}
        </p>
        {actionButtons()}
      </div>
    </Panel>
  );
}

export default TaskNotification;
