import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import { isSubmittableArea } from 'utils/formUtils';
import { EVENT_HOST_ACTOR_UPDATE_MUTATION } from '../graphql';

function UpdateEventHostActorInstagram({
  toastRef, eventHostActor, setIsEditing, refetchEventHostActor,
}) {
  const formRef = useRef();

  const { hostActor } = eventHostActor;

  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: { instagramHandle: hostActor.instagramHandle },
  });

  const [updateEventHostActorMutation] = useMutation(EVENT_HOST_ACTOR_UPDATE_MUTATION, {
    onCompleted: ({ eventHostActorUpdate }) => {
      refetchEventHostActor();
      showSuccessToast(toastRef, `Successfully updated hostActor ${eventHostActorUpdate.eventHostActor.hostActor.name}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating hostActor: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (isSubmittableArea(formRef, e)) {
        if (isDirty) {
          await updateEventHostActorMutation({
            variables: {
              input: {
                id: eventHostActor.id,
                hostActorFields: { instagramHandle: getValues('instagramHandle') },
              },
            },
          });
        }

        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty]);

  return (
    <div ref={formRef} className="mt-2">
      <InstagramHandleInput
        control={control}
        className="w-1/2"
        name="instagramHandle"
      />
    </div>
  );
}

export default UpdateEventHostActorInstagram;
