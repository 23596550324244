import React, { useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router';
import { showErrorToast } from 'utils/toastUtils';
import { mapSelectOptions } from 'utils/formUtils';
import EventHostActorProfile from './EventHostActorProfile';
import TasksSummaryCard from './TasksSummaryCard';
import MergeEventsModal from './MergeEventsForm/Modal';
import { EVENT_OVERVIEW_QUERY } from './graphql';

function OverviewTab() {
  const [event, setEvent] = useState(null);
  const [mergeableEvents, setMergeableEvents] = useState([]);
  const [showMergeEventsModal, setShowMergeEventsModal] = useState(false);
  const [currentVendor, setCurrentVendor] = useState(null);
  const toastRef = useRef(null);

  const { eventId } = useParams();

  const {
    eventQueryLoading, eventQueryError, refetch,
  } = useQuery(
    EVENT_OVERVIEW_QUERY,
    {
      variables: { filters: { id: eventId } },
      onCompleted: (data) => {
        setCurrentVendor(data.currentVendor);

        const eventData = data.currentActor.events.edges[0].node;
        const eventInviteObject = eventData.currentEventVendor || eventData.currentEventHostActor;

        setEvent(eventData);
        setMergeableEvents(eventInviteObject.mergeableEvents || []);
      },
      onError: ({ graphQLErrors }) => {
        graphQLErrors.map(() => showErrorToast(toastRef, 'Error fetching event'));
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  if (eventQueryLoading || eventQueryError || !event) { return null; }

  const {
    eventHostActors,
    currentEventHostActor,
    currentActorTaskOverview,
    currentVendorTaskOverview,
  } = event;

  return (
    <>
      <div className="flex justify-between">
        <p className="font-bold text-xl">Overview</p>
        {
          !!mergeableEvents.length && (
            <div>
              <Tooltip target=".event-merge-button" />
              <Button
                className="event-merge-button"
                icon="pi pi-arrow-right-arrow-left"
                size="small"
                data-pr-tooltip="Merge Events"
                data-pr-position="top"
                onClick={() => setShowMergeEventsModal(true)}
              />
            </div>
          )
        }
      </div>
      <div className="content-with-nav-bar event-overview-content-container">
        <div className="items-stretch flex flex-wrap grid grid-cols-12 gap-4 h-fit">
          <div className="xxs:col-span-12 xl:col-span-4">
            <EventHostActorProfile
              toastRef={toastRef}
              eventHostActors={eventHostActors}
              currentEventHostActor={currentEventHostActor}
              event={event}
              refetchEventOverview={refetch}
            />
          </div>
          <div className="xxs:col-span-12 xl:col-span-8">
            <TasksSummaryCard
              currentActorTaskOverview={currentActorTaskOverview}
              currentVendorTaskOverview={currentVendorTaskOverview}
              currentVendor={currentVendor}
              eventId={eventId}
            />
          </div>
        </div>
      </div>
      <Toast ref={toastRef} position="bottom-left" />
      <MergeEventsModal
        show={showMergeEventsModal}
        onHide={() => setShowMergeEventsModal(false)}
        toastRef={toastRef}
        mergeableEventOptions={mapSelectOptions({ data: mergeableEvents, labelFieldName: 'name', valueFieldName: 'id' })}
      />
    </>
  );
}

export default OverviewTab;
