import React, { useState, useRef } from 'react';
import reduce from 'lodash/reduce';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { showErrorToast } from 'utils/toastUtils';
import { titleize } from 'utils/stringUtils';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import Dropdown from 'components/Form/Dropdown';
import MultiSelect from 'components/Form/MultiSelect';
import NumberInput from 'components/Form/NumberInput';
import { US_STATES_QUERY, VENDOR_CATEGORIES_QUERY } from 'graphql/shared';
import { VENDOR_DETAILS_QUERY, VENDOR_UPDATE_MUTATION } from './graphql';

function CreateVendorProfile({ onFormSubmit }) {
  const [usStates, setUsStates] = useState([]);
  const [vendorCategories, setVendorCategories] = useState([]);

  const toastRef = useRef(null);

  const {
    control, handleSubmit, setValue,
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      instagramHandle: '',
      categories: [],
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipCode: '',
    },
  });

  const {
    usStatesQueryLoading, usStatesQueryError,
  } = useQuery(
    US_STATES_QUERY,
    {
      onCompleted: (data) => setUsStates(data.usStates),
    },
  );

  const {
    vendorDetailsLoading, vendorDetailsError,
  } = useQuery(
    VENDOR_DETAILS_QUERY,
    {
      onCompleted: ({ currentVendor }) => {
        setValue('name', currentVendor.name);
        setValue('email', currentVendor.email);
        setValue('categories', currentVendor.categories);
        setValue('street1', currentVendor.address?.street1);
        setValue('street2', currentVendor.address?.street2);
        setValue('city', currentVendor.address?.city);
        setValue('state', currentVendor.address?.usState?.id);
        setValue('zipCode', currentVendor.address?.zipCode);
      },
    },
  );

  const {
    categoriesLoading, categoriesError,
  } = useQuery(
    VENDOR_CATEGORIES_QUERY,
    {
      onCompleted: (data) => {
        const mappedVendorCategories = reduce(data.vendorCategories, (options, category) => {
          options.push({ label: titleize(category), value: category });
          return options;
        }, []);

        setVendorCategories(mappedVendorCategories);
      },
    },
  );

  const [updateVendorMutation] = useMutation(VENDOR_UPDATE_MUTATION, {
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  const onSubmit = (values) => {
    const address = {
      street1: values.street1,
      street2: values.street2,
      city: values.city,
      usStateId: values.state,
      zipCode: values.zipCode?.toString(),
    };

    updateVendorMutation({
      variables: {
        input: {
          name: values.name,
          email: values.email,
          phoneNumber: values.phoneNumber,
          instagramHandle: values.instagramHandle,
          categories: values.categories,
          address,
        },
      },
    });

    onFormSubmit();
  };

  if (
    categoriesLoading
    || categoriesError
    || usStatesQueryLoading
    || usStatesQueryError
    || vendorDetailsLoading
    || vendorDetailsError
  ) {
    return null;
  }

  return (
    <div className="flex justify-center mt-8">
      <Toast ref={toastRef} position="bottom-left" />
      <div className="w-full grid grid-cols-12">
        <p className="text-center text-xl col-span-12 mt-12">Set up your vendor profile!</p>
        <div className="w-full col-span-8 mt-6 col-start-3">
          <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-12 col-span-10 col-start-2 mt-6 gap-4">
            <TextInput
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="name"
              label="Name"
            />
            <MultiSelect
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="categories"
              label="Categories"
              options={vendorCategories}
              showSelectAll={false}
              placeholder="Select a category"
            />
            <EmailInput
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="email"
              label="Vendor Email"
              required={false}
            />
            <PhoneNumberInput
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="phoneNumber"
              label="Vendor Phone Number"
            />
            <InstagramHandleInput
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="instagramHandle"
              label="Vendor Instagram Handle"
              required={false}
            />
            <div className="col-span-6 xxs:hidden lg:block" />
            <p className="font-semibold text-base col-span-12 my-4">Address</p>
            <TextInput
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="street1"
              label="Address (line 1)"
              required={false}
            />
            <TextInput
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="street2"
              label="Address (line 2)"
              required={false}
            />
            <TextInput
              className="xxs:col-span-12 md:col-span-6"
              control={control}
              name="city"
              label="City"
              required={false}
            />
            <Dropdown
              className="xxs:col-span-12 md:col-span-4"
              control={control}
              name="state"
              label="State"
              options={usStates.map((usState) => ({
                label: usState.abbreviation,
                value: usState.id,
              }))}
              required={false}
            />
            <NumberInput
              className="xxs:col-span-12 md:col-span-2"
              control={control}
              name="zipCode"
              label="Zip Code"
              required={false}
            />
            <Button type="submit" label="Submit" size="small" className="xxs:col-span-12 md:col-span-2 md:col-start-6 mt-4" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateVendorProfile;
