import React from 'react';
import { CATEGORY_TEXT_COLOR_MAPPING } from 'constants/colors';
import { formatDateTimeStr } from 'utils/stringUtils';

const FILE_CATEGORY_BACKGROUND_COLOR_MAPPING = {
  ATTACHMENT: 'bg-blue-500',
  MISC: 'bg-green-500',
  CONTRACT: 'bg-yellow-500',
  PROPOSAL: 'bg-cyan-500',
  INVOICE: 'bg-pink-500',
  QUESTIONNAIRE: 'bg-purple-500',
  SUB_AGREEMENT: 'bg-red-500',
  TIMELINE: 'bg-teal-500',
};
export const vendorTextColorClassName = (category) => CATEGORY_TEXT_COLOR_MAPPING[category] || CATEGORY_TEXT_COLOR_MAPPING.default;
export const fileCategoryBackgroundColorClassName = (fileCategory) => (
  FILE_CATEGORY_BACKGROUND_COLOR_MAPPING[fileCategory] || FILE_CATEGORY_BACKGROUND_COLOR_MAPPING.default
);
export const fileDetails = (file) => (
  <div className="flex flex-col justify-center items-center gap-1 mt-4">
    <i className="pi pi-file text-2xl" />
    <div className="font-semibold text-center w-full pl-4 pr-4"><p className="truncate">{file.name}</p></div>
    <div className="text-sm">{formatDateTimeStr(file.createdAt, 'L/d/yy')}</div>
  </div>
);
