import React from 'react';
import reduce from 'lodash/reduce';
import take from 'lodash/take';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { useMutation } from '@apollo/react-hooks';

import Dropdown from 'components/Form/Dropdown';
import { showErrorToast } from 'utils/toastUtils';
import { formatTimes } from 'utils/displayUtils';
import { SUBTASK_CREATE_MUTATION } from './graphql';

function SelectTimeBlockForm({
  taskId, toastRef, timeBlocks, vendorName, appendTimeBlock, hideModal,
}) {
  const [createSubtaskMutation] = useMutation(SUBTASK_CREATE_MUTATION, {
    onCompleted: () => { hideModal(); },
    onError: () => showErrorToast(toastRef, 'Error adding time block'),

  });
  const { control } = useForm();

  const mapTimeBlockOptions = () => reduce(timeBlocks, (mappedData, timeBlock) => {
    const { startTime, endTime } = timeBlock;

    let label;
    label = timeBlock.description.split(' ');

    if (label.length > 3) {
      label = `${take(label, 3).join(' ')}...`;
    } else {
      label = label.join(' ');
    }

    if (startTime || endTime) {
      label += ` (${formatTimes({ startTime, endTime })})`;
    }

    mappedData.push({ label, value: timeBlock.id });
    return mappedData;
  }, []);

  const addTimeBlock = () => {
    appendTimeBlock();
  };

  return (
    <div className="text-center pt-4">
      <Dropdown
        control={control}
        labelClassName="justify-center w-full mb-2"
        name="timeBlockId"
        placeholder="Select time block..."
        label={`Select Existing Time Block for ${vendorName}`}
        options={mapTimeBlockOptions()}
        onChange={(timeBlockId) => { createSubtaskMutation({ variables: { input: { taskId, timeBlockId } } }); }}
      />
      <p className="my-4">or</p>
      <Button type="button" onClick={addTimeBlock}>Create New</Button>
    </div>
  );
}

export default SelectTimeBlockForm;
