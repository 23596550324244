import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import { showSuccessToast } from 'utils/toastUtils';
import { UPDATE_EVENT_FILE_MUTATION, DELETE_FILE_MUTATION } from './graphql';

function HideOrDeleteFileButton({
  file, eventFileId, refetchFiles, toastRef,
}) {
  const [updateEventFileMutation] = useMutation(UPDATE_EVENT_FILE_MUTATION, {
    onCompleted: () => {
      showSuccessToast(toastRef, `Successfully hid ${file.name}`);
      refetchFiles();
    },
  });

  const [deleteFileMutation] = useMutation(DELETE_FILE_MUTATION, {
    onCompleted: () => {
      showSuccessToast(toastRef, `Successfully deleted ${file.name}`);
      refetchFiles();
    },
  });

  const confirmHide = () => {
    const message = `Are you sure you want to hide ${file.name}?`;

    return confirmDialog({
      className: 'xl:w-3/12',
      message,
      header: 'Hide File',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Hide from files tab',
      acceptIcon: 'pi pi-eye-slash',
      rejectLabel: 'Delete file everywhere',
      rejectIcon: 'pi pi-trash',
      draggable: false,
      acceptClassName: 'text-sm',
      rejectClassName: '[&:not(:hover)]:bg-red-400 [&:not(:hover)]:border-red-400 hover:bg-red-500 hover:border-red-500 text-sm',
      accept: () => updateEventFileMutation({ variables: { input: { id: eventFileId, showOnSummary: false } } }),
      reject: () => deleteFileMutation({ variables: { input: { id: file.id } } }),
    });
  };

  return (
    <Button
      onClick={confirmHide}
      onMouseDown={(e) => e.preventDefault()}
      icon="pi pi-times"
      rounded
      text
      severity="danger"
      aria-label="hide"
    />
  );
}

export default HideOrDeleteFileButton;
