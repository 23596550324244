import React, { useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { showErrorToast } from 'utils/toastUtils';
import SelectVendor from 'containers/SelectVendor';
import { SELECT_ACTOR_TYPE_MUTATION } from './graphql';

function SelectActorType({ currentUser, refetchCurrentUserAndVendor }) {
  const [showSelectVendor, setShowSelectVendor] = useState(false);
  const toastRef = useRef(null);

  const [selectActorTypeMutation] = useMutation(SELECT_ACTOR_TYPE_MUTATION, {
    onCompleted: ({ actorTypeSelect }) => {
      if (actorTypeSelect.currentActor) {
        refetchCurrentUserAndVendor();
      } else {
        setShowSelectVendor(true);
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error logging in: ${message}`)
      ));
    },
  });

  const onSubmit = ({ actorType }) => {
    selectActorTypeMutation({
      variables: { input: { actorType } },
    });
  };

  if (showSelectVendor) {
    return <SelectVendor currentUser={currentUser} refetchCurrentUserAndVendor={refetchCurrentUserAndVendor} />;
  }

  return (
    <>
      <Card className="w-screen h-screen flex items-center justify-center select-actor-type-card bg-primary-gray">
        <div className="flex items-center justify-center bg-primary-gray w-full h-full text-center">
          <div className="xxs:w-1/2 lg:w-1/3">
            <div className="flex justify-center mb-16">
              <Image imageStyle={{ height: '4rem' }} src="/images/ep-logo.png" alt="Enterplan" />
            </div>
            <div className="card">
              <div className="flex xxs:flex-col md:flex-row md:divide-x">
                <div className="w-full md:w-5 md:flex flex-col align-items-center justify-content-center md:py-5 xxs:pb-2 md:pr-16">
                  <Button
                    label="Login as Team Member"
                    className="w-full hover:opacity-90"
                    onClick={() => { onSubmit({ actorType: 'TEAM_MEMBER' }); }}
                  />
                </div>
                <div className="w-full md:w-5 md:flex align-items-center justify-content-center md:py-5 xxs:pt-2 md:pl-16">
                  <Button label="Login as Host" className="w-full hover:opacity-90" onClick={() => { onSubmit({ actorType: 'HOST' }); }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default SelectActorType;
