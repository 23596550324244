import React, { useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';

import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function AutoCompleteField(props) {
  const {
    control,
    name,
    label,
    suggestions,
    completeMethod,
    className = '',
    inputClassName = '',
    controllerProps,
    inputProps,
    required = false,
    focus = false,
    multiple = false,
    onSelect,
  } = props;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: '',
    ...controllerProps,
  });

  const inputRef = useRef();

  useEffect(() => {
    if (focus) { inputRef?.current?.focus(); }
  }, [inputRef]);

  const autoCompleteClasses = classNames(
    'w-full',
    {
      'p-invalid': error,
    },
    inputClassName,
  );

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className="block mb-2">{label}</label> }
      <AutoComplete
        id={name}
        ref={inputRef}
        multiple={multiple}
        name={name}
        value={value}
        suggestions={suggestions}
        completeMethod={completeMethod}
        field="label"
        onChange={onChange}
        onSelect={onSelect}
        className={autoCompleteClasses}
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default AutoCompleteField;
