import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { Steps } from 'primereact/steps';
import InvalidPage from 'components/InvalidPage';
import CreateAccountForm from './CreateAccountForm';
import CreateVendorProfile from './CreateVendorProfile';
import { CURRENT_ACTOR_QUERY } from './graphql';

function Onboarding({
  refetchCurrentUserAndVendor, currentVendor, currentUser, setIsOnboarding,
}) {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [currentActor, setCurrentActor] = useState(0);

  const vendorOnboardSteps = [
    { label: 'Create an account' },
    { label: 'Set up vendor profile' },
    { label: 'Manage your events' },
  ];

  const actorOnboardSteps = [
    { label: 'Create an account' },
    { label: 'Manage your events' },
  ];

  const isOnboardingVendor = currentActor?.isVendorPrimaryPointOfContact;

  useEffect(() => {
    setIsOnboarding(true);
  }, []);

  useEffect(() => {
    if (currentVendor) {
      setActiveStepIndex(1);
    } else if (!isOnboardingVendor && currentUser) {
      setActiveStepIndex(1);
    }
  }, [currentVendor]);

  const handleOnFormSubmit = () => {
    if (!isOnboardingVendor) {
      setIsOnboarding(false);
    }

    setActiveStepIndex(activeStepIndex + 1);
  };

  const { loading } = useQuery(
    CURRENT_ACTOR_QUERY,
    {
      onCompleted: (data) => {
        setCurrentActor(data.currentActor);
      },
    },
  );

  const createAccountForm = () => (
    <CreateAccountForm
      currentActor={currentActor}
      refetchCurrentUserAndVendor={refetchCurrentUserAndVendor}
      onFormSubmit={handleOnFormSubmit}
    />
  );

  const renderHostForms = () => {
    switch (activeStepIndex) {
      case 0:
        return createAccountForm();
      case 1:
        setIsOnboarding(false);
        return <Navigate to="/app/events" />;
      default:
        return null;
    }
  };

  const renderVendorForms = () => {
    switch (activeStepIndex) {
      case 0:
        return createAccountForm();
      case 1:
        return (
          <CreateVendorProfile
            onFormSubmit={handleOnFormSubmit}
          />
        );
      case 2:
        setIsOnboarding(false);
        return <Navigate to="/app/events" />;
      default:
        return null;
    }
  };

  if (loading || !currentActor) { return null; }

  if (!currentActor.invitationToken) {
    return (
      <InvalidPage
        header="Party foul!"
        subHeader1="This link is invalid or expired."
        subHeader2="Please check your email for the newest invitation."
        imgSrc="/images/party-foul.jpeg"
      />
    );
  }

  return (
    <div>
      <Steps labelClassName="mr-2" model={isOnboardingVendor ? vendorOnboardSteps : actorOnboardSteps} activeIndex={activeStepIndex} />
      { isOnboardingVendor ? renderVendorForms() : renderHostForms() }
    </div>
  );
}

export default Onboarding;
