import gql from 'graphql-tag';

const UPLOADED_FILE_FIELDS = gql`
  fragment UploadedFileFields on UploadedFile {
    id
    isUpdatableByCurrentActor
    name
    fileCategory
    fileExtension
    viewableBy
    showOnSummary
    createdByVendor {
      id
      name
      categories
    }
    createdByActor {
      id
      name
    }
    createdAt
    task {
      id
      title
    }
    timelineTimeBlock {
      id
      description
      tasks {
        id
        title
      }
    }
  }
`;

export default UPLOADED_FILE_FIELDS;
