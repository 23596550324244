import React from 'react';
import { Dialog } from 'primereact/dialog';

import SelectTimeBlockForm from '.';

function SelectTimeBlockModal({
  show, toastRef, taskId, timeBlocks, vendorName, appendTimeBlock, onHide,
}) {
  return (
    <Dialog
      id="select-time-block-form-modal"
      header="Add time block"
      className="xl:w-1/4"
      visible={show}
      draggable={false}
      onHide={onHide}
      maximizable
      dismissableMask
    >
      <SelectTimeBlockForm
        toastRef={toastRef}
        taskId={taskId}
        timeBlocks={timeBlocks}
        vendorName={vendorName}
        appendTimeBlock={appendTimeBlock}
        hideModal={onHide}
      />
    </Dialog>
  );
}

export default SelectTimeBlockModal;
