import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';

function ManageDisplaySettings({ displaySettings, setShowRejected, setShowRemoved }) {
  return (
    <>
      <div className="flex justify-between items-center text-nowrap mb-4">
        <p className="flex flex-nowrap mr-4">Show Rejected</p>
        <InputSwitch checked={displaySettings.showRejected} onChange={({ value }) => setShowRejected(value)} />
      </div>
      <div className="flex justify-between items-center text-nowrap">
        <p className="flex flex-nowrap mr-4">Show Removed</p>
        <InputSwitch checked={displaySettings.showRemoved} onChange={({ value }) => setShowRemoved(value)} />
      </div>
    </>
  );
}

export default ManageDisplaySettings;
