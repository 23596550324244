import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PasswordInput from 'components/Form/PasswordInput';
import PasswordConfirmationInput from 'components/Form/PasswordConfirmationInput';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { updatePassword } from 'auth/api';

function UpdatePassword() {
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [createdNewPassword, setCreatedNewPassword] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get('reset-password-token');

  const { control, handleSubmit, getValues } = useForm({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = async ({ password, passwordConfirmation }) => {
    const { errors } = await updatePassword({
      password,
      passwordConfirmation,
      resetPasswordToken,
    });

    if (errors) {
      if (errors[0] === 'Unauthorized') {
        setIsInvalidToken(true);
        setSubmissionErrors(['The token you provided is invalid. Please try to reset password again.']);
      } else {
        setSubmissionErrors(errors.full_messages);
      }
    } else {
      setSubmissionErrors([]);
      setCreatedNewPassword(true);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="lg:w-3/12">
        {
          createdNewPassword ? (
            <>
              <p className="text-center">Password succesfully updated!</p>
              <p className="text-center"><Link to="/login">Return to login</Link></p>
            </>
          ) : null
        }
        <div className="text-center">
          {
            submissionErrors.map((error) => <p key={error} className="text-danger mt-2">{ error }</p>)
          }
        </div>

        {
          isInvalidToken ? (
            <p className="text-center">
              <Link to="/send-reset-password">Send password reset link</Link>
            </p>
          ) : null
        }

        <div className="update-password-form">
          <h3 className="text-center text-lg mb-6">Reset Password</h3>

          <form onSubmit={handleSubmit(onSubmit)}>
            <PasswordInput
              control={control}
              name="password"
              label="Password"
            />
            <PasswordConfirmationInput
              control={control}
              getValues={getValues}
            />
            <Button type="submit" label="Submit" className="mt-4 w-full" />
          </form>
        </div>

      </div>
    </div>
  );
}

export default UpdatePassword;
