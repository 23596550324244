import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { confirmDialog } from 'primereact/confirmdialog';

import FileModal from 'containers/Events/Show/FilesTab/FileModal';
import { DELETE_FILE_MUTATION } from './graphql';

function Attachments({
  className = '', attachments = [], deleteable = false, onDelete = () => {}, refetch = () => {},
}) {
  if (!attachments.length) { return null; }

  const [activeFile, setActiveFile] = useState(null);
  const toastRef = useRef();

  const [deleteFileMutation] = useMutation(DELETE_FILE_MUTATION, {
    onCompleted: ({ uploadedFileDelete }) => onDelete(uploadedFileDelete.id),
  });

  const deleteButton = ({ id }) => {
    const confirmDelete = () => {
      const message = 'Are you sure you want to delete this file?';

      return confirmDialog({
        className: 'xl:w-3/12',
        message,
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => deleteFileMutation({ variables: { input: { id } } }),
      });
    };

    return (
      <Button
        type="button"
        className="p-button-sm p-0 m-0 w-min ml-1"
        icon="pi pi-times"
        text
        severity="danger"
        onMouseDown={(e) => e.preventDefault()}
        onClick={confirmDelete}
      />
    );
  };

  const renderAttachment = (file) => (
    <div key={file.id} className={`flex items-center text-sm ${className}`}>
      <Tag icon="pi pi-file" value={file.name} onClick={() => setActiveFile(file)} className="mb-1 cursor-pointer" />
      { deleteable && deleteButton({ id: file.id }) }
      {
        activeFile === file && (
          <FileModal
            show
            onHide={() => setActiveFile(null)}
            file={file}
            toastRef={toastRef}
            refetch={refetch}
          />
        )
      }
    </div>
  );

  return attachments.map((attachment) => renderAttachment(attachment));
}

export default Attachments;
