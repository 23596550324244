import gql from 'graphql-tag';

const CHECKLIST_CREATE_MUTATION = gql`
  mutation CreateSubtaskChecklist($input: SubtaskChecklistCreateMutationInput!) {
    subtaskChecklistCreate(input: $input) {
      checklist {
        id
        title
      }
    }
  }
`;

const CHECKLIST_UPDATE_MUTATION = gql`
  mutation UpdateSubtaskChecklist($input: SubtaskChecklistUpdateMutationInput!) {
    subtaskChecklistUpdate(input: $input) {
      checklist {
        id
      }
    }
  }
`;

const CHECKLIST_ITEM_CREATE_MUTATION = gql`
  mutation CreateSubtaskChecklistItem($input: SubtaskChecklistItemCreateMutationInput!) {
    subtaskChecklistItemCreate(input: $input) {
      checklistItem {
        id
        body
      }
    }
  }
`;

const CHECKLIST_ITEM_UPDATE_MUTATION = gql`
  mutation UpdateChecklistItem($input: SubtaskChecklistItemUpdateMutationInput!) {
    subtaskChecklistItemUpdate(input: $input) {
      checklistItem {
        id
        body
        completed
      }
    }
  }
`;

const CHECKLIST_ITEM_DELETE_MUTATION = gql`
  mutation DeleteSubtaskChecklistItem($input: SubtaskChecklistItemDeleteMutationInput!) {
    subtaskChecklistItemDelete(input: $input) {
      id
    }
  }
`;

export {
  CHECKLIST_CREATE_MUTATION,
  CHECKLIST_UPDATE_MUTATION,
  CHECKLIST_ITEM_CREATE_MUTATION,
  CHECKLIST_ITEM_DELETE_MUTATION,
  CHECKLIST_ITEM_UPDATE_MUTATION,
};
