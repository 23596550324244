import gql from 'graphql-tag';

const UPDATE_ACTOR_MUTATION = gql`
  mutation UpdateActor($input: ActorUpdateMutationInput!) {
    actorUpdate(input: $input) {
      actor {
        id
      }
    }
  }
`;

const VENDOR_ROLES_QUERY = gql`
  query VendorRoles {
    currentVendor {
      teamMemberActorRoles  {
        id
        name
      }
    }
  }
`;

const CREATE_TEAM_MEMBER_ACTOR_MUTATION = gql`
  mutation CreateActor($input: TeamMemberActorCreateMutationInput!) {
    teamMemberActorCreate(input: $input) {
      teamMemberActor {
        id
        roles {
          id
          name
        }
        initials
        avatarUrl
        name
        firstName
        lastName
        email
        phoneNumber
        instagramHandle
        teamMemberUser {
          id
          inviteStatus
          accountHolder
          isCurrentUser
        }
      }
    }
  }
`;

const DEACTIVATE_TEAM_MEMBER_ACTOR_MUTATION = gql`
  mutation DeleteTeamMemberActor($input: TeamMemberActorDeactivateMutationInput!) {
    teamMemberActorDeactivate(input: $input) {
      id
    }
  }
`;

const INVITE_TEAM_MEMBER_ACTOR_MUTATION = gql`
  mutation InviteTeamMemberActor($input: TeamMemberActorInviteMutationInput!) {
    teamMemberActorInvite(input: $input) {
      teamMemberActor {
        id
        teamMemberUser {
          id
          inviteStatus
        }
      }
    }
  }
`;

export {
  CREATE_TEAM_MEMBER_ACTOR_MUTATION,
  UPDATE_ACTOR_MUTATION,
  VENDOR_ROLES_QUERY,
  DEACTIVATE_TEAM_MEMBER_ACTOR_MUTATION,
  INVITE_TEAM_MEMBER_ACTOR_MUTATION,
};
