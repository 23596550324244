import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Column } from 'primereact/column';

import Table from 'components/Table';
import { NOTIFICATIONS_SETTINGS_QUERY, NOTIFICATION_SETTING_UPDATE_MUTATION } from './graphql';

const NOTIFICATION_TYPES = ['inApp', 'email'];
function NotificationSettings() {
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [selectedNotificationSettings, setSelectedNotificationSettings] = useState([]);

  /* eslint-disable array-callback-return */
  useEffect(() => {
    const currentNotificationSettings = notificationSettings.reduce((mappedNotifications, notificationSetting, rowIndex) => {
      NOTIFICATION_TYPES.map((notificationType, cellIndex) => {
        if (notificationSetting[notificationType]) { mappedNotifications.push({ cellIndex: cellIndex + 1, rowIndex }); }
      });
      return mappedNotifications;
    }, []);

    setSelectedNotificationSettings(currentNotificationSettings);
  }, [notificationSettings.length]);
  /* eslint-enable array-callback-return */

  const { loading } = useQuery(
    NOTIFICATIONS_SETTINGS_QUERY,
    {
      onCompleted: (data) => {
        setNotificationSettings(data.currentActor.notificationSettings);
      },
    },
  );

  const [updateNotificationSettingMutation] = useMutation(
    NOTIFICATION_SETTING_UPDATE_MUTATION,
    { refetchQueries: ['CurrentUser'] },
  );

  const onCellSelect = ({ rowIndex, cellIndex }) => {
    updateNotificationSettingMutation({
      variables: {
        input: {
          id: notificationSettings[rowIndex].id,
          [NOTIFICATION_TYPES[cellIndex - 1]]: true,
        },
      },
    });
  };

  const onCellUnselect = ({ rowIndex, cellIndex }) => {
    updateNotificationSettingMutation({
      variables: {
        input: {
          id: notificationSettings[rowIndex].id,
          [NOTIFICATION_TYPES[cellIndex - 1]]: false,
        },
      },
    });
  };

  if (loading) { return null; }

  return (
    <>
      <div className="flex justify-between w-full">
        <p className="font-bold text-xl">Notifications</p>
      </div>
      <div className="content-with-nav-bar">
        <Table
          data={notificationSettings}
          showGridlines
          cellSelection
          selectionMode="multiple"
          selection={selectedNotificationSettings}
          onSelectionChange={(e) => setSelectedNotificationSettings(e.value)}
          onCellSelect={onCellSelect}
          onCellUnselect={onCellUnselect}
          dataKey="id"
          isDataSelectable={(event) => (event.data.field !== 'notificationType')}
          className="notification-settings-index-table w-full grid grid-cols-12"
          rowGroupMode="subheader"
          groupRowsBy="resource"
        >
          <Column className="col-span-8" header="Notification Type" field="notificationType" />
          <Column className="col-span-8" header="In App" field="inApp" body={() => null} />
          <Column className="col-span-8" header="Email" field="email" body={() => null} />
        </Table>
      </div>
    </>
  );
}

export default NotificationSettings;
