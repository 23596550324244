import React, { useEffect } from 'react';
import map from 'lodash/map';
import { Dialog } from 'primereact/dialog';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';
import TimeBlock from '.';

function TimeBlockModal(props) {
  const {
    currentVendor,
    show,
    onHide,
    actorOptions,
    vendorOptions,
    venueOptions,
    action,
    updateTimeBlock,
    deleteTimeBlock,
    timeBlock,
    setShowTimeBlockModal,
    onClickCreateSubTimeBlock,
    timeline,
  } = props;
  const header = () => <p className="col-span-6">Time Block</p>;
  const formatDateTimeValue = (dateTime) => DateTime.fromISO(dateTime).toISO();

  const {
    control, setValue, getValues, watch,
  } = useForm({
    defaultValues: {
      startTime: formatDateTimeValue(timeBlock.startTime),
      endTime: formatDateTimeValue(timeBlock.endTime),
      description: timeBlock.description,
      details: timeBlock.dateTime,
      venueIds: map(timeBlock.vendors.filter(({ isVenue }) => isVenue), 'id'),
      vendorIds: map(timeBlock.vendors.filter(({ isVenue }) => !isVenue), 'id'),
      participantActorIds: map(timeBlock.hostActors, 'id'),
      attachments: timeBlock.attachments,
    },
  });

  useEffect(() => {
    setValue('venueIds', map(timeBlock.vendors.filter(({ isVenue }) => isVenue), 'id'));
    setValue('vendorIds', map(timeBlock.vendors.filter(({ isVenue }) => !isVenue), 'id'));
    setValue('startTime', DateTime.fromISO(timeBlock.startTime).toISO());
    setValue('endTime', DateTime.fromISO(timeBlock.endTime).toISO());
    setValue('description', timeBlock.description);
    setValue('details', timeBlock.details);
    setValue('attachments', timeBlock.attachments);
    setValue('participantActorIds', map(timeBlock.hostActors, 'id'));
  }, [timeBlock.startTime, timeBlock.endTime, timeBlock.description, timeBlock.details, timeBlock.attachments, timeBlock.hostActorIds]);

  return (
    <Dialog
      header={header()}
      className="xl:w-1/3"
      visible={show}
      onHide={onHide}
      draggable={false}
      dismissableMask
      maximizable
      resizable
    >
      <TimeBlock
        minimumStartTime={timeline.startTime}
        action={action}
        actorOptions={actorOptions}
        vendorOptions={vendorOptions}
        venueOptions={venueOptions}
        setShowTimeBlockModal={setShowTimeBlockModal}
        onClickCreateSubTimeBlock={onClickCreateSubTimeBlock}
        updateTimeBlock={updateTimeBlock}
        deleteTimeBlock={deleteTimeBlock}
        timeBlock={timeBlock}
        currentVendor={currentVendor}
        control={control}
        getValues={getValues}
        setValue={setValue}
        watch={watch}
        updatable={timeBlock.isUpdatableByCurrentActor}
      />
    </Dialog>
  );
}

export default TimeBlockModal;
