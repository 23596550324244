import React, { useState, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Menubar } from 'primereact/menubar';
import { SplitButton } from 'primereact/splitbutton';
import { Avatar } from 'primereact/avatar';
import Notifications from './Notifications';
import { CURRENT_VENDOR_AND_ACTOR_DETAILS_QUERY } from './graphql';

function TopNav({ toggleSidebarVisible, refetchEvents }) {
  const navigate = useNavigate();
  const [showUserDropdownItems, setShowUserDropdownItems] = useState(false);
  const [currentVendor, setCurrentVendor] = useState([]);
  const [currentActor, setCurrentActor] = useState([]);
  const toastRef = useRef();
  const userDropdownRef = useRef();

  useQuery(
    CURRENT_VENDOR_AND_ACTOR_DETAILS_QUERY,
    {
      onCompleted: (data) => {
        setCurrentVendor(data.currentVendor);
        setCurrentActor(data.currentActor);
      },
    },
  );

  const userDropdownItems = [
    {
      label: 'Settings',
      icon: 'pi pi-cog',
      command: () => { navigate('/app/settings'); },
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: () => { navigate('/logout'); },
    },
  ];

  const start = (
    <div className="flex items-center">
      <Button onClick={() => navigate('/')} className="border-none bg-white p-0">
        <Image className="ml-1" imageStyle={{ height: '1.5rem' }} src="/images/ep-logo.png" alt="Enterplan" />
      </Button>
      <Button
        icon="pi pi-bars"
        className="toggle-sidebar-button ml-6 bg-white text-primary border-none"
        onClick={() => toggleSidebarVisible()}
      />
    </div>
  );

  const showUserDropdown = (event) => {
    if (showUserDropdownItems) {
      userDropdownRef.current.hide(event);
    } else {
      userDropdownRef.current.show(event);
    }
  };

  const avatarUrl = currentVendor?.avatarUrl || currentActor?.avatarUrl;

  const avatarClasses = classNames(
    'cursor-pointer',
    'bg-white',
    'text-primary',
    'border',
    { 'border-primary': !avatarUrl },
  );

  const end = (
    <div className="flex items-center">
      <Notifications toastRef={toastRef} refetchEvents={refetchEvents} />
      {
        (currentVendor || currentActor) && (
          <SplitButton
            ref={userDropdownRef}
            label={(
              <Avatar
                className={avatarClasses}
                label={currentVendor?.initials || currentActor?.initials}
                image={avatarUrl}
                size="normal"
                shape="circle"
              />
          )}
            onClick={showUserDropdown}
            onHide={() => setShowUserDropdownItems(false)}
            onShow={() => setShowUserDropdownItems(true)}
            model={userDropdownItems}
            buttonClassName="bg-none p-0 border-none"
            menuButtonClassName="hidden"
            severity="contrast"
          />
        )
      }
    </div>
  );

  return (
    <div className="app-top-nav app-top-nav fixed z-30 left-0 top-0 w-full py-0 pl-4 pr-8 bg-white flex items-center h-16">
      <Menubar start={start} className="bg-white border-none p-0 w-full" end={end} />
      <Toast
        ref={toastRef}
        position="bottom-left"
        style={{ zIndex: 1000 }}
      />
    </div>
  );
}

export default TopNav;
