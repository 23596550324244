import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import DropdownField from 'components/Form/Dropdown';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { actorItemTemplate } from 'utils/formUtils';
import { UPDATE_VENDOR_CONTACTS_MUTATION, VENDOR_CONTACTS_QUERY } from './graphql';

function UpdateDefaultContactsForm({ toastRef, actors }) {
  const [currentVendorName, setCurrentVendorName] = useState('');
  const {
    control, handleSubmit, setValue,
  } = useForm();
  const mappedActors = actors.map((actor) => ({
    label: actor.name, value: actor.id, id: actor.id, initials: actor.initials, avatarUrl: actor.avatarUrl,
  }));
  const {
    vendorContactsLoading, vendorContactsError,
  } = useQuery(
    VENDOR_CONTACTS_QUERY,
    {
      onCompleted: ({ currentVendor }) => {
        setCurrentVendorName(currentVendor.name);
        setValue('defaultTaskAssigneeActorId', currentVendor.defaultTaskAssigneeActor?.id);
        setValue('primaryPointOfContactActorId', currentVendor.primaryPointOfContactActor?.id);
      },
    },
  );

  if (vendorContactsLoading || vendorContactsError) { return null; }

  const [updateVendorContactsMutation] = useMutation(UPDATE_VENDOR_CONTACTS_MUTATION, {
    onCompleted: async () => {
      showSuccessToast(toastRef, 'Successfully updated default contacts');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const onSubmit = (values) => updateVendorContactsMutation({
    variables: { input: { ...values } },
  });

  return (
    <>
      <div className="flex">
        <p className="font-bold text-xl">Default Contacts</p>
        <div className="flex items-center">
          <Tooltip target=".default-contacts-tooltip-icon" />
          <i
            className="default-contacts-tooltip-icon pi pi-info-circle ml-1"
            data-pr-tooltip={`These will be the default contacts set on an event when ${currentVendorName} creates or is added to an event.`}
            data-pr-position="top"
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      <div className="content-with-nav-bar">
        <Card className="flex justify-center">
          <Toast ref={toastRef} position="bottom-left" />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="md:grid md:grid-cols-2 gap-2">
              <DropdownField
                control={control}
                name="defaultTaskAssigneeActorId"
                label="Default Task Assignee"
                options={mappedActors}
                inputProps={{
                  placeholder: 'Select a default task assignee',
                  itemTemplate: (option) => actorItemTemplate({ option }),
                  valueTemplate: (option) => actorItemTemplate({ option }),
                }}
              />
              <DropdownField
                control={control}
                name="primaryPointOfContactActorId"
                label="Primary Point of Contact"
                className="xxs:mt-5 md:mt-0"
                options={mappedActors}
                inputProps={{
                  placeholder: 'Select a primary point of contact',
                  itemTemplate: (option) => actorItemTemplate({ option }),
                  valueTemplate: (option) => actorItemTemplate({ option }),
                }}
              />
            </div>
            <div className="grid grid-cols-12 mt-4">
              <Button
                className="xxs:col-span-12 md:col-span-2 md:col-start-6 mx-0"
                label="Update"
                type="submit"
              />
            </div>
          </form>
        </Card>
      </div>
    </>
  );
}

export default UpdateDefaultContactsForm;
