import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { confirmDialog } from 'primereact/confirmdialog';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import Table from 'components/Table';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';

import AvatarWithName from 'components/AvatarWithName';
import { REVIEW_SOURCE_DELETE_MUTATION, REVIEW_SOURCE_CREATE_MUTATION, REVIEW_SOURCE_UPDATE_MUTATION } from './graphql';

function ManageReviewSourcesForm({
  reviewSources, refetch, toastRef,
}) {
  const [mappedReviewSources, setMappedReviewSources] = useState(reviewSources);
  const [isCreatingReviewSource, setIsCreatingReviewSource] = useState(false);

  const refresh = async () => {
    const response = await refetch();
    setMappedReviewSources(response.data.currentVendor.editableReviewSources);
  };

  const [createReviewSourceMutation] = useMutation(REVIEW_SOURCE_CREATE_MUTATION, {
    onCompleted: async () => {
      await refresh();
      showSuccessToast(toastRef, 'Successfully created review source');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const [updateReviewSourceMutation] = useMutation(REVIEW_SOURCE_UPDATE_MUTATION, {
    onCompleted: async () => {
      await refresh();
      showSuccessToast(toastRef, 'Successfully updated review source');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const [deleteReviewSourceMutation] = useMutation(REVIEW_SOURCE_DELETE_MUTATION, {
    onCompleted: async () => {
      await refresh();
      showSuccessToast(toastRef, 'Successfully deleted review source');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const deleteButton = (reviewSource) => {
    const confirmDelete = () => confirmDialog({
      className: 'xl:w-1/4',
      message: 'Are you sure you want to delete this review source?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteReviewSourceMutation({ variables: { input: { id: reviewSource.id } } }),
    });

    return (
      <Button
        key={`${reviewSource.id}-delete`}
        type="button"
        onClick={confirmDelete}
        icon="pi pi-times"
        rounded
        text
        className="review-source-action w-min p-2"
        aria-review-source="Delete"
        severity="danger"
      />
    );
  };

  const onRowEditComplete = ({ data: reviewSource, newData }) => {
    if (reviewSource.id) {
      updateReviewSourceMutation({ variables: { input: { id: reviewSource.id, name: newData.name } } });
    } else {
      createReviewSourceMutation({ variables: { input: { name: newData.name } } });
      setIsCreatingReviewSource(false);
    }
  };

  const textEditor = (options) => (
    <InputText className="w-full" required type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
  );

  const actionButtons = (reviewSource, { rowEditor }) => {
    if (isCreatingReviewSource && !reviewSource.name && !rowEditor.editing) { rowEditor.onInitClick({ preventDefault: () => {} }); }

    if (rowEditor?.editing) {
      return (
        <div className="flex items-center text-xs">
          <Button
            key={`${reviewSource.id}-save`}
            type="button"
            icon="pi pi-check"
            rounded
            text
            className="review-source-action w-min p-2"
            onClick={rowEditor.onSaveClick}
          />
          <Button
            key={`${reviewSource.id}-cancel`}
            type="button"
            icon="pi pi-times"
            rounded
            text
            className="review-source-action w-min p-2"
            onClick={rowEditor.onCancelClick}
          />
        </div>
      );
    }
    return (
      <div className="flex items-center text-xs">
        <Button
          key={`${reviewSource.id}-edit`}
          type="button"
          icon="pi pi-pencil"
          rounded
          text
          className="review-source-action w-min p-2"
          onClick={rowEditor.onInitClick}
        />
        { deleteButton(reviewSource) }
      </div>
    );
  };

  const onClickAdd = () => {
    setIsCreatingReviewSource(true);
    setMappedReviewSources([{ name: '' }].concat(mappedReviewSources));
  };

  const actorColumn = (actor = {}) => (
    <AvatarWithName
      id={actor.id}
      initials={actor.initials}
      avatarUrl={actor.avatarUrl}
      text={actor.name}
    />
  );

  return (
    <>
      <div className="flex justify-end">
        <Button
          rounded
          type="button"
          className="h-8 w-8"
          icon="pi pi-plus"
          onClick={onClickAdd}
          disabled={isCreatingReviewSource}
        />
      </div>
      <Table
        className="grid grid-cols-12"
        data={mappedReviewSources}
        editable
        onRowEditComplete={onRowEditComplete}
        pageSize={10}
        clickable={false}
        defaultSortField="createdBy"
        defaultSortOrder={-1}
      >
        <Column className="col-span-5" field="name" header="Name" editor={(options) => textEditor(options)} />
        <Column
          className="col-span-3 xxs:hidden lg:table-cell"
          headerClassName="xxs:hidden lg:table-cell"
          header="Created By"
          body={({ createdByActor }) => actorColumn(createdByActor)}
        />
        <Column
          className="col-span-3 xxs:hidden lg:table-cell"
          headerClassName="xxs:hidden lg:table-cell"
          header="Last Updated By"
          body={({ lastUpdatedByActor }) => actorColumn(lastUpdatedByActor)}
        />
        <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }} body={actionButtons} />
      </Table>
    </>
  );
}

export default ManageReviewSourcesForm;
