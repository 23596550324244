import gql from 'graphql-tag';

const EVENT_HOST_ACTOR_CREATE_MUTATION = gql`
  mutation CreateEventHostActor($input: EventHostActorCreateMutationInput!) {
    eventHostActorCreate(input: $input) {
      eventHostActor {
        id
      }
    }
  }
`;

const EVENT_HOST_ACTOR_UPDATE_MUTATION = gql`
  mutation UpdateEventHostActor($input: EventHostActorUpdateMutationInput!) {
    eventHostActorUpdate(input: $input) {
      eventHostActor {
        id
        notes
        internalNotes
        hostActor {
          id
          firstName
          lastName
          name
          email
          phoneNumber
          instagramHandle
          avatarUrl
        }
      }
    }
  }
`;

const EVENT_INVITE_UPDATE_MUTATION = gql`
  mutation UpdateEventInvite($input: EventInviteUpdateMutationInput!) {
    eventInviteUpdate(input: $input) {
      eventInvite {
        id
        status
        event {
          id
        }
      }
    }
  }
`;

export {
  EVENT_HOST_ACTOR_CREATE_MUTATION,
  EVENT_HOST_ACTOR_UPDATE_MUTATION,
  EVENT_INVITE_UPDATE_MUTATION,
};
