import gql from 'graphql-tag';

const NOTIFICATIONS_QUERY = gql`
  query Notifications($eventVendorFilters: EventVendorFilterInput) {
    currentVendor {
      id
    }
    currentActor {
      id
      notifications {
        id
        createdAt
        isRead
        category
        currentVendorIsActioned
        currentVendorIsActioner
        currentActorIsActioner
        actioningActor {
          id
          name
          initials
          avatarUrl
          vendor {
            id
            name
            initials
            categories
          }
        }
        actionedActor {
          id
          name
          initials
          avatarUrl
          vendor {
            id
            name
            initials
            categories
          }
        }
        action
        relativeUrl
        task {
          id
          title
        }
        inviteDetails {
          id
          currentActorIsOnEvent
          approvalSubjectType
          eventVendor {
            id
            vendor {
              id
              name
            }
          }
          eventHostActor {
            id
            hostActor {
              id
              name
            }
          }
          invitedByActor {
            id
            name
            initials
            avatarUrl
            vendor {
              id
              name
              initials
              categories
            }
          }
          requestedByActor {
            id
            name
            initials
            avatarUrl
            vendor {
              id
              name
              initials
              categories
            }
          }
          event {
            id
            name
            startDate
            eventVendors(filters: $eventVendorFilters) {
              id
              categories
              vendor {
                id
                initials
                categories
              }
            }
          }
        }
      }
    }
  }
`;

const NOTIFICATION_REMOVE_MUTATION = gql`
  mutation RemoveNotification($input: NotificationRemoveMutationInput!) {
    notificationRemove(input: $input) {
      id
    }
  }
`;

const NOTIFICATION_MARK_AS_READ_MUTATION = gql`
  mutation MarkNotificationAsRead($input: NotificationMarkAsReadMutationInput!) {
    notificationMarkAsRead(input: $input) {
      notification {
        id
        isRead
      }
    }
  }
`;

export {
  NOTIFICATIONS_QUERY,
  NOTIFICATION_REMOVE_MUTATION,
  NOTIFICATION_MARK_AS_READ_MUTATION,
};
