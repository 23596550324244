import React from 'react';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';

import { titleize } from 'utils/stringUtils';
import { mergeClassNames } from 'utils/styleUtils';

function Table({
  data,
  className = '',
  editable = false,
  showGridlines = false,
  showHeaders = true,
  editMode = 'row',
  onRowClick = () => {},
  onRowEditComplete = () => {},
  children: columns,
  pageSize = 10,
  defaultSortField = null,
  defaultSortOrder = '-1',
  sortMode = 'single',
  scrollHeight = 'calc(100vh - 23rem)',
  clickable = true,
  onCellSelect = () => {},
  onCellUnselect = () => {},
  cellSelection = false,
  rowGroupHeaderTemplate = null,
  rowGroupMode = 'subheader',
  groupRowsBy = null,
  reorderableRows = false,
  expandedRows = [],
  onRowToggle = () => {},
  rowExpansionTemplate = null,
  isDataSelectable = null,
  selection = [],
  selectionMode = null,
  onSelectionChange = () => {},
}) {
  const defaultRowGroupHeaderTemplate = (rowData) => <span className="font-bold">{titleize(rowData[groupRowsBy])}</span>;
  const tableClassNames = classNames(
    'w-full',
    {
      'cursor-pointer': clickable,
    },
  );

  return (
    <DataTable
      value={data}
      showGridlines={showGridlines}
      showHeaders={showHeaders}
      editMode={editable && editMode}
      onRowEditComplete={onRowEditComplete}
      rowGroupMode={groupRowsBy ? rowGroupMode : null}
      groupRowsBy={groupRowsBy}
      isDataSelectable={isDataSelectable}
      sortMode={sortMode}
      scrollable
      scrollHeight={scrollHeight}
      sortField={defaultSortField}
      sortOrder={defaultSortOrder}
      rowGroupHeaderTemplate={rowGroupHeaderTemplate || defaultRowGroupHeaderTemplate}
      onRowClick={onRowClick}
      rows={pageSize}
      cellSelection={cellSelection}
      onCellSelect={onCellSelect}
      onCellUnselect={onCellUnselect}
      metaKeySelection={false}
      reorderableRows={reorderableRows}
      expandedRows={expandedRows}
      onRowToggle={onRowToggle}
      rowExpansionTemplate={rowExpansionTemplate}
      selectionMode={selectionMode}
      selection={selection}
      onSelectionChange={onSelectionChange}
      className={mergeClassNames(`${tableClassNames} ${className}`)}
    >
      { columns }
    </DataTable>
  );
}

export default Table;
