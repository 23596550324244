import React from 'react';
import { classNames } from 'primereact/utils';
import { Sidebar as BaseSidebar } from 'primereact/sidebar';

import SidebarMenuItem from './SidebarMenuItem';

function Sidebar({
  sidebarMenuItems, visible, toggleSidebarVisible,
}) {
  const hiddenClassNames = classNames(
    {
      hidden: !visible,
    },
  );

  return (
    <>
      <div className={
        `app-sidebar xxs:hidden md:block ${hiddenClassNames} sticky bg-white shrink-0
         h-screen left-0 top-0 z-20 border-r border-gray-200 select-none w-[12rem] pt-16
      `
      }
      >
        <div className="flex flex-col h-full">
          <div className="overflow-auto">
            <ul className="list-none p-4 overflow-hidden">
              {
              sidebarMenuItems.map((item) => (
                <SidebarMenuItem
                  key={item.name}
                  text={item.name}
                  icon={item.icon}
                  route={item.route}
                />
              ))
            }
            </ul>
          </div>
        </div>
      </div>
      <div className="card flex justify-center">
        <BaseSidebar visible={!visible} maskClassName="md:hidden mobile-sidebar w-48" onHide={() => toggleSidebarVisible()}>
          <ul className="list-none p-4 pt-0 overflow-hidden">
            {
            sidebarMenuItems.map((item) => (
              <SidebarMenuItem
                key={item.name}
                text={item.name}
                icon={item.icon}
                route={item.route}
                toggleSidebarVisible={toggleSidebarVisible}
              />
            ))
          }
          </ul>
        </BaseSidebar>
      </div>
    </>
  );
}

export default Sidebar;
