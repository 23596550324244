import gql from 'graphql-tag';

const EVENT_INVITE_UPDATE_MUTATION = gql`
  mutation UpdateEventInvite($input: EventInviteUpdateMutationInput!) {
    eventInviteUpdate(input: $input) {
      eventInvite {
        id
        status
        event {
          id
        }
      }
    }
  }
`;

export {
  EVENT_INVITE_UPDATE_MUTATION,
};
