import gql from 'graphql-tag';

const TASK_UPDATE_MUTATION = gql`
  mutation UpdateTask($input: TaskUpdateMutationInput!) {
    taskUpdate(input: $input) {
      task {
        id
        title
        description
        templateName
        assignedToActor {
          id
          vendor {
            id
            name
          }
        }
        dueDate
      }
    }
  }
`;

const SUBTASK_TEMPLATE_APPLY_MUTATION = gql`
  mutation ApplySubtaskTemplate($input: SubtaskTemplateApplyMutationInput!) {
    subtaskTemplateApply(input: $input) {
      task {
        id
      }
    }
  }
`;

const TASK_ARCHIVE_MUTATION = gql`
  mutation ArchiveTask($input: TaskArchiveMutationInput!) {
    taskArchive(input: $input) {
      task {
        id
      }
    }
  }
`;

const TASK_UNARCHIVE_MUTATION = gql`
  mutation UnarchiveTask($input: TaskUnarchiveMutationInput!) {
    taskUnarchive(input: $input) {
      task {
        id
      }
    }
  }
`;

const TASK_DELETE_MUTATION = gql`
  mutation DeleteTask($input: TaskDeleteMutationInput!) {
    taskDelete(input: $input) {
      id
    }
  }
`;

export {
  TASK_UPDATE_MUTATION,
  TASK_ARCHIVE_MUTATION,
  TASK_UNARCHIVE_MUTATION,
  TASK_DELETE_MUTATION,
  SUBTASK_TEMPLATE_APPLY_MUTATION,
};
