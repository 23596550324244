import React from 'react';
import RichTextAreaMentionableInput from '../RichTextAreaMentionableInput';

function RichTextAreaInput(props) {
  return (
    <RichTextAreaMentionableInput mentionsDisabled disabledMessage="" {...props} />
  );
}

export default RichTextAreaInput;
