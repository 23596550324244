import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';

import FileModal from 'containers/Events/Show/FilesTab/FileModal';

const FILE_CATEGORY_TAG_CLASSES = {
  ATTACHMENT: 'border-blue-500 bg-blue-500 text-white',
  MISC: 'border-green-500 bg-green-500 text-white',
  CONTRACT: 'border-yellow-500 bg-yellow-500 text-white',
  PROPOSAL: 'border-cyan-500 bg-cyan-500 text-white',
  INVOICE: 'border-pink-500 bg-pink-500 text-white',
  QUESTIONNAIRE: 'border-purple-500 bg-purple-500 text-white',
  SUB_AGREEMENT: 'border-red-500 bg-red-500 text-white',
  TIMELINE: 'border-teal-500 bg-teal-500 text-white',
};
function FileItem({
  file, eventFile, failedFile, onUpload, onRemove, onUpdate, isUploading,
}) {
  const [showFileModal, setShowFileModal] = useState(false);
  const toastRef = useRef();

  const confirmRemove = () => {
    const message = `Are you sure you want to remove ${file.name}?`;

    return confirmDialog({
      className: 'xl:w-3/12',
      message,
      header: 'Remove File',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Remove',
      acceptIcon: 'pi pi-trash',
      rejectLabel: 'Cancel',
      draggable: false,
      acceptClassName: 'text-sm',
      accept: () => onRemove(),
    });
  };

  const retryButton = () => (
    <div className="flex col-span-3 md:col-span-2 items-center">
      <Button type="button" icon="pi pi-refresh" text severity="danger" onClick={() => onUpload({ files: [failedFile] })} />
    </div>
  );

  return (
    <>
      <div
        key={`${file.name}-file-item`}
        className="flex items-center justify-between [&:not(:hover)]:px-0 cursor-pointer hover:bg-primary hover:text-white hover:pl-1 rounded"
      >
        { failedFile && retryButton() }
        <p className="w-6/12 p-1 text-left" onClick={() => !isUploading && setShowFileModal(true)}>
          { file.name }
        </p>
        {
          !isUploading && (
            <Tag
              onClick={() => setShowFileModal(true)}
              className={`text-nowrap border ${FILE_CATEGORY_TAG_CLASSES[file.fileCategory]}`}
              icon="pi pi-tag text-sm mr-1"
              value={file.fileCategory}
            />
          )
        }
        <Button type="button" icon="pi pi-times" text severity="danger" onClick={confirmRemove} />
      </div>
      {
        showFileModal && (
          <FileModal
            show
            onHide={() => setShowFileModal(false)}
            file={file}
            eventFile={eventFile}
            toastRef={toastRef}
            refetch={onUpdate}
          />
        )
      }
    </>
  );
}

export default FileItem;
