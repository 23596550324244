import gql from 'graphql-tag';

const VENDORS_SEARCH_QUERY = gql`
  query VendorsSearch($filters: VendorFilterInput) {
    vendorsSearch(filters: $filters) {
      id
      name
    }
  }
`;

const VENDOR_DETAILS_QUERY = gql`
  query VendorsDetailsFetch($filters: VendorFilterInput) {
    vendorsSearch(filters: $filters) {
      id
      name
      googlePlaceId
      address {
        street1
        street2
        city
        usState {
          id
          name
        }
        zipCode
      }
    }
  }
`;

export { VENDORS_SEARCH_QUERY, VENDOR_DETAILS_QUERY };
