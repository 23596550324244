const showSuccessToast = (toastRef, detail, life = 3000) => {
  toastRef.current.show({
    severity: 'success', summary: 'Success!', detail, life,
  });
};

const showErrorToast = (toastRef, detail, life = 5000) => {
  toastRef.current.show({
    severity: 'error', summary: 'Error!', detail, life,
  });
};

const showInfoToast = (toastRef, detail, life = 5000) => {
  toastRef.current.show({
    severity: 'info', summary: '', detail, life,
  });
};

export {
  showSuccessToast,
  showInfoToast,
  showErrorToast,
};
