import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import map from 'lodash/map';
import TextInput from 'components/Form/TextInput';
import DateInput from 'components/Form/DateInput';
import { showErrorToast } from 'utils/toastUtils';
import FindOrCreateHostForm from 'containers/Events/CreateHostForm/FindOrCreateHostForm';
import CreateHostModal from 'containers/Events/CreateHostForm/Modal';
import { EVENT_CREATE_MUTATION } from './graphql';

const TIME_ZONES_MAPPING = {
  'America/Chicago': 'AMERICA_CHICAGO',
  'America/New_York': 'AMERICA_NEW_YORK',
  'America/Los_Angeles': 'AMERICA_LOS_ANGELES',
  'America/Denver': 'AMERICA_DENVER',
  'Pacific/Honolulu': 'PACIFIC_HONOLULU',
  'America/Anchorage': 'AMERICA_ANCHORAGE',
};
function CreateEventModal(props) {
  const [showCreateHostModal, setShowCreateHostModal] = useState(false);
  const navigate = useNavigate();
  const {
    show,
    showAddHost,
    onHide,
    refetchEvents,
    toastRef,
  } = props;

  const {
    control, handleSubmit, setValue, getValues, watch,
  } = useForm({
    defaultValues: {
      eventName: '',
      hostActors: [],
      eventStartDate: null,
    },
  });

  const [createEventMutation] = useMutation(EVENT_CREATE_MUTATION, {
    onCompleted: async ({ eventCreate }) => {
      onHide();
      await refetchEvents();
      navigate(`/app/events/${eventCreate.event.id}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error creating event: ${message}`)
      ));
    },
  });

  const onSubmit = (values) => {
    createEventMutation({
      variables: {
        input: {
          name: values.eventName,
          startDate: values.eventStartDate,
          timeZone: TIME_ZONES_MAPPING[Intl.DateTimeFormat().resolvedOptions().timeZone],
          hostActorIds: map(values.hostActors, 'value'),
        },
      },
    });
  };

  const addToSelectedHostActors = ({ id, name }) => {
    const hostActorOption = { value: id, label: name };

    setValue('hostActors', getValues('hostActors').concat([hostActorOption]));
  };

  return (
    <>
      {
        !showCreateHostModal && (
          <Dialog
            className="xl:w-5/12"
            header="Create new event"
            visible={show}
            onHide={onHide}
            dismissableMask
            resizable={false}
            draggable={false}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center md:grid md:grid-cols-2 gap-4 mt-4 mb-6">
                <TextInput
                  focus
                  className="mb-0"
                  control={control}
                  name="eventName"
                  label="Event Name"
                />
                <DateInput
                  control={control}
                  name="eventStartDate"
                  label="Event Date"
                  className="mb-0"
                  tooltip="Not sure? Skip it and set it later"
                  required={false}
                />
              </div>
              {
                showAddHost && (
                  <FindOrCreateHostForm
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    getValues={getValues}
                    setShowCreateHostModal={setShowCreateHostModal}
                  />
                )
              }
              <div className="w-full flex justify-end mt-4">
                <Button
                  label="Add"
                  size="small"
                  type="submit"
                />
              </div>
            </form>
          </Dialog>
        )
      }
      <CreateHostModal
        show={showCreateHostModal}
        onHide={() => setShowCreateHostModal(false)}
        toastRef={toastRef}
        afterCreate={addToSelectedHostActors}
      />
    </>
  );
}

export default CreateEventModal;
