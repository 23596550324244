import React, { useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { Tag } from 'primereact/tag';

import Dropdown from 'components/Form/Dropdown';
import { cursorClassName, mergeClassNames } from 'utils/styleUtils';

function EditableDropdown({
  label,
  labelClassName = '',
  name,
  value,
  icon,
  options,
  isEditing,
  setIsEditing,
  onUpdate = () => {},
  control,
  setValue,
  updatable,
  className = '',
  hoverClassNames = 'hover:bg-white hover:text-info hover:border hover:border-info',
  inputClassName = '',
  inputProps = {},
  inputWrapperClassName = '',
  placeholder = 'N/A',
  showClose = true,
}) {
  useEffect(() => {
    setValue(name, value);
  }, [value]);

  const updatableHoverClasses = classNames(
    { [hoverClassNames]: updatable },
  );

  return (
    <div className={`${className} ${cursorClassName(updatable)}`}>
      { label && <p className={`mb-1 ${labelClassName}`}>{label}</p> }
      {
        isEditing ? (
          <div className="flex items-center">
            <Dropdown
              control={control}
              name={name}
              value={value}
              options={options}
              className={mergeClassNames(`m-0 p-0 text-sm w-unset ${inputWrapperClassName}`)}
              inputClassName={inputClassName}
              inputProps={{
                onChange: (data) => (value !== data.value ? onUpdate(data.value) : {}),
                placeholder: 'Select...',
                ...inputProps,
              }}
            />
            { showClose && <i className="pi pi-times text-xs ml-1 cursor-pointer text-gray-500" onClick={() => setIsEditing(false)} /> }
          </div>
        ) : (
          <div className="flex items-center">
            <Tag
              onClick={updatable ? () => setIsEditing(true) : () => {}}
              className={updatableHoverClasses}
              icon={icon ? `pi pi-${icon} text-sm mr-1` : null}
              value={options.find((option) => option.value === value)?.label || placeholder}
              severity="info"
            />
          </div>
        )
      }
    </div>
  );
}

export default EditableDropdown;
