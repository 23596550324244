import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Image } from 'primereact/image';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import EmailInput from 'components/Form/EmailInput';
import PasswordInput from 'components/Form/PasswordInput';
import { loginUser } from 'auth/api';

function Login({ refetchCurrentUserAndVendor }) {
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async ({ email, password }) => {
    const { errors } = await loginUser({ email, password });

    if (errors) {
      setSubmissionErrors(errors);
    } else {
      refetchCurrentUserAndVendor();
    }
  };

  return (
    <Card className="w-screen h-screen login-container-wrapper">
      <div className="flex items-center login-container w-full h-full grid grid-cols-12">
        <div className="xxs:col-span-12 md:col-span-5">
          <div className="flex justify-center mb-6">
            <Image imageStyle={{ height: '4rem' }} src="/images/ep-logo.png" alt="Enterplan" />
          </div>
          <div className="text-center">
            {
              submissionErrors.map((error) => <p key={error} className="text-danger mt-2">{ error }</p>)
            }
          </div>
          <form className="grid grid-cols-12" onSubmit={handleSubmit(onSubmit)}>
            <EmailInput
              control={control}
              className="col-span-8 col-start-3 mb-4"
              name="email"
              label="Email"
            />
            <PasswordInput
              control={control}
              name="password"
              className="col-span-8 col-start-3"
              label="Password"
              feedback={false}
            />
            <Button type="submit" label="Submit" className="col-span-8 col-start-3 mt-4" />
          </form>
          <div className="flex justify-center w-full mt-4">
            <Link to="/sign-up">Create an account</Link>
          </div>
          <div className="flex justify-center w-full mt-1">
            <Link to="/send-reset-password">Forgot password?</Link>
          </div>
        </div>
        <div className="xxs:hidden md:block md:col-span-7 event-planning-image h-full bg-cover bg-center" />
      </div>
    </Card>
  );
}

export default Login;
