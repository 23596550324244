import gql from 'graphql-tag';

const DELETE_ANSWER_MUTATION = gql`
  mutation DeleteSubtaskQuestionAnswer($input: SubtaskQuestionAnswerDeleteMutationInput!) {
    subtaskQuestionAnswerDelete(input: $input) {
      id
    }
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteTaskComment($input: TaskCommentDeleteMutationInput!) {
    taskCommentDelete(input: $input) {
      id
    }
  }
`;

const MENTION_MARK_AS_READ_MUTATION = gql`
  mutation MarkMentionAsRead($input: MentionMarkAsReadMutationInput!) {
    mentionMarkAsRead(input: $input) {
      mention {
        id
      }
    }
  }
`;

const UPDATE_ANSWER_MUTATION = gql`
  mutation UpdateSubtaskQuestionAnswer($input: SubtaskQuestionAnswerUpdateMutationInput!) {
    subtaskQuestionAnswerUpdate(input: $input) {
      answer {
        id
      }
    }
  }
`;

const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateTaskComment($input: TaskCommentUpdateMutationInput!) {
    taskCommentUpdate(input: $input) {
      comment {
        id
      }
    }
  }
`;

const CREATE_ANSWER_MUTATION = gql`
  mutation CreateSubtaskQuestionAnswer($input: SubtaskQuestionAnswerCreateMutationInput!) {
    subtaskQuestionAnswerCreate(input: $input) {
      answer {
        id
      }
    }
  }
`;

const CREATE_COMMENT_MUTATION = gql`
  mutation CreateTaskComment($input: TaskCommentCreateMutationInput!) {
    taskCommentCreate(input: $input) {
      comment {
        id
      }
    }
  }
`;

export {
  DELETE_ANSWER_MUTATION,
  DELETE_COMMENT_MUTATION,
  MENTION_MARK_AS_READ_MUTATION,
  CREATE_ANSWER_MUTATION,
  CREATE_COMMENT_MUTATION,
  UPDATE_ANSWER_MUTATION,
  UPDATE_COMMENT_MUTATION,
};
