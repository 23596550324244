import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { DateTime } from 'luxon';
import first from 'lodash/first';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import { mergeClassNames } from 'utils/styleUtils';
import { EVENT_INVITE_UPDATE_MUTATION } from './graphql';

function EventInvite({
  toastRef,
  className = '',
  onSuccess = () => {},
  onUndo = () => {},
  refetch = () => {},
  onRemove = null,
  event,
  createdAt,
  status,
  notificationId,
  onInView,
  inviterActor,
  requesterActor,
  actionerActor,
  actionedActorOrVendor = {},
  approvalSubjectType,
  eventVendorId,
  eventHostActorId,
  currentActorIsOnEvent,
  currentActorIsActioner,
  currentVendorIsActioner,
  currentVendorIsActioned,
}) {
  const isNotification = !!notificationId;
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && onInView) { onInView({ id: notificationId }); }
  }, [inView]);

  const navigate = useNavigate();
  const categoryBackgroundColor = (category) => CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default;
  const displayName = (actor) => {
    if (currentActorIsActioner) {
      return 'You';
    } if (currentVendorIsActioner) {
      return actor.name;
    }
    return actor.vendor?.name || actor.name;
  };

  /* eslint-disable consistent-return */
  const actionText = () => {
    const actionerName = displayName(actionerActor);
    let actionedName = actionedActorOrVendor.vendor?.name || 'you';

    switch (status) {
      case 'REQUESTED': {
        return <p><span className="font-semibold">{ actionerName }</span>{' requested to join'}</p>;
      }
      case 'INVITED': {
        return (
          <p>
            <span className="font-semibold">{ actionerName }</span>{' invited '}
            <span className="font-semibold">{ actionedName }</span>{' to join'}
          </p>
        );
      }
      case 'REJECTED':
      case 'APPROVED': {
        const action = status === 'APPROVED' ? 'accepted' : 'rejected';
        let name = '';

        if (approvalSubjectType === 'INVITE') {
          name = currentActorIsOnEvent ? 'your' : `${inviterActor.vendor?.name || inviterActor.name}'s`;
        } else {
          name = currentActorIsOnEvent ? `${requesterActor.vendor?.name || requesterActor.name}'s` : 'your';
        }

        return (
          <p>
            <span className="font-semibold">{ actionerName }</span>{` ${action} `}
            <span className="font-semibold">{ name }</span>{` ${(approvalSubjectType || 'request').toLowerCase()} to join`}
          </p>
        );
      }
      case 'REMOVED': {
        if (!currentVendorIsActioner && !currentVendorIsActioned) { actionedName = eventVendorId ? 'itself' : 'themself'; }

        return (
          <p>
            <span className="font-semibold">{ actionerName }</span>{' removed '}
            <span className="font-semibold">{ actionedName }</span>{' from'}
          </p>
        );
      }
      default:
    }
  };
  /* eslint-enable consistent-return */

  /* eslint-disable no-use-before-define */
  /* eslint-disable react/no-unstable-nested-components */
  const showAcceptedInviteToast = () => {
    toastRef.current.show({
      severity: 'success',
      summary: `You've been added to ${event.name}!`,
      life: 5000,
      content: ({ message }) => (
        <div className="flex flex-col flex-1 items-left">
          <div>{message.summary}</div>
          <Button
            className="p-button-sm max-w-min text-nowrap"
            label="Go to event"
            severity="success"
            onClick={() => {
              toastRef.current.clear();
              navigate(`/app/events/${event.id}`);
            }}
          />
        </div>
      ),
    });
  };

  const showRejectedInviteToast = () => {
    toastRef.current.show({
      severity: 'info',
      summary: `You won't be added to ${event.name}.`,
      life: 5000,
      content: ({ message }) => (
        <div className="flex flex-col flex-1 items-left">
          <div>{message.summary}</div>
          <Button
            className="p-button-sm max-w-min text-nowrap"
            label="Undo"
            severity="info"
            onClick={() => updateEventInvite({ status: 'INVITED' })}
          />
        </div>
      ),
    });
  };

  const showCancelledRequestToast = () => {
    toastRef.current.show({
      severity: 'info',
      summary: `Request to join ${event.name} cancelled`,
      life: 5000,
      content: ({ message }) => (
        <div className="flex flex-col items-start flex-1">
          <div>{message.summary}</div>
          <Button
            className="p-button-sm max-w-min text-nowrap"
            label="Undo"
            severity="info"
            onClick={() => updateEventInvite({ status: 'REQUESTED' })}
          />
        </div>
      ),
    });
  };
  /* eslint-enable react/no-unstable-nested-components */
  /* eslint-enable no-use-before-define */

  const [updateEventInviteMutation, { loading }] = useMutation(EVENT_INVITE_UPDATE_MUTATION, {
    onCompleted: ({ eventInviteUpdate }) => {
      const { eventInvite } = eventInviteUpdate;

      switch (eventInvite.status) {
        case 'APPROVED': {
          if (currentActorIsOnEvent) {
            showSuccessToast(toastRef, 'Approved!');
          } else {
            showAcceptedInviteToast();
          }
          onSuccess();
          break;
        }
        case 'REJECTED': {
          if (currentActorIsOnEvent) {
            showSuccessToast(toastRef, 'Rejected');
          } else {
            showRejectedInviteToast();
          }
          onSuccess();
          break;
        }
        case 'INVITED': {
          onUndo({ eventId: eventInvite.event.id });
          break;
        }
        case 'REQUESTED': {
          showSuccessToast(toastRef, 'Request to join sent!');
          onSuccess();
          break;
        }
        case 'REMOVED': {
          showCancelledRequestToast();
          onSuccess();
          break;
        }
        default: break;
      }

      refetch();
    },
    onError: () => showErrorToast(toastRef, 'Something went wrong :('),
    refetchQueries: ['Notifications', 'Events'],
  });

  const updateEventInvite = (eventInvite) => {
    toastRef.current?.clear();
    updateEventInviteMutation({ variables: { input: { eventVendorId, eventHostActorId, status: eventInvite.status } } });
  };

  const actionButtons = () => {
    const buttonContainerClassNames = classNames(
      { flex: isNotification },
      'md:flex',
      'justify-center',
      'mt-4',
      'grid',
      { 'xxs:grid-cols-1 md:grid-cols-2 w-8/12 gap-2': !isNotification },
      { 'grid-cols-2 w-full gap-1': isNotification },
    );
    const buttonClassNames = classNames(
      { 'text-xs': isNotification },
      'mr-0',
      'text-nowrap',
    );

    switch (status) {
      case 'APPROVED': {
        return (
          <div className={mergeClassNames(buttonContainerClassNames, 'grid-cols-1')}>
            <Button
              className={buttonClassNames}
              label="Go to event"
              type="button"
              onClick={() => navigate(`/app/events/${event.id}`)}
            />
          </div>
        );
      }
      case 'INVITED': {
        return (
          <div className={buttonContainerClassNames}>
            <Button
              label="Reject"
              className={`${buttonClassNames} ${isNotification ? 'mr-2' : ''} md:mr-2`}
              type="button"
              severity="danger"
              disabled={loading}
              onClick={() => updateEventInvite({ status: 'REJECTED' })}
            />
            <Button
              label="Accept"
              className={buttonClassNames}
              type="button"
              severity="success"
              disabled={loading}
              onClick={() => updateEventInvite({ status: 'APPROVED' })}
            />
          </div>
        );
      }
      case 'REQUESTED': {
        if (currentActorIsOnEvent) {
          return (
            <div className={buttonContainerClassNames}>
              <Button
                label="Reject"
                className={`${buttonClassNames} ${isNotification ? 'mr-2' : ''} md:mr-2`}
                type="button"
                severity="danger"
                disabled={loading}
                onClick={() => updateEventInvite({ status: 'REJECTED' })}
              />
              <Button
                label="Accept"
                className={buttonClassNames}
                type="button"
                severity="success"
                disabled={loading}
                onClick={() => updateEventInvite({ status: 'APPROVED' })}
              />
            </div>
          );
        }
        return (
          <div className={buttonContainerClassNames}>
            <Button
              label="Cancel request"
              className={`${buttonClassNames} ${isNotification ? 'mr-2' : ''} md:mr-2`}
              type="button"
              severity="danger"
              disabled={loading}
              onClick={() => updateEventInvite({ status: 'REMOVED' })}
            />
            <Button
              label="Re-send request to join"
              className={buttonClassNames}
              type="button"
              disabled={loading}
              onClick={() => updateEventInvite({ status: 'REQUESTED' })}
            />
          </div>
        );
      }
      case 'REJECTED':
      case 'REMOVED': {
        if (currentActorIsOnEvent) {
          return (
            <Button
              label="Re-send invite to join"
              className={`${buttonClassNames} mt-4`}
              type="button"
              disabled={loading}
              onClick={() => updateEventInvite({ status: 'INVITED' })}
            />
          );
        }
        return (
          <Button
            label="Re-send request to join"
            className={`${buttonClassNames} mt-4`}
            type="button"
            disabled={loading}
            onClick={() => updateEventInvite({ status: 'REQUESTED' })}
          />
        );
      }
      default: return null;
    }
  };

  const actionerAttribute = (attributeName) => (actionerActor.vendor || {})[attributeName] || actionerActor.attributeName;

  return (
    <Panel className={className} key={`${event.id}-actioned-event`}>
      <div ref={ref} className="flex flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <div className="w-12 h-12 mr-2 rounded-full">
            <Avatar
              key={`${event.id}-actioner-avatar`}
              image={actionerAttribute('avatarUrl')}
              label={actionerAttribute('initials')}
              shape="circle"
              size="large"
              className={`${first(categoryBackgroundColor(actionerActor.vendor?.categories))} text-white`}
            />
          </div>
          <div className="flex align-items-start">
            {actionText()}
            {
              onRemove && (
                <Button
                  icon="pi pi-times"
                  className="p-0 max-w-min ml-2"
                  text
                  size="small"
                  type="button"
                  severity="secondary"
                  onClick={onRemove}
                />
              )
            }
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full text-center text-lg mt-4">
          <div
            className="cursor-pointer text-primary mb-4 font-semibold"
            onClick={() => navigate(`/app/events/${event.id}`)}
          >
            {event.name}
          </div>
          { event.startDate && <div>{DateTime.fromISO(event.startDate).toFormat('M/d/yy')}</div> }
          {
            event.eventVendors.length >= 1 ? (
              <AvatarGroup>
                {
                  event.eventVendors.map((eventVendor) => (
                    <Avatar
                      key={`${eventVendor.id}-avatar`}
                      image={eventVendor.vendor.avatarUrl}
                      label={eventVendor.vendor.initials}
                      className={`${categoryBackgroundColor(eventVendor.categories[0])} text-white`}
                      shape="circle"
                    />
                  ))
                }
              </AvatarGroup>
            ) : null
          }
        </div>
        <p className="text-xs mr-2 mt-2">
          {DateTime.fromISO(createdAt).toRelative()}
        </p>
        {actionButtons()}
      </div>
    </Panel>
  );
}

export default EventInvite;
