import React from 'react';
import { Dialog } from 'primereact/dialog';
import CreateTeamMemberGroupForm from './CreateTeamMemberGroupForm';

function CreateTeamMemberGroupModal({
  show, onHide, teamMemberActors, refetchTeamMembersAndGroups, toastRef,
}) {
  return (
    <Dialog
      header="Create New Team Member Group"
      className="xl:w-5/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
    >
      <CreateTeamMemberGroupForm
        teamMemberActors={teamMemberActors}
        refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
        hideModal={onHide}
        toastRef={toastRef}
      />
    </Dialog>
  );
}

export default CreateTeamMemberGroupModal;
