import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import TextInput from 'components/Form/TextInput';
import EmailInput from 'components/Form/EmailInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import { showErrorToast } from 'utils/toastUtils';
import { HOST_ACTOR_CREATE_MUTATION } from './graphql';

function CreateHostModal(props) {
  const {
    show,
    onHide,
    afterCreate = () => {},
    toastRef,
  } = props;

  const {
    control, handleSubmit, getValues, reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  });

  const onClose = () => {
    reset();
    onHide();
  };

  const [createHostActorMutation] = useMutation(HOST_ACTOR_CREATE_MUTATION, {
    onCompleted: async ({ hostActorCreate }) => {
      afterCreate(hostActorCreate.hostActor);
      onClose();
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error creating host: ${message}`)
      ));
    },
  });

  const onSubmit = () => (
    createHostActorMutation({
      variables: {
        input: {
          email: getValues('email'),
          firstName: getValues('firstName'),
          lastName: getValues('lastName'),
          phoneNumber: getValues('phoneNumber'),
        },
      },
    })
  );

  return (
    <Dialog
      className="xl:w-5/12"
      header="Create new host"
      visible={show}
      onHide={onClose}
      dismissableMask
      resizable={false}
      draggable={false}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center grid grid-cols-2 gap-4 mt-4">
          <TextInput
            required
            control={control}
            name="firstName"
            label="First Name"
          />
          <TextInput
            required
            control={control}
            name="lastName"
            label="Last Name"
          />
        </div>
        <div className="flex items-center grid grid-cols-2 gap-4">
          <EmailInput
            required
            control={control}
            name="email"
            label="Email"
          />
          <PhoneNumberInput
            control={control}
            name="phoneNumber"
            label="Phone Number"
          />
        </div>
        <div className="w-full flex justify-end mt-4">
          <Button
            label="Add"
            size="small"
            type="submit"
          />
        </div>
      </form>
    </Dialog>
  );
}

export default CreateHostModal;
