import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import { titleize } from 'utils/stringUtils';
import { showErrorToast } from 'utils/toastUtils';
import CompleteQuestionForm from './CompleteQuestionForm';
import SubtaskStatus from './SubtaskStatus';
import CompleteFileRequestForm from './CompleteFileRequestForm';
import CompleteChecklistForm from './CompleteChecklistForm';
import CompleteTimeBlockForm from './CompleteTimeBlockForm';
import { SUBTASK_DELETE_MUTATION } from './graphql';

function CompleteSubtaskForm({
  subtask,
  isSubtaskTemplate,
  setSubtaskUpdated,
  onRemoveEmptySubtask,
  task = {},
  onCreate = () => {},
  mentionables,
  currentActor,
  refetch,
  toastRef,
}) {
  const [activeSubtask, setActiveSubtask] = useState(subtask);

  useEffect(() => {
    setActiveSubtask(subtask);
  }, [subtask]);

  const {
    id, subtaskType, question, checklist, fileRequest, timeBlock, templateName,
  } = activeSubtask;

  const [deleteSubtaskMutation] = useMutation(SUBTASK_DELETE_MUTATION, {
    onCompleted: async () => { await refetch(); },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const deleteButton = () => {
    const confirmDelete = () => {
      if (!activeSubtask.id) { return onRemoveEmptySubtask(); }

      const message = `Are you sure you want to delete this ${titleize(subtaskType).toLowerCase()}?`;

      return confirmDialog({
        className: 'xl:w-3/12',
        message,
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => deleteSubtaskMutation({ variables: { input: { id } } }),
      });
    };

    return (
      <Button
        key={`subtask-${id}-delete`}
        icon="pi pi-trash"
        size="small"
        text
        type="button"
        severity="danger"
        aria-label="Delete"
        onClick={confirmDelete}
        onMouseDown={(e) => e.preventDefault()}
      />
    );
  };

  const header = (title) => {
    if (!task.id) { return null; }

    return (
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          {
            !task.isTemplate && (
              <SubtaskStatus
                className="mr-2"
                subtask={activeSubtask}
                setSubtaskUpdated={setSubtaskUpdated}
                key={`${id}-subtask-status`}
                refetch={refetch}
                toastRef={toastRef}
              />
            )
          }
          <p>{title}</p>
        </div>
        { deleteButton() }
      </div>
    );
  };

  const completeQuestionForm = () => (
    <CompleteQuestionForm
      task={task}
      onCreate={onCreate}
      isSubtaskTemplate={isSubtaskTemplate}
      subtaskId={id}
      templateName={templateName}
      setSubtaskUpdated={setSubtaskUpdated}
      header={header('Question')}
      isUpdatableByCurrentActor={task.isUpdatableByCurrentActor || isSubtaskTemplate}
      mentionables={mentionables}
      onRemoveEmptyQuestion={onRemoveEmptySubtask}
      currentActor={currentActor}
      question={question}
      refetch={refetch}
      toastRef={toastRef}
    />
  );
  const completeFileRequestForm = () => (
    <CompleteFileRequestForm
      subtaskId={id}
      templateName={templateName}
      onCreate={onCreate}
      isSubtaskTemplate={isSubtaskTemplate}
      setSubtaskUpdated={setSubtaskUpdated}
      header={header('File Request')}
      task={task}
      onRemoveEmptyFileRequest={onRemoveEmptySubtask}
      isUpdatableByCurrentActor={task.isUpdatableByCurrentActor || isSubtaskTemplate}
      currentActorId={currentActor?.id}
      fileRequest={fileRequest}
      refetch={refetch}
      toastRef={toastRef}
    />
  );
  const completeChecklistForm = () => (
    <CompleteChecklistForm
      subtaskId={id}
      templateName={templateName}
      isSubtaskTemplate={isSubtaskTemplate}
      setSubtaskUpdated={setSubtaskUpdated}
      onCreate={onCreate}
      toastRef={toastRef}
      header={header('Checklist')}
      task={task}
      checklist={checklist}
      refetch={refetch}
    />
  );
  const completeTimeBlockForm = () => (
    <CompleteTimeBlockForm
      subtaskId={id}
      onCreate={onCreate}
      header={header('Time Block')}
      task={task}
      timeBlock={timeBlock}
      refetch={refetch}
    />
  );

  const body = () => {
    switch (subtaskType) {
      case 'QUESTION':
        return completeQuestionForm();
      case 'FILE_REQUEST':
        return completeFileRequestForm();
      case 'CHECKLIST':
        return completeChecklistForm();
      case 'TIME_BLOCK':
        return completeTimeBlockForm();
      default:
        return null;
    }
  };

  return body();
}

export default CompleteSubtaskForm;
