import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DateTime } from 'luxon';
import { useForm } from 'react-hook-form';

import CreateSubTimeBlockGroupForm from '.';

function CreateSubTimeBlockGroupModal({
  parentTimeBlock,
  createTimeBlock,
  actorOptions,
  vendorOptions,
  venueOptions,
  teamMemberOptionsForVendorId,
  currentVendor,
  toastRef,
  show,
  onHide,
  timeline,
}) {
  const header = () => <p className="col-span-6">Create Sub Time Block Group</p>;
  const startTime = DateTime.fromISO(timeline.startTime);

  const {
    control, setValue, watch, handleSubmit,
  } = useForm({
    defaultValues: {
      vendorId: parentTimeBlock.vendors.find(({ isVenue }) => !isVenue)?.id,
      venueId: parentTimeBlock.vendors.find(({ isVenue }) => isVenue)?.id,
      startTime: startTime.toISO(),
      endTime: startTime.plus({ minutes: 15 }).toISO(),
      description: '',
      details: '',
      attachmentIds: [],
      participantActorIds: [],
      subTimeBlocks: [],
    },
  });

  const onHideModal = () => {
    onHide();
    setValue('vendorId', currentVendor?.id);
    setValue('venueId', null);
    setValue('description', '');
    setValue('details', '');
    setValue('attachmentIds', []);
    setValue('participantActorIds', []);
    setValue('subTimeBlocks', []);
  };

  return (
    <Dialog
      header={header()}
      className="xl:w-2/3"
      visible={show}
      onHide={onHideModal}
      draggable={false}
      dismissableMask
      maximizable
      resizable
    >
      <CreateSubTimeBlockGroupForm
        handleSubmit={handleSubmit}
        createTimeBlock={createTimeBlock}
        toastRef={toastRef}
        control={control}
        setValue={setValue}
        watch={watch}
        parentTimeBlock={parentTimeBlock}
        teamMemberOptionsForVendorId={teamMemberOptionsForVendorId}
        actorOptions={actorOptions}
        vendorOptions={vendorOptions}
        venueOptions={venueOptions}
      />
    </Dialog>
  );
}

export default CreateSubTimeBlockGroupModal;
