import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useFieldArray } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { showErrorToast } from 'utils/toastUtils';
import { confirmDialog } from 'primereact/confirmdialog';
import map from 'lodash/map';
import omit from 'lodash/omit';
import compact from 'lodash/compact';

import { formatDateTimeStr } from 'utils/stringUtils';
import { actorItemTemplate } from 'utils/formUtils';
import { timeOptions } from 'constants/selectOptions';
import EditableDropdown from 'components/Form/EditableFields/Dropdown';
import EditableMultiSelect from 'components/Form/EditableFields/MultiSelect';
import EditableTextArea from 'components/Form/EditableFields/TextArea';
import ActorsOrVendorsItem from '../ActorsOrVendorsItem';

function SubTimeBlockGroup({
  subTimeBlockGroup,
  updateTimeBlock,
  deleteTimeBlock,
  parentTimeBlock,
  setShowSubTimeBlockGroupModal,
  updatable,
  toastRef,
  actorOptions,
  vendorOptions,
  venueOptions,
  teamMemberOptionsForVendorId,
  control,
  watch,
  setValue,
  getValues,
}) {
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [teamMemberOptions, setTeamMemberOptions] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(getValues('vendorId'));
  const [isEditing, setIsEditing] = useState(false);
  const timeSelectOptions = timeOptions({
    startTime: DateTime.fromISO(parentTimeBlock.startTime),
    endTime: DateTime.fromISO(parentTimeBlock.endTime),
  });
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const {
    fields: subTimeBlockFields,
    append: appendSubTimeBlock,
    remove: removeSubTimeBlock,
  } = useFieldArray({ control, name: 'subTimeBlocks' });

  useEffect(() => {
    const vendorId = getValues('vendorId');

    setSelectedVendorId(vendorId);
    setTeamMemberOptions(teamMemberOptionsForVendorId({ vendorId }));

    if (selectedVendorId !== vendorId) {
      setValue('teamMemberActorIds', []);
    }
  }, [watch('vendorId')]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const deleteButton = () => {
    if (!subTimeBlockGroup) { return null; }

    const confirmDelete = () => confirmDialog({
      appendTo: document.getElementById('sub-time-block-group-modal'),
      className: 'xl:w-3/12',
      message: 'Are you sure you want to delete this sub time block group?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteTimeBlock({ timeBlockId: subTimeBlockGroup.id }),
      reject: () => setShowSubTimeBlockGroupModal(true),
    });

    return (
      <Button
        key={`${subTimeBlockGroup.id}-delete`}
        type="button"
        onClick={confirmDelete}
        icon="pi pi-trash"
        size="small"
        label="Delete"
        severity="danger"
        aria-label="Delete"
      />
    );
  };

  const formatTimes = ({ startTime, endTime }) => {
    const formattedStartTime = formatDateTimeStr(startTime, 'h:mm a');
    const formattedEndTime = formatDateTimeStr(endTime, 'h:mm a');

    if (endTime) {
      return (
        <div>
          <p className="font-semibold text-nowrap">{`${formattedStartTime} - ${formattedEndTime}`}</p>
        </div>
      );
    }
    return <p className="font-semibold">{formattedStartTime}</p>;
  };
  const subTimeBlockField = ({ idx }) => {
    const fieldName = `subTimeBlocks.${idx}`;
    const smallScreenSize = screenWidth <= 1023;

    return (
      <div className="mb-2 w-full">
        <div className={`grid grid-cols-12 gap-2 ${idx && !smallScreenSize ? 'items-start' : 'items-center'}`}>
          <div className={isEditing ? 'col-span-10' : 'col-span-12'}>
            <div className="grid grid-cols-12 gap-2 items-start">
              {
                isEditing ? (
                  <>
                    <EditableDropdown
                      updatable={updatable}
                      inputWrapperClassName="xs:col-span-6 lg:col-span-2"
                      className="xs:col-span-6 lg:col-span-2"
                      control={control}
                      required
                      name={`${fieldName}.startDateTime`}
                      value={watch(`${fieldName}.startDateTime`)}
                      setValue={setValue}
                      label={smallScreenSize || !idx ? 'Start Time' : null}
                      labelClassName="font-semibold"
                      options={timeSelectOptions}
                      inputProps={{ placeholder: 'Select...' }}
                      showClose={false}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      onUpdate={(startTime) => setValue(`${fieldName}.startDateTime`, startTime)}
                    />
                    <EditableDropdown
                      updatable={updatable}
                      value={watch(`${fieldName}.endDateTime`)}
                      setValue={setValue}
                      onUpdate={(endTime) => setValue(`${fieldName}.endDateTime`, endTime)}
                      inputWrapperClassName="xs:col-span-6 lg:col-span-2"
                      className="xs:col-span-6 lg:col-span-2"
                      control={control}
                      name={`${fieldName}.endDateTime`}
                      required={false}
                      label={smallScreenSize || !idx ? 'End Time' : null}
                      labelClassName="font-semibold"
                      options={timeSelectOptions}
                      showClose={false}
                      inputProps={{ placeholder: 'Select...' }}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  </>
                ) : (
                  <div className="xs:col-span-6 lg:col-span-2">
                    { !idx ? <p className="font-semibold mb-1">Time</p> : null }
                    { formatTimes({ startTime: getValues(`${fieldName}.startDateTime`), endTime: getValues(`${fieldName}.endDateTime`) }) }
                  </div>
                )
              }
              <EditableTextArea
                required
                updatable={updatable}
                value={watch(`${fieldName}.description`)}
                setValue={setValue}
                getValues={getValues}
                formats={[]}
                onUpdate={(description) => setValue(`${fieldName}.description`, description)}
                textViewClassName="xs:col-span-12 lg:col-span-5"
                textViewWrapperClassName="xs:col-span-12 lg:col-span-5"
                name={`${fieldName}.description`}
                control={control}
                label={smallScreenSize || !idx ? 'Description' : null}
                labelClassName="font-semibold mb-1"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                setSubmitDisabled={setSubmitDisabled}
              />
              <EditableMultiSelect
                updatable={updatable}
                value={watch(`${fieldName}.participantActorIds`)}
                setValue={setValue}
                onUpdate={(participantActorIds) => setValue(`${fieldName}.participantActorIds`, participantActorIds)}
                className="xs:col-span-12 lg:col-span-3"
                textViewClassName="xs:col-span-12 lg:col-span-3"
                name={`${fieldName}.participantActorIds`}
                required={false}
                control={control}
                label={smallScreenSize || !idx ? 'Hosts' : null}
                labelClassName="font-semibold"
                options={actorOptions}
                placeholder="Select..."
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                inputProps={{
                  itemTemplate: (option) => actorItemTemplate({ option }),
                }}
              >
                <div className="xs:col-span-12 lg:col-span-3">
                  <ActorsOrVendorsItem actorsOrVendors={getValues(`${fieldName}.participantActors`)} />
                </div>
              </EditableMultiSelect>
            </div>
          </div>
          <div className={`col-span-2 ${idx ? 'mt-2' : ''}`}>
            { isEditing ? <i className="pi pi-times text-xs ml-1 cursor-pointer text-gray-500" onClick={() => removeSubTimeBlock(idx)} /> : null }
          </div>
        </div>
        { smallScreenSize ? <Divider className="my-8" /> : null }
      </div>
    );
  };

  const onSubmit = async () => {
    if (!(getValues('subTimeBlocks') || []).length) {
      return showErrorToast(toastRef, 'At least one sub block time required');
    }

    const response = await updateTimeBlock({
      timeBlockId: subTimeBlockGroup.id,
      details: getValues('details'),
      description: getValues('description'),
      vendorIds: compact([getValues('vendorId'), getValues('venueId')]),
      participantActorIds: getValues('teamMemberActorIds'),
      subTimeBlocks: map(getValues('subTimeBlocks'), (subTimeBlock) => omit(subTimeBlock, 'participantActors')),
      attachmentIds: getValues('attachmentIds'),
    });

    if (!response?.errors) {
      return setIsEditing(false);
    }

    return true;
  };

  const onClick = (e) => {
    const clickableTarget = String(e.target.className).toLowerCase().match('button|multiselect|mask|svg|header');

    if (updatable && e.target.tagName !== 'IMG' && !clickableTarget) {
      setIsEditing(true);
    }
  };

  return (
    <div onClick={onClick} className="time-block-form grid grid-cols-1">
      {
        venueOptions.length > 1 ? (
          <EditableDropdown
            required={false}
            updatable={updatable}
            control={control}
            name="venueId"
            value={watch('venueId')}
            setValue={setValue}
            label="Venue"
            className={updatable || isEditing || !!getValues('venueId') ? 'font-semibold w-full' : 'hidden'}
            labelclassNameClassName={updatable || isEditing || !!getValues('venueId') ? 'font-semibold' : 'hidden'}
            inputWrapperClassName="w-full"
            inputClassName="w-1/2 mb-2"
            showClose={false}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            options={venueOptions}
            onUpdate={(venueId) => setValue('venueId', venueId || null)}
            inputProps={{ showClear: true, placeholder: 'Select Venue...' }}
          />
        ) : null
      }
      {
        vendorOptions.length ? (
          <div className="grid grid-cols-2 gap-2 w-full mt-2">
            <EditableDropdown
              required={false}
              updatable={updatable}
              control={control}
              name="vendorId"
              value={watch('vendorId')}
              setValue={setValue}
              label="Vendor"
              className={updatable || isEditing || !!getValues('vendorId') ? 'font-semibold w-full' : 'hidden'}
              labelclassNameClassName={updatable || isEditing || !!getValues('vendorId') ? 'font-semibold' : 'hidden'}
              inputWrapperClassName="w-full"
              inputClassName="py-[1.5px]"
              showClose={false}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              options={vendorOptions}
              onUpdate={(vendorId) => setValue('vendorId', vendorId || null)}
              inputProps={{ showClear: true, placeholder: 'Select Vendor...' }}
            />
            <EditableMultiSelect
              required={false}
              className="w-full"
              updatable={updatable}
              control={control}
              label="Team Members"
              labelClassName={updatable || isEditing || getValues('teamMemberActorIds')?.length ? 'font-semibold' : 'hidden'}
              name="teamMemberActorIds"
              value={watch('teamMemberActorIds')}
              setValue={setValue}
              options={teamMemberOptions}
              isEditing={isEditing}
              itemTemplate={(option) => actorItemTemplate({ option })}
            >
              <ActorsOrVendorsItem actorsOrVendors={subTimeBlockGroup.teamMemberActors} />
            </EditableMultiSelect>
          </div>
        ) : null
      }
      <div className="w-full">
        <EditableTextArea
          focus={false}
          formats={[]}
          textInputClassName="mt-1"
          textViewClassName="text-with-line-breaks"
          label="Description"
          labelClassName="font-semibold mt-4"
          isEditing={isEditing}
          setIsEditing={() => {}}
          name="description"
          updatable={updatable}
          onUpdate={(values) => setValue('description', values.description)}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
        <EditableTextArea
          focus={false}
          updatable={updatable}
          label="Details"
          emptyTextViewClassName={updatable || isEditing || !!getValues('details') ? '' : 'hidden'}
          labelClassName={updatable || isEditing || !!getValues('details') ? 'font-semibold mt-4' : 'hidden'}
          attachmentsDisabled={false}
          attachments={watch('attachments')}
          name="details"
          placeholder="Add details..."
          isEditing={isEditing}
          setIsEditing={() => {}}
          onUpdate={(values) => setValue('details', values.details)}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </div>
      <div className="mt-6">
        { subTimeBlockFields.map((object, idx) => subTimeBlockField({ idx })) }
      </div>
      {
        isEditing && updatable ? (
          <div>
            <Button
              icon="pi pi-plus"
              label="Add Time"
              className="text-primary p-0 mt-4"
              type="button"
              text
              size="small"
              onClick={() => appendSubTimeBlock({ description: '', participantActorIds: [] })}
            />
            <div className="flex w-full justify-between mr-2 mt-12">
              <div>
                { deleteButton() }
              </div>
              <div>
                <Button
                  label="Cancel"
                  className="mr-2"
                  disabled={submitDisabled}
                  onClick={() => setIsEditing(false)}
                  size="small"
                />
                <Button
                  label="Update"
                  onClick={onSubmit}
                  disabled={submitDisabled}
                  size="small"
                />
              </div>
            </div>
          </div>
        ) : null
        }
    </div>
  );
}

export default SubTimeBlockGroup;
