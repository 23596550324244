import React from 'react';
import { useController } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';

import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function DateInput(props) {
  const {
    control,
    name,
    label,
    labelClassName,
    className,
    disabled,
    minDate,
    tooltip,
    required = true,
    showToday = false,
    showClear = false,
    controllerProps,
    inputProps,
  } = props;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    ...controllerProps,
  });

  const calendarClasses = classNames(
    'w-full',
    {
      'p-invalid': error,
    },
  );

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      {
        (label || tooltip) && (
          <div className="flex items-center mb-2">
            { label && <label htmlFor={name} className={`block ${labelClassName}`}>{label}</label> }
            { tooltip && <span className={`form-field-tooltip-${name} p-button-icon pi pi-info-circle ml-1`} /> }
          </div>
        )
      }
      <Calendar
        id={name}
        value={value}
        onChange={onChange}
        className={calendarClasses}
        showButtonBar={showToday || showClear}
        todayButtonClassName={showToday ? '' : 'invisible'}
        clearButtonClassName={showClear ? '' : 'invisible'}
        disabled={disabled}
        minDate={minDate}
        showIcon
        {...inputProps}
      />
      {
        tooltip && (
          <Tooltip target={`.form-field-tooltip-${name}`} position="top">
            { tooltip }
          </Tooltip>
        )
      }
      { renderFormFieldError(error) }
    </div>
  );
}

export default DateInput;
