import React from 'react';
import { useController } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { classNames } from 'primereact/utils';

import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function InstagramHandleInput(props) {
  const {
    control, name, label, className, inputClassName = '', required = false, controllerProps,
  } = props;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: '',
    ...controllerProps,
  });

  const inputClasses = classNames(
    'w-full',
    inputClassName,
    {
      'p-invalid': error,
    },
  );

  const onInputChange = (event) => {
    let formattedInstagramHandle = event.target.value;

    if (formattedInstagramHandle[0] && formattedInstagramHandle[0] !== '@') {
      formattedInstagramHandle = `@${formattedInstagramHandle}`;
    }

    onChange(formattedInstagramHandle);
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className="block mb-2">{label}</label> }
      <IconField iconPosition="left">
        <InputIcon className="pi pi-instagram" />
        <InputText
          id={name}
          className={inputClasses}
          value={value || ''}
          onChange={onInputChange}
        />
      </IconField>
      { renderFormFieldError(error) }
    </div>
  );
}

export default InstagramHandleInput;
