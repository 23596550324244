import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Card } from 'primereact/card';
import { showErrorToast } from 'utils/toastUtils';
import { useForm, useFieldArray } from 'react-hook-form';
import ChecklistFields from './ChecklistFields';
import { CHECKLIST_ITEM_UPDATE_MUTATION } from './graphql';

function CompleteChecklistForm({
  subtaskId,
  isSubtaskTemplate,
  templateName,
  task = {},
  setSubtaskUpdated = () => {},
  checklist,
  onCreate = () => {},
  header,
  refetch,
  toastRef,
}) {
  const { id, title = 'Checklist', items = [] } = checklist;

  const mapItems = (itemsToMap) => (
    itemsToMap.map((item) => ({
      id: item.id, itemId: item.id, body: item.body, completed: item.completed,
    }))
  );

  // react-hook-form overrides id field, so have to use itemId here
  const {
    control, setValue, getValues, watch,
  } = useForm({
    defaultValues: {
      templateName,
      checklists: [{
        id,
        title,
        items: mapItems(items),
      }],
    },
  });

  const removeFieldAndResetValue = ({ field, remove, idx }) => {
    setValue(field, null);
    remove(idx);
  };

  const [subtaskChecklistItemUpdateMutation] = useMutation(CHECKLIST_ITEM_UPDATE_MUTATION, {
    onCompleted: async () => {
      const { data: { currentActor } } = await refetch();
      let subtasks;

      if (isSubtaskTemplate) {
        subtasks = currentActor.subtaskTemplates;
      } else if (task.isTemplate) {
        subtasks = currentActor.taskTemplates[0].subtasks;
      } else {
        subtasks = currentActor.tasks.edges[0].node.subtasks;
      }

      setSubtaskUpdated(true);

      setValue('checklists.0.items', mapItems(subtasks.find((subtask) => subtask.id === subtaskId).checklist.items));
    },
    onError: () => showErrorToast(toastRef, 'Error updating checklist item'),
  });

  const updateChecklistItem = (values) => {
    subtaskChecklistItemUpdateMutation({ variables: { input: { ...values } } });
  };

  const toggleChecklistItemComplete = ({ target }) => {
    updateChecklistItem({ id: target.id, completed: target.checked });
  };

  return (
    <form className="complete-checklist-form">
      <Card
        title={header}
        key={`${subtaskId}-subtask-card`}
        className="text-sm relative flex justify-center subtask-card subtask-complete-checklist-card"
      >
        <ChecklistFields
          id={id}
          taskId={task.id}
          isCreating={!id}
          isSubtaskTemplate={isSubtaskTemplate}
          templateName={templateName}
          refetch={refetch}
          control={control}
          getValues={getValues}
          watch={watch}
          removeFieldAndResetValue={removeFieldAndResetValue}
          toastRef={toastRef}
          setValue={setValue}
          onCreate={onCreate}
          onChecklistItemToggleComplete={toggleChecklistItemComplete}
          onUpdateChecklistItem={updateChecklistItem}
          useFieldArray={useFieldArray}
          updatable={task.isUpdatableByCurrentActor}
        />
      </Card>
    </form>
  );
}

export default CompleteChecklistForm;
