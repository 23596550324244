import gql from 'graphql-tag';

const TASK_UPDATE_MUTATION = gql`
  mutation UpdateTask($input: TaskUpdateMutationInput!) {
    taskUpdate(input: $input) {
      task {
        id
      }
    }
  }
`;

export {
  TASK_UPDATE_MUTATION,
};
