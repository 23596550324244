import gql from 'graphql-tag';

const DELETE_FILE_MUTATION = gql`
  mutation DeleteFile($input: UploadedFileDeleteMutationInput!) {
    uploadedFileDelete(input: $input) {
      id
    }
  }
`;

const FETCH_FILE_QUERY = gql`
  query File($uuid: ID!) {
    currentActor {
      id
      viewableFile(uuid: $uuid) {
        id
        isUpdatableByCurrentActor
        name
        fileCategory
        fileExtension
        createdByVendor {
          id
          name
          categories
        }
        createdByActor {
          id
          name
        }
        createdAt
        task {
          id
          title
        }
        timelineTimeBlock {
          id
          description
          tasks {
            id
            title
          }
        }
      }
    }
  }
`;

export {
  DELETE_FILE_MUTATION,
  FETCH_FILE_QUERY,
};
