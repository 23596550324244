import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import { Badge } from 'primereact/badge';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';
import { DragDropContext } from '@hello-pangea/dnd';

import { showErrorToast } from 'utils/toastUtils';
import AvatarWithName from 'components/AvatarWithName';
import { titleize } from 'utils/stringUtils';
import { TASK_UPDATE_MUTATION } from './graphql';
import Lane from './Lane';

const MAX_ASSIGNEE_COLUMNS = 10;
const UNASSIGNED = 'Unassigned';
const NO_LABEL = 'none';

export default function Board({
  tasks,
  actors,
  onCardClick,
  showEventInfo,
  selectedGrouping,
  showEmptyGroups,
  showCompleted,
  labels,
  currentActor,
  currentVendorId,
  refetchTasks,
}) {
  const toastRef = useRef();
  const { taskId, templateId } = useParams();
  const [orderedTasks, setOrderedTasks] = useState(tasks);
  const [laneCards, setLaneCards] = useState([]);
  const [laneAssignees, setLaneAssignees] = useState([]);
  const [laneLabels, setLaneLabels] = useState([]);
  const taskStatuses = ['NOT_STARTED', 'IN_PROGRESS'];

  useEffect(() => {
    setLaneCards([]);
    setOrderedTasks(tasks);
  }, [selectedGrouping, tasks]);

  const [taskUpdateMutation] = useMutation(TASK_UPDATE_MUTATION, {
    onCompleted: async () => { await refetchTasks(); },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ));
    },
  });

  const reorderTasksInLane = ({ laneIdx, sourceCardIdx, destinationCardIdx }) => {
    const result = laneCards[laneIdx];
    const [removed] = result.splice(sourceCardIdx, 1);
    result.splice(destinationCardIdx, 0, removed);

    laneCards[laneIdx] = result;

    setOrderedTasks(uniq(flatten(laneCards)));
  };

  /* eslint-disable consistent-return */
  const moveTaskToNewLane = ({
    sourceLane,
    destinationLane,
    sourceLaneIdx,
    destinationLaneIdx,
    sourceCard,
    destinationCard,
    taskIdBeingMoved,
    sourceLaneValue,
    destinationLaneValue,
  }) => {
    const [removed] = sourceLane.splice(sourceCard.index, 1);
    destinationLane.splice(destinationCard.index, 0, removed);

    laneCards[sourceLaneIdx] = sourceLane;
    laneCards[destinationLaneIdx] = destinationLane;

    const sortedTasks = uniq(flatten(laneCards));

    switch (selectedGrouping) {
      case 'status': {
        sortedTasks.find((task) => task.id === taskIdBeingMoved).status = destinationLaneValue;
        break;
      }
      case 'assignee': {
        const taskIdx = sortedTasks.findIndex((task) => task.id === taskIdBeingMoved);
        sortedTasks[taskIdx].assignedToActor = laneAssignees.find((assignee) => assignee.id === destinationLaneValue);
        break;
      }
      case 'label': {
        const taskIdx = sortedTasks.findIndex((task) => task.id === taskIdBeingMoved);
        if (destinationLaneValue === NO_LABEL) {
          sortedTasks[taskIdx].labels = [];
        } else {
          const taskLabels = sortedTasks[taskIdx].labels.filter((label) => label.id !== sourceLaneValue);
          taskLabels.push(labels.find((label) => label.id === destinationLaneValue));
          sortedTasks[taskIdx].labels = taskLabels;
        }
        break;
      }
      default:
    }

    setOrderedTasks(sortedTasks);
  };
  const updateTask = (values) => {
    taskUpdateMutation({ variables: { input: { ...values } } });
  };
  const onDragEnd = ({ draggableId, source, destination }) => {
    if (!destination) { return; }

    const sourceLaneIdx = last(source.droppableId.split('-'));
    const destinationLaneIdx = last(destination.droppableId.split('-'));
    const sourceCardIdx = source.index;
    const destinationCardIdx = destination.index;
    const movingInSameLane = sourceLaneIdx === destinationLaneIdx;
    const taskIdBeingMoved = last(draggableId.split('-'));
    const taskBeingMoved = laneCards[sourceLaneIdx][sourceCardIdx];
    const cardInPositionToReplace = laneCards[destinationLaneIdx][destinationCardIdx];
    const lastCardPosition = last(laneCards[destinationLaneIdx])?.position;

    if (movingInSameLane) {
      reorderTasksInLane({ laneIdx: sourceLaneIdx, sourceCardIdx, destinationCardIdx });
      updateTask({ id: taskIdBeingMoved, position: cardInPositionToReplace?.position });
    } else {
      let position = cardInPositionToReplace?.position;

      if (!position) {
        position = lastCardPosition + 1 || 1;
      } else if (taskBeingMoved.position < position) {
        position -= 1;
      }

      const sourceLaneValue = source.droppableId.split('-')[1];
      let destinationLaneValue = destination.droppableId.split('-')[1];

      moveTaskToNewLane({
        sourceLane: laneCards[sourceLaneIdx],
        destinationLane: laneCards[destinationLaneIdx],
        sourceLaneIdx,
        destinationLaneIdx,
        sourceCard: source,
        destinationCard: destination,
        taskIdBeingMoved,
        sourceLaneValue,
        destinationLaneValue,
      });

      let attribute = selectedGrouping;

      switch (selectedGrouping) {
        case 'assignee': {
          attribute = 'assignedToActorId';
          break;
        }
        case 'label': {
          attribute = 'labelIds';
          break;
        }
        default:
      }
      if (destinationLaneValue === UNASSIGNED) { destinationLaneValue = null; }

      if (attribute === 'labelIds') {
        if (destinationLaneValue === NO_LABEL) {
          destinationLaneValue = [];
        } else {
          destinationLaneValue = without(map(taskBeingMoved.labels, 'id'), sourceLaneValue).concat([destinationLaneValue]);
        }
      }

      updateTask({
        id: taskIdBeingMoved,
        position,
        [attribute]: destinationLaneValue,
      });
    }
  };

  const header = (headerValue) => {
    switch (selectedGrouping) {
      case 'status': {
        return (
          <div className="flex justify-center items-center text-base">
            <Badge value="" className={`status-${headerValue}-bg h-4 w-4 min-w-0 mr-1`} />
            <p className="p-0 m-0 font-normal">
              {titleize(headerValue)}
            </p>
          </div>
        );
      }
      case 'label': {
        return (
          <div className="flex justify-center items-center text-base">
            <Badge
              value=""
              style={{ backgroundColor: headerValue.colorHexCode }}
              className="h-4 w-4 min-w-0 mr-1"
            />
            <p className="p-0 m-0 font-normal">
              {headerValue.name}
            </p>
          </div>
        );
      }
      case 'assignee': {
        return (
          <div className="flex justify-center items-center text-base">
            {
              headerValue === UNASSIGNED ? (
                <div className="flex items-center h-8">
                  <i className="fa-regular fa-face-meh-blank mr-1 text-xl" />
                  <p className="p-0 m-0 font-normal">
                    {headerValue}
                  </p>
                </div>
              ) : (
                <AvatarWithName
                  id={headerValue.id}
                  initials={headerValue.initials}
                  avatarUrl={headerValue.avatarUrl}
                  text={headerValue.name}
                />
              )
            }
          </div>
        );
      }
      default:
    }
  };

  const sortAssignees = ({ assigneesToSort, sortedIds = [] }) => (
    assigneesToSort.reduce((mapped, assignee) => {
      if (!assignee) { return mapped; }

      if (!sortedIds.includes(assignee.id)) {
        sortedIds.push(assignee.id);

        if (assignee.actorType === 'TEAM_MEMBER_GROUP') {
          mapped.unshift(assignee);
        } else {
          mapped.push(assignee);
        }
      }

      return mapped;
    }, [])
  );

  /* eslint-disable react/no-array-index-key */
  const sortedTaskLanes = () => {
    const sortedLaneCards = [];

    switch (selectedGrouping) {
      case 'status': {
        if (showCompleted) { taskStatuses.push('COMPLETED'); }

        const statusLanes = taskStatuses.map((status, idx) => {
          const filteredTasks = orderedTasks.filter((task) => task.status === status);

          sortedLaneCards.push(filteredTasks);

          return (
            <Lane
              key={`lane-${status}`}
              droppableId={`lane-${status}-${idx}`}
              showEmpty={showEmptyGroups}
              header={header(status)}
              showEventInfo={showEventInfo}
              tasks={filteredTasks}
              onCardClick={onCardClick}
            />
          );
        });

        if (!laneCards.length) { setLaneCards(sortedLaneCards); }

        return statusLanes;
      }
      case 'label': {
        const allLabels = [{ name: 'No Label', colorHexCode: '#959797' }].concat(labels);
        const labelLanes = (
          allLabels.map((label, idx) => {
            const filteredTasks = orderedTasks.filter((task) => (
              label.id ? task.labels.map((taskLabel) => taskLabel.id).includes(label.id) : !task.labels.length));

            sortedLaneCards.push(filteredTasks);

            return (
              <Lane
                key={`lane-${label.id || NO_LABEL}-${idx}`}
                droppableId={`lane-${label.id || NO_LABEL}-${idx}`}
                className="col-span-6 md:col-span-3"
                showEmpty={showEmptyGroups}
                header={header(label)}
                showEventInfo={showEventInfo}
                tasks={filteredTasks}
                onCardClick={onCardClick}
              />
            );
          })
        );

        if (!laneLabels.length) { setLaneLabels(allLabels); }
        if (!laneCards.length) { setLaneCards(sortedLaneCards); }

        return labelLanes;
      }
      case 'assignee': {
        let assignees = [];
        assignees = assignees.concat(sortAssignees({ assigneesToSort: orderedTasks.flatMap((task) => task.assignedToActor) }));

        if (actors.length <= MAX_ASSIGNEE_COLUMNS) {
          assignees = assignees.concat(sortAssignees({ sortedIds: map(assignees, 'id'), assigneesToSort: actors }));
        }

        const assigneeLanes = uniqBy([UNASSIGNED].concat([currentActor]).concat(sortBy(assignees, 'id')), 'id').map((assignee, idx) => {
          const useVendorAsColumn = assignee.vendor && assignee.vendor.id !== currentVendorId;
          const filteredTasks = orderedTasks.filter((task) => (assignee.id ? task.assignedToActor?.id === assignee.id : !task.assignedToActor));

          sortedLaneCards.push(filteredTasks);

          if (assignee === UNASSIGNED && !filteredTasks.length) { return; }

          return (
            <Lane
              key={`lane-${assignee.id || UNASSIGNED}`}
              droppableId={`lane-${assignee.id || UNASSIGNED}-${idx}`}
              showEmpty={assignees.length < MAX_ASSIGNEE_COLUMNS ? showEmptyGroups : false}
              header={useVendorAsColumn ? header(assignee.vendor) : header(assignee)}
              showEventInfo={showEventInfo}
              showAssignee={false}
              tasks={filteredTasks}
              onCardClick={onCardClick}
            />
          );
        });

        if (!taskId && !templateId) {
          if (!laneAssignees.length && assignees.length) { setLaneAssignees(assignees); }
          if (!laneCards.length && sortedLaneCards.length) { setLaneCards(sortedLaneCards); }
        }

        return assigneeLanes;
      }
      default:
    }
  };
  /* eslint-enable react/no-array-index-key */
  /* eslint-enable consistent-return */

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex task-board overflow-x-auto">
        { sortedTaskLanes() }
      </div>
    </DragDropContext>
  );
}
