import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import map from 'lodash/map';

import LazyPaginatedTable from 'components/Table/LazyPaginatedTable';
import AvatarWithName from 'components/AvatarWithName';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router';
import StatusTag from 'containers/Events/StatusTag';
import EventInviteModal from '../EventInviteModal';

function EventsIndexTable(props) {
  const [showEventInviteModal, setShowEventInviteModal] = useState(false);
  const [activeEventId, setActiveEventId] = useState(null);
  const [activeEventStatus, setActiveEventStatus] = useState(null);
  const toastRef = useRef();
  const navigate = useNavigate();
  const {
    paginationBounds,
    setPaginationBounds,
    currentPage,
    refetch,
    setCurrentPage,
    eventsData,
    pageSize,
  } = props;

  if (eventsData.edges.length === 0) {
    return (
      <p>No events match the selected filters. Double check your display settings if you can't find what you're looking for.</p>
    );
  }

  const onShowEventInviteModal = ({ eventId, status }) => {
    setActiveEventId(eventId);
    setActiveEventStatus(status);
    setShowEventInviteModal(true);
  };

  const onHideEventInviteModal = () => {
    setActiveEventId(null);
    setActiveEventStatus(null);
    setShowEventInviteModal(false);
  };

  const onRowClick = ({ originalEvent, data }) => {
    const eventInviteObject = data.currentEventVendor || data.currentEventHostActor;

    if (eventInviteObject.status === 'APPROVED') {
      if (originalEvent.metaKey || originalEvent.ctrlKey) {
        return window.open(`/app/events/${data.id}`, '_blank');
      }

      return navigate(`/app/events/${data.id}`);
    }

    return onShowEventInviteModal({ eventId: data.id, status: eventInviteObject.status });
  };

  const eventNameLink = ({
    id, name, currentEventVendor, currentEventHostActor,
  }) => {
    const eventInviteObject = currentEventVendor || currentEventHostActor;

    return (
      <div className="flex">
        {
          eventInviteObject.status === 'APPROVED' ? (
            <Link className="mr-4 text-default" to={`/app/events/${id}`}>{ name }</Link>
          ) : (
            <Link className="mr-4 text-default" to="#" onClick={() => onShowEventInviteModal({ eventId: id, status: eventInviteObject.status })}>
              { name }
            </Link>
          )
        }
        <StatusTag status={eventInviteObject.status} cursorClass="pointer" />
      </div>
    );
  };

  const actorOrVendorColumn = ({ id, actorsOrVendors }) => (
    actorsOrVendors.map((actorOrVendor, idx) => (
      <AvatarWithName
        key={`${id}-${actorOrVendor.id}`}
        className={idx && 'mt-1'}
        id={actorOrVendor.id}
        initials={actorOrVendor.initials}
        avatarUrl={actorOrVendor.avatarUrl}
        text={actorOrVendor.name}
      />
    ))
  );

  return (
    <>
      <LazyPaginatedTable
        graphQLData={eventsData}
        paginationBounds={paginationBounds}
        setPaginationBounds={setPaginationBounds}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onRowClick={onRowClick}
        pageSize={pageSize}
      >
        <Column
          field="name"
          header="Name"
          body={eventNameLink}
        />
        <Column
          field="hostName"
          body={({ id, hostActors }) => actorOrVendorColumn({ id, actorsOrVendors: hostActors })}
          header="Host"
        />
        <Column
          field="venues"
          body={({ id, venues }) => actorOrVendorColumn({ id, actorsOrVendors: map(venues, 'vendor') })}
          header="Venue"
        />
        <Column
          field="startDateTime"
          body={(event) => (event.startDate ? moment.utc(event.startDate).format('M/D/YY') : null)}
          header="Event Date"
        />
      </LazyPaginatedTable>
      <EventInviteModal
        eventId={activeEventId}
        status={activeEventStatus}
        show={showEventInviteModal}
        setShow={onShowEventInviteModal}
        onHide={onHideEventInviteModal}
        refetch={refetch}
        toastRef={toastRef}
      />
      <Toast
        ref={toastRef}
        position="bottom-left"
        style={{ zIndex: 1000 }}
      />
    </>
  );
}

export default EventsIndexTable;
