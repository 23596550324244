// Run this example by adding <%= javascript_pack_tag 'hello_react' %>
// to the head of your layout file, like app/views/layouts/application.html.erb.
// All it does is render <div>Hello React</div> at the bottom // of the page.

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';
import Router from './containers/Router';

const httpLink = createUploadLink({
  uri: (operation) => `${process.env.REACT_APP_ENTERPLAN_BASE_URL}/graphql?request=${operation?.operationName}`,
});

const authLink = setContext((_, { headers }) => {
  const authHeaders = localStorage.getItem('authHeaders');

  return {
    headers: {
      ...headers,
      ...JSON.parse(authHeaders),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

document.addEventListener('DOMContentLoaded', () => {
  document.body.appendChild(document.createElement('div')).setAttribute('id', 'root');

  return ReactDOM.createRoot(document.getElementById('root')).render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Router resetGqlCache={() => client.clearStore()} />
      </ApolloProvider>
    </BrowserRouter>,
  );
});
