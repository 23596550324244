import gql from 'graphql-tag';
import UPLOADED_FILE_FIELDS from 'graphql/fragments/uploadedFile';

const FILES_QUERY = gql`
  ${UPLOADED_FILE_FIELDS}

  query Files($eventFilters: EventFilterInput, $fileFilters: EventUploadedFileFilterInput) {
    currentVendor {
      id
      name
    }
    currentActor {
      id
      events(filters: $eventFilters) {
        edges {
          cursor
          node {
            id
            eventUploadedFiles(filters: $fileFilters) {
              id
              file {
                ...UploadedFileFields
              }
            }
          }
        }
      }
    }
  }
`;

const DOWNLOAD_FILE_MUTATION = gql`
  mutation DownloadFile($input: UploadedFileDownloadMutationInput!) {
    uploadedFileDownload(input: $input) {
      downloadUrl
    }
  }
`;

const PREVIEW_FILE_MUTATION = gql`
  mutation PreviewFile($input: UploadedFilePreviewMutationInput!) {
    uploadedFilePreview(input: $input) {
      previewUrl
    }
  }
`;

const UPDATE_EVENT_FILE_MUTATION = gql`
  mutation UpdateFile($input: EventUploadedFileUpdateMutationInput!) {
    eventUploadedFileUpdate(input: $input) {
      eventUploadedFile {
        id
        file {
          id
          viewableBy
        }
      }
    }
  }
`;

const UPDATE_FILE_MUTATION = gql`
  mutation UpdateFile($input: UploadedFileUpdateMutationInput!) {
    uploadedFileUpdate(input: $input) {
      uploadedFile {
        id
        name
        fileCategory
      }
    }
  }
`;

const DELETE_FILE_MUTATION = gql`
  mutation DeleteFile($input: UploadedFileDeleteMutationInput!) {
    uploadedFileDelete(input: $input) {
      id
    }
  }
`;

export {
  FILES_QUERY,
  DOWNLOAD_FILE_MUTATION,
  PREVIEW_FILE_MUTATION,
  UPDATE_EVENT_FILE_MUTATION,
  UPDATE_FILE_MUTATION,
  DELETE_FILE_MUTATION,
};
