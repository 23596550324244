import React, { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import without from 'lodash/without';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import MultiSelect from 'components/Form/MultiSelect';
import { isSubmittableArea } from 'utils/formUtils';
import ManageReviewSourcesModal from 'containers/ManageReviewSourcesForm/Modal';
import { EVENT_VENDOR_UPDATE_MUTATION } from '../graphql';

function UpdateEventVendorReviewSources({
  toastRef, eventVendor, setIsEditing, refetchEventVendor, reviewSources, reviewSourceOptions, editableReviewSources,
}) {
  const formRef = useRef();
  const [mappedReviewSourceOptions, setMappedReviewSourceOptions] = useState([]);
  const [showManageReviewSourcesModal, setShowManageReviewSourcesModal] = useState(false);

  useEffect(() => {
    const mappedOptions = reduce(reviewSourceOptions, (options, reviewSource) => {
      options.push({ label: reviewSource.name, value: reviewSource.id });
      return options;
    }, []);
    mappedOptions.push({ label: 'Manage Sources', value: 'non-selectable' });
    setMappedReviewSourceOptions(mappedOptions);
  }, [reviewSourceOptions]);

  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: { reviewSourceIds: map(reviewSources, 'id') },
  });

  const [updateEventVendorMutation] = useMutation(EVENT_VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ eventVendorUpdate }) => {
      refetchEventVendor();
      showSuccessToast(toastRef, `Successfully updated vendor ${eventVendorUpdate.eventVendor.vendor.name}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (!showManageReviewSourcesModal && isSubmittableArea(formRef, e)) {
        if (isDirty) {
          await updateEventVendorMutation({
            variables: {
              input: {
                id: eventVendor.id,
                vendorFields: { reviewSourceIds: without(getValues('reviewSourceIds'), 'non-selectable') },
              },
            },
          });
        }

        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty]);

  const onChange = (item) => {
    if (item.selectedOption.value === 'non-selectable') {
      setShowManageReviewSourcesModal(true);
    }
  };

  return (
    <div ref={formRef} className="mt-2 w-1/2">
      <MultiSelect
        control={control}
        className="w-full"
        name="reviewSourceIds"
        options={mappedReviewSourceOptions}
        placeholder="Select review sources"
        onChange={onChange}
        inputProps={{
          itemClassName: 'review-source-option',
        }}
      />
      <ManageReviewSourcesModal
        reviewSources={editableReviewSources}
        show={showManageReviewSourcesModal}
        onHide={() => setShowManageReviewSourcesModal(false)}
        refetch={refetchEventVendor}
        toastRef={toastRef}
      />
    </div>
  );
}

export default UpdateEventVendorReviewSources;
