import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import UrlInput from 'components/Form/UrlInput';
import { isSubmittableArea } from 'utils/formUtils';
import { EVENT_VENDOR_UPDATE_MUTATION } from '../graphql';

function UpdateEventVendorWebsiteUrl({
  toastRef, vendor, eventVendor, setIsEditing, refetchEventVendor,
}) {
  const formRef = useRef();

  const {
    control,
    getValues,
    formState: { isDirty },
  } = useForm({
    defaultValues: { websiteUrl: vendor.websiteUrl },
  });

  const [updateEventVendorMutation] = useMutation(EVENT_VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ eventVendorUpdate }) => {
      refetchEventVendor();
      showSuccessToast(toastRef, `Successfully updated vendor ${eventVendorUpdate.eventVendor.vendor.name}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (isSubmittableArea(formRef, e)) {
        if (isDirty) {
          await updateEventVendorMutation({
            variables: {
              input: {
                id: eventVendor.id,
                vendorFields: { websiteUrl: getValues('websiteUrl') },
              },
            },
          });
        }

        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty]);

  return (
    <div ref={formRef} className="mt-2 w-1/2">
      <UrlInput
        control={control}
        className="w-full"
        name="websiteUrl"
      />
    </div>
  );
}

export default UpdateEventVendorWebsiteUrl;
