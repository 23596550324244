import gql from 'graphql-tag';

const LABEL_DELETE_MUTATION = gql`
  mutation DeleteLabel($input: TaskLabelDeleteMutationInput!) {
    taskLabelDelete(input: $input) {
      id
    }
  }
`;

const LABEL_CREATE_MUTATION = gql`
  mutation CreateLabel($input: TaskLabelCreateMutationInput!) {
    taskLabelCreate(input: $input) {
      label {
        id
      }
    }
  }
`;

const LABEL_UPDATE_MUTATION = gql`
  mutation UpdateLabel($input: TaskLabelUpdateMutationInput!) {
    taskLabelUpdate(input: $input) {
      label {
        id
      }
    }
  }
`;

export {
  LABEL_DELETE_MUTATION,
  LABEL_CREATE_MUTATION,
  LABEL_UPDATE_MUTATION,
};
