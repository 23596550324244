import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { useForm } from 'react-hook-form';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { mapInfo } from 'utils/displayUtils';
import { cursorClassName } from 'utils/styleUtils';
import EditableTextArea from 'components/Form/EditableFields/TextArea';
import EventInviteForm from 'containers/Events/Show/VendorsTab/EventInviteForm';
import UpdateEventHostActorBasicInfo from './UpdateEventHostActorBasicInfo';
import UpdateEventHostActorEmail from './UpdateEventHostActorEmail';
import UpdateEventHostActorPhone from './UpdateEventHostActorPhone';
import UpdateEventHostActorInstagram from './UpdateEventHostActorInstagram';
import { EVENT_HOST_ACTOR_UPDATE_MUTATION } from './graphql';

function EventHostActorProfileModal(props) {
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isEditingInternalNotes, setIsEditingInternalNotes] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  const [isEditingBasicInfo, setIsEditingBasicInfo] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const {
    show,
    onHide,
    toastRef,
    eventHostActor,
    event,
    confirmSendInvite,
    isUpdatableByCurrentActor,
    refetchEventOverview,
  } = props;

  useEffect(() => {
    if (
      !isUpdatableByCurrentActor
      || isEditingNotes
      || isEditingInternalNotes
      || isEditingEmail
      || isEditingPhone
      || isEditingInstagram
    ) {
      setIsEditable(false);
    } else {
      setIsEditable(isUpdatableByCurrentActor);
    }
  }, [
    isEditingNotes,
    isEditingInternalNotes,
    isEditingEmail,
    isEditingPhone,
    isEditingInstagram,
  ]);
  const { hostActor, notes, internalNotes } = eventHostActor;

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      notes,
      internalNotes,
    },
  });

  const [updateEventHostActorMutation] = useMutation(EVENT_HOST_ACTOR_UPDATE_MUTATION, {
    onCompleted: async () => {
      await refetchEventOverview();
      showSuccessToast(toastRef, 'Updated host!');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(() => showErrorToast(toastRef, 'Error updating host!'));
    },
  });

  const updateEventHostActor = (data) => {
    updateEventHostActorMutation({
      variables: { input: { id: eventHostActor.id, notes: data.notes, internalNotes: data.internalNotes } },
    });
  };

  const notesField = () => (
    <div className="border-t mt-6 border-gray-200">
      <p className="m-0 my-4 text-gray-800 font-semibold">Public Notes</p>
      <EditableTextArea
        updatable
        formats={['bold', 'italic', 'underline', 'strike']}
        name="notes"
        updateFieldName="notes"
        textViewClassName="mb-0"
        isEditing={isEditingNotes}
        setIsEditing={setIsEditingNotes}
        onUpdate={updateEventHostActor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  const internalNotesField = () => (
    <div className="border-t mt-6 border-gray-200">
      <p className="m-0 my-4 text-gray-800 font-semibold">Internal Notes</p>
      <EditableTextArea
        updatable
        formats={['bold', 'italic', 'underline', 'strike']}
        name="internalNotes"
        updateFieldName="internalNotes"
        textViewClassName="mb-0"
        isEditing={isEditingInternalNotes}
        setIsEditing={setIsEditingInternalNotes}
        onUpdate={updateEventHostActor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  const modalHeader = () => (
    <div
      className={cursorClassName(eventHostActor.isUpdatableByCurrentActor)}
      onClick={() => (eventHostActor.isUpdatableByCurrentActor ? setIsEditingBasicInfo(true) : {})}
    >
      {
        isEditingBasicInfo ? (
          <UpdateEventHostActorBasicInfo
            eventHostActor={eventHostActor}
            refetch={refetchEventOverview}
            hostActor={hostActor}
            toastRef={toastRef}
            setIsEditing={setIsEditingBasicInfo}
          />
        ) : (
          <div className="text-gray-800 flex">
            <div className="flex items-center">
              <Avatar
                key={`${hostActor.id}-avatar`}
                label={hostActor.name[0]}
                size="xlarge"
                shape="circle"
                className="mr-2 bg-gray text-white"
                image={hostActor.avatarUrl}
              />
              <p key={`${hostActor.id}-host-actor-name`} className="font-semibold m-0 text-gray-800">
                <span className="mr-2">{ hostActor.name }</span>
              </p>
            </div>
          </div>
        )
      }
      <div className={!eventHostActor.isAdmin && eventHostActor.status !== 'APPROVED' ? 'mt-2' : ''}>
        <EventInviteForm
          toastRef={toastRef}
          event={event}
          eventVendorOrActor={eventHostActor}
          refetch={refetchEventOverview}
          confirmSendInvite={confirmSendInvite}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      className="xl:w-5/12"
      header={modalHeader()}
      visible={show}
      onHide={onHide}
      dismissableMask
      resizable={false}
      draggable={false}
    >
      <div className="event-host-actor-profile" key={`${eventHostActor.id}-event-host-actor-profile`}>
        <p className="mb-2 text-gray-800 font-semibold">Host Info</p>
        {
          isEditingEmail ? (
            <UpdateEventHostActorEmail
              eventHostActor={eventHostActor}
              refetchEventHostActor={refetchEventOverview}
              toastRef={toastRef}
              setIsEditing={setIsEditingEmail}
            />
          ) : (
            <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingEmail(isUpdatableByCurrentActor)}>
              {
                mapInfo({
                  data: hostActor,
                  field: 'email',
                  icon: 'envelope',
                  className: 'text-default text-sm mb-2',
                  shouldLink: !isUpdatableByCurrentActor,
                  showEmpty: isUpdatableByCurrentActor,
                })
              }
            </div>
          )
        }
        {
          isEditingPhone ? (
            <UpdateEventHostActorPhone
              eventHostActor={eventHostActor}
              refetchEventHostActor={refetchEventOverview}
              toastRef={toastRef}
              setIsEditing={setIsEditingPhone}
            />
          ) : (
            <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingPhone(isUpdatableByCurrentActor)}>
              {
                mapInfo({
                  data: hostActor,
                  field: 'phoneNumber',
                  icon: 'phone',
                  className: 'text-default text-sm mb-2',
                  shouldLink: !isUpdatableByCurrentActor,
                  showEmpty: isUpdatableByCurrentActor,
                })
              }
            </div>
          )
        }
        {
          isEditingInstagram ? (
            <UpdateEventHostActorInstagram
              eventHostActor={eventHostActor}
              refetchEventHostActor={refetchEventOverview}
              toastRef={toastRef}
              setIsEditing={setIsEditingInstagram}
            />
          ) : (
            <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingInstagram(isUpdatableByCurrentActor)}>
              {
                mapInfo({
                  data: hostActor,
                  field: 'instagramHandle',
                  icon: 'instagram',
                  className: 'text-default text-sm mb-2',
                  shouldLink: !isUpdatableByCurrentActor,
                  showEmpty: isUpdatableByCurrentActor,
                })
              }
            </div>
          )
        }
        { notesField() }
        { internalNotesField() }
      </div>
    </Dialog>
  );
}

export default EventHostActorProfileModal;
