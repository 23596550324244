import gql from 'graphql-tag';

const ACTOR_DETAILS_QUERY = gql`
  query CurrentActor {
    currentActor {
      id
      firstName
      lastName
      email
      phoneNumber
      instagramHandle
      avatarUrl
    }
  }
`;

const LOGIN_DETAILS_QUERY = gql`
  query LoginDetails {
    currentUser {
      id
      email
    }
  }
`;

const USER_UPDATE_MUTATION = gql`
  mutation UpdateUser($input: UserUpdateMutationInput!) {
    userUpdate(input: $input) {
      user {
        id
        email
      }
    }
  }
`;

const ACTOR_UPDATE_MUTATION = gql`
  mutation UpdateActor($input: ActorUpdateMutationInput!) {
    actorUpdate(input: $input) {
      actor {
        id
        firstName
        lastName
        email
        phoneNumber
        instagramHandle
        avatarUrl
      }
    }
  }
`;

const USER_PASSWORD_CONFIRM_MUTATION = gql`
  mutation ConfirmPassword($input: UserPasswordConfirmMutationInput!) {
    userPasswordConfirm(input: $input) {
      passwordConfirmed
    }
  }
`;

export {
  ACTOR_UPDATE_MUTATION,
  ACTOR_DETAILS_QUERY,
  LOGIN_DETAILS_QUERY,
  USER_PASSWORD_CONFIRM_MUTATION,
  USER_UPDATE_MUTATION,
};
