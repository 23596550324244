import React from 'react';
import { Card } from 'primereact/card';
import UploadFileForm from './UploadFileForm';

function CompleteFileRequestForm({
  task,
  subtaskId,
  onCreate = () => {},
  isSubtaskTemplate,
  templateName,
  setSubtaskUpdated,
  isUpdatableByCurrentActor,
  onRemoveEmptyFileRequest,
  header,
  fileRequest,
  refetch,
  toastRef,
}) {
  return (
    <Card
      title={header}
      key={`${subtaskId}-subtask-card`}
      className="text-sm relative flex justify-center subtask-card"
    >
      <UploadFileForm
        refetch={refetch}
        setSubtaskUpdated={setSubtaskUpdated}
        isUpdatableByCurrentActor={isUpdatableByCurrentActor}
        onRemoveEmptyFileRequest={onRemoveEmptyFileRequest}
        onCreate={onCreate}
        toastRef={toastRef}
        subtaskId={subtaskId}
        templateName={templateName}
        isSubtaskTemplate={isSubtaskTemplate}
        task={task}
        fileRequest={fileRequest}
      />
    </Card>
  );
}

export default CompleteFileRequestForm;
