import { DateTime } from 'luxon';

const timeOptions = ({ startTime = null, endTime = null, intervalInMinutes = 5 }) => {
  let startDateTime = startTime?.isValid ? startTime : DateTime.local().startOf('day');
  const startDay = startDateTime.c.day;
  let endDateTime = endTime?.isValid ? endTime : startDateTime.plus({ days: 1, hours: 4, minutes: intervalInMinutes });
  const options = [];

  while (startDateTime < endDateTime) {
    let label = startDateTime.toFormat('h:mm a');
    const pastMidnight = startDay !== startDateTime.c.day;

    if (pastMidnight) {
      if (label === '4:00 AM') { endDateTime = startDateTime; }
      label = `${label} (next day)`;
    }

    options.push({
      value: startDateTime.toISO(),
      label,
    });

    startDateTime = startDateTime.plus({ minutes: intervalInMinutes });
  }

  return options;
};

export {
  timeOptions,
};
