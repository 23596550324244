import React, { useRef, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams, useLocation, useNavigate } from 'react-router';
import { TabView, TabPanel } from 'primereact/tabview';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import replace from 'lodash/replace';
import capitalize from 'lodash/capitalize';

import { showErrorToast } from 'utils/toastUtils';
import Tasks from 'containers/Tasks/Index';
import EditableText from 'components/Form/EditableFields/Text';
import EditableDate from 'components/Form/EditableFields/Date';
import OverviewTab from './OverviewTab';
import VendorsTab from './VendorsTab';
import TimelineTab from './TimelineTab';
import FilesTab from './FilesTab';

import { EVENT_QUERY, EVENT_UPDATE_MUTATION } from './graphql';

const TABS = ['overview', 'vendors', 'tasks', 'files', 'timeline'];
function EventShow({ canCreateTasks }) {
  const menuRef = useRef(null);
  const toastRef = useRef(null);
  const [currentVendor, setCurrentVendor] = useState(null);
  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDate, setIsEditingDate] = useState(false);
  const { eventId, tabName } = useParams();
  const updatable = event?.isUpdatableByCurrentActor;
  const {
    control, setValue, getValues,
  } = useForm({
    defaultValues: {
      name: event?.name,
      startDate: event?.startDate ? new Date(event.startDate) : null,
    },
  });

  useEffect(() => {
    if (event) {
      setValue('name', event.name);
      setValue('startDate', event?.startDate ? new Date(event.startDate) : null);
    }
  }, [event]);

  const [eventUpdateMutation] = useMutation(EVENT_UPDATE_MUTATION, {
    onCompleted: async ({ eventUpdate }) => {
      setEvent({ ...event, ...eventUpdate.event });
      setIsEditingName(false);
      setIsEditingDate(false);
    },
    onError: () => { showErrorToast(toastRef, 'Error fetching events'); },
  });

  useEffect(() => {
    const tabFromPath = tabName;

    if (TABS.includes(tabFromPath)) {
      setActiveTabIndex(TABS.indexOf(tabFromPath));
    } else {
      navigate(`/app/events/${eventId}/${TABS[0]}`);
    }
  }, [tabName]);

  const {
    loading, error, refetch,
  } = useQuery(
    EVENT_QUERY,
    {
      variables: { filters: { id: eventId } },
      onCompleted: (data) => {
        const eventData = data.currentActor.events.edges[0]?.node;

        setCurrentVendor(data.currentVendor);
        setEvent(eventData);
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const onTabChange = ({ index }) => {
    navigate(replace(path, TABS[activeTabIndex], TABS[index]));
    setActiveTabIndex(index);
  };

  if (error || !event) { return null; }

  const items = [
    {
      label: 'Overview',
      command: () => onTabChange({ index: TABS.indexOf('overview') }),
    },
    {
      label: 'Vendors',
      command: () => onTabChange({ index: TABS.indexOf('vendors') }),
    },
    {
      label: 'Tasks',
      command: () => onTabChange({ index: TABS.indexOf('tasks') }),
    },
    {
      label: 'Files',
      command: () => onTabChange({ index: TABS.indexOf('files') }),
    },
    {
      label: 'Timeline',
      command: () => onTabChange({ index: TABS.indexOf('timeline') }),
    },
  ];

  const currentTab = () => {
    switch (activeTabIndex) {
      case 0: {
        return <OverviewTab />;
      }
      case 1: {
        return <VendorsTab />;
      }
      case 2: {
        return <Tasks canCreateTasks={canCreateTasks} />;
      }
      case 3: {
        return <FilesTab />;
      }
      case 4: {
        return (
          <TimelineTab
            event={event}
            currentVendor={currentVendor}
            eventsLoading={loading}
            refetchEvent={refetch}
          />
        );
      }
      default: return null;
    }
  };

  const updateEvent = ({ name, startDate }) => {
    eventUpdateMutation({ variables: { input: { id: event.id, name, startDate } } });
  };

  const nameAndDate = ({ marginClass = '' }) => (
    <>
      <p className={`font-semibold text-xl text-gray-800 ${marginClass}`}>
        <EditableText
          text={event.name}
          isEditing={isEditingName}
          setIsEditing={setIsEditingName}
          name="name"
          updatable={updatable}
          onUpdate={updateEvent}
          control={control}
          setValue={setValue}
          getValues={getValues}
        />
      </p>
      <p className="font-normal text-base text-gray-700">
        <EditableDate
          name="startDate"
          value={event.startDate ? DateTime.fromISO(event.startDate).toLocaleString(DateTime.DATE_MED) : 'Date: TBD'}
          isEditing={isEditingDate}
          setIsEditing={setIsEditingDate}
          updatable={updatable}
          onUpdate={updateEvent}
          control={control}
        />
      </p>
    </>
  );

  return (
    <>
      <div className="xxs:hidden md:block">
        <div className="flex items-center">
          { nameAndDate({ marginClass: 'mr-4' }) }
        </div>
        <TabView activeIndex={activeTabIndex} onTabChange={onTabChange} className="mt-2">
          {
            TABS.map((tab) => (
              <TabPanel header={capitalize(tab)} key={`${tab}-tab`}>
                { currentTab() }
              </TabPanel>
            ))
          }
        </TabView>
      </div>

      <div className="xxs:block md:hidden p-0 m-0">
        { nameAndDate({}) }
        <Menu model={items} popup ref={menuRef} />
        <Button icon="pi pi-bars" size="small" onClick={(e) => menuRef.current.toggle(e)} className="mb-6" />
        { currentTab() }
      </div>
    </>
  );
}

export default EventShow;
