import React from 'react';

function Features() {
  return (
    <div className="sm:px-4 mx-auto max-w-6xl xxs:px-6 sm:px-8 xxs:mt-20 sm:mt-32">
      <div className="text-center">
        <h2 className="text-3xl font-bold leading-tight text-gray-900 xl:text-5xl">We make event planning easy and collaborative</h2>
        <p className="mt-4 text-base leading-7 text-gray-600">Say goodbye to digging through long, unorganized email threads</p>
      </div>

      <div className="mt-10 text-center xxs:mt-16 xxs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xxs:gap-x-12 grid sm:gap-0 xl:mt-24">
        <div className="xxs:mt-20 md:mt-0 sm:p-8 md:p-14 sm:border-gray-200">
          <i className="pi pi-calendar text-6xl" />
          <h3 className="xxs:mt-12 text-xl font-bold text-gray-900">Collaborative Events</h3>
          <p className="mt-5 text-base text-gray-600">
            Add team members, vendors, and hosts to shared events to keep all data organized, transparent, and up-to-date
          </p>
        </div>

        <div className="xxs:mt-20 md:mt-0 sm:p-8 md:p-14 sm:border-l sm:border-gray-200">
          <i className="pi pi-check-square text-6xl" />
          <h3 className="xxs:mt-12 text-xl font-bold text-gray-900">Tasks</h3>
          <p className="mt-5 text-base text-gray-600">
            Assign tasks to collaborators to delegate building event details and make the planning process easier
          </p>
        </div>

        <div className="xxs:mt-20 md:mt-0 sm:p-8 md:p-14 md:border-l sm:border-gray-200">
          <i className="fa-regular fa-rectangle-list text-6xl" />
          <h3 className="xxs:mt-12 text-xl font-bold text-gray-900">Timelines</h3>
          <p className="mt-5 text-base text-gray-600">Build collaborative and interactive timelines to ensure people know where to be at what time</p>
        </div>

        <div className="xxs:mt-20 md:mt-0 sm:p-8 md:p-14 sm:border-l md:border-l-0 md:border-t sm:border-gray-200">
          <i className="pi pi-bell text-6xl" />
          <h3 className="xxs:mt-12 text-xl font-bold text-gray-900">Notifications</h3>
          <p className="mt-5 text-base text-gray-600">Get notified when collaborators update details and when action is needed on your end</p>
        </div>

        <div className="xxs:mt-20 md:mt-0 sm:p-8 md:p-14 md:border-l md:border-t sm:border-gray-200">
          <i className="pi pi-file text-6xl" />
          <h3 className="xxs:mt-12 text-xl font-bold text-gray-900">Files</h3>
          <p className="mt-5 text-base text-gray-600">Keep contracts and invoices organized and create a central source of truth</p>
        </div>

        <div className="xxs:mt-20 md:mt-0 sm:p-8 md:p-14 sm:border-l md:border-t sm:border-gray-200">
          <i className="pi pi-lock text-6xl" />
          <h3 className="xxs:mt-12 text-xl font-bold text-gray-900">Permissions</h3>
          <p className="mt-5 text-base text-gray-600">Share the details that you want and control what other event collaborators have access to</p>
        </div>
      </div>
    </div>
  );
}

export default Features;
