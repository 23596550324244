import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import { showSuccessToast } from 'utils/toastUtils';
import { UPDATE_EVENT_FILE_MUTATION } from './graphql';

function ShowFileButton({
  file, eventFileId, refetchFiles, toastRef,
}) {
  const [updateEventFileMutation] = useMutation(UPDATE_EVENT_FILE_MUTATION, {
    onCompleted: () => {
      showSuccessToast(toastRef, `Successfully made ${file.name} visible`);
      refetchFiles();
    },
  });

  const confirmShow = () => {
    const message = `Are you sure you want to make ${file.name} visible?`;

    return confirmDialog({
      className: 'xl:w-3/12',
      message,
      header: 'Show File',
      acceptLabel: 'Show on files tab',
      rejectLabel: 'Cancel',
      acceptIcon: 'pi pi-eye',
      draggable: false,
      acceptClassName: 'text-sm',
      accept: () => updateEventFileMutation({ variables: { input: { id: eventFileId, showOnSummary: true } } }),
    });
  };

  return (
    <Button
      onClick={confirmShow}
      onMouseDown={(e) => e.preventDefault()}
      icon="pi pi-eye"
      rounded
      text
      aria-label="show"
    />
  );
}

export default ShowFileButton;
