import gql from 'graphql-tag';

const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
    }
    currentVendor {
      id
      name
    }
    currentActor {
      id
    }
    currentAbilities {
      id
      create
      update
    }
  }
`;

export default CURRENT_USER_QUERY;
