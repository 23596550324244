import gql from 'graphql-tag';
import PAGE_INFO_FIELDS from 'graphql/fragments/pageInfo';

const TEAM_MEMBER_GROUP_ACTORS_QUERY = gql`
  query TeamMemberGroups {
    currentVendor {
      id
      teamMemberGroupActors {
        id
        name
        email
        phoneNumber
        memberActors {
          id
          name
          initials
          avatarUrl
        }
      }
    }
  }
`;

const TEAM_MEMBER_ACTORS_QUERY = gql`
  ${PAGE_INFO_FIELDS}

  query TeamMemberActors($first: Int, $last: Int, $before: String, $after: String, $actorFilters: ActorFilterInput, $roleFilters: RoleFilterInput) {
    currentVendor {
      id
      actors {
        edges {
          node {
            id
            name
            initials
            avatarUrl
          }
        }
      }
      teamMemberActorRoles {
        id
        name
      }
      teamMemberActors(first: $first, last: $last, before: $before, after: $after, filters: $actorFilters) {
        totalRecords
        pageInfo {
          ...PageInfoFields
        }
        edges {
          cursor
          node {
            id
            roles(filters: $roleFilters) {
              id
              name
            }
            initials
            avatarUrl
            name
            firstName
            lastName
            email
            phoneNumber
            instagramHandle
            teamMemberUser {
              id
              inviteStatus
              accountHolder
              isCurrentUser
            }
          }
        }
      }
    }
  }
`;

export {
  TEAM_MEMBER_ACTORS_QUERY,
  TEAM_MEMBER_GROUP_ACTORS_QUERY,
};
