import React from 'react';
import { useController } from 'react-hook-form';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';

import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function SwitchInput(props) {
  const {
    control, name, label, readOnly, tooltip = null, labelClassName = '', className, controllerProps, inputProps,
  } = props;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: null,
    ...controllerProps,
  });

  const inputClasses = classNames(
    'block',
    {
      'p-invalid': error,
    },
  );

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      <div className="flex items-center mb-2">
        { label && <label htmlFor={name} className={`block mb-2 ${labelClassName}`}>{label}</label> }
        {
          tooltip && (
            <div className="flex">
              <Tooltip target={`.switch-input-tooltip-icon-${name}`} />

              <i
                className={`switch-input-tooltip-icon-${name} pi pi-${tooltip.icon} ml-1`}
                data-pr-tooltip={tooltip.message}
                data-pr-position="top"
                style={{ cursor: 'pointer' }}
              />
            </div>
          )
        }
      </div>
      <InputSwitch
        id={name}
        checked={value}
        disabled={readOnly}
        value={value}
        onChange={onChange}
        className={inputClasses}
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default SwitchInput;
