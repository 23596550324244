import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';

function ManageDisplaySettings(props) {
  const {
    groupingOptions,
    displaySettings,
    setSelectedGrouping,
    setShowHidden,
  } = props;

  return (
    <>
      <div className="flex justify-between items-center text-nowrap mb-4">
        <p className="flex flex-nowrap mr-4">Group By</p>
        <Dropdown
          value={displaySettings.grouping}
          onChange={({ value }) => setSelectedGrouping(value)}
          options={groupingOptions}
          className="w-full"
        />
      </div>
      <div className="flex justify-between items-center text-nowrap mb-4">
        <p className="flex flex-nowrap mr-4">Show Hidden</p>
        <InputSwitch checked={displaySettings.showHidden} onChange={({ value }) => setShowHidden(value)} />
      </div>
    </>
  );
}

export default ManageDisplaySettings;
