import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { useForm } from 'react-hook-form';
import { mapSelectOptions } from 'utils/formUtils';
import CreateTaskForm from '.';

function CreateTaskModal(props) {
  const {
    task,
    isTemplate,
    templates,
    activeTemplate,
    show,
    onHide,
    refetch,
  } = props;
  const toastRef = useRef(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(activeTemplate);

  useEffect(() => {
    setTemplateOptions(mapSelectOptions({
      data: templates,
      labelFieldName: 'templateName',
      valueFieldName: 'id',
    }));
  }, [templates]);

  const {
    reset,
  } = useForm({
    defaultValues: {
      templateId: '',
    },
  });

  const onHideModal = () => {
    onHide();
    setSelectedTemplate(null);
    reset();
  };

  const header = () => {
    if (isTemplate) {
      return (
        <div className="grid grid-cols-12">
          <p className="col-span-6">Create template</p>
        </div>
      );
    }
    return (
      <div className="flex flex-wrap items-center justify-between mr-6 grid grid-cols-12">
        <p className="col-span-12 md:col-span-6">Create task</p>
      </div>
    );
  };

  return (
    <>
      <Dialog
        header={header()}
        className="xl:w-1/3"
        visible={show}
        onHide={onHideModal}
        draggable={false}
        dismissableMask
        maximizable
        resizable
      >
        <CreateTaskForm
          task={task}
          isTemplate={isTemplate}
          selectedTemplate={selectedTemplate}
          templateOptions={templateOptions}
          templates={templates}
          refetch={refetch}
          toastRef={toastRef}
          hideModal={onHide}
        />
      </Dialog>
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default CreateTaskModal;
