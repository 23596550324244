import React, { useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ManageLabelsForm from '.';

function ManageLabelsModal(props) {
  const {
    labels,
    refetchLabels,
    show,
    onHide,
  } = props;
  const toastRef = useRef(null);

  return (
    <>
      <Dialog
        className="manage-labels-modal xl:w-7/12"
        header="Manage Labels"
        visible={show}
        onHide={onHide}
        draggable={false}
        maximizable
        dismissableMask
        resizable
      >
        <ManageLabelsForm
          labels={labels}
          refetchLabels={refetchLabels}
          toastRef={toastRef}
          hideModal={onHide}
        />
      </Dialog>
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default ManageLabelsModal;
