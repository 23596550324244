import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

function PromptToComplete({
  updateTask,
  show,
  setShow,
}) {
  const setTaskAsCompleted = () => {
    updateTask({ status: 'COMPLETED' });
    setShow(false);
  };

  const footer = () => (
    <div className="flex items-center justify-end">
      <Button label="No" text onClick={() => setShow(false)} />
      <Button label="Yes" raised onClick={setTaskAsCompleted} />
    </div>
  );

  return (
    <Dialog
      className="xl:w-1/3"
      header={<><span className="pi pi-info-circle mr-2" />Complete Task?</>}
      footer={footer()}
      visible={show}
      onHide={() => setShow(false)}
      dismissableMask
      resizable={false}
      draggable={false}
      modal
    >
      All subtasks on this task have been marked as completed.  Do you want to move this task to complete?
    </Dialog>
  );
}

export default PromptToComplete;
