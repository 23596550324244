import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Draggable } from '@hello-pangea/dnd';

import { showErrorToast } from 'utils/toastUtils';
import EditableTextArea from 'components/Form/EditableFields/TextArea';
import DraggablePortal from 'components/DragAndDrop/DraggablePortal';
import {
  CHECKLIST_ITEM_CREATE_MUTATION,
  CHECKLIST_ITEM_DELETE_MUTATION,
} from './graphql';

const NEW_ITEM_ID = 'new';

function ChecklistItemFields({
  checklistId,
  disableAddItem = false,
  control,
  toastRef,
  setValue,
  getValues,
  refetch,
  setIsEditingChecklist,
  onToggleComplete,
  onUpdate,
  useFieldArray,
  updatable,
  droppablePlaceholder,
  checklistIdx,
}) {
  const [addingAnotherItem, setAddingAnotherItem] = useState(false);
  const [itemIdBeingEdited, setItemIdBeingEdited] = useState(null);
  const {
    fields: checklistItemFields,
    append: appendChecklistItem,
    remove: removeChecklistItem,
  } = useFieldArray({ control, name: `checklists.${checklistIdx}.items` });

  const removeEmptyItems = () => {
    const indexesToRemove = [];

    checklistItemFields.map((itemField, idx) => {
      if (itemField.body || itemField.itemId) { return null; }

      return indexesToRemove.push(idx);
    });

    indexesToRemove.map((idx) => removeChecklistItem(idx));
  };

  useEffect(() => {
    removeEmptyItems();
  }, [checklistItemFields]);

  const [createChecklistItemMutation] = useMutation(CHECKLIST_ITEM_CREATE_MUTATION, {
    onCompleted: async ({ subtaskChecklistItemCreate }) => {
      const { id, body } = subtaskChecklistItemCreate.checklistItem;

      removeChecklistItem(checklistItemFields.length - 1);
      appendChecklistItem({ body, itemId: id });
      setItemIdBeingEdited(null);

      if (!addingAnotherItem) { refetch(); }
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const createChecklistItem = (values) => {
    if (!checklistId || !values.body) { return; }

    createChecklistItemMutation({ variables: { input: { checklistId, ...values } } });
  };

  const appendItem = () => {
    if (!checklistId) { return setIsEditingChecklist(false); }
    if (itemIdBeingEdited) {
      setAddingAnotherItem(true);
      const itemAdded = getValues('checklists.0.items').find((item) => item.itemId === itemIdBeingEdited);
      return itemAdded ? createChecklistItem({ body: itemAdded.body }) : null;
    }

    appendChecklistItem({ body: null, itemId: NEW_ITEM_ID });
    return setItemIdBeingEdited(NEW_ITEM_ID);
  };

  useEffect(() => {
    if (addingAnotherItem && !itemIdBeingEdited) {
      appendChecklistItem({ body: '', itemId: NEW_ITEM_ID });
      setItemIdBeingEdited(NEW_ITEM_ID);
      setAddingAnotherItem(false);
    }
  }, [addingAnotherItem, itemIdBeingEdited]);

  const [deleteChecklistItemMutation] = useMutation(CHECKLIST_ITEM_DELETE_MUTATION, {
    onCompleted: async () => {
      await refetch();
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const deleteChecklistItem = ({ checklistItemIdx }) => {
    const id = checklistItemFields[checklistItemIdx].itemId;

    if (id !== NEW_ITEM_ID) {
      deleteChecklistItemMutation({ variables: { input: { id } } });
    }

    setItemIdBeingEdited(null);
    removeChecklistItem(checklistItemIdx);
  };

  const itemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    color: isDragging ? 'white' : 'unset',
    background: isDragging ? '#318CE7' : 'unset',
  });

  const checklistItem = ({ itemId, completed, checklistItemIdx }) => {
    const fieldName = `checklists.${checklistIdx}.items.${checklistItemIdx}`;
    const updateChecklistItem = (values) => {
      onUpdate({ id: itemId, ...values });
      setItemIdBeingEdited(null);
    };
    const isEditing = itemIdBeingEdited === itemId;
    const onToggleEdit = (value) => (value ? setItemIdBeingEdited(itemId) : setItemIdBeingEdited(null));

    return (
      <span className="flex items-center" key={itemId}>
        <Checkbox
          id={itemId}
          className={`mr-2 ${isEditing ? 'mb-12' : ''}`}
          disabled={itemId === NEW_ITEM_ID}
          control={control}
          onChange={onToggleComplete}
          placeholder="item"
          name={`${fieldName}.completed`}
          checked={completed}
        />
        <EditableTextArea
          formats={['bold', 'italic', 'underline', 'strike']}
          name={`${fieldName}.body`}
          updateFieldName="body"
          textViewClassName="mb-0"
          updatable={updatable}
          isEditing={isEditing}
          setIsEditing={onToggleEdit}
          onClear={() => deleteChecklistItem({ fieldName, checklistItemIdx })}
          onUpdate={itemId !== NEW_ITEM_ID ? updateChecklistItem : createChecklistItem}
          control={control}
          setValue={setValue}
          getValues={getValues}
          placeholder="Add item..."
        />
        {
          isEditing && (
            <Button
              icon="pi pi-trash"
              className="mb-11"
              type="button"
              onMouseDown={(e) => e.preventDefault()}
              text
              rounded
              severity="danger"
              aria-label="Remove"
              onClick={() => deleteChecklistItem({ fieldName, checklistItemIdx })}
            />
          )
        }
      </span>
    );
  };

  const renderChecklistItem = ({ itemId, completed, checklistItemIdx }) => {
    if (itemIdBeingEdited !== itemId) {
      return (
        <Draggable
          key={`draggable-checklist-${checklistIdx}-item-${itemId}`}
          isDragDisabled={!updatable || !!itemIdBeingEdited}
          draggableId={itemId}
          index={checklistItemIdx}
        >
          {(provided, snapshot) => (
            <DraggablePortal snapshot={snapshot}>
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                style={itemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                )}
                className="flex items-center p-1"
              >
                { checklistItem({ itemId, completed, checklistItemIdx }) }
              </div>
            </DraggablePortal>
          )}
        </Draggable>
      );
    }
    return checklistItem({ itemId, completed, checklistItemIdx });
  };

  return (
    <>
      {
        checklistItemFields.map(({
          itemId, completed,
        }, checklistItemIdx) => renderChecklistItem({ itemId, completed, checklistItemIdx }))
      }
      {droppablePlaceholder}
      {
        updatable && (
          <Button
            icon="pi pi-plus"
            label="Add Item"
            className="text-primary p-0 mt-2 add-item-checklist-button mt-4"
            type="button"
            disabled={disableAddItem}
            text
            size="small"
            onClick={appendItem}
          />
        )
      }
    </>
  );
}

export default ChecklistItemFields;
