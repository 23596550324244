import React, { useEffect } from 'react';
import { Badge } from 'primereact/badge';
import { classNames } from 'primereact/utils';

import Dropdown from 'components/Form/Dropdown';
import { titleize } from 'utils/stringUtils';

const STATUSES = [
  { label: 'Not Started', value: 'NOT_STARTED' },
  { label: 'In Progress', value: 'IN_PROGRESS' },
  { label: 'Completed', value: 'COMPLETED' },
];
function Status({
  status, isEditing, setIsEditing, updateTask, control, setValue,
}) {
  useEffect(() => {
    setValue('status', status);
  }, [status]);
  const itemTemplate = (option) => <Badge className={`status-badge status-${option.value}-bg`} value={titleize(option.label)} />;

  if (isEditing) {
    return (
      <>
        <div className="flex items-center justify-end w-full">
          <i className="pi pi-bolt text-xs mr-1" /><p className="text-bluegray-500">Status</p>
        </div>
        <div className="flex items-center justify-end w-full flex-wrap cursor-pointer">
          <Dropdown
            control={control}
            name="status"
            value={status}
            className="m-0 p-0 text-xs w-unset"
            options={STATUSES}
            inputProps={{
              onChange: ({ value }) => (status !== value ? updateTask({ status: value }) : {}),
              placeholder: 'Select a status',
              itemTemplate,
            }}
          />
          <i className="pi pi-times text-xs ml-1" onClick={() => setIsEditing(false)} />
        </div>
      </>
    );
  }

  const badgeClassNames = classNames(
    'status-badge',
    `status-${status}-bg`,
    `status-${status}-border`,
    'no-hover',
    'border',
    'font-semibold',
    'hover:text-bluegray-500',
    'hover:border-bluegray-500',
    'hover:bg-white',
    'task-info-item-badge',
    'rounded-md',
    'max-w-max',
    'self-end',
    'float-end',
    'mt-1',
    'mb-3',
    'cursor-pointer',
  );

  return (
    <>
      <div className="flex items-center justify-end">
        <i className="pi pi-bolt text-xs mr-1" /><p className="text-bluegray-500">Status</p>
      </div>
      <div className="flex items-center justify-end">
        <Badge
          onClick={() => setIsEditing(true)}
          className={badgeClassNames}
          value={titleize(status)}
        />
      </div>
    </>
  );
}

export default Status;
