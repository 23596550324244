import React from 'react';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';

import { titleize } from 'utils/stringUtils';
import HideOrDeleteFileButton from './HideOrDeleteFileButton';
import ShowFileButton from './ShowFileButton';
import { fileCategoryBackgroundColorClassName, fileDetails } from './helpers';

function UploaderFileCard({
  setActiveEventFileId, eventFile, refetchFiles, toastRef,
}) {
  const { file } = eventFile;

  const handleClick = (event) => {
    if (event.target.className.includes('button')) { return; }
    setActiveEventFileId(file.id);
  };

  return (
    <Card className="border border-round cursor-pointer file-card" onClick={handleClick}>
      <div className="flex items-center justify-between w-full">
        <Tag className={fileCategoryBackgroundColorClassName(file.fileCategory)} value={titleize(file.fileCategory)} />
        {
          file.isUpdatableByCurrentActor && (
            file.showOnSummary ? (
              <HideOrDeleteFileButton file={file} eventFileId={eventFile.id} refetchFiles={refetchFiles} toastRef={toastRef} />
            ) : (
              <ShowFileButton file={file} eventFileId={eventFile.id} refetchFiles={refetchFiles} toastRef={toastRef} />
            )
          )
        }
      </div>
      { fileDetails(file) }
    </Card>
  );
}

export default UploaderFileCard;
