import React from 'react';
import { Dialog } from 'primereact/dialog';
import UpdateTeamMemberGroupForm from './UpdateTeamMemberGroupForm';

function UpdateTeamMemberGroupModal({
  teamMemberActors, teamMemberGroupActor, show, onHide, refetchTeamMembersAndGroups, toastRef,
}) {
  return (
    <Dialog
      header="Update Team Member Group"
      className="xl:w-5/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
    >
      <UpdateTeamMemberGroupForm
        teamMemberActors={teamMemberActors}
        teamMemberGroupActor={teamMemberGroupActor}
        refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
        hideModal={onHide}
        toastRef={toastRef}
      />
    </Dialog>
  );
}

export default UpdateTeamMemberGroupModal;
