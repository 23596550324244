import React from 'react';
import { Card as CardComponent } from 'primereact/card';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import moment from 'moment';
import { titleize } from 'utils/stringUtils';
import Labels from '../Labels';

export default function Card({
  task, onClick, showEventInfo, showAssignee,
}) {
  const archivedIcon = () => {
    if (!task.archived) { return null; }

    return (
      <>
        <Tooltip key={`${task.id}-archived-tooltip`} target={`.task-board-card--archived-tooltip-icon-${task.id}`} />
        <i
          key={`${task.id}-archived-icon`}
          className={`task-board-card--archived-tooltip-icon-${task.id} pi pi-trash mr-1 text-sm text-danger`}
          data-pr-tooltip="Archived"
          data-pr-position="top"
        />
      </>
    );
  };

  const dueDateIcon = () => {
    if (!task.dueDate) { return null; }

    const dueDateClasses = classNames(
      `task-board-card--due-date-tooltip-icon-${task.id}`,
      'pi',
      'pi-clock',
      'mr-1',
      'text-sm',
      { 'text-danger': task.overdue },
    );

    return (
      <>
        <Tooltip key={`${task.id}-due-date-tooltip`} target={`.task-board-card--due-date-tooltip-icon-${task.id}`} />
        <i
          key={`${task.id}-due-date-icon`}
          className={dueDateClasses}
          data-pr-tooltip={`Due: ${moment(task.dueDate).format('M/D/YY')}`}
          data-pr-position="top"
        />
      </>
    );
  };

  const statusIcon = () => (
    <>
      <Tooltip key={`${task.id}-status-tooltip`} target={`.status-tooltip-icon-${task.id}`} />
      <Badge
        key={`${task.id}-status-badge`}
        className={`status-tooltip-icon-${task.id} status-badge status-${task.status}-bg task-info-item-badge h-4 min-w-0 w-4 mr-1`}
        data-pr-tooltip={titleize(task.status)}
        data-pr-position="top"
      />
    </>
  );

  const eventLinkIcon = () => {
    if (!showEventInfo || !task.event) { return null; }

    return (
      <>
        <Tooltip key={`${task.id}-event-tooltip`} target={`.event-tooltip-icon-${task.id}`} />
        <Button
          key={`${task.id}-event-link`}
          text
          rounded
          icon="pi pi-calendar"
          className={`event-tooltip-icon-${task.id} text-sm text-primary h-auto w-min p-0 mr-1`}
          onClick={() => window.open(`/app/events/${task.event.id}`, '_blank')}
          target="_blank"
          data-pr-tooltip={task.event.name}
          data-pr-position="top"
        />
      </>
    );
  };

  const assigneeIcon = () => {
    if (!showAssignee) { return null; }

    return (
      <>
        <Tooltip key={`${task.id}-assigned-to-tooltip`} target={`.task-card-assignee-icon-tooltip-${task.id}`} />
        {
          task.assignedToActor && (
            <Avatar
              key={`${task.id}-assigned-to-avatar`}
              image={task.assignedToActor.avatarUrl}
              label={task.assignedToActor.initials}
              shape="circle"
              className={`task-card-assignee-icon task-card-assignee-icon-tooltip-${task.id} bg-info text-white rounded-full p-0`}
              data-pr-tooltip={task.assignedToActor.name}
              data-pr-position="top"
            />
          )
        }
      </>
    );
  };

  const taskCardClasses = classNames(
    'mb-2',
    'task-board-lane-card',
    'w-80',
    'relative',
    'overflow-y-auto',
    'overflow-x-hidden',
    'cursor-pointer',
  );

  return (
    <CardComponent
      key={`${task.id}-card`}
      className={taskCardClasses}
      onClick={() => onClick(task)}
    >
      <div className="flex flex-col justify-between h-full">
        <div className="flex items-start w-full justify-between task-card-info-icons min-h-8">
          <div className="flex items-center task-card-info-icons" key={`${task.id}-card-icons`}>
            { statusIcon() }
            { eventLinkIcon() }
            { dueDateIcon() }
            { archivedIcon() }
          </div>
          <div className="w-8 h-8">
            { assigneeIcon() }
          </div>
        </div>
        <div className="min-h-8 p-3"><h4 key={`${task.id}-card-title`} className="text-center font-normal text-sm">{task.title}</h4></div>
        <div key={`${task.id}-card-labels`} className="task-card-labels flex items-end min-h-8"><Labels labels={task.labels} /></div>
      </div>
    </CardComponent>
  );
}
