import React from 'react';
import { Dialog } from 'primereact/dialog';
import ManageReviewSourcesForm from '.';

function ManageReviewSourcesModal({
  reviewSources, show, onHide, refetch, toastRef,
}) {
  return (
    <Dialog
      header="Manage Review Sources"
      className="xl:w-5/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
    >
      <ManageReviewSourcesForm
        reviewSources={reviewSources}
        refetch={refetch}
        toastRef={toastRef}
      />
    </Dialog>
  );
}

export default ManageReviewSourcesModal;
