import React from 'react';
import PasswordInput from '../PasswordInput';

function PasswordConfirmationInput(props) {
  const {
    control,
    name = 'passwordConfirmation',
    label = 'Confirm Password',
    className,
    controllerProps,
    inputProps,
    feedback = false,
    passwordFieldName = 'password',
    getValues,
  } = props;

  return (
    <PasswordInput
      control={control}
      className={className}
      controllerProps={controllerProps}
      inputProps={inputProps}
      feedback={feedback}
      name={name}
      label={label}
      required
      validate={(passwordConfirmationValue) => {
        const passwordsMatch = getValues(passwordFieldName) === passwordConfirmationValue;

        return passwordsMatch || 'Password confirmation must match password';
      }}
    />
  );
}

export default PasswordConfirmationInput;
