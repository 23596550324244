import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import reduce from 'lodash/reduce';
import { showErrorToast } from 'utils/toastUtils';
import { titleize } from 'utils/stringUtils';
import GooglePlacesSearchInput from 'components/Form/GooglePlacesSearchInput';
import TextInput from 'components/Form/TextInput';
import Dropdown from 'components/Form/Dropdown';
import NumberInput from 'components/Form/NumberInput';
import MultiSelect from 'components/Form/MultiSelect';
import { US_STATES_QUERY, VENDOR_CATEGORIES_QUERY } from 'graphql/shared';

function CreateVendorForm({ control, setValue, toastRef }) {
  const [usStates, setUsStates] = useState([]);
  const [mappedCategories, setMappedCategories] = useState([]);

  const {
    usStatesQueryLoading, usStatesQueryError,
  } = useQuery(
    US_STATES_QUERY,
    {
      onCompleted: (data) => setUsStates(data.usStates),
    },
  );

  const {
    categoriesLoading, categoriesError,
  } = useQuery(
    VENDOR_CATEGORIES_QUERY,
    {
      onCompleted: (data) => {
        const mappedVendorCategories = reduce(data.vendorCategories, (options, category) => {
          options.push({ label: titleize(category), value: category });
          return options;
        }, []);

        setMappedCategories(mappedVendorCategories);
      },
    },
  );

  if (
    categoriesLoading
    || categoriesError
    || usStatesQueryLoading
    || usStatesQueryError
  ) {
    return null;
  }

  const fillVendorInfo = ({ vendorInfo, name }) => {
    setValue('categories', null);

    if (vendorInfo) {
      setValue('name', vendorInfo.name);
      setValue('googlePlaceId', vendorInfo.googlePlaceId);
      setValue('street1', vendorInfo.address?.street1);
      setValue('street2', vendorInfo.address?.street2);
      setValue('city', vendorInfo.address?.city);
      setValue('state', vendorInfo.address?.usState?.id);
      setValue('zipCode', vendorInfo.address?.zipCode);
    } else {
      setValue('name', name);
      showErrorToast(toastRef, 'Error fetching vendor details. Please manually fill in details.');
    }
  };

  return (
    <div className="md:grid md:grid-cols-12 gap-2">
      <GooglePlacesSearchInput
        focus
        control={control}
        name="vendorSearch"
        className="md:col-span-12"
        setValue={setValue}
        fillVendorInfo={fillVendorInfo}
      />
      <p className="font-bold text-base col-span-12 mt-6 mb-4">Vendor Details</p>
      <TextInput
        control={control}
        name="name"
        label="Name"
        className="md:col-span-6"
        inputClassName="h-12"
      />
      <MultiSelect
        control={control}
        name="categories"
        label="Categories"
        showSelectAll={false}
        options={mappedCategories}
        placeholder="Select a category"
        className="md:col-span-6"
        inputClassName="min-h-12"
      />
      <p className="font-bold text-base col-span-12 mt-6 mb-4">Address</p>
      <TextInput
        control={control}
        name="street1"
        label="Street 1"
        required={false}
        className="md:col-span-6"
      />
      <TextInput
        control={control}
        name="street2"
        label="Street 2"
        required={false}
        className="md:col-span-6"
      />
      <TextInput
        control={control}
        name="city"
        label="City"
        required={false}
        className="md:col-span-5"
      />
      <Dropdown
        control={control}
        name="state"
        label="State"
        className="md:col-span-3"
        required={false}
        options={usStates.map((usState) => ({
          label: usState.abbreviation,
          value: usState.id,
        }))}
      />
      <NumberInput
        control={control}
        name="zipCode"
        label="Zip Code"
        className="md:col-span-4"
        required={false}
      />
    </div>
  );
}

export default CreateVendorForm;
