import React, { useRef, useState, useEffect } from 'react';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import EmailInput from 'components/Form/EmailInput';
import TextInput from 'components/Form/TextInput';
import PhoneNumberInput from 'components/Form/PhoneNumberInput';
import InstagramHandleInput from 'components/Form/InstagramHandleInput';
import PasswordInput from 'components/Form/PasswordInput';
import UpdateLoginSettingsForm from './UpdateLoginSettingsForm';
import {
  ACTOR_DETAILS_QUERY,
  ACTOR_UPDATE_MUTATION,
  USER_PASSWORD_CONFIRM_MUTATION,
} from './graphql';

function UserProfile() {
  const [currentActor, setCurrentActor] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState(null);
  const [showConfirmPasswordInput, setShowConfirmPasswordInput] = useState(false);
  const [showLoginSettingsForm, setShowLoginSettingsForm] = useState(false);

  const {
    control, handleSubmit, setValue, getValues, watch,
  } = useForm();

  useEffect(() => {
    if (!currentPassword && currentActor && (watch('newPassword') || watch('loginEmail') !== currentActor.email)) {
      setShowConfirmPasswordInput(true);
    }
  }, [watch('loginEmail'), watch('newPassword')]);

  const updateActorValues = (data) => {
    setCurrentActor(data);

    setValue('firstName', data.firstName);
    setValue('lastName', data.lastName);
    setValue('contactEmail', data.email);
    setValue('phoneNumber', data.phoneNumber);
    setValue('instagramHandle', data.instagramHandle);
  };

  useQuery(
    ACTOR_DETAILS_QUERY,
    { onCompleted: (data) => updateActorValues(data.currentActor) },
  );

  const toastRef = useRef(null);

  const [updateActorMutation] = useMutation(ACTOR_UPDATE_MUTATION, {
    onCompleted: ({ actorUpdate }) => {
      updateActorValues(actorUpdate.actor);
      showSuccessToast(toastRef, 'Successfully updated user details!');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating user: ${message}`)
      ));
    },
  });

  const updateActor = (values) => {
    updateActorMutation({
      variables: {
        input: {
          id: currentActor.id,
          avatar: values.avatar,
          email: values.contactEmail,
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          instagramHandle: values.instagramHandle,
        },
      },
    });
  };

  const avatarUpload = () => (
    <div className="flex flex-col">
      <Avatar image={currentActor?.avatarUrl} size="xlarge" shape="circle" className="w-52 h-52" />
      <div className="flex w-full justify-center">
        <FileUpload
          mode="basic"
          chooseOptions={{ icon: 'pi pi-pencil', iconOnly: true, className: '-mt-4 border-circle p-3 button-icon-only' }}
          onSelect={({ files }) => updateActor({ avatar: files[0] })}
        />
      </div>
    </div>
  );

  const [confirmPasswordMutation] = useMutation(USER_PASSWORD_CONFIRM_MUTATION, {
    onCompleted: ({ userPasswordConfirm }) => {
      if (userPasswordConfirm.passwordConfirmed) {
        setShowConfirmPasswordInput(false);
        setShowLoginSettingsForm(true);
      } else {
        setCurrentPassword(null);
        setCurrentPasswordErrorMessage('Current password is invalid');
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error confirming password: ${message}`)
      ));
    },
  });

  const onConfirmPasswordSubmit = () => {
    const password = getValues('currentPassword');

    if (!password) { setCurrentPasswordErrorMessage('Current password is required'); }

    setCurrentPassword(password);
    confirmPasswordMutation({ variables: { input: { password } } });
  };

  const confirmPasswordModal = () => (
    <Dialog
      className="xl:w-1/3"
      visible={showConfirmPasswordInput}
      dismissableMask
      draggable={false}
      header={<p>Enter current password to update login credentials</p>}
      footer={(
        <div className="grid grid-cols-4">
          <Button
            key="confirm-password-submit"
            type="button"
            label="Submit"
            className="col-span-2 col-start-2 mx-0 -mt-4"
            onClick={onConfirmPasswordSubmit}
          />
        </div>
      )}
      onHide={() => setShowConfirmPasswordInput(false)}
    >
      <PasswordInput
        control={control}
        feedback={false}
        name="currentPassword"
        label="Current Password"
        inputProps={{ onKeyPress: ({ key }) => key === 'Enter' && onConfirmPasswordSubmit() }}
        required={false}
      />
      <p className="text-danger mt-2 ml-3">{currentPasswordErrorMessage}</p>
    </Dialog>
  );

  return (
    <>
      <p className="font-bold text-xl">User Profile</p>
      <div className="content-with-nav-bar">
        <Card className="flex justify-center">
          <Toast ref={toastRef} position="bottom-left" />
          <form onSubmit={handleSubmit(updateActor)} className="p-4">
            <div className="w-full justify-center xxs:flex md:hidden">
              { avatarUpload() }
            </div>
            <div className="flex items-center grid xxs:grid-cols-1 md:grid-cols-2 gap-2">
              <div>
                <TextInput
                  control={control}
                  name="firstName"
                  label="First Name"
                />
                <TextInput
                  control={control}
                  name="lastName"
                  label="Last Name"
                />
                <EmailInput
                  control={control}
                  name="contactEmail"
                  label="Contact Email"
                />
                <PhoneNumberInput
                  control={control}
                  name="phoneNumber"
                  label="Phone Number"
                />
                <InstagramHandleInput
                  control={control}
                  name="instagramHandle"
                  label="Instagram Handle"
                  required={false}
                />
                {
                  !showLoginSettingsForm && (
                    <Button
                      label="Edit Login Settings"
                      type="button"
                      icon="pi pi-user-edit"
                      text
                      className="pl-1 mt-4"
                      onClick={() => setShowConfirmPasswordInput(true)}
                    />
                  )
                }
              </div>
              <div className="md:flex md:w-full md:justify-center xxs:hidden">
                { avatarUpload() }
              </div>
            </div>
            <div className="flex w-full justify-center mt-4 grid grid-cols-12">
              <Button key="update-actor-submit" type="submit" label="Submit" className="mt-2 xxs:col-span-12 md:col-span-2 md:col-start-6" />
            </div>
          </form>
          {
            currentPassword && showLoginSettingsForm && (
              <UpdateLoginSettingsForm
                toastRef={toastRef}
                currentPassword={currentPassword}
                onSubmit={() => setShowLoginSettingsForm(false)}
              />
            )
          }
          { confirmPasswordModal() }
        </Card>
      </div>
    </>
  );
}

export default UserProfile;
