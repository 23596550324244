import gql from 'graphql-tag';

const CURRENT_ACTOR_QUERY = gql`
  query CurrentActorDetails {
    currentActor {
      id
      firstName
      lastName
      phoneNumber
      instagramHandle
      actorType
      invitationToken
      isVendorPrimaryPointOfContact
    }
  }
`;

const VENDOR_UPDATE_MUTATION = gql`
  mutation UpdateVendor($input: VendorUpdateMutationInput!) {
    vendorUpdate(input: $input) {
      vendor {
        id
        name
        categories
        address {
          id
          street1
          street2
          city
          usState {
            id
            name
          }
          zipCode
        }
      }
    }
  }
`;

const VENDOR_DETAILS_QUERY = gql`
  query Vendor {
    currentVendor {
      id
      name
      email
      categories
      address {
        id
        street1
        street2
        city
        usState {
          id
          name
        }
        zipCode
      }
    }
  }
`;

export {
  CURRENT_ACTOR_QUERY,
  VENDOR_UPDATE_MUTATION,
  VENDOR_DETAILS_QUERY,
};
