import gql from 'graphql-tag';

const SUBTASK_CREATE_MUTATION = gql`
  mutation CreateSubtask($input: SubtaskCreateMutationInput!) {
    subtaskCreate(input: $input) {
      subtask {
        id
      }
    }
  }
`;

export {
  SUBTASK_CREATE_MUTATION,
};
