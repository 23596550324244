import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMutation } from '@apollo/react-hooks';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Fieldset } from 'primereact/fieldset';
import { confirmDialog } from 'primereact/confirmdialog';
import { classNames } from 'primereact/utils';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { showErrorToast } from 'utils/toastUtils';
import EditableTextArea from 'components/Form/EditableFields/TextArea';
import AvatarWithName from 'components/AvatarWithName';
import {
  DELETE_ANSWER_MUTATION,
  DELETE_COMMENT_MUTATION,
  MENTION_MARK_AS_READ_MUTATION,
  UPDATE_ANSWER_MUTATION,
  UPDATE_COMMENT_MUTATION,
  CREATE_ANSWER_MUTATION,
  CREATE_COMMENT_MUTATION,
} from './graphql';

function CommentCard({
  comment, mentionables, updatable, currentActorId, refetch, toastRef, commentType, commentableId, focus = false,
}) {
  const deleteMutation = commentType === 'comment' ? DELETE_COMMENT_MUTATION : DELETE_ANSWER_MUTATION;
  const {
    id, body, createdByActor, updatedAt, mentions = [], attachments = [],
  } = comment;
  const [isEditing, setIsEditing] = useState(focus);
  const {
    control, setValue, getValues, reset, watch,
  } = useForm({ defaultValues: { body, attachments } });
  const { ref, inView } = useInView();

  const currentActorUnreadMentions = mentions.filter((mention) => mention.mentionedActor.id === currentActorId && !mention.read);

  const [markMentionAsRead] = useMutation(MENTION_MARK_AS_READ_MUTATION, {
    onCompleted: () => { refetch(); },
  });

  useEffect(() => {
    if (inView && currentActorUnreadMentions.length) {
      currentActorUnreadMentions.map((mention) => markMentionAsRead({ variables: { input: { id: mention.id } } }));
    }
  }, [inView]);

  const [deleteCommentMutation] = useMutation(deleteMutation, {
    onCompleted: async () => {
      await refetch();
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const confirmDeleteComment = () => {
    const message = `Are you sure you want to remove this ${commentType}?`;

    return confirmDialog({
      className: 'xl:w-3/12',
      message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteCommentMutation({ variables: { input: { id: comment.id } } }),
    });
  };

  const [updateCommentMutation] = useMutation(commentType === 'comment' ? UPDATE_COMMENT_MUTATION : UPDATE_ANSWER_MUTATION, {
    onCompleted: async () => {
      await refetch();
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const updateComment = ({ body: newBody, attachmentIds }) => updateCommentMutation({
    variables: { input: { id, body: newBody, attachmentIds } },
  });

  const [createCommentMutation] = useMutation(commentType === 'comment' ? CREATE_COMMENT_MUTATION : CREATE_ANSWER_MUTATION, {
    onCompleted: async () => {
      reset({ body: '', attachments: [] });
      await refetch();
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const createComment = ({ body: newBody, attachmentIds }) => {
    if (commentType === 'comment') {
      return createCommentMutation({
        variables: { input: { taskId: commentableId, body: newBody, attachmentIds } },
      });
    }
    return createCommentMutation({
      variables: { input: { questionId: commentableId, body: newBody, attachmentIds } },
    });
  };

  const textAreaClassNames = classNames(
    'mt-4',
    'ml-10',
    'flex',
    'items-center',
    { 'mb-6': isEditing && id },
    { 'text-sm': !id },
  );

  return (
    <form className="mb-2 p-0 comment-card">
      <Fieldset>
        <div ref={ref}>
          <AvatarWithName
            id={createdByActor.id}
            initials={createdByActor.initials}
            avatarUrl={createdByActor.avatarUrl}
            text={createdByActor.name}
            subtext={!updatable && createdByActor.vendor?.name}
          />
          <div className={textAreaClassNames}>
            <EditableTextArea
              focus
              attachmentsDisabled={false}
              attachments={watch('attachments')}
              mentionsDisabled={false}
              mentionables={mentionables}
              emptyTextViewClassName="mb-4"
              name="body"
              placeholder={`Add ${commentType}...`}
              updatable={updatable}
              onUpdate={id ? updateComment : createComment}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              control={control}
              setValue={setValue}
              getValues={getValues}
            />
            {
              updatable && isEditing && id && (
                <Button
                  icon="pi pi-times"
                  onClick={confirmDeleteComment}
                  text
                  type="button"
                  severity="danger"
                  className="w-min comment-action-button ml-4 mb-11"
                />
              )
            }
          </div>
        </div>
        {
          id && (
            <div className="flex items-center justify-end">
              <Tooltip target={`.comment--${comment.id}-updated-at-tooltip`} />
              <p
                className={`text-xs mr-2 comment--${comment.id}-updated-at-tooltip`}
                data-pr-tooltip={moment(updatedAt).format('M/D/YY h:mma')}
                data-pr-position="top"
              >
                {moment(updatedAt).fromNow()}
              </p>
            </div>
          )
        }
      </Fieldset>
    </form>
  );
}

export default CommentCard;
