import React, { useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { showErrorToast } from 'utils/toastUtils';
import { confirmDialog } from 'primereact/confirmdialog';
import { titleize } from 'utils/stringUtils';
import CompleteSubtaskForm from '../CompleteTaskForm/CompleteSubtaskForm';
import { SUBTASK_DELETE_MUTATION } from './graphql';

function CreateOrUpdateSubtaskTemplateModal(props) {
  const {
    subtaskTemplate,
    action,
    show,
    onHide,
    refetch,
  } = props;
  const toastRef = useRef(null);
  const { subtaskType } = subtaskTemplate;

  const [deleteSubtaskMutation] = useMutation(SUBTASK_DELETE_MUTATION, {
    onCompleted: async () => {
      await refetch();
      onHide();
    },
    onError: () => showErrorToast(toastRef, 'Error deleting subtask'),
  });

  const header = () => (
    <div className="grid grid-cols-12">
      <p className="col-span-6">{titleize(action)} subtask template</p>
    </div>
  );

  const subtaskId = subtaskTemplate.id;

  const deleteButton = () => {
    if (!subtaskId) { return null; }

    const confirmDelete = () => confirmDialog({
      className: 'xl:w-3/12',
      message: 'Are you sure you want to delete this subtask template?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteSubtaskMutation({ variables: { input: { id: subtaskId } } }),
    });

    return (
      <Button
        type="button"
        onClick={confirmDelete}
        icon="pi pi-trash"
        size="small"
        label="Delete"
        severity="warning"
        outlined
        aria-label="Delete"
      />
    );
  };

  return (
    <>
      <Dialog
        header={header()}
        className="xl:w-1/3"
        closable={action === 'update' || subtaskType !== 'CHECKLIST'}
        visible={show}
        onHide={onHide}
        draggable={false}
        dismissableMask
        maximizable
        resizable
      >
        <div className="p-0 z-10 border-solid border-2 border-gray-200 mt-2 rounded my-4">
          <CompleteSubtaskForm
            subtask={subtaskTemplate}
            isSubtaskTemplate
            refetch={refetch}
            toastRef={toastRef}
            onRemoveEmptySubtask={() => {}}
            hideModal={onHide}
          />
        </div>
        <div className="flex justify-end w-full">{ deleteButton() }</div>
      </Dialog>
      <Toast ref={toastRef} position="bottom-left" />
    </>
  );
}

export default CreateOrUpdateSubtaskTemplateModal;
