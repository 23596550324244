import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';

import EmailInput from 'components/Form/EmailInput';
import TextInput from 'components/Form/TextInput';
import PasswordInput from 'components/Form/PasswordInput';
import SwitchInput from 'components/Form/SwitchInput';
import MultiSelect from 'components/Form/MultiSelect';
import { signUpUser } from 'auth/api';
import reduce from 'lodash/reduce';
import { titleize } from 'utils/stringUtils';
import PasswordConfirmationInput from 'components/Form/PasswordConfirmationInput';
import { VENDOR_CATEGORIES_QUERY } from 'graphql/shared';

function SignUp({ refetchCurrentUserAndVendor }) {
  const [submissionErrors, setSubmissionErrors] = useState([]);
  const [mappedCategories, setMappedCategories] = useState([]);
  const toastRef = useRef();

  const {
    control, handleSubmit, getValues, watch,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = async ({
    firstName, lastName, email, password, vendorName, vendorCategories,
  }) => {
    const { errors } = await signUpUser({
      email,
      password,
      firstName,
      lastName,
      vendorName,
      vendorCategories,
    });

    if (errors) {
      return setSubmissionErrors(errors.full_messages);
    }

    return refetchCurrentUserAndVendor();
  };

  useQuery(
    VENDOR_CATEGORIES_QUERY,
    {
      onCompleted: (data) => {
        const mappedVendorCategories = reduce(data.vendorCategories, (options, category) => {
          options.push({ label: titleize(category), value: category });
          return options;
        }, []);

        setMappedCategories(mappedVendorCategories);
      },
    },
  );

  return (
    <Card className="w-screen h-screen login-container-wrapper">
      <div className="flex items-center login-container w-full h-full grid grid-cols-12">
        <div className="xxs:col-span-12 md:col-span-5">
          <div className="flex justify-center mb-6">
            <Image imageStyle={{ height: '4rem' }} src="/images/ep-logo.png" alt="Enterplan" />
          </div>
          <div className="text-center">
            {
              submissionErrors.map((error) => <p key={error} className="text-danger mt-2">{ error }</p>)
            }
          </div>
          <form className="grid grid-cols-12" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-8 col-start-3 flex items-center gap-2 mb-2">
              <TextInput
                control={control}
                name="firstName"
                label="First Name"
              />
              <TextInput
                control={control}
                name="lastName"
                label="Last Name"
              />
            </div>
            <EmailInput
              control={control}
              name="email"
              label="Email"
              className="col-span-8 col-start-3 mb-4"
            />
            <div className="col-span-8 col-start-3 flex items-center gap-2 mb-4">
              <PasswordInput
                control={control}
                name="password"
                label="Password"
              />
              <PasswordConfirmationInput
                control={control}
                getValues={getValues}
              />
            </div>
            <SwitchInput
              label="Are you a vendor?"
              name="isVendor"
              className="col-span-8 col-start-3 flex"
              labelClassName="mr-2"
              control={control}
              getValues={getValues}
            />
            {
              watch('isVendor') && (
                <div className="col-span-8 col-start-3 flex items-center gap-2 mb-4">
                  <TextInput
                    control={control}
                    name="vendorName"
                    label="Vendor Name"
                  />
                  <MultiSelect
                    control={control}
                    name="vendorCategories"
                    label="Categories"
                    showSelectAll={false}
                    options={mappedCategories}
                    placeholder="Select a category"
                    inputClassName="min-h-12"
                  />
                </div>
              )
            }
            <Button type="submit" label="Submit" className="col-span-8 col-start-3 mt-4" />
            <Toast ref={toastRef} position="bottom-left" />
          </form>
          <div className="flex justify-center w-full mt-4">
            <Link to="/login">Already have an account? Log in</Link>
          </div>
        </div>
        <div className="xxs:hidden md:block md:col-span-7 event-planning-image h-full bg-cover bg-center" />
      </div>
    </Card>
  );
}

export default SignUp;
