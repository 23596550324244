import React from 'react';
import { useController } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';

import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function MultiSelectField(props) {
  const {
    control,
    name,
    label,
    labelClassName = '',
    inputClassName = '',
    options,
    placeholder,
    className,
    searchable = true,
    controllerProps,
    required = true,
    tooltip = null,
    onChange = () => {},
    showSelectAll = true,
    inputProps,
  } = props;

  const {
    field: { value, onChange: onFieldChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: null,
    ...controllerProps,
  });

  const multiSelectClasses = classNames(
    'w-full',
    'p-0',
    'm-0',
    'items-center',
    inputClassName,
    {
      'p-invalid': error,
    },
  );

  const handleChange = (selectedField) => {
    if (selectedField.selectedOption.value !== 'non-selectable') { onFieldChange(selectedField); }
    onChange(selectedField);
  };

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      {
        (label || tooltip) && (
          <div className="flex items-center mb-2">
            { label && <label htmlFor={name} className={`block ${labelClassName}`}>{label}</label> }
            {
              tooltip && (
                <>
                  <Tooltip target={`.multiselect-tooltip-icon-${name}`} />

                  <i
                    className={`multiselect-tooltip-icon-${name} pi pi-${tooltip.icon} ml-1`}
                    data-pr-tooltip={tooltip.message}
                    data-pr-position="top"
                    style={{ cursor: 'pointer' }}
                  />
                </>
              )
            }
          </div>
        )
      }
      <MultiSelect
        id={name}
        name={name}
        filter={searchable}
        value={value}
        options={options}
        disabled={!options.length}
        onChange={handleChange}
        showSelectAll={showSelectAll}
        placeholder={placeholder}
        display="chip"
        className={multiSelectClasses}
        itemTemplate={(option) => option.label}
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default MultiSelectField;
