import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Checkbox } from 'primereact/checkbox';

import { mergeClassNames } from 'utils/styleUtils';
import { showErrorToast } from 'utils/toastUtils';
import { SUBTASK_STATUS_UPDATE_MUTATION } from './graphql';

function SubtaskStatus({
  subtask, setSubtaskUpdated, refetch, toastRef, className = '',
}) {
  const { id, completed } = subtask;

  const [updateSubtaskStatusMutation] = useMutation(SUBTASK_STATUS_UPDATE_MUTATION, {
    onCompleted: async () => {
      setSubtaskUpdated(true);
      await refetch();
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  return (
    <Checkbox
      className={mergeClassNames(`subtask-completed-checkbox ${className}`)}
      onChange={(e) => (id ? updateSubtaskStatusMutation({ variables: { input: { id, completed: e.checked } } }) : {})}
      checked={completed}
    />
  );
}

export default SubtaskStatus;
