/* eslint-disable prefer-destructuring */
const REACT_APP_ENTERPLAN_BASE_URL = process.env.REACT_APP_ENTERPLAN_BASE_URL;
/* eslint-enable prefer-destructuring */

/* eslint-disable max-len */
export const GOOGLE_OAUTH_LINK = `${REACT_APP_ENTERPLAN_BASE_URL}/auth/google_oauth2?auth_origin_url=${REACT_APP_ENTERPLAN_BASE_URL}/set-auth-headers`;
/* eslint-enable max-len */

export async function setAuthHeaders(authHeaders) {
  window.localStorage.setItem('authHeaders', JSON.stringify(authHeaders));
}

export async function signUpUser({
  email, password, passwordConfirmation, firstName, lastName, vendorName, vendorCategories,
}) {
  const response = await fetch(
    `${REACT_APP_ENTERPLAN_BASE_URL}/auth`,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        vendor_name: vendorName,
        vendor_categories: vendorCategories,
        password_confirmation: passwordConfirmation,
      }),
    },
  );

  const authHeaders = {
    'access-token': response.headers.get('access-token'),
    client: response.headers.get('client'),
    uid: response.headers.get('uid'),
    expiry: response.headers.get('expiry'),
    'token-type': response.headers.get('token-type'),
  };

  setAuthHeaders(authHeaders);

  return response.json();
}

export async function loginUser({ email, password }) {
  const response = await fetch(
    `${REACT_APP_ENTERPLAN_BASE_URL}/auth/sign_in`,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email, password,
      }),
    },
  );

  const authHeaders = {
    'access-token': response.headers.get('access-token'),
    client: response.headers.get('client'),
    uid: response.headers.get('uid'),
    expiry: response.headers.get('expiry'),
    'token-type': response.headers.get('token-type'),
  };

  setAuthHeaders(authHeaders);

  return response.json();
}

export function logoutUser() {
  fetch(
    `${REACT_APP_ENTERPLAN_BASE_URL}/auth/sign_out`,
    {
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      body: localStorage.getItem('authHeaders'),
    },
  );

  window.localStorage.removeItem('authHeaders');
}

export async function sendResetPasswordLink({ email }) {
  const response = await fetch(
    `${REACT_APP_ENTERPLAN_BASE_URL}/auth/password`,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, redirect_url: `${REACT_APP_ENTERPLAN_BASE_URL}/update-password` }),
    },
  );

  return response.json();
}

export async function acceptInvitation({
  firstName, lastName, password, passwordConfirmation, invitationToken,
}) {
  const response = await fetch(
    `${REACT_APP_ENTERPLAN_BASE_URL}/omniauth/invitation`,
    {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        password,
        password_confirmation: passwordConfirmation,
        invitation_token: invitationToken,
      }),
    },
  );

  const authHeaders = {
    'access-token': response.headers.get('access-token'),
    client: response.headers.get('client'),
    uid: response.headers.get('uid'),
    expiry: response.headers.get('expiry'),
    'token-type': response.headers.get('token-type'),
  };

  setAuthHeaders(authHeaders);

  return response.json();
}

export async function updatePassword({ password, passwordConfirmation, resetPasswordToken }) {
  const response = await fetch(
    `${REACT_APP_ENTERPLAN_BASE_URL}/auth/password`,
    {
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken,
      }),
    },
  );

  return response.json();
}
