import React from 'react';
import { useController } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

import { renderFormFieldError } from 'utils/formUtils';
import { mergeClassNames } from 'utils/styleUtils';

function PasswordInput(props) {
  const {
    control,
    name,
    label,
    required = true,
    className,
    controllerProps,
    inputProps,
    feedback = true,
    validate = () => {},
  } = props;

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required, validate },
    defaultValue: null,
    ...controllerProps,
  });

  const inputClasses = classNames(
    'block',
    'w-full',
    'password-input',
    {
      'p-invalid': error,
    },
  );

  return (
    <div className={mergeClassNames(`field w-full ${className}`)}>
      { label && <label htmlFor={name} className="block mb-2">{label}</label> }
      <Password
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        feedback={feedback}
        toggleMask
        className={inputClasses}
        {...inputProps}
      />
      { renderFormFieldError(error) }
    </div>
  );
}

export default PasswordInput;
