import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Divider } from 'primereact/divider';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

import { showErrorToast } from 'utils/toastUtils';
import EditableText from 'components/Form/EditableFields/Text';
import ChecklistItemFields from './ChecklistItemFields';
import { CHECKLIST_CREATE_MUTATION, CHECKLIST_UPDATE_MUTATION } from './graphql';

function ChecklistFields({
  showDivider = false,
  isSubtaskTemplate,
  control,
  toastRef,
  setValue,
  getValues,
  templateName,
  refetch,
  watch,
  onChecklistItemToggleComplete = () => {},
  onUpdateChecklistItem = () => {},
  removeFieldAndResetValue = () => {},
  onCreate = () => {},
  isCreating,
  id,
  taskId,
  showLabel = false,
  updatable = true,
  useFieldArray,
}) {
  const [createdChecklistId, setCreatedChecklistId] = useState(id);
  const [isEditingTemplateName, setIsEditingTemplateName] = useState(isSubtaskTemplate && isCreating);
  const [isEditingTitle, setIsEditingTitle] = useState(isCreating);

  useEffect(() => {
    setValue('templateName', templateName);
  }, [templateName]);

  const [createChecklistMutation] = useMutation(CHECKLIST_CREATE_MUTATION, {
    onCompleted: async ({ subtaskChecklistCreate }) => {
      const { checklist } = subtaskChecklistCreate;

      await refetch();

      setCreatedChecklistId(checklist.id);
      setIsEditingTitle(false);
      setIsEditingTemplateName(false);
      onCreate(checklist);
    },
    onError: () => showErrorToast(toastRef, 'Error creating checklist'),
  });

  const createChecklist = () => {
    if (isSubtaskTemplate && (!getValues('templateName') || !getValues('checklists.0.title'))) { return null; }

    return createChecklistMutation({
      variables: {
        input: {
          taskId,
          isTemplate: isSubtaskTemplate,
          templateName: getValues('templateName'),
          title: getValues('checklists.0.title'),
        },
      },
    });
  };

  const [updateChecklistMutation] = useMutation(CHECKLIST_UPDATE_MUTATION, {
    onCompleted: async () => {
      await refetch();
    },
    onError: () => showErrorToast(toastRef, 'Error updating checklist'),
  });

  const reorderChecklistItems = (checklistIdx, startIndex, endIndex) => {
    const result = Array.from(getValues(`checklists.${checklistIdx}.items`));
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    setValue(`checklists.${checklistIdx}.items`, result);
  };

  const onDragEnd = ({ draggableId, source, destination }) => {
    if (!destination) { return; }

    const checklistIdx = source.droppableId.split('checklist-')[1];

    reorderChecklistItems(checklistIdx, source.index, destination.index);

    onUpdateChecklistItem({ id: draggableId, position: destination.index + 1 });
  };

  const setIsEditingChecklist = () => {
    setIsEditingTemplateName(false);
    setIsEditingTitle(false);
  };

  return (
    watch('checklists').map((checklist, checklistIdx) => {
      const checklistId = checklist.id || createdChecklistId;
      const field = `checklists.${checklistIdx}.title`;
      const updateChecklist = () => (
        updateChecklistMutation({ variables: { input: { id: checklistId, templateName: getValues('templateName'), title: getValues(field) } } })
      );

      return (
        <div key={`checklist-fields-${checklistId}`}>
          { showDivider && <Divider /> }
          <div className="flex items-center mb-2">
            <div className="w-full">
              {
                isSubtaskTemplate && (
                  <EditableText
                    focus
                    className={`w-1/2 ${!isEditingTemplateName ? 'mb-5' : ''}`}
                    textInputClassName={`w-full ${isCreating ? '' : 'mb-4'}`}
                    text={getValues('templateName')}
                    defaultValue={getValues('templateName')}
                    label="Template Name"
                    isEditing={isCreating || isEditingTemplateName}
                    setIsEditing={setIsEditingTemplateName}
                    name="templateName"
                    updatable={updatable}
                    onUpdate={checklistId ? updateChecklist : createChecklist}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                  />
                )
              }
              <EditableText
                key={`checklist--${checklistId}`}
                focus={!isEditingTemplateName}
                className="p-0 mb-2"
                label={showLabel ? 'Checklist' : null}
                labelClassName="font-bold"
                textViewClassName="text-base mb-2"
                name={field}
                updateFieldName="title"
                text={getValues(field)}
                updatable={updatable}
                onUpdate={checklistId ? updateChecklist : createChecklist}
                isEditing={isEditingTitle}
                setIsEditing={setIsEditingTitle}
                control={control}
                setValue={setValue}
                getValues={getValues}
                placeholder="Title"
                defaultValue={isCreating ? 'Checklist' : null}
              />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`checklist-${checklistIdx}`}>
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <ChecklistItemFields
                        checklistId={checklistId}
                        refetch={refetch}
                        control={control}
                        toastRef={toastRef}
                        setValue={setValue}
                        getValues={getValues}
                        onToggleComplete={onChecklistItemToggleComplete}
                        onUpdate={onUpdateChecklistItem}
                        removeFieldAndResetValue={removeFieldAndResetValue}
                        useFieldArray={useFieldArray}
                        checklistIdx={checklistIdx}
                        updatable={updatable}
                        disableAddItem={!checklistId}
                        setIsEditingChecklist={setIsEditingChecklist}
                        droppablePlaceholder={provided.placeholder}
                      />
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
      );
    })
  );
}

export default ChecklistFields;
