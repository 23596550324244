import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { Tag } from 'primereact/tag';

import { titleize } from 'utils/stringUtils';
import { cursorClassName } from 'utils/styleUtils';
import { CATEGORY_BACKGROUND_COLOR_MAPPING } from 'constants/colors';
import EventVendorProfile from '.';
import UpdateEventVendorBasicInfo from './UpdateEventVendorBasicInfo';
import EventInviteForm from '../EventInviteForm';

function EventVendorProfileModal({
  show,
  onHide,
  toastRef,
  event,
  eventVendor,
  currentVendor,
  updatable,
  confirmSendInvite,
  refetchEventVendors,
  refetchActiveEventVendor,
  isManagingCurrentVendor,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const { vendor } = eventVendor;
  const categoriesMapping = () => (
    eventVendor.categories.map((category) => (
      <Tag
        key={`${vendor.id}-${category}`}
        value={titleize(category)}
        className={`${CATEGORY_BACKGROUND_COLOR_MAPPING[category] || CATEGORY_BACKGROUND_COLOR_MAPPING.default} mr-1`}
      />
    ))
  );

  const modalHeader = () => (
    <div
      className={cursorClassName(eventVendor.isUpdatableByCurrentActor)}
      onClick={() => (eventVendor.isUpdatableByCurrentActor ? setIsEditing(true) : {})}
    >
      {
        isEditing ? (
          <UpdateEventVendorBasicInfo
            eventVendor={eventVendor}
            refetchEventVendors={refetchEventVendors}
            vendor={vendor}
            toastRef={toastRef}
            setIsEditing={setIsEditing}
          />
        ) : (
          <div className="text-gray-800 flex">
            <div className="flex items-start">
              <Avatar
                key={`${vendor.id}-avatar`}
                label={vendor.name[0]}
                size="xlarge"
                shape="circle"
                className="mr-2 bg-gray text-white"
                image={vendor.avatarUrl}
              />
              <div>
                <p key={`${vendor.id}-vendor-name`} className="font-semibold m-0 text-gray-800"><span className="mr-2">{ vendor.name }</span></p>
                <div key={`${vendor.id}-vendor-categories`} className="font-medium -mt-1">{ categoriesMapping() }</div>
              </div>
            </div>
          </div>
        )
      }
      <div className={!eventVendor.isAdmin && eventVendor.status !== 'APPROVED' ? 'mt-2' : ''}>
        <EventInviteForm
          toastRef={toastRef}
          event={event}
          eventVendorOrActor={eventVendor}
          refetchEventVendors={refetchEventVendors}
          confirmSendInvite={confirmSendInvite}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      key={`${eventVendor.id}-event-vendor-profile-modal`}
      header={modalHeader()}
      headerClassName="pb-4"
      className="xl:w-7/12"
      visible={show}
      onHide={onHide}
      resizable={false}
      draggable={false}
      dismissableMask
      maximizable
    >
      <EventVendorProfile
        toastRef={toastRef}
        updatable={updatable}
        refetchActiveEventVendor={refetchActiveEventVendor}
        eventVendor={eventVendor}
        currentVendor={currentVendor}
        isManagingCurrentVendor={isManagingCurrentVendor}
      />
    </Dialog>
  );
}

export default EventVendorProfileModal;
