import gql from 'graphql-tag';

const NOTIFICATIONS_SETTINGS_QUERY = gql`
  query NotificationsSettings {
    currentActor {
      id
      notificationSettings {
        id
        notificationType
        inApp
        email
        resource
      }
    }
  }
`;

const NOTIFICATION_SETTING_UPDATE_MUTATION = gql`
  mutation UpdateNotificationSetting($input: NotificationSettingUpdateMutationInput!) {
    notificationSettingUpdate(input: $input) {
      notificationSetting {
        id
        notificationType
        inApp
        email
      }
    }
  }
`;

export {
  NOTIFICATIONS_SETTINGS_QUERY,
  NOTIFICATION_SETTING_UPDATE_MUTATION,
};
