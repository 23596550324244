import React from 'react';
import DateInput from 'components/Form/DateInput';

import { cursorClassName, mergeClassNames } from 'utils/styleUtils';

function EditableDate({
  name,
  label = null,
  className = '',
  inputClassName = '',
  value,
  updatable,
  isEditing,
  setIsEditing,
  control,
  onUpdate,
  showClose = true,
}) {
  if (isEditing) {
    return (
      <div className={className}>
        {
          label && (
            <div className="flex items-center justify-end">
              <i className="pi pi-clock text-xs mr-1" /><p className="text-primary">{ label }</p>
            </div>
          )
        }
        <div className="flex items-center justify-end w-full cursor-pointer">
          <DateInput
            control={control}
            name={name}
            onMouseDown={(e) => e.preventDefault()}
            minDate={new Date()}
            className={mergeClassNames(`m-0 p-0 text-sm w-unset ${inputClassName}`)}
            inputProps={{
              onChange: (data) => onUpdate({ [name]: data.value }),
            }}
          />
          { showClose && <i className="pi pi-times text-xs ml-1 cursor-pointer" onClick={() => setIsEditing(false)} /> }
        </div>
      </div>
    );
  }
  return (
    <p className={`${className} ${cursorClassName(updatable)}`} onClick={updatable ? () => setIsEditing(true) : () => {}}>
      { value }
    </p>
  );
}

export default EditableDate;
