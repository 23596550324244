import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import map from 'lodash/map';
import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';

import MultiSelect from 'components/Form/MultiSelect';
import Attachments from 'components/RichTextView/Attachments';
import RichTextAreaInput from 'components/Form/RichTextAreaInput';
import { actorItemTemplate } from 'utils/formUtils';
import { timeOptions } from 'constants/selectOptions';
import Dropdown from 'components/Form/Dropdown';
import TextAreaInput from 'components/Form/TextAreaInput';

function CreateTimeBlockForm({
  timeBlock,
  focus = true,
  minimumStartTime,
  maximumEndTime,
  createTimeBlock,
  deleteTimeBlock,
  control,
  showSubmit = true,
  handleSubmit,
  actorOptions,
  vendorOptions,
  venueOptions,
  watch,
  setValue,
  getValues,
  onHideModal = () => {},
  onCancel = () => {},
  showCancel = true,
  showDelete = true,
}) {
  const defaultTimeOptions = timeOptions({
    startTime: DateTime.fromISO(minimumStartTime),
    endTime: DateTime.fromISO(maximumEndTime),
  });
  const [attachments, setAttachments] = useState(timeBlock?.attachments || []);
  const [startTimeSelectOptions, setStartTimeSelectOptions] = useState(defaultTimeOptions);
  const [endTimeSelectOptions, setEndTimeSelectOptions] = useState(defaultTimeOptions);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const onSubmit = async ({
    startTime,
    endTime,
    participantActorIds,
    venueIds,
    vendorIds,
    description,
    details,
  }) => {
    await createTimeBlock({
      startStr: startTime,
      endStr: endTime,
      description,
      details,
      participantActorIds,
      vendorIds: vendorIds.concat(venueIds),
      attachmentIds: map(attachments, 'id'),
    });
    onHideModal();
  };

  useEffect(() => {
    setValue('attachmentIds', map(attachments, 'id'));
    setValue('attachments', attachments);
  }, [attachments]);

  useEffect(() => {
    setAttachments(timeBlock?.attachments || []);
  }, [timeBlock?.attachments]);

  useEffect(() => {
    const endDateTime = DateTime.fromISO(maximumEndTime);
    const startDateTime = DateTime.fromISO(watch('startTime') || minimumStartTime);

    setStartTimeSelectOptions(timeOptions({ startTime: startDateTime, endTime: endDateTime }));
    setEndTimeSelectOptions(timeOptions({ startTime: startDateTime.plus({ minutes: 5 }), endTime: endDateTime }));

    if (!getValues('endTime')) { setValue('endTime', null); }
  }, [minimumStartTime, maximumEndTime, watch('startTime'), watch('endTime')]);

  const onDeleteAttachment = (attachmentId) => { setAttachments(attachments.filter((attachment) => attachment.id !== attachmentId)); };

  const deleteButton = () => {
    if (!timeBlock) { return null; }

    const confirmDelete = () => confirmDialog({
      className: 'xl:w-3/12',
      message: 'Are you sure you want to delete this time block?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteTimeBlock(),
      reject: () => {},
    });

    return (
      <Button
        label="Delete"
        disabled={submitDisabled}
        type="button"
        icon="pi pi-trash"
        onClick={confirmDelete}
        size="small"
        severity="danger"
        className="mr-2"
      />
    );
  };

  const clearEndTime = (value) => (value ? {} : setValue('endTime', null));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="time-block-form grid grid-cols-1">
      <TextAreaInput
        focus={focus}
        control={control}
        label="Description"
        labelClassName="font-semibold"
        name="description"
      />
      <div className="flex items-center grid grid-cols-2 gap-2 mt-2">
        <Dropdown
          control={control}
          required={false}
          name="startTime"
          label="Start Time"
          labelClassName="font-semibold"
          options={startTimeSelectOptions}
          inputProps={{ showClear: true, placeholder: 'Select Start Time' }}
        />
        <Dropdown
          control={control}
          name="endTime"
          required={false}
          label="End Time"
          labelClassName="font-semibold"
          options={endTimeSelectOptions}
          onChange={clearEndTime}
          inputProps={{ showClear: true, placeholder: 'Select End Time' }}
        />
      </div>
      {
        venueOptions.length > 1 ? (
          <MultiSelect
            required={false}
            control={control}
            name="venueIds"
            label="Venues"
            labelClassName="font-semibold"
            options={venueOptions}
            placeholder="Select..."
            inputProps={{
              itemTemplate: (option) => actorItemTemplate({ option }),
            }}
          />
        ) : null
      }
      <MultiSelect
        className="mb-0"
        required={false}
        control={control}
        name="vendorIds"
        label="Vendors"
        labelClassName="font-semibold"
        options={vendorOptions}
        placeholder="Select..."
        inputProps={{
          itemTemplate: (option) => actorItemTemplate({ option }),
        }}
      />
      {
        actorOptions?.length ? (
          <div className="w-full mt-2">
            <MultiSelect
              className="mb-0"
              required={false}
              control={control}
              name="participantActorIds"
              label="Participants"
              labelClassName="font-semibold"
              options={actorOptions}
              placeholder="Select..."
              inputProps={{
                itemTemplate: (option) => actorItemTemplate({ option }),
              }}
            />
          </div>
        ) : null
      }
      <div className="w-full mt-4">
        <RichTextAreaInput
          focus={false}
          control={control}
          required={false}
          name="details"
          placeholder="Add details..."
          label="Details"
          labelClassName="font-semibold"
          className="w-full p-0"
          editorClassName="w-full border rounded-md"
          onAttachmentsAdded={(newAttachments) => setAttachments(attachments.concat(newAttachments))}
          onImageUploadLoading={() => setSubmitDisabled(true)}
          onImageUploadComplete={() => setSubmitDisabled(false)}
        />
        <div className="w-full py-0 mb-3">
          <Attachments attachments={attachments} deleteable onDelete={onDeleteAttachment} />
        </div>
      </div>
      {
        showSubmit ? (
          <div className="flex submittable-area justify-end w-full mt-2 sm:mt-0">
            { showDelete && deleteButton() }
            <Button
              label={timeBlock ? 'Update' : 'Create'}
              disabled={submitDisabled}
              type="submit"
              className="mr-2"
              size="small"
            />
            <Button
              label="Cancel"
              disabled={submitDisabled}
              type="button"
              onClick={onCancel}
              size="small"
              className={timeBlock || !showCancel ? 'hidden' : ''}
            />
          </div>
        ) : (
          <div className={`flex submittable-area justify-end w-full mt-2 sm:mt-0 ${timeBlock ? 'hidden' : ''}`}>
            <Button
              label="Cancel"
              disabled={submitDisabled}
              type="button"
              onClick={onCancel}
              size="small"
              className={timeBlock || !showCancel ? 'hidden' : ''}
            />
          </div>
        )
      }
    </form>
  );
}

export default CreateTimeBlockForm;
