import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import { useFieldArray } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { showErrorToast } from 'utils/toastUtils';
import map from 'lodash/map';
import compact from 'lodash/compact';

import MultiSelect from 'components/Form/MultiSelect';
import Attachments from 'components/RichTextView/Attachments';
import RichTextAreaInput from 'components/Form/RichTextAreaInput';
import { actorItemTemplate } from 'utils/formUtils';
import { timeOptions } from 'constants/selectOptions';
import Dropdown from 'components/Form/Dropdown';
import TextInput from 'components/Form/TextInput';

function CreateSubTimeBlockGroupForm({
  createTimeBlock,
  parentTimeBlock,
  toastRef,
  actorOptions,
  vendorOptions,
  venueOptions,
  teamMemberOptionsForVendorId,
  control,
  watch,
  setValue,
  handleSubmit,
}) {
  const [attachments, setAttachments] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [teamMemberOptions, setTeamMemberOptions] = useState([]);
  const timeSelectOptions = timeOptions({
    startTime: DateTime.fromISO(parentTimeBlock.startTime),
    endTime: DateTime.fromISO(parentTimeBlock.endTime),
  });
  const onDeleteAttachment = (attachmentId) => { setAttachments(attachments.filter((attachment) => attachment.id !== attachmentId)); };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const {
    fields: subTimeBlockFields,
    append: appendSubTimeBlock,
    remove: removeSubTimeBlock,
  } = useFieldArray({ control, name: 'subTimeBlocks' });

  useEffect(() => {
    setTeamMemberOptions(teamMemberOptionsForVendorId({ vendorId: watch('vendorId') }));
    setValue('participantActorIds', []);
  }, [watch('vendorId')]);

  useEffect(() => {
    setSubmitDisabled(!!watch('subTimeBlocks').find((subTimeBlock) => !subTimeBlock.startDateTime || !subTimeBlock.description));
  }, [watch()]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const subTimeBlockField = ({ idx }) => {
    const fieldName = `subTimeBlocks.${idx}`;
    const smallScreenSize = screenWidth < 990;

    return (
      <div>
        <div className={`flex grid grid-cols-12 gap-2 ${idx && !smallScreenSize ? 'items-start' : 'items-center'}`}>
          <div className="col-span-10">
            <div className="grid grid-cols-12 gap-2 items-start">
              <Dropdown
                className="xs:col-span-6 lg:col-span-2"
                control={control}
                required
                name={`${fieldName}.startDateTime`}
                label={smallScreenSize || !idx ? 'Start Time' : null}
                labelClassName="font-semibold"
                options={timeSelectOptions}
                inputProps={{ placeholder: 'Select Start Time' }}
              />
              <Dropdown
                className="xs:col-span-6 lg:col-span-2"
                control={control}
                name={`${fieldName}.endDateTime`}
                required={false}
                label={smallScreenSize || !idx ? 'End Time' : null}
                labelClassName="font-semibold"
                options={timeSelectOptions}
                inputProps={{ placeholder: 'Select End Time' }}
              />
              <TextInput
                className="xs:col-span-12 lg:col-span-5"
                name={`${fieldName}.description`}
                control={control}
                label={smallScreenSize || !idx ? 'Description' : null}
                labelClassName="font-semibold"
              />
              {
                actorOptions?.length ? (
                  <MultiSelect
                    className="xs:col-span-12 lg:col-span-3"
                    name={`${fieldName}.participantActorIds`}
                    required={false}
                    control={control}
                    label={smallScreenSize || !idx ? 'Participants' : null}
                    labelClassName="font-semibold"
                    options={actorOptions}
                    placeholder="Select..."
                    inputProps={{
                      itemTemplate: (option) => actorItemTemplate({ option }),
                    }}
                  />
                ) : null
              }
            </div>
          </div>
          <div className={`col-span-2 ${idx ? 'mt-3' : 'mt-6'}`}>
            <i className="pi pi-times text-xs ml-1 cursor-pointer text-gray-500" onClick={() => removeSubTimeBlock(idx)} />
          </div>
        </div>
        { smallScreenSize ? <Divider className="my-8" /> : null }
      </div>
    );
  };

  const onSubmit = ({
    participantActorIds,
    vendorId,
    venueId,
    description,
    attachments: submittedAttachments,
    details,
    subTimeBlocks,
  }) => {
    if (!subTimeBlocks.length) {
      return showErrorToast(toastRef, 'At least one sub block time required');
    }

    return createTimeBlock({
      description,
      details,
      vendorIds: compact([vendorId, venueId]),
      participantActorIds,
      subTimeBlocks,
      attachmentIds: map(submittedAttachments, 'id'),
    });
  };

  const clearVenue = (value) => setValue('venueId', value || null);
  const clearVendor = (value) => setValue('vendorId', value || null);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="time-block-form grid grid-cols-1">
      {
        venueOptions.length > 1 ? (
          <Dropdown
            required={false}
            control={control}
            name="venueId"
            label="Venue"
            labelClassName="font-semibold"
            inputClassName="w-1/2"
            onChange={clearVenue}
            options={venueOptions}
            inputProps={{ showClear: true, placeholder: 'Select a Venue' }}
            placeholder="Select Venue..."
          />
        ) : null
      }
      {
        vendorOptions.length ? (
          <div className="flex grid-cols-2 gap-2 mt-2">
            <Dropdown
              required={false}
              control={control}
              name="vendorId"
              label="Vendor"
              labelClassName="font-semibold"
              inputClassName="py-[1.5px]"
              options={vendorOptions}
              onChange={clearVendor}
              placeholder="Select Vendor..."
              inputProps={{ showClear: true, placeholder: 'Select a Vendor' }}
            />
            <MultiSelect
              required={false}
              control={control}
              name="participantActorIds"
              label="Team Members"
              labelClassName="font-semibold"
              inputClassName={watch('participantActorIds').length ? '' : 'py-[1.5px]'}
              options={teamMemberOptions}
              placeholder="Select..."
              inputProps={{
                itemTemplate: (option) => actorItemTemplate({ option }),
              }}
            />
          </div>
        ) : null
      }
      <div className="w-full mt-4">
        <TextInput
          control={control}
          label="Description"
          labelClassName="font-semibold"
          name="description"
        />
        <RichTextAreaInput
          focus={false}
          control={control}
          required={false}
          name="details"
          placeholder="Add details..."
          label="Details"
          labelClassName="font-semibold"
          className="w-full p-0 mt-6"
          editorClassName="w-full border rounded-md"
          onAttachmentsAdded={(newAttachments) => setAttachments(attachments.concat(newAttachments))}
          onImageUploadLoading={() => setSubmitDisabled(true)}
          onImageUploadComplete={() => setSubmitDisabled(false)}
        />
        <div className="w-full py-0 mb-3">
          <Attachments attachments={attachments} deleteable onDelete={onDeleteAttachment} />
        </div>
      </div>
      <div className="mt-6">
        { subTimeBlockFields.map((object, idx) => subTimeBlockField({ idx })) }
      </div>
      <div>
        <Button
          icon="pi pi-plus"
          label="Add Time"
          className="text-primary p-0 mt-4"
          type="button"
          text
          size="small"
          onClick={() => appendSubTimeBlock({})}
        />
      </div>
      <div className="flex w-full justify-end">
        <Button
          label="Create"
          disabled={submitDisabled || !subTimeBlockFields.length}
          type="submit"
          className="mr-2"
          size="small"
        />
      </div>
    </form>
  );
}

export default CreateSubTimeBlockGroupForm;
