import React from 'react';
import { Dialog } from 'primereact/dialog';
import EventInvite from 'components/EventInvite';

function EventInviteModal({
  toastRef, show, onHide, refetch, status, eventVendorOrActor, event,
}) {
  return (
    <Dialog
      className="xl:w-5/12"
      header="Send Event Invite"
      visible={show}
      onHide={onHide}
      dismissableMask
      resizable={false}
      draggable={false}
    >
      <EventInvite
        className="border-0"
        status={status}
        toastRef={toastRef}
        onSuccess={onHide}
        event={event}
        createdAt={eventVendorOrActor.inviteLastActionedAt}
        inviterActor={eventVendorOrActor.invitedByActor}
        requesterActor={eventVendorOrActor.requestedByActor}
        actionerActor={eventVendorOrActor.inviteLastActionedByActor}
        actionedActorOrVendor={eventVendorOrActor.vendor || eventVendorOrActor.hostActor}
        eventVendorId={eventVendorOrActor.id}
        currentActorIsOnEvent
        currentActorIsActioner={eventVendorOrActor.currentActorIsLastInviteActioner}
        currentVendorIsActioner={eventVendorOrActor.currentVendorIsLastInviteActioner}
        approvalSubjectType={eventVendorOrActor.inviteApprovalSubjectType}
        currentVendorIsActioned={false}
        refetch={refetch}
      />
    </Dialog>

  );
}

export default EventInviteModal;
