import gql from 'graphql-tag';

const SUBTASK_STATUS_UPDATE_MUTATION = gql`
  mutation UpdateSubtaskStatus($input: SubtaskUpdateMutationInput!) {
    subtaskUpdate(input: $input) {
      subtask {
        id
        completed
      }
    }
  }
`;

const SUBTASK_DELETE_MUTATION = gql`
  mutation DeleteSubtask($input: SubtaskDeleteMutationInput!) {
    subtaskDelete(input: $input) {
      id
    }
  }
`;

export {
  SUBTASK_STATUS_UPDATE_MUTATION,
  SUBTASK_DELETE_MUTATION,
};
