import gql from 'graphql-tag';

const UPLOAD_FILES_MUTATION = gql`
  mutation UploadSubtaskFileRequestFiles($input: SubtaskFileRequestFilesUploadMutationInput!) {
    subtaskFileRequestFilesUpload(input: $input) {
      uploadResults {
        success
        file {
          id
          name
          isUpdatableByCurrentActor
        }
      }
    }
  }
`;

const DELETE_FILE_MUTATION = gql`
  mutation DeleteSubtaskFileRequestFile($input: SubtaskFileRequestFileDeleteMutationInput!) {
    subtaskFileRequestFileDelete(input: $input) {
      id
    }
  }
`;

const CREATE_FILE_REQUEST_MUTATION = gql`
  mutation CreateSubtaskFileRequest($input: SubtaskFileRequestCreateMutationInput!) {
    subtaskFileRequestCreate(input: $input) {
      fileRequest {
        id
        fileRequested
      }
    }
  }
`;

const UPDATE_FILE_REQUEST_MUTATION = gql`
  mutation UpdateSubtaskFileRequest($input: SubtaskFileRequestUpdateMutationInput!) {
    subtaskFileRequestUpdate(input: $input) {
      fileRequest {
        id
        fileRequested
      }
    }
  }
`;

export {
  UPLOAD_FILES_MUTATION,
  DELETE_FILE_MUTATION,
  CREATE_FILE_REQUEST_MUTATION,
  UPDATE_FILE_REQUEST_MUTATION,
};
