import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import take from 'lodash/take';

import Table from 'components/Table';
import AvatarWithName from 'components/AvatarWithName';
import { emailLink, phoneLink } from 'utils/stringUtils';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import UpdateTeamMemberGroupModal from '../UpdateTeamMemberGroupModal';
import CreateTeamMemberGroupModal from '../CreateTeamMemberGroupModal';
import { DEACTIVATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION } from './graphql';

function TeamMemberGroupsIndexTable({
  toastRef, teamMemberActors, teamMemberGroupActors, refetchTeamMembersAndGroups,
}) {
  const [showAddNewTeamMemberGroupModal, setShowAddNewTeamMemberGroupModal] = useState(false);
  const [showUpdateTeamMemberGroupModal, setShowUpdateTeamMemberGroupModal] = useState(false);
  const [selectedTeamMemberGroupActor, setSelectedTeamMemberGroupActor] = useState(false);

  const updateTeamMemberGroup = (teamMemberGroupActor) => {
    setShowUpdateTeamMemberGroupModal(true);
    setSelectedTeamMemberGroupActor(teamMemberGroupActor);
  };

  const [deactivateTeamMemberGroupMutation] = useMutation(DEACTIVATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION, {
    onCompleted: async () => {
      await refetchTeamMembersAndGroups();
      showSuccessToast(toastRef, 'Successfully deleted team member group');
    },
    onError: ({ graphQLErrors }) => (
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, message)
      ))
    ),
  });

  const editButton = (teamMemberGroupActor) => (
    <Button
      key={`${teamMemberGroupActor.id}-edit`}
      type="button"
      onClick={() => updateTeamMemberGroup(teamMemberGroupActor)}
      rounded
      text
      icon="pi pi-user-edit"
    />
  );

  const deleteButton = (teamMemberGroupActor) => {
    const confirmDelete = () => {
      const message = `Are you sure you want to delete ${teamMemberGroupActor.name}?`;

      return confirmDialog({
        className: 'xl:w-3/12',
        message,
        header: 'Delete Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => deactivateTeamMemberGroupMutation({ variables: { input: { id: teamMemberGroupActor.id } } }),
      });
    };

    return (
      <Button
        key={`${teamMemberGroupActor.id}-delete`}
        onClick={confirmDelete}
        icon="pi pi-user-minus"
        rounded
        text
        aria-label="Submit"
      />
    );
  };

  const mapTeamMemberGroupMembers = (teamMemberGroupActor) => (
    <>
      {
          take(teamMemberGroupActor.memberActors, 4).map((user, idx) => {
            if (idx < 3) {
              return (
                <AvatarWithName
                  key={`${user.id}-group-member`}
                  className="mb-1"
                  id={user.id}
                  initials={user.initials}
                  avatarUrl={user.avatarUrl}
                  text={user.name}
                />
              );
            }
            const otherMembersCount = teamMemberGroupActor.memberActors.length - 3;
            const othersStr = otherMembersCount === 1 ? 'other' : 'others';

            return <p key="other-group-member">{`& ${otherMembersCount} ${othersStr}`}</p>;
          })
        }
    </>
  );

  const actions = (teamMemberGroupActor) => (
    <div>
      { editButton(teamMemberGroupActor) }
      { deleteButton(teamMemberGroupActor) }
    </div>
  );

  return (
    <>
      <div className="flex justify-between w-full">
        <p className="font-bold text-xl">Team Member Groups</p>
        <Button
          rounded
          className="h-8 w-8 mr-4"
          icon="pi pi-plus"
          onClick={() => setShowAddNewTeamMemberGroupModal(true)}
        />
      </div>
      <div className="content-with-nav-bar">
        <Table
          data={teamMemberGroupActors}
          clickable={false}
          className="team-member-group-index-table w-full"
        >
          <Column sortable field="name" header="Name" body={({ name }) => name} />
          <Column
            sortable
            field="email"
            header="Email"
            className="xxs:hidden md:table-cell"
            headerClassName="xxs:hidden md:table-cell"
            body={({ email }) => emailLink({ email })}
          />
          <Column
            field="phoneNumber"
            header="Phone Number"
            className="xxs:hidden md:table-cell"
            headerClassName="xxs:hidden md:table-cell"
            body={({ phoneNumber }) => phoneLink({ phone: phoneNumber })}
          />
          <Column
            field="members"
            className="xxs:hidden md:table-cell"
            headerClassName="xxs:hidden md:table-cell"
            header="Members"
            body={mapTeamMemberGroupMembers}
          />
          <Column
            field="actions"
            header="Actions"
            body={actions}
          />
        </Table>
      </div>
      <CreateTeamMemberGroupModal
        show={showAddNewTeamMemberGroupModal}
        onHide={() => setShowAddNewTeamMemberGroupModal(false)}
        teamMemberActors={teamMemberActors}
        refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
        toastRef={toastRef}
      />
      <UpdateTeamMemberGroupModal
        show={showUpdateTeamMemberGroupModal}
        onHide={() => setShowUpdateTeamMemberGroupModal(false)}
        refetchTeamMembersAndGroups={refetchTeamMembersAndGroups}
        teamMemberGroupActor={selectedTeamMemberGroupActor}
        teamMemberActors={teamMemberActors}
        toastRef={toastRef}
      />
    </>
  );
}

export default TeamMemberGroupsIndexTable;
