import gql from 'graphql-tag';

const UPLOAD_FILES_MUTATION = gql`
  mutation UploadFiles($input: FilesUploadMutationInput!) {
    filesUpload(input: $input) {
      uploadResults {
        success
        file {
          id
          name
          uuid
        }
      }
    }
  }
`;

export {
  UPLOAD_FILES_MUTATION,
};
