import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import EmailInput from 'components/Form/EmailInput';
import { sendResetPasswordLink } from 'auth/api';

function SendResetLink() {
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [submissionErrors, setSubmissionErrors] = useState([]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async ({ email }) => {
    const { errors } = await sendResetPasswordLink({ email });

    if (errors) {
      setSubmissionErrors(errors);
    } else {
      setSubmissionErrors([]);
      setResetPasswordSent(true);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="lg:w-3/12">
        <div className="text-center">
          {
            submissionErrors.map((error) => <p key={error} className="text-danger mt-2">{ error }</p>)
          }
        </div>
        <h3 className="text-center text-lg mb-6">Reset Password</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <EmailInput
            control={control}
            name="email"
            label="Email"
          />
          {
            resetPasswordSent ? (
              <p className="text-center reset-password-confirmation text-primary">
                Please check your email for instructions to reset your password.
              </p>
            ) : null
          }
          <Button type="submit" label="Submit" className="mt-4 w-full" />
        </form>
      </div>
    </div>
  );
}

export default SendResetLink;
