import gql from 'graphql-tag';

const UPDATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION = gql`
  mutation UpdateTeamMemberGroupActor($input: TeamMemberGroupActorUpdateMutationInput!) {
    teamMemberGroupActorUpdate(input: $input) {
      teamMemberGroupActor {
        id
        name
        email
        phoneNumber
        memberActors {
          id
          name
        }
      }
    }
  }
`;

export {
  UPDATE_TEAM_MEMBER_GROUP_ACTOR_MUTATION,
};
