import gql from 'graphql-tag';
import UPLOADED_FILE_FIELDS from 'graphql/fragments/uploadedFile';

const MENTION_MARK_AS_READ_MUTATION = gql`
  mutation MarkMentionAsRead($input: MentionMarkAsReadMutationInput!) {
    mentionMarkAsRead(input: $input) {
      mention {
        id
      }
    }
  }
`;

const SUBTASK_QUESTION_UPDATE_MUTATION = gql`
  ${UPLOADED_FILE_FIELDS}

  mutation UpdateSubtaskQuestion($input: SubtaskQuestionUpdateMutationInput!) {
    subtaskQuestionUpdate(input: $input) {
      question {
        id
        body
        attachments {
          ...UploadedFileFields
        }
      }
    }
  }
`;

const SUBTASK_QUESTION_CREATE_MUTATION = gql`
  ${UPLOADED_FILE_FIELDS}

  mutation CreateSubtaskQuestion($input: SubtaskQuestionCreateMutationInput!) {
    subtaskQuestionCreate(input: $input) {
      question {
        id
        body
        attachments {
          ...UploadedFileFields
        }
      }
    }
  }
`;

export {
  MENTION_MARK_AS_READ_MUTATION,
  SUBTASK_QUESTION_UPDATE_MUTATION,
  SUBTASK_QUESTION_CREATE_MUTATION,
};
