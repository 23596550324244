import React, { useState } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import flatMap from 'lodash/flatMap';

import Comments from 'containers/Tasks/Index/CompleteTaskForm/Comments';
import ActivityLog from './ActivityLog';

function Footer({
  mentionables, actorOptions, task, currentActor, refetch, labels,
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const items = [
    { label: 'Comments', icon: 'fa-regular fa-comments' },
  ];

  if (task.activityLog.length) { items.push({ label: 'Activity Log', icon: 'fa-regular fa-rectangle-list' }); }

  /* eslint-disable consistent-return */
  const tabContent = () => {
    switch (activeTabIndex) {
      case 0: {
        return (
          <div className="grid grid-cols-12">
            <div className="col-span-12 mt-4 px-0">
              <Comments
                showAccordion={false}
                mentionables={mentionables}
                commentableId={task.id}
                comments={task.comments}
                commentType="comment"
                currentActor={currentActor}
                refetch={refetch}
              />
            </div>
          </div>
        );
      }
      case 1: {
        return (
          <ActivityLog
            activityLog={task.activityLog}
            labels={labels}
            actors={flatMap(actorOptions, 'items')}
          />
        );
      }
      default:
    }
  };
  /* eslint-enable consistent-return */

  return (
    <div className="card xxs:col-span-12 lg:col-span-10 task-card-footer">
      <TabMenu model={items} onTabChange={({ index }) => setActiveTabIndex(index)} activeIndex={activeTabIndex} />
      { tabContent() }
    </div>
  );
}

export default Footer;
