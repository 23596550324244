import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import EmailInput from 'components/Form/EmailInput';
import PasswordInput from 'components/Form/PasswordInput';
import PasswordConfirmationInput from 'components/Form/PasswordConfirmationInput';
import { LOGIN_DETAILS_QUERY, USER_UPDATE_MUTATION } from './graphql';

function UpdateLoginSettingsForm({
  currentPassword, onSubmit, toastRef,
}) {
  const [userId, setUserId] = useState(null);

  const {
    control, handleSubmit, setValue, getValues,
  } = useForm();

  const updateUserValues = (data) => setValue('email', data.email);

  useQuery(
    LOGIN_DETAILS_QUERY,
    {
      onCompleted: (data) => {
        setValue('email', data.currentUser.email);
        setUserId(data.currentUser.id);
      },
    },
  );

  const [updateUserMutation] = useMutation(USER_UPDATE_MUTATION, {
    onCompleted: ({ userUpdate }) => {
      setValue('currentPassword', null);
      setValue('newPassword', null);
      updateUserValues(userUpdate.user);
      onSubmit();
      showSuccessToast(toastRef, 'Successfully updated user details!');
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating user: ${message}`)
      ));
    },
  });

  const updateUser = (values) => {
    updateUserMutation({
      variables: {
        input: {
          id: userId,
          email: values.email,
          currentPassword,
          newPassword: values.newPassword,
        },
      },
    });
  };

  return (
    <div className="p-4">
      <p className="font-semibold my-4">Login Settings</p>
      <form onSubmit={handleSubmit(updateUser)}>
        <EmailInput
          control={control}
          name="email"
          label="Email"
        />
        <div className="xxs:hidden md:block" />
        <PasswordInput
          control={control}
          feedback
          name="newPassword"
          label="New Password"
        />
        <PasswordConfirmationInput
          passwordFieldName="newPassword"
          control={control}
          getValues={getValues}
        />
        <div className="flex w-full justify-center mt-4 grid grid-cols-12">
          <Button key="update-user-submit" type="submit" label="Submit" className="mt-2 xxs:col-span-12 md:col-span-2 md:col-start-6" />
        </div>
      </form>
    </div>
  );
}

export default UpdateLoginSettingsForm;
