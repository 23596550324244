import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import { showErrorToast } from 'utils/toastUtils';

import EditableTextArea from 'components/Form/EditableFields/TextArea';
import { mapInfo } from 'utils/displayUtils';
import { emailLink, phoneLink, instagramLink } from 'utils/stringUtils';
import UpdateEventVendorAddress from './UpdateEventVendorAddress';
import UpdateEventVendorEmail from './UpdateEventVendorEmail';
import UpdateEventVendorWebsiteUrl from './UpdateEventVendorWebsiteUrl';
import UpdateEventVendorPhone from './UpdateEventVendorPhone';
import UpdateEventVendorInstagram from './UpdateEventVendorInstagram';
import UpdateEventVendorContacts from './UpdateEventVendorContacts';
import UpdateEventVendorReviewSources from './UpdateEventVendorReviewSources';
import CreateTeamMemberModal from './CreateTeamMemberForm/Modal';
import UpdateTeamMember from './UpdateTeamMemberForm';
import { EVENT_VENDOR_UPDATE_MUTATION } from './graphql';

const mappedRoleToDisplay = {
  defaultTaskAssigneeActor: 'Tasks',
  primaryPointOfContactActor: 'Primary',
  secondaryPointOfContactActor: 'Secondary',
  dayOfPointOfContactActor: 'Day of',
};

function EventVendorProfile({
  toastRef, eventVendor, refetchActiveEventVendor, isManagingCurrentVendor, currentVendor,
}) {
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isEditingInternalNotes, setIsEditingInternalNotes] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingWebsiteUrl, setIsEditingWebsiteUrl] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);
  const [isEditingContacts, setIsEditingContacts] = useState(false);
  const [isEditingContactActorId, setIsEditingContactActorId] = useState(null);
  const [isEditingReviewSources, setIsEditingReviewSources] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [showCreateTeamMemberModal, setShowCreateTeamMemberModal] = useState(false);
  const {
    id,
    vendor,
    primaryPointOfContactActor,
    secondaryPointOfContactActor,
    dayOfPointOfContactActor,
    isUpdatableByCurrentActor,
    notes,
    internalNotes,
  } = eventVendor;

  useEffect(() => {
    if (
      !isUpdatableByCurrentActor
      || isEditingNotes
      || isEditingInternalNotes
      || isEditingAddress
      || isEditingEmail
      || isEditingWebsiteUrl
      || isEditingPhone
      || isEditingInstagram
      || isEditingContacts
      || isEditingReviewSources
      || isEditingContactActorId
    ) {
      setIsEditable(false);
    } else {
      setIsEditable(isUpdatableByCurrentActor);
    }
  }, [
    isEditingNotes,
    isEditingInternalNotes,
    isEditingAddress,
    isEditingEmail,
    isEditingWebsiteUrl,
    isEditingPhone,
    isEditingInstagram,
    isEditingContacts,
    isEditingReviewSources,
    isEditingContactActorId,
  ]);

  const { control, getValues, setValue } = useForm({
    defaultValues: {
      notes,
      internalNotes,
    },
  });

  const [updateEventVendorMutation] = useMutation(EVENT_VENDOR_UPDATE_MUTATION, {
    onCompleted: ({ eventVendorUpdate }) => {
      setValue('notes', eventVendorUpdate.eventVendor.notes);
      setValue('internalNotes', eventVendorUpdate.eventVendor.internalNotes);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating vendor: ${message}`)
      ));
    },
  });

  /* eslint-disable no-param-reassign */
  const mappedContactActors = reduce({
    primaryPointOfContactActor,
    secondaryPointOfContactActor,
    dayOfPointOfContactActor,
  }, (mappedContacts, actor, contactRole) => {
    if (actor) {
      const role = mappedRoleToDisplay[contactRole];
      const alreadyMappedContact = mappedContacts.find((contact) => contact.id === actor.id);

      if (alreadyMappedContact) {
        if (role) { alreadyMappedContact.roles.push(role); }
      } else {
        if (role) { actor.roles = [role]; }

        mappedContacts.push(actor);
      }
    }

    return mappedContacts;
  }, []);
  /* eslint-enable no-param-reassign */

  const onStopEditContactActor = () => {
    setIsEditingContactActorId(null);
    setIsEditingContacts(false);
  };

  const contacts = () => (
    <div className="grid sm:grid-cols-3 xs:grid-cols-2 xs:gap-4">
      {
        mappedContactActors.map((contactActor) => {
          if (isEditingContactActorId === contactActor.id) {
            return (
              <UpdateTeamMember
                teamMemberActor={contactActor}
                eventVendorId={eventVendor.id}
                toastRef={toastRef}
                stopEditing={onStopEditContactActor}
                refetchEventVendor={refetchActiveEventVendor}
              />
            );
          }
          return (
            <div className="text-center w-full flex justify-center">
              <div className="h-fit w-fit text-nowrap" onClick={() => (isEditable ? setIsEditingContactActorId(contactActor.id) : {})}>
                <p className="m-0 mt-4 text-gray-800 font-semibold">{contactActor.roles.join(', ')}</p>
                <div className="mt-4 text-sm">
                  <div className="w-full flex justify-center mb-2">
                    <Avatar
                      label={contactActor.initials}
                      image={contactActor.avatarUrl}
                      size="large"
                      shape="circle"
                      className={`text-white ${contactActor.avatarUrl ? '' : 'bg-info'}`}
                    />
                  </div>
                  <p key={`${contactActor.id}-actor-name`}>{ contactActor.name }</p>
                  <p className="text-sm">{ contactActor.actorType === 'TEAM_MEMBER' ? 'Admin' : 'Group' }</p>
                  <p className="mb-2">
                    {
                      emailLink({
                        email: contactActor.email,
                        className: 'text-sm',
                        iconClassName: 'text-sm',
                        showEmpty: isUpdatableByCurrentActor,
                        showIcon: true,
                        shouldLink: !isEditable,
                      })
                    }
                  </p>
                  <p className="mb-2">
                    {
                      phoneLink({
                        phone: contactActor.phoneNumber,
                        className: 'text-sm',
                        iconClassName: 'text-sm',
                        showEmpty: isUpdatableByCurrentActor,
                        showIcon: true,
                        shouldLink: !isEditable,
                      })
                    }
                  </p>
                  {
                    instagramLink({
                      instagramHandle: contactActor.instagramHandle,
                      className: 'text-sm',
                      iconClassName: 'text-sm',
                      showEmpty: isUpdatableByCurrentActor,
                      showIcon: true,
                      shouldLink: !isEditable,
                    })
                  }
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );

  const mappedTeamMembers = () => {
    if (isEmpty(mappedContactActors) && !isUpdatableByCurrentActor) { return null; }

    return (
      <div
        className={`border-t mt-6 border-gray-200 ${isEditable ? 'cursor-pointer' : ''}`}
        onClick={() => setIsEditingContacts(isUpdatableByCurrentActor)}
      >
        <p className="m-0 mt-4 text-lg text-gray-800 font-semibold">Event Team Members</p>
        <div className="flex w-full justify-between items-center">
          <p className="m-0 mt-4 text-gray-800 font-semibold">Contacts</p>
          <Button
            rounded
            className={isEditingContacts ? 'h-6 w-6 cursor-pointer text-xs' : 'hidden'}
            icon="pi pi-plus"
            onClick={() => setShowCreateTeamMemberModal(true)}
          />
        </div>
        {
          (isEditingContacts && !isEditingContactActorId) ? (
            <UpdateEventVendorContacts
              eventVendor={eventVendor}
              refetchEventVendor={refetchActiveEventVendor}
              vendor={vendor}
              toastRef={toastRef}
              setIsEditing={setIsEditingContacts}
              hideOnBlur={!showCreateTeamMemberModal}
            />
          ) : (
            <div>
              {
                isEmpty(mappedContactActors) ? (
                  <p className="text-xs italic mt-2">Add Contacts</p>
                ) : (
                  contacts()
                )
              }
            </div>
          )
        }
      </div>
    );
  };

  const updateEventVendor = (data) => {
    updateEventVendorMutation({
      variables: { input: { id, notes: data.notes, internalNotes: data.internalNotes } },
    });
  };

  const notesField = () => (
    <div className="border-t mt-6 border-gray-200">
      <div className="flex items-center">
        <p className="m-0 my-4 text-lg text-gray-800 font-semibold">Public Notes</p>
        <Tooltip target=".dropdown-tooltip-icon-notes" />
        <i
          className="dropdown-tooltip-icon-notes pi pi-eye ml-2"
          data-pr-tooltip="Visible to everyone"
          data-pr-position="top"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <EditableTextArea
        formats={['bold', 'italic', 'underline', 'strike']}
        name="notes"
        updateFieldName="notes"
        textViewClassName="mb-0"
        updatable
        isEditing={isEditingNotes}
        setIsEditing={setIsEditingNotes}
        onUpdate={updateEventVendor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  const internalNotesField = () => (
    <div className="border-t mt-6 border-gray-200">
      <div className="flex items-center">
        <p className="m-0 my-4 text-lg text-gray-800 font-semibold">Internal Notes</p>
        <Tooltip target=".dropdown-tooltip-icon-internal-notes" />
        <i
          className="dropdown-tooltip-icon-internal-notes pi pi-eye-slash ml-2"
          data-pr-tooltip={`Only visible to ${currentVendor?.name || 'you'}`}
          data-pr-position="top"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <EditableTextArea
        formats={['bold', 'italic', 'underline', 'strike']}
        name="internalNotes"
        updateFieldName="internalNotes"
        textViewClassName="mb-0"
        updatable
        isEditing={isEditingInternalNotes}
        setIsEditing={setIsEditingInternalNotes}
        onUpdate={updateEventVendor}
        control={control}
        setValue={setValue}
        getValues={getValues}
        placeholder="Add notes..."
      />
    </div>
  );

  const onCreateTeamMemberActor = () => {
    setShowCreateTeamMemberModal(false);
    setIsEditingContacts(false);
  };

  const mappedReviewSources = () => (
    vendor.reviewSources.length || isUpdatableByCurrentActor ? (
      <div className="flex items-center">
        <i className="pi pi-star mr-2" />
        <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingReviewSources(isUpdatableByCurrentActor)}>
          {
            vendor.reviewSources.length ? (
              <div className="text-sm">{ vendor.reviewSources.map((reviewSource) => reviewSource.name).join(', ') }</div>
            ) : (
              <div className="text-xs italic">Add Review Sources</div>
            )
          }
        </div>
      </div>
    ) : null
  );

  return (
    <div className="event-vendor-profile" key={`${eventVendor.id}-event-vendor-profile`}>
      <p className="mb-2 text-gray-800 font-semibold">Vendor Info</p>
      {
        isEditingEmail ? (
          <UpdateEventVendorEmail
            eventVendor={eventVendor}
            refetchEventVendor={refetchActiveEventVendor}
            vendor={vendor}
            toastRef={toastRef}
            setIsEditing={setIsEditingEmail}
          />
        ) : (
          <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingEmail(isUpdatableByCurrentActor)}>
            {
              mapInfo({
                data: vendor,
                field: 'email',
                icon: 'envelope',
                className: 'text-default text-sm mb-2',
                shouldLink: !isUpdatableByCurrentActor,
                showEmpty: isUpdatableByCurrentActor,
              })
            }
          </div>
        )
      }
      {
        isEditingPhone ? (
          <UpdateEventVendorPhone
            eventVendor={eventVendor}
            refetchEventVendor={refetchActiveEventVendor}
            vendor={vendor}
            toastRef={toastRef}
            setIsEditing={setIsEditingPhone}
          />
        ) : (
          <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingPhone(isUpdatableByCurrentActor)}>
            {
              mapInfo({
                data: vendor,
                field: 'phoneNumber',
                icon: 'phone',
                className: 'text-default text-sm mb-2',
                shouldLink: !isUpdatableByCurrentActor,
                showEmpty: isUpdatableByCurrentActor,
              })
            }
          </div>
        )
      }
      {
        isEditingInstagram ? (
          <UpdateEventVendorInstagram
            eventVendor={eventVendor}
            refetchEventVendor={refetchActiveEventVendor}
            vendor={vendor}
            toastRef={toastRef}
            setIsEditing={setIsEditingInstagram}
          />
        ) : (
          <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingInstagram(isUpdatableByCurrentActor)}>
            {
              mapInfo({
                data: vendor,
                field: 'instagramHandle',
                icon: 'instagram',
                className: 'text-default text-sm mb-2',
                shouldLink: !isUpdatableByCurrentActor,
                showEmpty: isUpdatableByCurrentActor,
              })
            }
          </div>
        )
      }
      {
        isEditingWebsiteUrl ? (
          <UpdateEventVendorWebsiteUrl
            eventVendor={eventVendor}
            refetchEventVendor={refetchActiveEventVendor}
            vendor={vendor}
            toastRef={toastRef}
            setIsEditing={setIsEditingWebsiteUrl}
          />
        ) : (
          <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingWebsiteUrl(isUpdatableByCurrentActor)}>
            {
              mapInfo({
                data: vendor,
                field: 'websiteUrl',
                icon: 'globe',
                className: 'text-default text-sm mb-2',
                shouldLink: !isUpdatableByCurrentActor,
                showEmpty: isUpdatableByCurrentActor,
              })
            }
          </div>
        )
      }
      {
        isEditingAddress ? (
          <UpdateEventVendorAddress
            eventVendor={eventVendor}
            refetchEventVendor={refetchActiveEventVendor}
            vendor={vendor}
            toastRef={toastRef}
            setIsEditing={setIsEditingAddress}
          />
        ) : (
          <div className={isEditable ? 'cursor-pointer' : ''} onClick={() => setIsEditingAddress(isUpdatableByCurrentActor)}>
            {
              mapInfo({
                data: vendor.address || {},
                field: 'address',
                isFormatted: true,
                icon: 'map-marker',
                className: 'text-default text-sm mb-2',
                shouldLink: !isUpdatableByCurrentActor,
                showEmpty: isUpdatableByCurrentActor,
              })
            }
          </div>
        )
      }
      {
        isEditingReviewSources ? (
          <div className="flex items-center">
            <i className="pi pi-star mr-2" />
            <UpdateEventVendorReviewSources
              eventVendor={eventVendor}
              refetchEventVendor={refetchActiveEventVendor}
              reviewSources={vendor.reviewSources}
              editableReviewSources={currentVendor.editableReviewSources}
              reviewSourceOptions={currentVendor.reviewSourceOptions}
              toastRef={toastRef}
              setIsEditing={setIsEditingReviewSources}
            />
          </div>
        ) : mappedReviewSources()
      }
      { mappedTeamMembers() }
      { notesField() }
      { internalNotesField() }
      <CreateTeamMemberModal
        show={showCreateTeamMemberModal}
        onHide={onCreateTeamMemberActor}
        refetchEventVendor={refetchActiveEventVendor}
        isManagingCurrentVendor={isManagingCurrentVendor}
        eventVendor={eventVendor}
        toastRef={toastRef}
      />
    </div>
  );
}

export default EventVendorProfile;
