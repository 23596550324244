import React from 'react';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router';

function TasksSummaryCard({
  currentActorTaskOverview, currentVendorTaskOverview, currentVendor, eventId,
}) {
  const navigate = useNavigate();

  const unreadMentionsText = (counts) => (
    <div><span className="text-900 font-medium text-xl text-purple-500">{counts.unreadMentionsCount}</span>{' unread mentions'}</div>
  );

  const mapCounts = (counts) => (
    <>
      <div><span className="text-900 font-medium text-xl text-red-500">{counts.overdueCount}</span>{' overdue tasks'}</div>
      <div><span className="text-900 font-medium text-xl text-orange-500">{counts.notStartedCount}</span>{' unstarted tasks'}</div>
      <div><span className="text-900 font-medium text-xl text-blue-500">{counts.inProgressCount}</span>{' in progress tasks'}</div>
    </>
  );

  const mapOverview = (counts) => (
    <div className="flex items-center justify-between mt-2 grid grid-cols-2 gap-16 p-2">
      <div>
        <div className="font-semibold mb-2">Created</div>
        <div className="border-r border-black pr-2">
          { mapCounts(counts.createdBy) }
        </div>
      </div>
      <div>
        <div className="font-semibold mb-2">Assigned</div>
        { mapCounts(counts.assignedTo) }
      </div>
    </div>
  );

  return (
    <Card className="h-full cursor-pointer" onClick={() => navigate(`/app/events/${eventId}/tasks`, { replace: true })}>
      <div className="flex justify-between w-full">
        <div className="font-semibold mb-4">Tasks Summary</div>
        <div className="flex items-center justify-center bg-green-100 rounded h-8 w-8">
          <i className="pi pi-check-square text-green-500 text-xl" />
        </div>
      </div>
      {
        currentVendorTaskOverview ? (
          <div className="block md:flex items-center justify-between grid xxs:grid-cols-1 md:grid-cols-2 gap-16 p-3">
            <div>
              <div className="font-semibold mb-2">You</div>
              <div className="xxs:border-r-0 md:border-r border-black">
                { unreadMentionsText(currentActorTaskOverview) }
                { mapOverview(currentActorTaskOverview) }
              </div>
            </div>
            <div>
              <div className="font-semibold mb-2">{currentVendor?.name}</div>
              { unreadMentionsText(currentVendorTaskOverview) }
              { mapOverview(currentVendorTaskOverview) }
            </div>
          </div>
        ) : (
          <div>
            { unreadMentionsText(currentActorTaskOverview) }
            { mapOverview(currentActorTaskOverview) }
          </div>
        )
      }
    </Card>
  );
}

export default TasksSummaryCard;
