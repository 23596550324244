import gql from 'graphql-tag';

const EVENT_VENDOR_UPDATE_MUTATION = gql`
  mutation UpdateEventVendor($input: EventVendorUpdateMutationInput!) {
    eventVendorUpdate(input: $input) {
      eventVendor {
        id
        notes
        categories
        internalNotes
        vendor {
          id
          avatarUrl
          name
          categories
          address {
            id
            street1
            street2
            city
            usState {
              id
              name
            }
            zipCode
          }
        }
      }
    }
  }
`;

export {
  EVENT_VENDOR_UPDATE_MUTATION,
};
