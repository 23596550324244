import React, { useEffect, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import { FileUpload } from 'primereact/fileupload';
import { Avatar } from 'primereact/avatar';

import { showSuccessToast, showErrorToast } from 'utils/toastUtils';
import TextInput from 'components/Form/TextInput';
import { isSubmittableArea } from 'utils/formUtils';
import { EVENT_HOST_ACTOR_UPDATE_MUTATION } from '../graphql';

function UpdateEventHostActorBasicInfo({
  toastRef, eventHostActor, setIsEditing, refetch,
}) {
  const { hostActor } = eventHostActor;
  const formRef = useRef();

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      avatarUrl: hostActor.avatarUrl,
      firstName: hostActor.firstName,
      lastName: hostActor.lastName,
    },
  });

  const [updateEventHostActorMutation] = useMutation(EVENT_HOST_ACTOR_UPDATE_MUTATION, {
    onCompleted: ({ eventHostActorUpdate }) => {
      setValue('avatarUrl', eventHostActorUpdate.eventHostActor.hostActor.avatarUrl);
      refetch();
      showSuccessToast(toastRef, `Successfully updated hostActor ${eventHostActorUpdate.eventHostActor.hostActor.name}`);
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors.map(({ message }) => (
        showErrorToast(toastRef, `Error updating hostActor: ${message}`)
      ));
    },
  });

  useEffect(() => {
    const handleBlur = async (e) => {
      if (isSubmittableArea(formRef, e)) {
        if (isDirty) {
          await updateEventHostActorMutation({
            variables: {
              input: {
                id: eventHostActor.id,
                hostActorFields: {
                  firstName: getValues('firstName'),
                  lastName: getValues('lastName'),
                },
              },
            },
          });
        }

        setIsEditing(false);
      }
    };

    document.addEventListener('mousedown', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isDirty]);

  const avatarUpload = () => (
    <div className="flex flex-col">
      <Avatar image={watch('avatarUrl')} size="xlarge" shape="circle" className="w-52 h-52" />
      <div className="flex w-full justify-center">
        <FileUpload
          mode="basic"
          chooseOptions={{ icon: 'pi pi-pencil', iconOnly: true, className: '-mt-4 border-circle p-3 button-icon-only' }}
          onSelect={({ files }) => (
            updateEventHostActorMutation({ variables: { input: { id: eventHostActor.id, hostActorFields: { avatar: files[0] } } } })
          )}
        />
      </div>
    </div>
  );

  return (
    <div className="mt-4 flex w-1/2 text-sm text-default">
      <div ref={formRef} className="mt-4 flex items-center w-full">
        <div className="mr-4">
          { avatarUpload() }
        </div>
        <div className="w-full">
          <TextInput
            control={control}
            name="firstName"
            label="First Name"
          />
          <TextInput
            control={control}
            name="lastName"
            label="Last Name"
          />
        </div>
      </div>
    </div>
  );
}

export default UpdateEventHostActorBasicInfo;
