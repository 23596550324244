import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import flatMap from 'lodash/flatMap';
import GroupedDropdown from 'components/Form/GroupedDropdown';

function Assignee({
  actorOptions, actorItem, taskId, actorItemTemplate, assignedToActorId, isEditing, setIsEditing, updateTask, control,
}) {
  const tooltip = 'A vendor needs a default task assignee to be assigned to';

  if (isEditing) {
    return (
      <>
        <div className="flex items-center justify-end">
          <i className="pi pi-user text-xs mr-1" /><p className="text-bluegray-500">Assigned To</p>
          <Tooltip target=".assignee-item" />
          <i
            className="pi pi-info-circle assignee-item text-xs ml-1"
            data-pr-tooltip={tooltip}
            data-pr-position="top"
          />
        </div>
        <div className="flex items-center justify-end cursor-pointer">
          <GroupedDropdown
            control={control}
            name="assignedToActorId"
            className="p-0 m-0 text-xs w-full"
            options={actorOptions}
            inputProps={{
              placeholder: 'Select an assignee',
              itemTemplate: (option) => actorItemTemplate({ option }),
              valueTemplate: (option) => actorItemTemplate({
                option,
                showClear: true,
                onClear: () => updateTask({ id: taskId, assignedToActorId: null }),
              }),
              onChange: ({ value }) => updateTask({ id: taskId, assignedToActorId: value }),
            }}
          />
          <i className="pi pi-times text-xs ml-1 cursor-pointer" onClick={() => setIsEditing(false)} />
        </div>
      </>
    );
  }

  return (
    actorItem({
      actor: flatMap(actorOptions, 'items').find((option) => assignedToActorId === option.value),
      label: 'Assigned To',
      tooltip,
      updateAction: () => setIsEditing(true),
    })
  );
}

export default Assignee;
