import React from 'react';
import reduce from 'lodash/reduce';
import flatMap from 'lodash/flatMap';

import AvatarWithName from 'components/AvatarWithName';

const mapOptions = (options) => (options[0].items ? flatMap(options, 'items') : options);
const findOption = ({ options, value }) => mapOptions(options).find((option) => option.value === value);
const actorItemTemplate = ({
  option, placeholder = 'Select...', showClear = false, onClear = () => {},
}) => {
  if (option) {
    return (
      <div className="flex items-center justify-between">
        <AvatarWithName
          id={option.id}
          initials={option.initials}
          avatarUrl={option.avatarUrl}
          text={option.label}
        />
        { showClear && <i className="pi pi-times text-xs ml-1" onClick={onClear} /> }
      </div>
    );
  }
  return <span>{placeholder}</span>;
};
const selectedActorItemTemplate = ({ options, value, placeholder = 'Select...' }) => {
  if (!value) { return placeholder; }

  const option = findOption({ options, value });

  return (
    <AvatarWithName
      className="text-xs bg-secondary text-white py-1 px-2 mb-1 mr-1"
      avatarClassName="mr-1 text-xs bg-white text-secondary"
      id={option.id}
      initials={option.initials}
      avatarUrl={option.avatarUrl}
      text={option.label}
      size="small"
    />
  );
};

const renderFormFieldError = (error) => {
  if (!error) { return null; }

  let errorMessage = '';
  switch (error.type) {
    case 'required':
      errorMessage = error.message || 'This is required';
      break;
    case 'pattern':
      errorMessage = error.message || 'This is not valid';
      break;
    case 'validate':
      errorMessage = error.message || 'This is not valid';
      break;
    default:
      break;
  }

  return <p className="text-danger mt-2">{errorMessage}</p>;
};

const clickableTarget = (targetClassName = '') => (
  String(targetClassName).toLowerCase().match('dropdown|checkbox|button|multiselect|avatar|mask|svg|header|tag')
);

const isSubmittableArea = (formRef, e) => {
  const targetClassName = String(e.target.className).toLowerCase();

  return (
    targetClassName.match('submittable-area') || (
      formRef.current
      && !formRef.current.contains(e.target)
      && !!targetClassName && !clickableTarget(targetClassName)
    )
  );
};

const mapSelectOptions = ({ data, labelFieldName = 'name', valueFieldName = 'id' }) => (
  reduce(data, (mappedData, dataItem) => {
    mappedData.push({ label: dataItem[labelFieldName], value: dataItem[valueFieldName] });
    return mappedData;
  }, [])
);

export {
  renderFormFieldError,
  mapSelectOptions,
  actorItemTemplate,
  isSubmittableArea,
  clickableTarget,
  selectedActorItemTemplate,
};
