import { useEffect } from 'react';
import { logoutUser } from 'auth/api';

function Logout({ clearCurrentUserData }) {
  useEffect(() => {
    logoutUser();
    clearCurrentUserData();
  }, []);

  return null;
}

Logout.defaultProps = {};

Logout.propTypes = {};

export default Logout;
