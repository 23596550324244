import gql from 'graphql-tag';

const SELECT_VENDOR_MUTATION = gql`
  mutation SelectVendor($input: VendorSelectMutationInput!) {
    vendorSelect(input: $input) {
      currentVendor {
        id
      }
    }
  }
`;

const VENDORS_QUERY = gql`
  query Vendors {
    currentUser {
      id
      employerVendors {
        id
        name
      }
    }
  }
`;

export {
  SELECT_VENDOR_MUTATION,
  VENDORS_QUERY,
};
