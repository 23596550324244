import gql from 'graphql-tag';

const HOSTS_SEARCH_QUERY = gql`
  query Hosts($filters: HostActorFilterInput!) {
    hostActorsSearch(filters: $filters) {
      id
      name
    }
  }
`;

const HOST_ACTOR_CREATE_MUTATION = gql`
  mutation CreateHostActor($input: HostActorCreateMutationInput!) {
    hostActorCreate(input: $input) {
      hostActor {
        id
        name
      }
    }
  }
`;

export {
  HOST_ACTOR_CREATE_MUTATION,
  HOSTS_SEARCH_QUERY,
};
